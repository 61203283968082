import RequestAccountQuery from 'Query/RequestAccount.query';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isUserCanCreateAccount: state.SaasConfigReducer.config?.user_can_create_account ?? true,
        isCocFieldExcludedFromForm: state.SaasConfigReducer.config?.register_form_exclude_coc_field ?? false
    };
};

export class MyAccountCreateAccountContainer {
    containerProps = (args, callback, instance) => {
        const { isCocFieldExcludedFromForm, isUserCanCreateAccount } = instance.props;

        return {
            ...callback(...args),
            isCocFieldExcludedFromForm,
            isUserCanCreateAccount
        };
    };

    onCreateAccountSuccess = async (args, callback, instance) => {
        const {
            isLoading,
            isUserCanCreateAccount,
            setLoadingState,
            showNotification
        } = instance.props;

        if (isUserCanCreateAccount) {
            callback.apply(instance, args);

            return;
        }

        if (isLoading) {
            return;
        }

        const [fields] = args;
        const companyData = {
            ...fields
        };

        fetchMutation(RequestAccountQuery.getMutation(companyData)).then(
            () => {
                showNotification('success', __('Your request has been sent to 1 of our administrators'));

                history.push(appendWithStoreCode('/'));
            },
            (error) => showNotification('error', error[0].message)
        ).finally(
            () => setLoadingState(false)
        );
    };
}

const {
    containerProps,
    onCreateAccountSuccess
} = new MyAccountCreateAccountContainer();

export default {
    'Component/MyAccountCreateAccount/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            containerProps,
            onCreateAccountSuccess
        }
    }
};

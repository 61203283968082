export const ROUTE_FORGOT_PASSWORD = '/account/forgotpassword';
export const ROUTE_LOGIN = '/account/login';
export const ROUTE_REGISTER = '/account/create';
export const COOKIE_POLICY = '/cookie-policy';

export const NOT_REDIRECTING_ROUTES = [
    ROUTE_FORGOT_PASSWORD,
    ROUTE_LOGIN,
    ROUTE_REGISTER,
    COOKIE_POLICY
];

export const ROUTE_BLOG = 'blog';
export const ROUTE_RECIPES = 'recipes';
export const ROUTE_STORES = 'stores';

export const AVAILABLE_BLOG_ROUTES = [
    ROUTE_BLOG,
    ROUTE_RECIPES
];

/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateDeliveryDate } from 'Store/OrderDate/OrderDate.action';
import { customerType } from 'Type/Account';
import { MixType } from 'Type/Common';
import { ExpectedDeliveryType } from 'Type/Store';
import {
    getAvailableDateAndTimeRange,
    getAvailableOrderDates
} from 'Util/OrderDate';
import SaasDate from 'Util/Saas';

import OrderDateSelect from './OrderDateSelect.component';

/** @namespace Pwasaas/Component/OrderDateSelect/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    daysToDeliver: state.SaasConfigReducer.config?.days_to_deliver,
    expectedDelivery: state.SaasConfigReducer.expectedDelivery,
    holidays: state.SaasConfigReducer.holidays,
    isCustomerEnabled: state.SaasConfigReducer.config?.enable_customer_related || false,
    isShowTime: state.SaasConfigReducer.config?.show_time || '0',
    orderDates: state.SaasConfigReducer.orderDates,
    timeToPreparation: state.SaasConfigReducer.config?.time_to_preparation,
    selectedDate: state.OrderDateReducer.selectedDate,
    timeInterval: state.SaasConfigReducer.config?.time_interval,
    timeToDeliver: state.SaasConfigReducer.config?.time_to_deliver,
    pickupShippingMethod: state.SaasConfigReducer.config?.pickup_shipping_method,
    deliveryShippingMethod: state.SaasConfigReducer.config?.delivery_shipping_method
});

/** @namespace Pwasaas/Component/OrderDateSelect/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateDeliveryDate: (value) => dispatch(updateDeliveryDate(value))
});

/** @namespace Pwasaas/Component/OrderDateSelect/Container/OrderDateSelectContainer */
export class OrderDateSelectContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        daysToDeliver: PropTypes.number.isRequired,
        expectedDelivery: ExpectedDeliveryType.isRequired,
        holidays: PropTypes.arrayOf(PropTypes.shape({
            content: PropTypes.string,
            date: PropTypes.string,
            repeat: true
        })).isRequired,
        mix: MixType,
        isCustomerEnabled: PropTypes.bool.isRequired,
        // TODO: Add orderDates as custom PropType
        orderDates: PropTypes.shape({}).isRequired,
        timeToPreparation: PropTypes.number.isRequired,
        selectedDate: PropTypes.shape({
            selectedDay: PropTypes.string,
            selectedTime: PropTypes.string
        }).isRequired,
        selectedShippingMethodCode: PropTypes.string,
        isShowTime: PropTypes.string.isRequired,
        timeToDeliver: PropTypes.number.isRequired,
        timeInterval: PropTypes.number.isRequired,
        updateDeliveryDate: PropTypes.func.isRequired,
        deliveryShippingMethod: PropTypes.string.isRequired,
        pickupShippingMethod: PropTypes.string.isRequired
    };

    static defaultProps = {
        mix: {},
        selectedShippingMethodCode: ''
    };

    containerFunctions = {
        handleSetDeliveryDay: this.handleSetDeliveryDay.bind(this),
        handleSetDeliveryTime: this.handleSetDeliveryTime.bind(this)
    };

    componentDidUpdate(prevProps) {
        const {
            selectedDate: { selectedDay: prevDay },
            selectedShippingMethodCode: prevSelectedMethod
        } = prevProps;

        const {
            selectedDate: { selectedDay = '' },
            selectedShippingMethodCode
        } = this.props;

        if (selectedShippingMethodCode !== prevSelectedMethod || prevDay !== selectedDay) {
            const dateRange = this._getAvailableDeliveryDates();

            if (!dateRange.length) {
                return;
            }

            const [{ timeRange = [] }] = dateRange;

            if (!timeRange.length) {
                return;
            }

            const [{ value }] = timeRange;

            this.handleSetDeliveryTime(value);
        }
    }

    containerProps = () => {
        const {
            selectedDate,
            isShowTime,
            mix
        } = this.props;

        return {
            availableDateRange: this._getAvailableDeliveryDates(),
            headingTitle: this._getHeadingTitle(),
            selectedDate,
            isShowTime: isShowTime !== '0',
            mix
        };
    };

    _getAvailableDeliveryDates() {
        const {
            customer: {
                orderDates: customerOrderDates = {}
            },
            daysToDeliver,
            expectedDelivery: {
                all = []
            },
            holidays = [],
            orderDates = {},
            timeToPreparation,
            timeToDeliver,
            timeInterval
        } = this.props;

        const dates = this._getIsCustomerEnabled()
            ? customerOrderDates
            : orderDates;

        const cleanDates = JSON.parse(JSON.stringify(dates));

        if (this.isDelivery()) {
            Object.keys(cleanDates).forEach((index) => {
                if (!all.includes(index)) {
                    cleanDates[index] = null;
                }
            });
        }

        const availableDates = getAvailableOrderDates(
            cleanDates,
            holidays
        );

        const timeToAddInMinutes = this.isDelivery()
            ? timeToDeliver + timeToPreparation
            : timeToPreparation;

        const availableDateAndTimeRange = getAvailableDateAndTimeRange(
            availableDates,
            timeInterval,
            new SaasDate().setTimeFormat('HH:mm:ss').addMinutes(timeToAddInMinutes).getTime(),
            this.isDelivery(),
            timeToDeliver
        );

        if (this.isDelivery()) {
            availableDateAndTimeRange.splice(0, daysToDeliver);
        }

        return availableDateAndTimeRange;
    }

    isDelivery() {
        const {
            deliveryShippingMethod,
            pickupShippingMethod,
            selectedShippingMethodCode
        } = this.props;

        if (selectedShippingMethodCode === pickupShippingMethod) {
            return false;
        }

        if (selectedShippingMethodCode === deliveryShippingMethod) {
            return true;
        }

        return false;
    }

    _getHeadingTitle() {
        return this.isDelivery()
            ? __('Select delivery date')
            : __('Select pick-up time');
    }

    _getIsCustomerEnabled() {
        const {
            customer: {
                orderDates: customerOrderDates = {}
            },
            isCustomerEnabled
        } = this.props;

        return isCustomerEnabled && Object.keys(customerOrderDates).length;
    }

    handleSetDeliveryDay(value) {
        const {
            selectedDate,
            updateDeliveryDate
        } = this.props;

        const dateValue = {
            ...selectedDate,
            selectedDay: value
        };

        updateDeliveryDate(dateValue);
    }

    handleSetDeliveryTime(value) {
        const {
            selectedDate,
            updateDeliveryDate
        } = this.props;

        const dateTimeValue = {
            ...selectedDate,
            selectedTime: value
        };

        updateDeliveryDate(dateTimeValue);
    }

    render() {
        return (
            <OrderDateSelect
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDateSelectContainer);

import { cloneElement } from 'react';

export class AddAllPropsToReplaceWidgetMethodPlugin {
    replaceWidget = (args, callback, instance) => {
        const RenderedComponent = callback(instance, args);
        const { props } = RenderedComponent;

        return cloneElement(RenderedComponent, {
            ...instance.props,
            ...props
        });
    };
}

const {
    replaceWidget
} = new AddAllPropsToReplaceWidgetMethodPlugin();

export default {
    'Component/Html/Component': {
        'member-function': {
            replaceWidget
        }
    }
};

/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
// TODO: When the text is comming from the admin settings, this eslint-disable can be removed aswell
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { MixType } from 'Type/Common';

/** @namespace Pwasaas/Component/StoreLocatorSelect/Component/StoreLocatorSelectComponent */
export class StoreLocatorSelectComponent extends PureComponent {
    static propTypes = {
        getDefaultValues: PropTypes.func.isRequired,
        handleSetPickupStore: PropTypes.func.isRequired,
        mix: MixType,
        pickupStore: PropTypes.number.isRequired,
        storeSelectList: PropTypes.shape([]).isRequired
    };

    static defaultProps = {
        mix: {}
    };

    renderCheckoutPickupSelect() {
        const {
            getDefaultValues,
            handleSetPickupStore,
            pickupStore,
            storeSelectList
        } = this.props;

        const fieldMap = {};

        fieldMap.pickup_store = {
            type: 'select',
            label: __('Your pickup store'),
            validation: ['notEmpty'],
            value: pickupStore,
            selectOptions: storeSelectList,
            sortBy: false,
            onChange: (value) => handleSetPickupStore(value)
        };

        const fieldObject = Object.entries(fieldMap).map((field) => getDefaultValues(field));

        return (
            <>
                <h2
                  block="Checkout"
                  elem="Heading"
                >
                    { __('Select pickup store') }
                </h2>
                <p block="CheckoutDeliveryOption" elem="Explanation">
                    { __('The store you select below is your service point for returns and complaints. You can also choose to pick up your order in this store. With over 100 stores, there is always one nearby!') }
                </p>
                <div
                  block="CheckoutDeliveryOption"
                  elem="PickupStore"
                >
                    <Field { ...fieldObject[0] } />
                </div>
            </>
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <div block="StoreLocatorSelect" mix={ mix }>
                { this.renderCheckoutPickupSelect() }
            </div>
        );
    }
}

export default StoreLocatorSelectComponent;

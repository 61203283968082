export class StoreSwitcherComponent {
    render = (args, callback, instance) => {
        const { isShowStoreSwitcher, storeList } = instance.props;

        if (!isShowStoreSwitcher || storeList.length <= 1) {
            return null;
        }

        return instance.renderDesktopStoreSwitcher();
    };
}

const {
    render
} = new StoreSwitcherComponent();

export default {
    'Component/StoreSwitcher/Component': {
        'member-function': {
            render
        }
    }
};

import { showNotification } from 'Store/Notification/Notification.action';
import { getStore } from 'Util/Store';

export const SaasConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasConfig/SaasConfig.dispatcher'
);

export const saveMasterAccountAfter = (args, callback, instance) => {
    const { dispatch, getState } = getStore();
    const { SaasConfigReducer: { masterAccount } } = getState();

    if (!masterAccount) {
        callback.apply(instance, args);

        return;
    }

    SaasConfigDispatcher.then(
        ({ default: dispatcher }) => dispatcher.saveOrderAccountManager(masterAccount).then(
            /** @namespace Pwasaas/Plugin/Feature/StoreLocator/StoreLocatorPluginStore/savePickupStore/then */
            () => callback.apply(instance, args),
            /** @namespace Pwasaas/Plugin/Feature/StoreLocator/StoreLocatorPluginStore/savePickupStore/then */
            (error) => dispatch(showNotification('error', 'Error saving master account!', error))
        )
    );
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: saveMasterAccountAfter
        }
    }
};

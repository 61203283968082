/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import DatePicker from 'react-datepicker';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM } from 'Component/ChevronIcon/ChevronIcon.config';

import 'react-datepicker/dist/react-datepicker.min.css';
import './SaasDatepicker.style';

/** @namespace Pwasaas/Component/SaasDatepicker/Component/SaasDatepickerComponent */
export class SaasDatepickerComponent extends PureComponent {
    static propTypes = {
        availableDates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        dateFormat: PropTypes.string.isRequired,
        locale: PropTypes.shape({
            code: PropTypes.string
        }).isRequired,
        placeholder: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.string
        ]).isRequired,
        customInput: PropTypes.shape({}).isRequired,
        handleOnChange: PropTypes.func.isRequired,
        selectedDate: PropTypes.string.isRequired
    };

    render() {
        const {
            availableDates = [],
            dateFormat,
            selectedDate,
            handleOnChange,
            locale: { code = {} },
            placeholder,
            customInput
        } = this.props;

        return (
            <div block="SaasDatepicker">
                <DatePicker
                  customInput={ customInput }
                  dateFormat={ dateFormat }
                  includeDates={ availableDates }
                  locale={ code }
                  onSelect={ handleOnChange }
                  placeholderText={ placeholder }
                  selected={ selectedDate }
                />
                <ChevronIcon direction={ BOTTOM } />
            </div>
        );
    }
}

export default SaasDatepickerComponent;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link } from 'react-scroll';

import { MixType } from 'Type/Common';

/** @namespace Pwasaas/Component/SaasScrollToLink/Component/SaasScrollToLinkComponent */
export class SaasScrollToLinkComponent extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        offset: PropTypes.number,
        duration: PropTypes.number,
        isSmooth: PropTypes.bool,
        mix: MixType,
        to: PropTypes.string.isRequired
    };

    static defaultProps = {
        offset: 100,
        duration: 500,
        isSmooth: true,
        mix: {}
    };

    render() {
        const {
            name,
            offset,
            duration,
            isSmooth,
            mix,
            to
        } = this.props;

        return (
            <div
              block="SaasScrollToLink"
              mix={ mix }
            >
              <Link
                to={ to }
                key={ name }
                smooth={ isSmooth }
                offset={ offset }
                duration={ duration }
              >
                { name }
              </Link>
            </div>
        );
    }
}

export default SaasScrollToLinkComponent;

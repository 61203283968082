/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import PageHeader from 'Component/PageHeader';
import { withStoreRegex } from 'Component/Router/Router.component';
import {
    BEFORE_ITEMS_TYPE,
    NAVIGATION_TABS,
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
import { ROUTE_STORES } from 'Component/Router/Router.custom.config';
import StoreNotice from 'Component/StoreNotice';
import { getCustomRoute } from 'Util/Saas';

export const StoreListPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "storelist" */ 'Route/StoreListPage'
));

export const StoreDetailPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "storedetail" */ 'Route/StoreDetailPage'
));

export const SuccessPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "success" */ 'Route/SuccessPage'
));

/** @namespace Pwasaas/Component/Router/Component/RouterComponent */
export class RouterComponent {
    beforeItemsType = (originalMember) => [
        {
            component: <StoreNotice />,
            position: 14
        },
        {
            component: <PageHeader />,
            position: 31
        },
        ...originalMember.filter(({ name }) => name !== NAVIGATION_TABS)
    ];

    switchItemsType = (originalMember) => [
        ...originalMember,
        {
            component: <Route
              path={ withStoreRegex('/order/success') }
              render={ (props) => <SuccessPage { ...props } /> }
            />,
            position: 84
        },
        {
            component: <Route
              exact
              path={ withStoreRegex(`/${getCustomRoute(ROUTE_STORES)}`) }
              render={ (props) => <StoreListPage { ...props } /> }
            />,
            position: 88
        },
        {
            component: <Route
              path={ withStoreRegex(`${getCustomRoute(ROUTE_STORES)}/:storeUrl?`) }
              render={ (props) => <StoreDetailPage { ...props } /> }
            />,
            position: 89
        }
    ];
}

const {
    beforeItemsType,
    switchItemsType
} = new RouterComponent();

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: beforeItemsType,
            [SWITCH_ITEMS_TYPE]: switchItemsType
        }
    }
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isCustomerNoteEnabled: state.SaasConfigReducer.config?.customer_notes_enabled_in_checkout
    };
};

export class CheckoutDeliveryOptionsContainer {
    containerFunctions = (originalMap, instance) => ({
        ...originalMap,
        handleCustomerNote: this.onChangeCustomerNote.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const {
            isCustomerNoteEnabled,
            handleCustomerNote
        } = instance.props;

        return {
            ...callback(...args),
            isCustomerNoteEnabled,
            handleCustomerNote
        };
    };

    onChangeCustomerNote(value) {
        const { handleCustomerNote } = this.props;

        handleCustomerNote(value);
    }
}

const {
    containerFunctions,
    containerProps
} = new CheckoutDeliveryOptionsContainer();

export default {
    'Component/CheckoutDeliveryOptions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutDeliveryOptions/Container': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions
        }
    }
};

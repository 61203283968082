import MyAccountLoginAsCustomer from 'Component/MyAccountLoginAsCustomer';
import {
    CUSTOMER_GROUP_MASTER,
    LOGIN_AS_CUSTOMER
} from 'Component/MyAccountLoginAsCustomer/MyAccountLoginAsCustomer.config';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

export const getCustomerIsGroupMaster = () => {
    if (!isSignedIn()) {
        return false;
    }

    const { group_name = '' } = BrowserDatabase.getItem(CUSTOMER) || {};

    return group_name === CUSTOMER_GROUP_MASTER;
};

export const tabMap = (originalTabMap) => {
    const tabMap = getCustomerIsGroupMaster()
        ? {
            ...originalTabMap,
            [LOGIN_AS_CUSTOMER]: {
                url: '/login-as-customer',
                name: __('Login as Customer')
            }
        }
        : originalTabMap;

    return tabMap;
};

export const renderMap = (originalRenderMap) => {
    const renderMap = getCustomerIsGroupMaster()
        ? {
            ...originalRenderMap,
            [LOGIN_AS_CUSTOMER]: MyAccountLoginAsCustomer
        }
        : originalRenderMap;

    return renderMap;
};

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap
        }
    }
};

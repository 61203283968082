import PropTypes from 'prop-types';

export class FieldContainer {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        isMultipliable: PropTypes.bool,
        isWithInputWrapper: PropTypes.bool,
        onHandleMultiple: PropTypes.func,
        position: PropTypes.number
    });

    defaultProps = (originalProps) => ({
        ...originalProps,
        isMultipliable: false,
        isWithInputWrapper: false,
        onHandleMultiply: () => {},
        position: null
    });

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        handleMultiplyField: this.handleMultiplyField.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const {
            isMultipliable,
            isWithInputWrapper,
            position
        } = instance.props;

        return {
            ...callback(...args),
            isMultipliable,
            isWithInputWrapper,
            position
        };
    };

    handleMultiplyField(event, field) {
        const { onHandleMultiply } = this.props;

        onHandleMultiply(event, field);
    }
}

const {
    containerFunctions,
    containerProps,
    defaultProps,
    staticPropTypes
} = new FieldContainer();

export default {
    'Component/Field/Container': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions
        },
        'static-member': {
            propTypes: staticPropTypes,
            defaultProps
        }
    }
};

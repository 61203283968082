export const getIndexedAttributes = (args, callback) => {
    const [attributes] = args;
    const attributesToSort = !Array.isArray(attributes)
        ? [attributes]
        : attributes;

    return callback(attributesToSort.sort(
        ({ attribute_position: posA }, { attribute_position: posB }) => {
            if (posA === posB) {
                return 0;
            }

            return posA > posB ? 1 : -1;
        }
    ));
};

export default {
    'Util/Product/getIndexedAttributes': {
        function: getIndexedAttributes
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/SaasMenu';

import './MenuButton.style';

/** @namespace Pwasaas/Component/MenuButton/Component/MenuButtonComponent */
export class MenuButtonComponent extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        handleKeyDown: PropTypes.func.isRequired,
        handleToggle: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        item: MenuItemType.isRequired
    };

    render() {
        const {
            device,
            handleKeyDown,
            handleToggle,
            isActive,
            item: { title }
        } = this.props;

        const toggled = device.isMobile ? false : isActive;

        return (
            <div
              block="MenuButton"
              mods={ { isActive: toggled } }
              onClick={ handleToggle }
              onKeyDown={ handleKeyDown }
              tabIndex="0"
              role="button"
            >
                <div
                  block="MenuButton"
                  elem="Inner"
                >
                    <span />
                    <span />
                    <span />
                    { title ?? __('Menu') }
                </div>
            </div>
        );
    }
}

export default MenuButtonComponent;

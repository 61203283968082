/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AddProductsToCartQuery from 'Query/AddProductsToCartQuery.query';
import CartQuery from 'Query/Cart.query';
import CustomerCartQuery from 'Query/CustomerCart.query';
import ProductListQuery from 'Query/ProductList.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateInfoLoadStatus } from 'Store/ProductListInfo/ProductListInfo.action';
import { MixType } from 'Type/Common';
import { PagesType } from 'Type/ProductList';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import AddAllToCart from './AddAllToCart.component';
import {
    PRODUCT_TYPE_SIMPLE,
    QTY_IN_MAGAZINE,
    RESULT_MAX_PAGE_SIZE
} from './AddAllToCart.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Pwasaas/Component/AddAllToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => {
    const { show_category_order_button: isAddAllToCart = false } = state.CategoryReducer.category;
    const {
        defaultQty = {},
        quantity_set: quantitySet = []
    } = state.SaasConfigReducer.product || {};

    return {
        categoryId: state.CategoryReducer.category.id,
        defaultQty,
        isAddAllToCart,
        quantitySet
    };
};

/** @namespace Pwasaas/Component/AddAllToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateLoadStatus: (isLoading) => dispatch(updateInfoLoadStatus(isLoading))
});

/** @namespace Pwasaas/Component/AddAllToCart/Container/AddAllToCartContainer */
export class AddAllToCartContainer extends DataContainer {
    static propTypes = {
        addProductToCart: PropTypes.func.isRequired,
        defaultQty: PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.string,
            value: PropTypes.number
        }).isRequired,
        isAddAllToCart: PropTypes.number,
        isDisabled: PropTypes.bool,
        mix: MixType,
        pages: PagesType.isRequired,
        quantitySet: PropTypes.arrayOf({
            label: PropTypes.string,
            id: PropTypes.string,
            value: PropTypes.number
        }).isRequired,
        showNotification: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired
    };

    static defaultProps = {
        isAddAllToCart: 0,
        isDisabled: false,
        mix: {}
    };

    containerFunctions = {
        addAllToCart: this.addAllToCart.bind(this)
    };

    containerProps = () => {
        const {
            categoryId,
            isAddAllToCart: isVisible,
            isDisabled,
            mix
        } = this.props;

        return {
            isActive: !isDisabled && categoryId,
            isVisible: !!isVisible,
            mix
        };
    };

    _createEmptyCart() {
        return fetchMutation(CartQuery.getCreateEmptyCartMutation()).then(
            /** @namespace Pwasaas/Component/AddAllToCart/Container/fetchMutation/then */
            ({ createEmptyCart }) => setGuestQuoteId(createEmptyCart),
            /** @namespace Pwasaas/Component/AddAllToCart/Container/fetchMutation/then */
            (error) => showNotification('error', error[0].message)
        );
    }

    _getProductsToAdd(items, defaultQty) {
        return items.reduce(
            (acc, product) => {
                const {
                    attributes,
                    sku,
                    type_id
                } = product;

                if (type_id !== PRODUCT_TYPE_SIMPLE) {
                    return acc;
                }

                const { attribute_value } = attributes.find(
                    ({ attribute_code }) => attribute_code === QTY_IN_MAGAZINE
                ) || {};

                const quantity = Number(attribute_value || defaultQty);

                /** Add complete first product */
                if (!acc[0]) {
                    return [{
                        product,
                        quantity
                    }];
                }

                return [
                    ...acc,
                    {
                        sku,
                        quantity
                    }
                ];
            }, []
        );
    }

    async addAllToCart() {
        const {
            defaultQty: {
                value
            } = {},
            quantitySet = [],
            showNotification,
            updateLoadStatus
        } = this.props;

        const quantity = value || quantitySet.length
            ? quantitySet[0].value
            : 1;

        updateLoadStatus(true);

        this.fetchData(
            [ProductListQuery.getQuery(this._getFilterOptions())],
            async ({ products: { items = [] } }) => {
                const productsToAdd = this._getProductsToAdd(items, quantity);
                const [firstProduct, ...otherProducts] = productsToAdd;

                if (!getGuestQuoteId()) {
                    await this._createEmptyCart();
                }

                this._addCartProducts(firstProduct, otherProducts);
            },
            () => {
                showNotification('error', __('Something went wrong while adding products to cart!'));
                updateLoadStatus(false);
            }
        );
    }

    _addCartProducts(firstProduct, products) {
        const {
            addProductToCart,
            showNotification,
            updateLoadStatus
        } = this.props;

        this.fetchData(
            [CustomerCartQuery.getCustomerCart()],
            ({ customerCart: { id } }) => {
                if (id) {
                    this._addProductsToCart(id, products).then(
                        /** @namespace Pwasaas/Component/AddAllToCart/Container/_addProductsToCart/then */
                        () => {
                            /** Add the first complete product to cart to update all prices */
                            addProductToCart(firstProduct);

                            showNotification('success', __('Products added to cart!'));
                        }
                    ).catch(
                        /** @namespace Pwasaas/Component/AddAllToCart/Container/_addProductsToCart/then/catch */
                        () => {
                            showNotification('error', __('Something went wrong while adding products to cart!'));
                        }
                    ).finally(
                        () => {
                            updateLoadStatus(false);
                        }
                    );
                }
            }
        );
    }

    async _addProductsToCart(cartId, cartItems) {
        await fetchMutation(AddProductsToCartQuery.getAddProductsToCartMutation(cartId, cartItems));
    }

    _getFilterOptions() {
        const { categoryId } = this.props;

        return {
            args: {
                filter: {
                    categoryIds: categoryId
                },
                pageSize: RESULT_MAX_PAGE_SIZE
            }
        };
    }

    render() {
        return (
            <AddAllToCart
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAllToCartContainer);

import { CHECKOUT_BUTTON_PORTAL } from 'Component/CheckoutShipping/CheckoutShipping.config';

export const INTERVAL_IN_MS = 50;

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        device: state.ConfigReducer.device
    };
};

export const containerProps = (args, callback, instance) => {
    const { device } = instance.props;
    const { buttonPortal } = instance.state;

    return {
        ...callback(...args),
        device,
        buttonPortal
    };
};

export class AddDeviceAndPortalToCheckoutShippingPlugin {
    componentDidMount = (args, callback, instance) => {
        const _this = setInterval(() => {
            const portal = document.getElementById(CHECKOUT_BUTTON_PORTAL);

            if (portal) {
                clearInterval(_this);

                instance.setState({ buttonPortal: portal });
            }
        }, INTERVAL_IN_MS);

        return callback(...args);
    };

    state = (originalMember) => ({
        ...originalMember,
        buttonPortal: null
    });
}

const {
    componentDidMount,
    state
} = new AddDeviceAndPortalToCheckoutShippingPlugin();

export default {
    'Component/CheckoutShipping/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            componentDidMount,
            containerProps
        },
        'member-property': {
            state
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidMount,
            containerProps
        },
        'member-property': {
            state
        }
    }
};

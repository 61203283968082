import { showPopup } from 'Store/Popup/Popup.action';

import { IN_BACKORDER_LABEL } from '../../ShowProductStock/component/ProductStock/ProductStock.config';
import { EXCEED_STOCK_POPUP_ID } from '../component/ExceedStockPopup/ExceedStockPopup.config';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    const {
        show_exceed_qty_popup,
        exceed_qty_content
    } = state.SaasConfigReducer.product;

    return {
        ...callback(state),
        show_exceed_qty_popup,
        exceed_qty_content
    };
};
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        showPopup: (payload) => dispatch(showPopup(EXCEED_STOCK_POPUP_ID, payload))
    };
};

class CartItemChangeQuantityPlugin {
    toggleExceedQtyPopup(instance) {
        const {
            showPopup,
            item: {
                product: {
                    name,
                    stock_item: {
                        qty
                    }
                }
            }
        } = instance.props;

        showPopup({ qty, name });
    }

    handleChangeQuantity = (args, callback, instance) => {
        const [quantity] = args;
        const {
            show_exceed_qty_popup,
            item,
            item: {
                qty,
                product: {
                    stock_status,
                    stock_item
                }
            }
        } = instance.props;

        if (show_exceed_qty_popup && stock_status !== IN_BACKORDER_LABEL && quantity > 0 && quantity > stock_item.qty) {
            this.toggleExceedQtyPopup(instance);
            return;
        }

        this.handleChangeState = {
            newQuantity: quantity,
            oldQuantity: qty,
            item
        };

        callback(...args);
    };
}

const {
    handleChangeQuantity
} = new CartItemChangeQuantityPlugin();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            handleChangeQuantity
        }
    },
    'Component/CartItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

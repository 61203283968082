export const DATE_OF_BIRTH = 'date_of_birth';

export const DATE_OF_BIRTH_FIELD = {
    [DATE_OF_BIRTH]: {
        info: __('*Pay attention! Age is checked at the door because of alcohol sales'),
        label: __('Date of Birth'),
        placeholder: __('DD-MM-YYYY'),
        validation: ['notEmpty', 'date', 'age'],
        validateSeparately: false,
        enabledCheck: 'date_of_birth_enabled_in_checkout'
    }
};

import { PRICE_LABEL_MAP, TIER_PRICES } from './Price.config';

export * from 'Util/Price/GetPrice.js';
export * from 'SourceUtil/Price/Price.js';

/** @namespace Pwasaas/Util/Price/getPriceLabel */
export const getPriceLabel = (type_id, price_tiers) => {
    const typeId = price_tiers.length ? TIER_PRICES : type_id;

    const label = PRICE_LABEL_MAP[typeId];
    if (!label) {
        return null;
    }

    return label;
};

import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

import './Notification.override.manual.style';

/** @namespace Pwasaas/Component/Notification/Component/NotificationComponent */
export class NotificationComponent extends SourceNotification {}

export default NotificationComponent;

/**
 * toggle customer account overlay on mobile devices
 */
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { isSignedIn } from 'Util/Auth';

export class HeaderContainerPlugin {
    onMyAccountButtonClick = (args, callback, instance) => {
        const { device } = instance.props;
        const { showMyAccountLogin } = instance.state;

        if (device.isMobile && showMyAccountLogin && !isSignedIn()) {
            instance.setState({ showMyAccountLogin: false }, () => {
                hideActiveOverlay();
            });

            return;
        }

        callback.apply(instance, args);
    };
}

const {
    onMyAccountButtonClick
} = new HeaderContainerPlugin();

export const config = {
    'Component/Header/Container': {
        'member-function': {
            onMyAccountButtonClick
        }
    }
};

export default config;

import { MY_WISHLIST } from 'Type/Account';
import { getStore } from 'Util/Store';

export class MyAccountContainer {
    tabMap = (originalTabMap) => {
        const { wishlist_general_active = false } = getStore().getState().ConfigReducer;

        if (wishlist_general_active) {
            return originalTabMap;
        }

        return Object.entries(originalTabMap).reduce(
            (newTabs, [type, tabInfo]) => {
                if (type !== MY_WISHLIST) {
                    return {
                        ...newTabs,
                        [type]: tabInfo
                    };
                }

                return newTabs;
            }, {}
        );
    };
}

const {
    tabMap
} = new MyAccountContainer();

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    }
};

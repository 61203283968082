export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        show_tier_price_in_cart: state.SaasConfigReducer.config.show_tier_price_in_cart || false
    };
};

export class CartItemContainer {
    containerProps = (args, callback, instance) => {
        const {
            show_tier_price_in_cart
        } = instance.props;

        return {
            ...callback(...args),
            show_tier_price_in_cart
        };
    };
}

const {
    containerProps
} = new CartItemContainer();

export default {
    'Component/CartItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartItem/Container': {
        'member-function': {
            containerProps
        }
    }
};

import { cloneElement } from 'react';

import MyAccountReorderButton from 'Component/MyAccountReorderButton';

export class MyAccountOrderTableRowComponent {
    render = (args, callback = () => {}, instance) => {
        const RenderedComponent = callback(instance, args);
        const { isReorderEnabled } = instance.props;

        if (!isReorderEnabled) {
            return RenderedComponent;
        }

        const { props, props: { children: baseChildren = [] } } = RenderedComponent;
        const { base_order_info: { id: orderId } } = instance.props;
        const children = [...baseChildren,
            <td>
                <MyAccountReorderButton
                  orderId={ orderId }
                />
            </td>
        ];

        return cloneElement(RenderedComponent, {
            ...props,
            children
        });
    };
}

const {
    render
} = new MyAccountOrderTableRowComponent();

export default {
    'Component/MyAccountOrderTableRow/Component': {
        'member-function': {
            render
        }
    }
};

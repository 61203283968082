export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isSurfacePriceCalculationEnabled: state.SaasConfigReducer.config?.surface_price_calculation_enabled || false
});

export class ProductActionsContainer {
    containerProps = (args, callback, { props: { isSurfacePriceCalculationEnabled } }) => ({
        ...callback(...args),
        isSurfacePriceCalculationEnabled
    });
}

const {
    containerProps
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    }
};

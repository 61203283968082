import { UPDATE_DELIVERY_DATE } from './OrderDate.action';

/** @namespace Pwasaas/Store/OrderDate/Reducer/getInitialState */
export const getInitialState = () => ({
    selectedDate: {
        selectedDay: '',
        selectedTime: ''
    }
});

/** @namespace Pwasaas/Store/OrderDate/Reducer/OrderDateReducer */
export const OrderDateReducer = (state = getInitialState(), action) => {
    const {
        selectedDate,
        type
    } = action;

    switch (type) {
    case UPDATE_DELIVERY_DATE:
        return {
            ...state,
            selectedDate
        };

    default:
        return state;
    }
};

export default OrderDateReducer;

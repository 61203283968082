/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common';

import './CustomTextPlaceholder.style';

/** @namespace Pwasaas/Component/CustomTextPlaceholder/Component/CustomTextPlaceholderComponent */
export class CustomTextPlaceholderComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number
        ]),
        length: PropTypes.oneOf([
            'block',
            'short',
            'medium',
            'long',
            'paragraph',
            'custom'
        ]),
        mix: MixType
    };

    static defaultProps = {
        content: '',
        length: 'short',
        mix: {}
    };

    render() {
        const { content, length, mix } = this.props;
        if (content) {
            return <span dangerouslySetInnerHTML={ { __html: content } } />;
        }

        return (
            <span mix={ mix } block="TextPlaceholder" mods={ { length } } />
        );
    }
}

export default CustomTextPlaceholderComponent;

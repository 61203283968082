export const SaasLayoutDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/SaasLayout/SaasLayout.dispatcher'
);

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const result = callback(...args);
    const { init } = result;

    result.init = (...args) => {
        SaasLayoutDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.handleData(dispatch);
            }
        );
        init(...args);
    };

    return result;
};

export default {
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

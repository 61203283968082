export const UPDATE_ACTIVE_MENU_ITEM = 'UPDATE_ACTIVE_MENU_ITEM';
export const UPDATE_ACTIVE_NAVIGATION_ITEM = 'UPDATE_ACTIVE_NAVIGATION_ITEM';
export const UPDATE_FOOTER_MENU = 'UPDATE_FOOTER_MENU';
export const UPDATE_HEADER_MENU = 'UPDATE_HEADER_MENU';
export const UPDATE_IS_TOGGLED = 'UPDATE_IS_TOGGLED';

/** @namespace Pwasaas/Store/SaasMenu/Action/updateHeaderMenu */
export const updateHeaderMenu = (headerMenu) => ({
    type: UPDATE_HEADER_MENU,
    headerMenu
});

/** @namespace Pwasaas/Store/SaasMenu/Action/updateFooterMenu */
export const updateFooterMenu = (footer) => ({
    type: UPDATE_FOOTER_MENU,
    footer
});

/** @namespace Pwasaas/Store/SaasMenu/Action/updateActiveMenuItem */
export const updateActiveMenuItem = (activeMenuItem = {}) => ({
    type: UPDATE_ACTIVE_MENU_ITEM,
    activeMenuItem
});

/** @namespace Pwasaas/Store/SaasMenu/Action/updateActiveNavigationItem */
export const updateActiveNavigationItem = (activeNavigationItem = {}) => ({
    type: UPDATE_ACTIVE_NAVIGATION_ITEM,
    activeNavigationItem
});

/** @namespace Pwasaas/Store/SaasMenu/Action/updateIsToggled */
export const updateIsToggled = (isToggled) => ({
    type: UPDATE_IS_TOGGLED,
    isToggled
});

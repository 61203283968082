import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';

import './StoreNotice.style';

/** @namespace Pwasaas/Component/StoreNotice/Component/StoreNoticeComponent */
export class StoreNoticeComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired,
        isEnabled: PropTypes.bool.isRequired,
        isStoreNoticeSticky: PropTypes.bool.isRequired
    };

    render() {
        const {
            content,
            isEnabled,
            isStoreNoticeSticky: isSticky
        } = this.props;

        if (!isEnabled) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ {
                  block: 'StoreNotice',
                  mods: { isSticky }
              } }
              wrapperMix={ {
                  block: 'StoreNotice',
                  elem: 'Wrapper'
              } }
              label="Store notice"
            >
                <Html content={ content } />
            </ContentWrapper>
        );
    }
}

export default StoreNoticeComponent;

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/GiftCard/Query/GiftCardQuery */
export class GiftCardQuery {
    getUpdateGiftMessageMutation(giftCard, quoteId) {
        const mutation = new Field('addGiftMessageToCart')
            .addArgument('gift_card', 'GiftMessageInput!', giftCard)
            .addFieldList(this._getGiftMessageFields());

        if (quoteId) {
            mutation.addArgument('cart_id', 'String', quoteId);
        }

        return mutation;
    }

    _getGiftMessageFields() {
        return [
            'to',
            'from',
            'message'
        ];
    }
}

export default new GiftCardQuery();

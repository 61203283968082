import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import { ExceedStockPopupComponent } from './ExceedStockPopup.component';
import { EXCEED_STOCK_POPUP_ID } from './ExceedStockPopup.config';

/** @namespace Pwasaas/Plugin/ExceedStockPopup/Component/ExceedStockPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[EXCEED_STOCK_POPUP_ID] || {},
    exceed_qty_content: state.SaasConfigReducer.product?.exceed_qty_content || ''
});

/** @namespace Pwasaas/Plugin/ExceedStockPopup/Component/ExceedStockPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwasaas/Plugin/ExceedStockPopup/Component/ExceedStockPopup/Container/ExceedStockPopupContainer */
export class ExceedStockPopupContainer extends DataContainer {
    static propTypes = {
        exceed_qty_content: PropTypes.string.isRequired,
        payload: {
            qty: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        }
    };

    containerProps() {
        const {
            exceed_qty_content,
            payload: {
                qty,
                name
            }
        } = this.props;

        return {
            exceed_qty_content,
            qty,
            name
        };
    }

    render() {
        return (
            <ExceedStockPopupComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExceedStockPopupContainer);

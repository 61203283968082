export const RELEVANCE = 'relevance';

export class CategorySortContainer {
    _getLabel = (args, callback, _instance) => {
        const [{ id }] = args;

        if (id === RELEVANCE) {
            return { asc: __('Relevance') };
        }

        return callback(...args);
    };
}

const {
    _getLabel
} = new CategorySortContainer();

export default {
    'Component/CategorySort/Container': {
        'member-function': {
            _getLabel
        }
    }
};

import CmsBlockQuery from 'Query/CmsBlock.query';
import { fetchQuery } from 'Util/Request/Query';

export const addCmsQueryToCartPageContainer = async (args, callback, instance) => {
    fetchQuery(CmsBlockQuery.getQuery({ identifiers: ['free_shipping'] })).then(
        ({ cmsBlocks }) => {
            const { items } = cmsBlocks;
            instance.setState({
                cmsBlockContent: items[0].content
            });
        }
    ).catch(() => {
        // cms block does not exist
    });

    callback.apply(instance, args);
};

export default {
    'Component/CartOverlay/Container': {
        'member-function': {
            componentDidMount: addCmsQueryToCartPageContainer
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { NAVIGATION_MAIN_ITEM_LEVEL } from 'Component/MenuNavigation/MenuNavigation.config';
import { MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/SaasMenu';

import MenuAdvanced from './MenuAdvanced.component';
import { MENU_ITEM_DEFAULT } from './MenuAdvanced.config';

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

/** @namespace Pwasaas/Component/MenuAdvanced/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeMenuItem: state.SaasMenuReducer.activeMenuItem,
    device: state.ConfigReducer.device,
    isToggled: state.SaasMenuReducer.isToggled,
    items: state.SaasMenuReducer.header.items || [],
    toggleItems: state.SaasMenuReducer.header.toggleItems || []
});

/** @namespace Pwasaas/Component/MenuAdvanced/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeMenu(dispatch)
    )
});

/** @namespace Pwasaas/Component/MenuAdvanced/Container/MenuAdvancedContainer */
export class MenuAdvancedContainer extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired,
        closeMenu: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        isToggled: PropTypes.bool.isRequired,
        items: PropTypes.arrayOf(
            MenuItemType
        ).isRequired,
        mix: MixType,
        toggleItems: PropTypes.arrayOf(
            MenuItemType
        ).isRequired
    };

    static defaultProps = {
        mix: {}
    };

    containerFunctions = {
        handleCloseMenu: this.handleCloseMenu.bind(this),
        handleMouseLeave: this.handleMouseLeave.bind(this)
    };

    containerProps = () => {
        const {
            activeMenuItem,
            activeMenuItem: {
                link_type: activeType = MENU_ITEM_DEFAULT
            },
            device,
            items,
            mix
        } = this.props;

        const toggleItems = this._getToggleItems();

        return {
            activeMenuItem,
            activeType,
            device,
            items,
            mix,
            toggleItems
        };
    };

    _getToggleItems() {
        const {
            device,
            items,
            toggleItems
        } = this.props;

        if (!device.isMobile) {
            return toggleItems;
        }

        if (
            items.length
                && !toggleItems.length
        ) {
            toggleItems.push({
                ...items[0],
                childs: items.map((item) => ({
                    ...item,
                    level: NAVIGATION_MAIN_ITEM_LEVEL + 1
                })),
                title: __('Menu')
            });
        }

        return toggleItems;
    }

    handleCloseMenu() {
        const {
            closeMenu,
            device
        } = this.props;

        if (device.isMobile) {
            return;
        }

        closeMenu();
    }

    handleMouseLeave() {
        const {
            closeMenu,
            isToggled
        } = this.props;

        if (isToggled) {
            return;
        }

        closeMenu();
    }

    render() {
        return (
            <MenuAdvanced
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuAdvancedContainer);

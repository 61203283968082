import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { ChildrenType } from 'Type/Common';
import DataContainer from 'Util/Request/DataContainer';

import ProductIconQuery from '../../query/ProductIcon.query';
import ProductAttributeIcons from './ProductAttributeIcons.component';

/** @namespace Pwasaas/Plugin/ProductAttributeIcons/Component/ProductAttributeIcons/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mediaUrl: state.ConfigReducer.secure_base_media_url
});

/** @namespace Pwasaas/Plugin/ProductAttributeIcons/Component/ProductAttributeIcons/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Pwasaas/Plugin/ProductAttributeIcons/Component/ProductAttributeIcons/Container/ProductAttributeIconsContainer */
export class ProductAttributeIconsContainer extends DataContainer {
    static propTypes = {
        children: ChildrenType,
        mediaUrl: PropTypes.string.isRequired,
        onSuccess: PropTypes.func,
        productSKU: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        onSuccess: () => {}
    };

    state = {
        isEnabled: false,
        productAttributeIcons: []
    };

    componentDidMount() {
        const { productSKU } = this.props;

        this.fetchData(
            [ProductIconQuery.getQuery(productSKU)],
            this._setStateData,
            this._handleError
        );
    }

    containerProps = () => {
        const {
            children,
            mediaUrl
        } = this.props;

        const {
            isEnabled,
            productAttributeIcons
        } = this.state;

        return {
            children,
            isEnabled,
            mediaUrl,
            productAttributeIcons
        };
    };

    _setStateData = ({
        productIcons: {
            show_product_usp_attributes: isEnabled,
            product_usp_attributes: productAttributeIcons = []
        }
    }) => {
        this.setState({
            isEnabled,
            productAttributeIcons
        }, () => {
            const { onSuccess } = this.props;

            onSuccess(isEnabled);
        });
    };

    _handleError = (error) => {
        const { showNotification } = this.props;

        showNotification('error', __('Something went wrong!'), error);
    };

    render() {
        return (
            <ProductAttributeIcons
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributeIconsContainer);

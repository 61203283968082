/** @namespace Pwasaas/Util/SessionDatabase/SessionDatabase */
export class SessionDatabase {
    sessionStore = window.sessionStorage;

    /**
     * Loads data from session storage
     * @param {String} key Name of the data to get
     * @memberof SessionDatabase
     */
    getItem(key) {
        try {
            const storageObject = JSON.parse(this.sessionStore.getItem(key));
            const { data } = storageObject;

            return data;
        } catch {
            return null;
        }
    }

    /**
     * set data in  session storage
     * @param {String} key Name of the data to store
     * @param {*} data The data to store
     * @memberof SessionDatabase
     */
    setItem(key, data) {
        this.sessionStore.setItem(key, JSON.stringify({
            createdAt: Date.now(),
            data
        }));
    }
}

export default new SessionDatabase();

import {
    mapDispatchToProps,
    mapStateToProps,
    setBlogPageHeader
} from '../util/PageHeader';

export class BlogPageContainer {
    componentDidUpdate = (args, callback, instance) => {
        const result = callback(...args);

        const { page: { categoryId } } = instance.props;
        const [{ page: { categoryId: prevId } }] = args;

        if (categoryId !== prevId) {
            const {
                page,
                navigationState = {},
                updatePageHeader = () => {}
            } = instance.props;

            setBlogPageHeader(
                page,
                navigationState,
                updatePageHeader
            );
        }

        return result;
    };

    componentWillUnmount = (args, callback, instance) => {
        const { updatePageHeader = () => {} } = instance.props;

        updatePageHeader();

        return callback(...args);
    };

    containerProps = (args, callback, instance) => {
        const { isPageHeaderVisible } = instance.props;

        return {
            ...callback(...args),
            isPageHeaderVisible
        };
    };
}

const {
    componentDidUpdate,
    componentWillUnmount,
    containerProps
} = new BlogPageContainer();

export default {
    'Pwasaas/Route/BlogPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Pwasaas/Route/BlogPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Route/BlogPage/Container/BlogPageContainer': {
        'member-function': {
            componentDidUpdate,
            componentWillUnmount,
            containerProps
        }
    }
};

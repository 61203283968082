import {
    CurrencySwitcher as SourceCurrencySwitcher
} from 'SourceComponent/CurrencySwitcher/CurrencySwitcher.component';

import './CurrencySwitcher.manual.style';

/** @namespace Pwasaas/Component/CurrencySwitcher/Component/CurrencySwitcherComponent */
export class CurrencySwitcherComponent extends SourceCurrencySwitcher {}

export default CurrencySwitcherComponent;

import ProductListPage from 'Component/ProductListPage';

export class ProductListComponent {
    renderPage = (args, callback, instance) => {
        const { isProductCardWithAttributes } = instance.props;
        const result = callback(...args);

        return (
            <ProductListPage
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...result.props }
              isProductCardWithAttributes={ isProductCardWithAttributes }
            />
        );
    };
}

const {
    renderPage
} = new ProductListComponent();

export default {
    'Component/ProductList/Component': {
        'member-function': {
            renderPage
        }
    }
};

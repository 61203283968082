import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { StoreInfoType } from 'Type/StoreLocator';

import StoreDetailWidgetFactory from './StoreDetailWidgetFactory.component';

/** @namespace Pwasaas/Component/StoreDetailWidgetFactory/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    storeInfo: state.StoreLocatorReducer.activeStorePage.storeInfo || {}
});

/** @namespace Pwasaas/Component/StoreDetailWidgetFactory/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/StoreDetailWidgetFactory/Container/StoreDetailWidgetFactoryContainer */
export class StoreDetailWidgetFactoryContainer extends PureComponent {
    static propTypes = {
        storeInfo: StoreInfoType.isRequired,
        title: PropTypes.string,
        type: PropTypes.string.isRequired
    };

    static defaultProps = {
        title: ''
    };

    containerProps = () => {
        const {
            storeInfo: {
                id: storeInfoId,
                address = '',
                content = '',
                email = '',
                image = '',
                lat = '',
                long = '',
                openingHours = {},
                postcode = '',
                place = '',
                social = {},
                storeName = '',
                telephone = '',
                thumbnail = '',
                usp = [],
                url: websiteUrl = ''
            },
            title: widgetTitle,
            type
        } = this.props;

        return {
            address,
            content,
            email,
            image,
            openingHours,
            postcode,
            place,
            lat,
            long,
            social,
            storeInfoId,
            storeName,
            telephone,
            thumbnail,
            type,
            usp,
            websiteUrl,
            widgetTitle
        };
    };

    render() {
        return (
            <StoreDetailWidgetFactory
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetailWidgetFactoryContainer);

import Field from 'Component/Field';

export class MyAccountCreateAccountComponent {
    renderCompanyNameField(instance) {
        const { isCocFieldExcludedFromForm } = instance.props;

        if (!isCocFieldExcludedFromForm) {
            return (
              <Field
                type="text"
                label={ __('Name company') }
                id="company"
                name="company"
                validation={ ['notEmpty'] }
              />
            );
        }

        return null;
    }

    renderCocField(instance) {
        const { isCocFieldExcludedFromForm } = instance.props;

        if (!isCocFieldExcludedFromForm) {
            return (
              <Field
                type="text"
                label={ __('Chamber of Commerce') }
                id="coc"
                name="coc"
                validation={ ['notEmpty'] }
              />
            );
        }

        return null;
    }

    renderCreateAccountPersonalInfoFields = (args, callback, instance) => {
        const { isUserCanCreateAccount } = instance.props;

        if (isUserCanCreateAccount) {
            return callback(...args);
        }

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <legend>{ __('Business Information') }</legend>
                { this.renderCompanyNameField(instance) }
                <Field
                  type="text"
                  label={ __('Contact person') }
                  id="contact_person"
                  name="contact_person"
                  validation={ ['notEmpty'] }
                />
                <Field
                  type="textarea"
                  label={ __('Address') }
                  id="address"
                  name="address"
                  validation={ ['notEmpty'] }
                />
                <Field
                  type="text"
                  label={ __('Phone number') }
                  id="telephone"
                  name="telephone"
                  validation={ ['notEmpty', 'telephone'] }
                />
                <Field
                  type="text"
                  label={ __('Email') }
                  id="email"
                  name="email"
                  autocomplete="email"
                  validation={ ['notEmpty', 'email'] }
                />
                { instance.renderVatNumberField() }
                { this.renderCocField(instance) }
            </fieldset>
        );
    };

    renderCreateAccountSignUpInfoFields = (args, callback, instance) => {
        const { isUserCanCreateAccount } = instance.props;

        if (isUserCanCreateAccount) {
            return callback(...args);
        }

        return null;
    };
}

const {
    renderCreateAccountPersonalInfoFields,
    renderCreateAccountSignUpInfoFields
} = new MyAccountCreateAccountComponent();

export default {
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            renderCreateAccountPersonalInfoFields,
            renderCreateAccountSignUpInfoFields
        }
    }
};

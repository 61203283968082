import {
    SearchField as SourceSearchField,
    SearchOverlay
} from 'SourceComponent/SearchField/SearchField.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './SearchField.override.manual.style';

export {
    SearchOverlay
};

/** @namespace Pwasaas/Component/SearchField/Component/SearchFieldComponent */
export class SearchFieldComponent extends SourceSearchField {
    onSearchEnterPress = (e) => {
        const {
            searchCriteria,
            hideActiveOverlay,
            onSearchBarChange
        } = this.props;

        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim().replace();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ search.replace(new RegExp('%', 'g'), '') }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };
}

export default SearchFieldComponent;

import { formatPrice } from '@scandipwa/scandipwa/src/util/Price';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './FreeShippingThreshold.style';

/** @namespace Pwasaas/Component/FreeShippingThreshold/Component/FreeShippingThresholdComponent */
export class FreeShippingThresholdComponent extends PureComponent {
    static propTypes = {
        isFreeShippingThresholdEnabled: PropTypes.bool.isRequired,
        freeShippingText: PropTypes.string.isRequired,
        freeShippingTextWithAmount: PropTypes.number.isRequired,
        freeShippingAmount: PropTypes.string.isRequired,
        isFreeShippingShowIcon: PropTypes.bool.isRequired,
        totals: PropTypes.shape().isRequired,
        elem: PropTypes.string.isRequired
    };

    renderIcon() {
        const {
            isFreeShippingShowIcon
        } = this.props;

        if (!isFreeShippingShowIcon) {
            return null;
        }

        return <Html content='<i class="fas fa-truck" />' />;
    }

    renderFinalText() {
        const {
            freeShippingText,
            freeShippingTextWithAmount,
            freeShippingAmount,
            totals: {
                subtotal_incl_tax: price = 0,
                quote_currency_code: currency
            }
        } = this.props;

        if (freeShippingTextWithAmount === '') {
            return freeShippingText;
        }

        const amountVariable = new RegExp(/{[xX]}/);
        const restAmount = (freeShippingAmount - price) <= 0 ? 0 : freeShippingAmount - price;
        const amountToPrice = `<b>${formatPrice(restAmount, currency)}</b>`;

        if (freeShippingText !== '' && restAmount === 0) {
            return freeShippingText;
        }

        return <Html content={ freeShippingTextWithAmount.replace(amountVariable, amountToPrice) } />;
    }

    render() {
        const {
            isFreeShippingThresholdEnabled,
            elem = ''
        } = this.props;

        if (!isFreeShippingThresholdEnabled) {
            return null;
        }

        if (elem !== '') {
            return (
                <div block="FreeShippingThreshold">
                    <p block="FreeShippingThreshold" elem={ elem }>
                        { this.renderIcon() }
                        { this.renderFinalText() }
                    </p>
                </div>
            );
        }

        // CartOverlay
        return (
            <div block="FreeShippingThreshold">
                { this.renderIcon() }
                { this.renderFinalText() }
            </div>
        );
    }
}

export default FreeShippingThresholdComponent;

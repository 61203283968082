import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import LinkedProductsReducer, { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';
import { withReducers } from 'Util/DynamicReducer';

import ThumbnailRelatedProductList from './ThumbnailRelatedProductList.component';

/** @namespace Pwasaas/Plugin/ThumbnailRelatedProductList/Component/ThumbnailRelatedProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    products: state.LinkedProductsReducer.linkedProducts,
    device: state.ConfigReducer.device
});

/** @namespace Pwasaas/Plugin/ThumbnailRelatedProductList/Component/ThumbnailRelatedProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwasaas/Plugin/ThumbnailRelatedProductList/Component/ThumbnailRelatedProductList/Container/ThumbnailRelatedProductListContainer */
export class ThumbnailRelatedProductListContainer extends PureComponent {
     static propTypes = {
         device: DeviceType.isRequired,
         numberOfProductsToDisplayOnMobile: PropTypes.number,
         numberOfProductsToDisplayOnDesktop: PropTypes.number,
         products: PropTypes.objectOf(ProductType).isRequired,
         title: PropTypes.string.isRequired
     };

     static defaultProps = {
         numberOfProductsToDisplayOnDesktop: 3,
         numberOfProductsToDisplayOnMobile: 1
     };

     containerProps() {
         const {
             device,
             numberOfProductsToDisplayOnDesktop,
             numberOfProductsToDisplayOnMobile,
             products,
             title
         } = this.props;

         const numberOfProductsToDisplay = device.isMobile
             ? numberOfProductsToDisplayOnMobile
             : numberOfProductsToDisplayOnDesktop;

         const relatedProducts = this._getRelatedProducts(products);

         return {
             isSlider: relatedProducts.length > numberOfProductsToDisplay,
             numberOfProductsToDisplay,
             products: relatedProducts,
             title
         };
     }

     _getRelatedProducts(products) {
         const { [RELATED]: { items = [] } } = products;

         return items.map(
             ({
                 id,
                 name,
                 price_range,
                 image: { url },
                 url: itemUrl
             }) => ({
                 id,
                 name,
                 price_range,
                 thumbnail: url,
                 url: itemUrl
             })
         );
     }

     render() {
         return (
             <ThumbnailRelatedProductList
               { ...this.containerProps() }
             />
         );
     }
}

export default withReducers({
    LinkedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(ThumbnailRelatedProductListContainer));

import { MY_ADDITIONAL_EMAILS } from '../component/MyAccountAdditionalEmails/MyAccountAdditionalEmails.config';

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isAdditionalEmailsEnabled: state.SaasConfigReducer.config?.additional_emails_enabled || false
});

export class MyAccountContainer {
    currentInstance = {};

    containerProps = (args, callback, instance) => {
        const { isAdditionalEmailsEnabled } = instance.props;

        this.currentInstance = instance;

        return {
            ...callback(...args),
            isAdditionalEmailsEnabled
        };
    };

    tabMap = (originalTabMap) => {
        if (!this.currentInstance.props) {
            return originalTabMap;
        }

        const { isAdditionalEmailsEnabled } = this.currentInstance.props;

        return isAdditionalEmailsEnabled
            ? {
                ...originalTabMap,
                [MY_ADDITIONAL_EMAILS]: {
                    url: '/my-additional-emails',
                    name: __('My Additional Emails')
                }
            }
            : originalTabMap;
    };
}

const {
    containerProps,
    tabMap
} = new MyAccountContainer();

export default {
    'Route/MyAccount/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/MyAccount/Container': {
        'member-function': {
            containerProps
        },
        'static-member': {
            tabMap
        }
    }
};

import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.manual.style';

/** @namespace Pwasaas/Component/ExpandableContent/Component/ExpandableContentComponent */
export class ExpandableContentComponent extends SourceExpandableContent {
    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <svg xmlns="http://www.w3.org/2000/svg" style={ { transform: 'rotate(180deg)' } } viewBox="0 0 11.414 7.12"><path d="M0 1.414 5.707 7.12l5.707-5.707L10 0 5.707 4.292 1.414 0 0 1.413Z" /></svg>;
        }

        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.414 7.12"><path d="M0 1.414 5.707 7.12l5.707-5.707L10 0 5.707 4.292 1.414 0 0 1.413Z" /></svg>;
    }
}

export default ExpandableContentComponent;

/**
 * Disable dropdown when FieldSelect is disabled
 *
 * TODO: Check if scandi bug is fixed in changelog
 * */

export const cancelSelectExpandBefore = (args, callback, instance) => {
    const { isDisabled } = instance.props;

    if (isDisabled) {
        return null;
    }

    return callback(...args);
};

export default {
    'Component/FieldSelect/Container': {
        'member-function': {
            handleSelectExpand: cancelSelectExpandBefore
        }
    }
};

/**
 * Add company field after lastname with source data to address table
 */

import { Fragment } from 'react';

import { getStore } from 'Util/Store';

/** @namespace Pwasaas/Plugin/CoreFix/CustomerCompanyData/Component/MyAccountAddressTable/Component/MyAccountAddressTableComponent */
export class MyAccountAddressTableComponent {
    renderTableRow = (args, callback, instance) => {
        const [{ key }] = args;
        const result = callback(...args);
        const companyShow = getStore().getState().SaasConfigReducer.config?.company_show || null;

        if (key === 'lastname' && ['opt', 'req'].includes(companyShow)) {
            const { address } = instance.props;

            const data = {
                key: 'company',
                label: __('Company'),
                source: address
            };

            return (
                <Fragment key={ data.key }>
                    { result }
                    { callback(data) }
                </Fragment>
            );
        }

        return result;
    };
}

const {
    renderTableRow
} = new MyAccountAddressTableComponent();

export default {
    'Component/MyAccountAddressTable/Component': {
        'member-function': {
            renderTableRow
        }
    }
};

import { cloneElement } from 'react';

export class FieldFormComponent {
    renderField = (args, callback, instance) => {
        const [fieldEntry] = args;
        const result = callback(...args);
        const { info } = instance.getDefaultValues(fieldEntry);
        const { props } = result;

        return cloneElement(result, {
            ...props,
            info
        });
    };
}

const {
    renderField
} = new FieldFormComponent();

export default {
    'Component/FieldForm/Component': {
        'member-function': {
            renderField
        }
    }
};

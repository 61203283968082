import { isInitiallySignedIn } from 'Util/Auth';

export const getInitialState = (args, callback, context) => ({
    ...callback.apply(context, args),
    isSignedIn: (typeof isInitiallySignedIn !== 'undefined' ? isInitiallySignedIn() : false)
});

export default {
    'Store/MyAccount/Reducer': {
        function: getInitialState
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEFAULT_MAX_PRODUCTS } from 'Component/ProductActions/ProductActions.config';
import { OrderListItemType } from 'Type/OrderList';

import OrderListItem from './OrderListItem.component';

export const OrderListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/OrderList/OrderList.dispatcher'
);

/** @namespace Pwasaas/Component/OrderListItem/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Pwasaas/Component/OrderListItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    changeItemQty: (item) => OrderListDispatcher.then(
        ({ default: dispatcher }) => dispatcher.changeItemQty(item, dispatch)
    ),
    removeItem: (item) => OrderListDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeProductFromList(item, dispatch)
    )
});

/** @namespace Pwasaas/Component/OrderListItem/Container/OrderListItemContainer */
export class OrderListItemContainer extends PureComponent {
    static propTypes = {
        changeItemQty: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired,
        item: OrderListItemType.isRequired,
        removeItem: PropTypes.func.isRequired,
        updateCartSelection: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleChangeQuantity: this.handleChangeQuantity.bind(this),
        handleRemoveItem: this.handleRemoveItem.bind(this),
        handleToggleItem: this.handleToggleItem.bind(this)
    };

    containerProps = () => ({
        isSelected: this._getIsSelected(),
        item: this._getCurrentProduct(),
        itemQty: this._getItemqty(),
        minItemQuantity: this._getMinQuantity(),
        maxItemQuantity: this._getMaxQuantity(),
        thumbnail: this._getThumbnail()
    });

    _getMinQuantity() {
        const { stock_item: { min_sale_qty } = {} } = this._getCurrentProduct() || {};
        return min_sale_qty || 1;
    }

    _getMaxQuantity() {
        const { stock_item: { max_sale_qty } = {} } = this._getCurrentProduct() || {};
        return max_sale_qty || DEFAULT_MAX_PRODUCTS;
    }

    _getCurrentProduct() {
        const {
            item: {
                product
            }
        } = this.props;

        return product;
    }

    _getIsSelected() {
        const { isSelected } = this.props;

        return isSelected;
    }

    _getItemqty() {
        const { item: { qty } } = this.props;

        return qty;
    }

    _getThumbnail() {
        const { item: { tmp_thumbnail } } = this.props;

        return tmp_thumbnail || '';
    }

    async handleChangeQuantity(strValue) {
        const value = Number(strValue);

        const {
            changeItemQty,
            isSelected,
            item: {
                product,
                qty: oldQty
            },
            updateCartSelection
        } = this.props;

        if (value === oldQty) {
            return;
        }

        const newItem = {
            product,
            qty: value
        };

        await changeItemQty(newItem);

        if (isSelected) {
            updateCartSelection(newItem);
        }
    }

    async handleRemoveItem() {
        const {
            item,
            removeItem
        } = this.props;

        await removeItem(item);
    }

    handleToggleItem() {
        const {
            item,
            updateCartSelection
        } = this.props;

        updateCartSelection(item);
    }

    render() {
        return (
            <OrderListItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListItemContainer);

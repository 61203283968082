import { UPDATE_CUSTOMER_DETAILS } from 'Store/MyAccount/MyAccount.action';
import {
    getOrderDateValuesFrom,
    removeStaticValuesFromAndReturn
} from 'Util/OrderDate';

export const MyAccountReducer = (args, callback, context) => {
    const [, action] = args;
    const updatedState = callback.apply(context, args);

    if (!action) {
        return updatedState;
    }

    const {
        type,
        customer: customerToUpdate
    } = action;

    switch (type) {
    case UPDATE_CUSTOMER_DETAILS:
        return {
            ...updatedState,
            customer: {
                ...removeStaticValuesFromAndReturn(customerToUpdate),
                orderDates: getOrderDateValuesFrom(customerToUpdate)
            }
        };

    default:
        return {
            ...updatedState
        };
    }
};

export default {
    'Store/MyAccount/Reducer': {
        function: MyAccountReducer
    }
};

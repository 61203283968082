export const BANNER_GROUP = 'BannerGroup';
export const CATALOG_PRODUCT_LIST = 'CatalogProductList';
export const CATEGORY_FILTER = 'CategoryFilterWidget';
export const DEFAULT = 'Default';
export const EVENT_CALENDAR = 'EventsCalendarWidget';
export const LAYOUT_GROUP = 'LayoutGroup';
export const MOST_ORDERED = 'MostOrderedWidget';
export const NEW_PRODUCTS = 'NewProducts';
export const RECENTLY_VIEWED = 'RecentlyViewed';
export const REORDER = 'Reorder';
export const SAAS_BUTTON_WIDGET = 'ButtonWidget';
export const SAAS_CMS_PAGE_LIST = 'CmsPageListWidget';
export const SAAS_SLIDER = 'SaasSlider';
export const SLIDER = 'Slider';
export const STORE_HOURS = 'StoreHours';
export const STORE_LOCATOR = 'StoreLocatorWidget';
export const VIDEO_WIDGET = 'VideoWidget';

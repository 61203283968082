import { GROUPED } from 'Util/Product/Types';

export class ProductCardComponent {
    renderProductPrice = (args, callback, instance) => {
        const {
            isShowGroupedProductPricesInCategory,
            product: {
                type_id
            }
        } = instance.props;

        if (type_id === GROUPED && !isShowGroupedProductPricesInCategory) {
            return null;
        }

        return callback(instance, args);
    };
}

const {
    renderProductPrice
} = new ProductCardComponent();

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderProductPrice
        }
    }
};

import PropTypes from 'prop-types';

export const ProductLabelType = PropTypes.shape({
    customerGroupIds: PropTypes.string,
    discountPercent: PropTypes.number,
    image: PropTypes.string,
    isVisible: PropTypes.bool,
    labelId: PropTypes.number,
    name: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.string,
    tooltipSettings: PropTypes.shape({
        status: PropTypes.number,
        color: PropTypes.string,
        text: PropTypes.string,
        text_color: PropTypes.string
    }),
    txt: PropTypes.string
});

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        defaultCategorySortBy: state.SaasConfigReducer.config?.default_sort_by
    };
};

export class CategoryPageContainer {
    config = (originalMember, instance) => {
        const {
            category: {
                default_sort_by
            },
            defaultCategorySortBy,
            isSearchPage
        } = instance.props;

        if (isSearchPage) {
            return originalMember;
        }

        return {
            ...originalMember,
            sortKey: default_sort_by || defaultCategorySortBy || originalMember.sortKey
        };
    };
}

const {
    config
} = new CategoryPageContainer();

export default {
    'Route/CategoryPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CategoryPage/Container': {
        'member-property': {
            config
        }
    }
};

import validationConfig from 'Component/Form/Form.config';
import {
    ADDITION,
    CITY,
    HOUSENUMBER,
    POSTCODE,
    STREET
} from 'Component/SaasCheckoutForm/SaasCheckoutForm.config';
import { fetchQuery } from 'Util/Request';

import { EDIT_FIELDS, POSTCODE_LENGTH } from '../PostcodeCheck.config';
import PostcodeCheckQuery from '../query/PostcodeCheck.query';
import { forceDisablePostcodeCheck } from './SaasConfig.reducer.plugin';

export const fetchPostcodeData = (options) => fetchQuery(
    PostcodeCheckQuery.getQuery(options)
).then(({ postcode }) => postcode);

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isForcedDisablePostcodeCheck: state.SaasConfigReducer.isForcedDisablePostcodeCheck,
        isPostcodeCheckEnabled: state.SaasConfigReducer.config?.postcode_check_enabled || false,
        customerAddress: state.CheckoutReducer.shippingFields || {}
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        forceDisablePostcodeCheck: (value) => dispatch(forceDisablePostcodeCheck(value))
    };
};

export class SaasCheckoutFormContainer {
    eventTimeout;

    state = (originalMember) => ({
        ...originalMember,
        [ADDITION]: '',
        [HOUSENUMBER]: '',
        [POSTCODE]: '',
        isCustomEdit: false,
        isLoading: false,
        postcodeInfo: {}
    });

    customFieldsOptionMap = (originalMap, instance) => {
        const {
            isForcedDisablePostcodeCheck,
            isPostcodeCheckEnabled
        } = instance.props;

        if (!isPostcodeCheckEnabled || isForcedDisablePostcodeCheck) {
            return originalMap;
        }

        return {
            ...originalMap,
            [ADDITION]: {
                ...originalMap[ADDITION] || {},
                onChangeHandler: 'onPostcodeChange'
            },
            [CITY]: {
                ...originalMap[CITY] || {},
                conditions: [
                    ...originalMap[CITY]?.conditions || [],
                    {
                        handler: (item) => !item,
                        prop: 'type',
                        selector: 'props.isCustomEdit'
                    }
                ]
            },
            [HOUSENUMBER]: {
                ...originalMap[HOUSENUMBER] || {},
                onChangeHandler: 'onPostcodeChange'
            },
            [STREET]: {
                ...originalMap[STREET] || {},
                conditions: [
                    ...originalMap[STREET]?.conditions || [],
                    {
                        handler: (item) => !item,
                        prop: 'type',
                        selector: 'props.isCustomEdit'
                    }
                ]
            }
        };
    };

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onCustomEdit: this.handleCustomEdit.bind(instance),
        onForceDisablePostcodeCheck: this.handleForceDisablePostcodeCheck.bind(instance),
        onInitialDataLoad: this.handleInitialDataLoad.bind(instance),
        onPostcodeChange: this.handlePostcodeChange.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const {
            props: {
                isForcedDisablePostcodeCheck,
                isPostcodeCheckEnabled
            },
            state: {
                editFields,
                isCustomEdit,
                isLoading,
                postcodeInfo
            }
        } = instance;

        if (!isPostcodeCheckEnabled || isForcedDisablePostcodeCheck) {
            return callback(...args);
        }

        return {
            ...callback(...args),
            [ADDITION]: instance.state[ADDITION] || instance.props.customerAddress?.street2,
            [HOUSENUMBER]: instance.state[HOUSENUMBER],
            [POSTCODE]: instance.state[POSTCODE],
            editFields: {
                ...editFields,
                ...EDIT_FIELDS
            },
            isCustomEdit,
            isLoading,
            isPostcodeCheckEnabled,
            postcodeInfo
        };
    };

    handleCustomEdit() {
        this.setState({ isCustomEdit: true });
    }

    handleForceDisablePostcodeCheck(value) {
        const { forceDisablePostcodeCheck } = this.props;

        forceDisablePostcodeCheck(value);
    }

    handleInitialDataLoad(fields) {
        const { onPostcodeChange } = this.containerFunctions;

        this.setState({
            [POSTCODE]: fields[POSTCODE]
        }, () => {
            onPostcodeChange(HOUSENUMBER, fields[HOUSENUMBER]);
        });
    }

    handlePostcodeChange(key, value) {
        const { postcodeInfo } = this.state;

        if (Object.keys(postcodeInfo).length) {
            if (key === ADDITION) {
                this.setState({ [ADDITION]: value });

                return;
            }

            /** Reset state on address changes after postcode info is fetched */
            this.setState({
                [ADDITION]: '',
                [HOUSENUMBER]: key === HOUSENUMBER
                    ? value.replace(/ +/g, '')
                    : '',
                [POSTCODE]: key === POSTCODE
                    ? value.replace(/ +/g, '')
                    : this.state[POSTCODE],
                isCustomEdit: false,
                postcodeInfo: {}
            });

            return;
        }

        const instance = this;
        const DELAY = 500;

        clearTimeout(this.eventTimeout);

        this.setState({ [key]: value.replace(/ +/g, '') }, () => {
            this.eventTimeout = setTimeout(async () => {
                if (instance.state[POSTCODE].length !== POSTCODE_LENGTH) {
                    return;
                }

                if (
                    !instance.state[HOUSENUMBER].length
                        || !validationConfig.houseNumber.validate({ value: instance.state[HOUSENUMBER] })
                ) {
                    return;
                }

                instance.setState({ isLoading: true });

                const postcodeInfo = await fetchPostcodeData({
                    postcode: instance.state[POSTCODE],
                    houseNumber: instance.state[HOUSENUMBER]
                }).finally(
                    () => instance.setState({ isLoading: false })
                );

                if (!postcodeInfo) {
                    return;
                }

                instance.setState({ postcodeInfo });
            }, DELAY);
        });
    }
}

const {
    containerFunctions,
    containerProps,
    customFieldsOptionMap,
    state
} = new SaasCheckoutFormContainer();

export default {
    'Pwasaas/Component/SaasCheckoutForm/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Pwasaas/Component/SaasCheckoutForm/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/SaasCheckoutForm/Container/SaasCheckoutFormContainer': {
        'member-function': {
            containerProps
        },
        'member-property': {
            customFieldsOptionMap,
            containerFunctions,
            state
        }
    }
};

export class CategoryQueryPlugin {
    _getDefaultFields = (args, callback) => [
        ...callback(args),
        'show_category_order_button'
    ];
}

const {
    _getDefaultFields
} = new CategoryQueryPlugin();

export const config = {
    'Query/Category': {
        'member-function': {
            _getDefaultFields
        }
    }
};

export default config;

import { cloneElement } from 'react';

import '../style/ProductPrice.manual.style';

export class ProductPriceComponent {
    renderCurrentPrice = (args, callback, instance) => {
        const {
            isSwitchInclusiveAndExclusivePrices,
            formattedSubPrice
        } = instance.props;

        if (!isSwitchInclusiveAndExclusivePrices) {
            return callback(...args);
        }

        if (!formattedSubPrice) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="Price">
                <span
                  aria-label={ __('Current product price excl. tax') }
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { formattedSubPrice }
                </span>
            </span>
        );
    };

    renderSubPrice = (args, callback, instance) => {
        const {
            formattedFinalPrice,
            isSwitchInclusiveAndExclusivePrices,
            label
        } = instance.props;

        if (!isSwitchInclusiveAndExclusivePrices) {
            return callback(...args);
        }

        const { itemProp = null, content = null } = instance.getCurrentPriceSchema();

        return (
            <span
              aria-label={ __('Current product price incl. tax') }
              itemProp={ itemProp }
              content={ content }
              block="ProductPrice"
              elem="SubPrice"
            >
                { instance.renderPriceBadge(label) }
                { __('Incl. tax: ') }
                { instance.renderOldPrice(true) }
                { formattedFinalPrice }
            </span>
        );
    };

    renderOldPrice = (args, callback, instance) => {
        const { isSwitchInclusiveAndExclusivePrices } = instance.props;
        const [forceRender] = args;

        if (!isSwitchInclusiveAndExclusivePrices || forceRender) {
            return callback(...args);
        }

        return null;
    };

    render = (args, callback, instance) => {
        const { isSwitchInclusiveAndExclusivePrices } = instance.props;
        const result = callback(...args);

        if (!isSwitchInclusiveAndExclusivePrices) {
            return result;
        }

        const { props, props: { className } } = result;

        return cloneElement(result, {
            ...props,
            className: `${className} ProductPrice_isSwitchInclusiveAndExclusivePrices`
        });
    };
}

const {
    render,
    renderCurrentPrice,
    renderOldPrice,
    renderSubPrice
} = new ProductPriceComponent();

export default {
    'Component/ProductPrice/Component': {
        'member-function': {
            render,
            renderCurrentPrice,
            renderOldPrice,
            renderSubPrice
        }
    }
};

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isSwitchInclusiveAndExclusivePrices:
        state.SaasConfigReducer.config?.switch_inclusive_and_exclusive_prices
});

export class ProductPriceContainer {
    containerProps = (args, callback, instance) => {
        const { isSwitchInclusiveAndExclusivePrices } = instance.props;

        return {
            ...callback(...args),
            isSwitchInclusiveAndExclusivePrices
        };
    };
}

const {
    containerProps
} = new ProductPriceContainer();

export default {
    'Component/ProductPrice/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductPrice/Container': {
        'member-function': {
            containerProps
        }
    }
};

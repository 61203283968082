export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isReorderEnabled: state.SaasConfigReducer.config?.reorder_enabled ?? true
});

export class MyAccountOrderPopupContainer {
    containerProps = (args, callback, { props: { isReorderEnabled } }) => ({
        ...callback(...args),
        isReorderEnabled
    });
}

const {
    containerProps
} = new MyAccountOrderPopupContainer();

export default {
    'Component/MyAccountOrderPopup/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MyAccountOrderPopup/Container': {
        'member-function': {
            containerProps
        }
    }
};

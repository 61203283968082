import { getInitialState } from 'Store/Checkout/Checkout.reducer';

export const UPDATE_ACTIVE_PROMOTIONS = 'UPDATE_ACTIVE_PROMOTIONS';

export const updateActivePromotions = (activePromotions) => ({
    type: UPDATE_ACTIVE_PROMOTIONS,
    activePromotions
});

export const _getInitialState = (args, callback) => ({
    ...callback(...args),
    activePromotions: []
});

export const CheckoutReducer = (args, callback) => {
    const [state = getInitialState(), action] = args;

    const {
        activePromotions,
        type
    } = action;

    switch (type) {
    case UPDATE_ACTIVE_PROMOTIONS:
        return {
            ...state,
            activePromotions
        };

    default:
        return callback(...args);
    }
};

export default {
    'Store/Checkout/Reducer/getInitialState': {
        function: _getInitialState
    },
    'Store/Checkout/Reducer/checkoutReducer': {
        function: CheckoutReducer
    }
};

export * from 'SourceUtil/Product';

/** @namespace Pwasaas/Util/Product/Index/mapAttributeOrder */
export const mapAttributeOrder = (attributes) => attributes;

/** @namespace Pwasaas/Util/Product/Index/getFilteredSelectedAttributes */
export const getFilteredSelectedAttributes = (
    attributes,
    type,
    contains
) => {
    const filteredAttributes = Object.entries(attributes)
        .reduce((acc, [key, val]) => {
            if (val.attribute_type === type && !contains) {
                return acc;
            }

            if (val.attribute_type !== type && contains) {
                return acc;
            }

            return {
                ...acc,
                [key]: val
            };
        }, {});

    return mapAttributeOrder(filteredAttributes);
};

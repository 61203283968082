export const SET_IS_LOADING = 'SET_IS_LOADING';
export const UPDATE_ACTIVE_PAGE = 'UPDATE_ACTIVE_PAGE';
export const UPDATE_ACTIVE_ROOT = 'UPDATE_ACTIVE_ROOT';
export const UPDATE_CATEGORY_POSTS = 'UPDATE_CATEGORY_POSTS';
export const UPDATE_CURRENT_RELATED_POSTS = 'UPDATE_CURRENT_RELATED_POSTS';
export const UPDATE_POST_RELATED_PRODUCTS = 'UPDATE_POST_RELATED_PRODUCTS';
export const UPDATE_POST_TAGS = 'UPDATE_POST_TAGS';
export const UPDATE_ROOT_CATEGORIES = 'UPDATE_ROOT_CATEGORIES';

/** @namespace Pwasaas/Store/Blog/Action/setIsLoading */
export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    isLoading
});

/** @namespace Pwasaas/Store/Blog/Action/updateActivePage */
export const updateActivePage = (activePage) => ({
    type: UPDATE_ACTIVE_PAGE,
    activePage
});

/** @namespace Pwasaas/Store/Blog/Action/updateActiveRoot */
export const updateActiveRoot = (activeRoot) => ({
    type: UPDATE_ACTIVE_ROOT,
    activeRoot
});

/** @namespace Pwasaas/Store/Blog/Action/updateCategoryPosts */
export const updateCategoryPosts = (postItems) => ({
    type: UPDATE_CATEGORY_POSTS,
    postItems
});

/** @namespace Pwasaas/Store/Blog/Action/updateCurrentRelatedPosts */
export const updateCurrentRelatedPosts = (relatedPosts) => ({
    type: UPDATE_CURRENT_RELATED_POSTS,
    relatedPosts
});

/** @namespace Pwasaas/Store/Blog/Action/updatePostRelatedProducts */
export const updatePostRelatedProducts = (relatedProducts) => ({
    type: UPDATE_POST_RELATED_PRODUCTS,
    relatedProducts
});

/** @namespace Pwasaas/Store/Blog/Action/updatePostTags */
export const updatePostTags = (tags) => ({
    type: UPDATE_POST_TAGS,
    tags
});

/** @namespace Pwasaas/Store/Blog/Action/updateRootCategories */
export const updateRootCategories = ({ categories, settings }) => ({
    type: UPDATE_ROOT_CATEGORIES,
    categories,
    settings
});

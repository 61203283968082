import Field from 'Component/Field';
import Link from 'Component/Link';

import '../style/CheckoutBilling.manual.style';

export class CheckoutBillingComponent {
    renderTermsAndConditions = (args, callback, instance) => {
        const result = callback(...args);
        const { activePromotions } = instance.props;

        if (!activePromotions.length) {
            return result;
        }

        return (
            <>
                { activePromotions.map((item, index) => this.renderPromotionCheckbox(item, index, instance)) }
                { result }
            </>
        );
    };

    renderPromotionCheckbox = ({
        checkout_text: label,
        name,
        pdf_url: pdfUrl,
        sales_promotion_id: promotionId
    }, index, instance) => {
        const {
            handlePromotionCheckboxChange,
            promotionSubscribers: {
                [promotionId]: isChecked
            } = {}
        } = instance.props;

        const checkboxLabel = label || __('I would like to participate in %s', name);

        return (
            <div
              block="CheckoutBilling"
              elem="Promotion"
              key={ promotionId || index }
            >
                <Field
                  id={ `promotion_${ promotionId }` }
                  name={ `promotion_${ promotionId }` }
                  type="checkbox"
                  mix={ { block: 'CheckoutBilling', elem: 'PromotionCheckbox' } }
                  checked={ isChecked }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ () => handlePromotionCheckboxChange(promotionId) }
                />
                <label
                  block="CheckoutBilling"
                  elem="PromotionLabel"
                  htmlFor={ `promotion_${ promotionId }` }
                >
                    { `${checkboxLabel }` }
                    { this.renderCheckboxLink(pdfUrl) }
                </label>
            </div>

        );
    };

    renderCheckboxLink(pdfUrl) {
        if (!pdfUrl) {
            return null;
        }

        return (
            <>
                { ' - ' }
                <Link
                  block="CheckoutBilling"
                  elem="PromotionLink"
                  to={ pdfUrl }
                  target="_blank"
                >
                        { `${__('read more')}` }
                </Link>
            </>
        );
    }
}

const {
    renderTermsAndConditions
} = new CheckoutBillingComponent();

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderTermsAndConditions
        }
    }
};

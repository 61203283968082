import MyAccountAddressTable from './component/MyAccountAddressTable.component';
import { CUSTOMER_COMPANY_DATA_FIX_ENABLED } from './CustomerCompanyData.config';
import MyAccountQuery from './query/MyAccount.query';

const plugin = () => (CUSTOMER_COMPANY_DATA_FIX_ENABLED
    ? {
        ...MyAccountAddressTable,
        ...MyAccountQuery
    }
    : {});

export default plugin();

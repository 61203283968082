import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductLabelType } from '../../type/ProductLabel';
import SaasProductLabels from './SaasProductLabels.component';
import { RIGHT_POSITION } from './SaasProductLabels.config';

/** @namespace Pwasaas/Plugin/ProductLabel/Component/SaasProductLabels/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    baseUrl: state.ConfigReducer.base_url
});

/** @namespace Pwasaas/Plugin/ProductLabel/Component/SaasProductLabels/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Plugin/ProductLabel/Component/SaasProductLabels/Container/SaasProductLabelsContainer */
export class SaasProductLabelsContainer extends PureComponent {
    static propTypes = {
        baseUrl: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(ProductLabelType).isRequired
    };

    containerProps = () => ({
        baseUrl: this._getBaseUrl(),
        defaultItems: this._getDefaultItems(),
        positionRightItem: this._getPositionRightItem()
    });

    _getBaseUrl() {
        const { baseUrl } = this.props;

        return baseUrl;
    }

    _getDefaultItems() {
        const { items } = this.props;
        return items.filter(({ position }) => position !== RIGHT_POSITION);
    }

    _getPositionRightItem() {
        const { items } = this.props;
        return items.find(({ position }) => position === RIGHT_POSITION) || {};
    }

    render() {
        return (
            <SaasProductLabels
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaasProductLabelsContainer);

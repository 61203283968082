/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import { CATEGORY } from 'Component/Header/Header.config';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductCard from 'Component/ProductCard';
import { BLOG_DETAIL_PAGE } from 'Route/BlogDetailPage/BlogDetailPage.config';
import { BLOG_PAGE } from 'Route/BlogPage/BlogPage.config';
import { BlogCategoryType } from 'Type/Blog';
import { ProductType } from 'Type/ProductList';
import { getIndexedProduct } from 'Util/Product';

import { BLOG, PRODUCT } from './PageHeader.config';

import './PageHeader.style';

/** @namespace Pwasaas/Component/PageHeader/Component/PageHeaderComponent */
export class PageHeaderComponent extends PureComponent {
    static propTypes = {
        buttonTitle: PropTypes.string.isRequired,
        buttonUrl: PropTypes.string.isRequired,
        currentState: PropTypes.shape({
            name: PropTypes.string
        }).isRequired,
        description: PropTypes.string.isRequired,
        detailImage: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        isVisible: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        widget: PropTypes.shape({
            item: PropTypes.oneOfType([
                BlogCategoryType,
                ProductType
            ]),
            type: PropTypes.string
        }).isRequired
    };

    activePages = [
        CATEGORY,
        BLOG_DETAIL_PAGE,
        BLOG_PAGE
    ];

    renderBlogWidget() {
        const {
            widget: {
                item: blog
            }
        } = this.props;

        if (!Object.keys(blog).length) {
            return null;
        }

        return (
            <div>
                { blog.title }
            </div>
        );
    }

    renderProductWidget() {
        const {
            widget: {
                item: product
            }
        } = this.props;

        if (!Object.keys(product).length) {
            return null;
        }

        return (
            <div
              block="PageHeader"
              elem="WidgetWrapper"
              mods={ { isProduct: true } }
            >
                <div
                  block="PageHeader"
                  elem="ContentProduct"
                  mods={ { isBlur: true } }
                >
                    <ProductCard
                      key={ product.id }
                      product={ getIndexedProduct(product) }
                    />
                </div>
            </div>
        );
    }

    renderWidget() {
        const {
            widget: {
                type = ''
            }
        } = this.props;

        switch (type) {
        case PRODUCT:
            return this.renderProductWidget();

        case BLOG:
            return this.renderBlogWidget();

        default:
            return null;
        }
    }

    renderDetailImage() {
        const {
            detailImage,
            name
        } = this.props;

        if (!detailImage) {
            return null;
        }

        return (
            <div
              block="PageHeader"
              elem="DetailImage"
            >
                <Image
                  src={ detailImage }
                  mix={ {
                      block: 'PageHeader',
                      elem: 'DetailPicture'
                  } }
                  ratio="custom"
                  alt={ name }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ detailImage }
                />
            </div>
        );
    }

    renderButton() {
        const { buttonTitle, buttonUrl } = this.props;

        if (!buttonTitle) {
            return null;
        }

        return (
            <div
              block="PageHeader"
              elem="Button"
            >
                <Link
                  block="Button"
                  mix={ {
                      block: 'Button',
                      mods: { isHollow: true }
                  } }
                  to={ buttonUrl }
                >
                    { buttonTitle }
                </Link>
            </div>
        );
    }

    renderContent() {
        const {
            description,
            name
        } = this.props;

        return (
            <>
                <ContentWrapper
                  mix={ {
                      block: 'PageHeader',
                      elem: 'ContentWrapper'
                  } }
                  wrapperMix={ {
                      block: 'PageHeader',
                      elem: 'Content'
                  } }
                  label="Page header content"
                >
                    { this.renderDetailImage() }
                    <div
                      block="PageHeader"
                      elem="ContentText"
                    >
                        <h1>{ name }</h1>
                        <Html content={ description || '' } />
                    </div>
                    { this.renderButton() }
                </ContentWrapper>
                { this.renderWidget() }
            </>
        );
    }

    renderPlaceholder() {
        return (
            <Image
              isPlaceholder
              width="100%"
              ratio="custom"
            />
        );
    }

    renderImage() {
        const {
            image,
            name
        } = this.props;

        if (!image) {
            return this.renderPlaceholder();
        }

        return (
            <Image
              mix={ { block: 'PageHeader', elem: 'Image' } }
              alt={ name }
              src={ image }
              width="100%"
              ratio="custom"
            />
        );
    }

    render() {
        const {
            currentState: {
                name
            },
            isVisible
        } = this.props;

        if (!isVisible || !this.activePages.includes(name)) {
            return null;
        }

        return (
            <section
              block="PageHeader"
              aria-label="Page header"
              mods={ { type: name } }
            >
                { this.renderImage() }
                { this.renderContent() }
            </section>
        );
    }
}

export default PageHeaderComponent;

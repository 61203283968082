import {
    ADDITION,
    HOUSENUMBER
} from 'Component/SaasCheckoutForm/SaasCheckoutForm.config';
import { getStore } from 'Util/Store';

import { ADDRESS_LINES_QTY } from '../PostcodeCheck.config';

export const getPostcodeAddressInfo = (address) => Object.entries(address).reduce(
    (fields, [name, value]) => {
        if (name === ADDITION) {
            return fields;
        }

        return {
            ...fields,
            [name]: name === HOUSENUMBER && address[ADDITION]
                ? `${value}-${address[ADDITION]}`
                : value
        };
    }, {}
);

export const getFormFields = (args, callback) => {
    const { postcode_check_enabled = false } = getStore().getState().SaasConfigReducer.config || {};

    if (!postcode_check_enabled) {
        return callback(...args);
    }

    const fields = getPostcodeAddressInfo(args[0]);

    return callback(fields, ADDRESS_LINES_QTY);
};

export default {
    'Util/Address/getFormFields': {
        function: getFormFields
    }
};

import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

export class ProductSectionNavigationComponent {
    sectionMap = (originalMap, { props: { isThumbnailRelatedProductEnabled } }) => {
        if (isThumbnailRelatedProductEnabled) {
            return Object.entries(originalMap).reduce(
                (sectionMap, [key, value]) => {
                    if (key === RELATED) {
                        return sectionMap;
                    }

                    return {
                        ...sectionMap,
                        [key]: value
                    };
                }, {}
            );
        }

        return originalMap;
    };
}

const {
    sectionMap
} = new ProductSectionNavigationComponent();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Component/ProductSectionNavigationComponent': {
        'member-property': {
            sectionMap
        }
    }
};

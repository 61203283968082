export class FieldContainer {
    containerProps = (args, callback, instance) => {
        const { isSelect } = instance.props;

        return {
            ...callback(...args),
            isSelect
        };
    };
}

const {
    containerProps
} = new FieldContainer();

export default {
    'Component/Field/Container': {
        'member-function': {
            containerProps
        }
    }
};

import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isShowStoreSwitcher: state.SaasLayoutReducer.header?.is_show_store_switcher || false
    };
};

export class StoreSwitcherContainer {
    containerProps = (args, callback, instance) => {
        const { isShowStoreSwitcher } = instance.props;

        return {
            ...callback(...args),
            isShowStoreSwitcher
        };
    };

    _formatStoreList = (args) => args[0].reduce((acc, {
        name, code, is_active, base_url, base_link_url, locale
    }) => {
        if (!is_active) {
            return acc;
        }

        return [
            ...acc,
            {
                id: `store_${ code }`,
                value: code,
                storeUrl: base_url,
                storeLinkUrl: base_link_url,
                label: name,
                locale,
                flag: `/media/flags/${code}.jpg`
            }
        ];
    }, []);

    _handleStoreSelect = (args, _callback, instance) => {
        const [storeCode] = args;
        const { showErrorNotification } = instance.props;
        const { storeList } = instance.state;

        const store = storeList.find(
            ({ value }) => value === storeCode
        );

        if (!store) {
            showErrorNotification(__('This store can not be opened!'));
            return;
        }

        BrowserDatabase.deleteItem(STORE_CONFIG_KEY);

        const { storeLinkUrl, locale } = store;
        window.location = `${storeLinkUrl}?language=${locale}`;
    };
}

const {
    _formatStoreList,
    _handleStoreSelect,
    containerProps
} = new StoreSwitcherContainer();

export default {
    'Component/StoreSwitcher/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/StoreSwitcher/Container': {
        'member-function': {
            _formatStoreList,
            _handleStoreSelect,
            containerProps
        }
    }
};

import { Field } from 'Util/Query';

export class CheckoutQuery {
    _getSaveAddressInformationFields = (args, callback) => [
        ...callback(...args),
        this.getActiveSalesPromotionsField()
    ];

    getActiveSalesPromotionsField() {
        return new Field('active_sale_promotions')
            .addFieldList([
                'checkout_text',
                'name',
                'pdf_url',
                'sales_promotion_id'
            ]);
    }
}

const {
    _getSaveAddressInformationFields
} = new CheckoutQuery();

export default {
    'Query/Checkout': {
        'member-function': {
            _getSaveAddressInformationFields
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import { AdditionalEmailType } from '../../type/AdditionalEmails';
import MyAccountAdditionalEmailsForm from '../MyAccountAdditionalEmailsForm';
import { ADDITIONAL_EMAILS_POPUP_ID } from './MyAccountAdditionalEmails.config';

import './MyAccountAdditionalEmails.style';

/** @namespace Pwasaas/Plugin/MageMacleanAdditionalEmails/Component/MyAccountAdditionalEmails/Component/MyAccountAdditionalEmailsComponent */
export class MyAccountAdditionalEmailsComponent extends PureComponent {
    static propTypes = {
        additionalEmails: PropTypes.arrayOf(AdditionalEmailType).isRequired,
        isLoading: PropTypes.bool.isRequired,
        onDeleteEmail: PropTypes.func.isRequired,
        onSaveEmail: PropTypes.func.isRequired,
        showCreateNewPopup: PropTypes.func.isRequired
    };

    renderPopup() {
        const { onSaveEmail } = this.props;

        return (
            <Popup
              id={ ADDITIONAL_EMAILS_POPUP_ID }
              mix={ { block: 'MyAccountAdditionalEmailsPopup' } }
            >
                <MyAccountAdditionalEmailsForm onSave={ onSaveEmail } />
            </Popup>
        );
    }

    renderTableHeadingRow() {
        return (
            <tr>
                <th>{ __('Name') }</th>
                <th>{ __('Email') }</th>
                <th>{ __('Action') }</th>
            </tr>
        );
    }

    renderNoEmails() {
        return (
            <tr
              block="MyAccountAdditionalEmails"
              elem="NoEmails"
            >
                <td colSpan="3">{ __('You have no additional emails.') }</td>
            </tr>
        );
    }

    renderTableRow = (item, index) => {
        const { onDeleteEmail } = this.props;

        return (
            <tr
              block="MyAccountAdditionalEmails"
              elem="TableRow"
              key={ item.id || index }
            >
                <td>{ item.name }</td>
                <td>{ item.email }</td>
                <td>
                    <button
                      block="MyAccountAdditionalEmails"
                      elem="Delete"
                      aria-label="Remove item from additional email list"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => {
                          onDeleteEmail(item.id);
                      } }
                    >
                        <CloseIcon />
                    </button>
                </td>
            </tr>
        );
    };

    renderTableRows() {
        const { additionalEmails } = this.props;

        if (!additionalEmails.length) {
            return this.renderNoEmails();
        }

        return additionalEmails.map(this.renderTableRow);
    }

    renderTable() {
        return (
            <table block="MyAccountAdditionalEmails" elem="Table">
                <thead>
                    { this.renderTableHeadingRow() }
                </thead>
                <tbody>
                    { this.renderTableRows() }
                </tbody>
            </table>
        );
    }

    renderActions() {
        const { showCreateNewPopup } = this.props;

        return (
            <button
              block="Button"
              mix={ { block: 'MyAccountAdditionalEmails', elem: 'Button' } }
              mods={ { isHollow: true } }
              onClick={ showCreateNewPopup }
            >
                { __('Add new email') }
            </button>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="MyAccountAdditionalEmails">
                <Loader isLoading={ isLoading } />
                { this.renderActions() }
                { this.renderTable() }
                { this.renderPopup() }
            </div>
        );
    }
}

export default MyAccountAdditionalEmailsComponent;

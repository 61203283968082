import FreeShippingThreshold from 'Component/FreeShippingThreshold';

export class CartOverlayComponent {
    renderPromo = (args, callback, instance) => {
        const {
            isFreeShippingThresholdEnabled,
            totals
        } = instance.props;

        if (!isFreeShippingThresholdEnabled) {
            return callback(...args);
        }

        return (
            <p
              block="CartOverlay"
              elem="Promo"
            >
                <FreeShippingThreshold totals={ totals } />
            </p>
        );
    };
}

const {
    renderPromo
} = new CartOverlayComponent();

export default {
    'Pwasaas/Component/CartOverlay/Component/CartOverlayComponent': {
        'member-function': {
            renderPromo
        }
    }
};

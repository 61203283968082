import {
    mapStateToProps
} from '../util/PageHeader';

export class BlogDetailPageContainer {
    containerProps = (args, callback, instance) => {
        const { isPageHeaderVisible } = instance.props;

        return {
            ...callback(...args),
            isPageHeaderVisible
        };
    };
}

const {
    containerProps
} = new BlogDetailPageContainer();

export default {
    'Pwasaas/Route/BlogDetailPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Route/BlogDetailPage/Container/BlogDetailPageContainer': {
        'member-function': {
            containerProps
        }
    }
};

import { cloneElement, createRef } from 'react';

import {
    getElementHeight,
    getFixedElementHeight
} from 'Util/CSS';

export class ProductListComponent {
    productListRef = createRef();

    componentDidUpdate = (args, callback, instance) => {
        const [prevProps] = args;
        const {
            currentPage,
            isInfiniteLoaderEnabled,
            isWidget
        } = instance.props;
        const { currentPage: prevCurrentPage } = prevProps;
        const elem = this.productListRef && this.productListRef.current;

        const shouldUpdate = prevCurrentPage !== currentPage
            && !isWidget
            && !!elem;

        if (!shouldUpdate || isInfiniteLoaderEnabled) {
            callback(...args);

            return;
        }

        /** Get fixed header height */
        const { top: headerHeight } = getFixedElementHeight();
        const headerOffsetFix = headerHeight + getElementHeight('StoreNotice');
        const elemToWindowTopDist = elem.getBoundingClientRect().top;
        const windowToPageTopDist = document.body.getBoundingClientRect().top;
        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist - headerOffsetFix;

        if (headerOffsetFix === elemToWindowTopDist) {
            return;
        }

        window.scrollTo({ top: topToElemDistance, behavior: 'smooth' });
    };

    /**
     * Add ref to product list
     * Add pagination to top of product list
     * */
    render = (args, callback, instance) => {
        const result = callback(instance, args);
        const { props, props: { children: baseChildren } } = result;

        /**
         * Todo: Move 'pagination on top' to SaasLayout setting
         */
        const children = Array.isArray(baseChildren)
            ? [instance.renderPagination(), ...baseChildren]
            : [instance.renderPagination(), baseChildren];

        return cloneElement(result, {
            ...props,
            children,
            ref: this.productListRef
        });
    };
}

const {
    componentDidUpdate,
    render
} = new ProductListComponent();

export default {
    'Component/ProductList/Component': {
        'member-function': {
            componentDidUpdate,
            render
        }
    }
};

import { getInitialState } from '../../SaasTheme/store/SaasTheme/SaasTheme.reducer';

export const UPDATE_BUTTONS = 'UPDATE_BUTTONS';
export const UPDATE_COLORS = 'UPDATE_COLORS';

export const updateButtons = (buttons) => ({
    type: UPDATE_BUTTONS,
    buttons
});

export const updateColors = (colors) => ({
    type: UPDATE_COLORS,
    colors
});

export const SaasThemeReducer = (args, callback) => {
    const [state = getInitialState(), action] = args;

    const {
        type,
        buttons,
        colors
    } = action;

    switch (type) {
    case UPDATE_BUTTONS:
        return {
            ...state,
            buttons: {
                ...state.buttons,
                ...buttons
            }
        };

    case UPDATE_COLORS:
        return {
            ...state,
            colors: {
                ...state.colors,
                ...colors
            }
        };

    default:
        return callback(...args);
    }
};

export default {
    'Pwasaas/Plugin/SaasTheme/Store/SaasTheme/Reducer/SaasThemeReducer': {
        function: SaasThemeReducer
    }
};

import {
    ProductAttributesContainer as SourceProductAttributesContainer
} from 'SourceComponent/ProductAttributes/ProductAttributes.container';

import ProductAttributes from './ProductAttributes.component';

/** @namespace Pwasaas/Component/ProductAttributes/Container/ProductAttributesContainer */
export class ProductAttributesContainer extends SourceProductAttributesContainer {
    render() {
        return (
            <ProductAttributes
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductAttributesContainer;

/**
 * Increased max products
 */
export const DEFAULT_MAX_PRODUCTS = 300;
export const ONE_HUNDRED_PERCENT = 100;

export const ADD_TO_CART = 'add_to_cart';
export const BUNDLE_ITEMS = 'bundle_items';
export const GROUPED_ITEMS = 'grouped_items';
export const CONFIGURABLE_ATTRIBUTES = 'configurable_attributes';
export const CUSTOMIZABLE_OPTIONS = 'customizable_options';
export const PDF_DOWNLOADER = 'pdf_downloader';
export const REVIEWS = 'reviews';
export const SHORT_DESCRIPTION = 'short_description';
export const TIER_PRICES = 'tier_prices';
export const TITLE = 'title';
export const USP = 'usp';

export const SHOW_PRODUCT_STOCK_STATUS = 'show_product_stock_status';
export const SHOW_THUMBNAIL_RELATED_PRODUCT_LIST = 'show_thumbnail_related_product_list';

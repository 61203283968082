import { cloneElement } from 'react';

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isReorderEnabled: state.SaasConfigReducer.config?.reorder_enabled ?? true
});

export class MyAccountMyOrdersContainer {
    containerProps = (args, callback, { props: { isReorderEnabled } }) => ({
        ...callback(...args),
        isReorderEnabled
    });

    render = (args, callback = () => {}, instance) => {
        const RenderedComponent = callback(instance, args);
        const { isReorderEnabled } = instance.props;

        const {
            isWidget,
            maxOrdersToShow,
            orderList: currentList
        } = instance.props;

        if (!isReorderEnabled || !isWidget) {
            return RenderedComponent;
        }

        const { props } = RenderedComponent;
        const orderList = currentList.slice(Math.max(currentList.length - maxOrdersToShow, 0));

        return cloneElement(RenderedComponent, {
            ...props,
            orderList
        });
    };
}

const {
    containerProps,
    render
} = new MyAccountMyOrdersContainer();

export default {
    'Component/MyAccountMyOrders/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MyAccountMyOrders/Container': {
        'member-function': {
            containerProps,
            render
        }
    }
};

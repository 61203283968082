export const QUANTITY = 'quantity';

export class ProductCardComponent {
    shouldComponentUpdate = (args, callback, instance) => {
        const { props } = instance;
        const [nextProps] = args;
        const result = callback(...args);

        if (props[QUANTITY] !== nextProps[QUANTITY]) {
            return true;
        }

        return result;
    };
}

const {
    shouldComponentUpdate
} = new ProductCardComponent();

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            shouldComponentUpdate
        }
    }
};

import SaasDate from 'Util/Saas';

export const AVAILABLE_DAYS = {
    sun: 'sunday',
    mon: 'monday',
    tue: 'tuesday',
    wed: 'wednesday',
    thu: 'thursday',
    fri: 'friday',
    sat: 'saturday'
};

export const MINUTES_IN_ONE_HOUR = 60;

export const ORDER_DATE_FIELDS = [
    'open_hour',
    'close_hour'
];

export const DEFAULT_TIME = '00,00,00';

export const UPCOMING_PERIOD_IN_DAYS = 14;

/** @namespace Pwasaas/Util/OrderDate/Index/getFormattedDate */
export const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

/** @namespace Pwasaas/Util/OrderDate/Index/getFormattedTime */
export const getFormattedTime = (date) => {
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');

    return `${hours}:${minutes}`;
};

/** @namespace Pwasaas/Util/OrderDate/Index/getFormattedDates */
export const getFormattedDates = (days = 1) => {
    if (days < 1) {
        return [];
    }

    const thisDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
    );
    const year = thisDay.getFullYear();
    const month = thisDay.getMonth();
    const day = thisDay.getDate();

    const formattedDays = Array.from(Array(days), (_, i) => {
        const date = new Date(year, month, day + i);

        return {
            day: Object.values(AVAILABLE_DAYS)[date.getDay()],
            date: getFormattedDate(date)
        };
    });

    return formattedDays;
};

/** @namespace Pwasaas/Util/OrderDate/Index/getAvailableDateAndTimeRange */
export const getAvailableDateAndTimeRange = (
    dates,
    interval,
    currentTime,
    isDelivery,
    timeToDeliver,
    locale = 'nl-NL'
) => dates.reduce(
    (acc, { date, open_hour, close_hour }) => {
        const times = [];
        const openHour = Number(open_hour.substring(0, 2));
        const closeHour = Number(close_hour.substring(0, 2));
        const passTimes = Math.floor(MINUTES_IN_ONE_HOUR / interval);

        // eslint-disable-next-line fp/no-let
        for (let hour = openHour; hour <= closeHour; hour++) {
            Array.from(Array(passTimes), (_, integer) => integer).forEach(
                (integer, index) => {
                    if (index === 0) {
                        times.push([hour, 0]);

                        return;
                    }

                    if (hour < closeHour) {
                        times.push([hour, interval * integer]);
                    }
                }
            );
        }

        const newDate = new Date();
        const formatter = new Intl.DateTimeFormat(locale, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        });

        const compareDate = new Date();
        const [derivedHour, derivedTime] = times[0];
        compareDate.setHours(derivedHour);
        compareDate.setMinutes(derivedTime);
        const compareFormatted = formatter.format(compareDate);

        const range = times.reduce((filtered, [hour, minute]) => {
            newDate.setHours(hour);
            newDate.setMinutes(minute);

            const formatted = formatter.format(newDate);

            if (isDelivery && timeToDeliver > 0) {
                newDate.setHours(hour);
                newDate.setMinutes(minute - timeToDeliver);

                const deliverFormatted = formatter.format(newDate);

                if (deliverFormatted >= compareFormatted) {
                    filtered.push({
                        label: `${formatter.format(newDate) } - ${ formatted}`,
                        value: formatted
                    });
                }
            } else {
                filtered.push({
                    label: formatted,
                    value: formatted
                });
            }

            return filtered;
        }, []);

        const today = new SaasDate().getDate();
        const timeRange = date === today
            ? range.filter((time) => time.value > currentTime)
            : range;

        if (timeRange.length) {
            acc.push({
                date,
                timeRange
            });
        }

        return acc;
    }, []
);

/** @namespace Pwasaas/Util/OrderDate/Index/getAvailableOrderDates */
export const getAvailableOrderDates = (
    orderDates,
    holidays
) => {
    const upcomingDates = getFormattedDates(UPCOMING_PERIOD_IN_DAYS);
    return upcomingDates.reduce(
        (result, { day, date }) => {
            if (orderDates[day] && !holidays.some(({ date: holiday }) => date === holiday)) {
                result.push({
                    date,
                    ...orderDates[day]
                });
            }

            return result;
        }, []
    );
};

/** @namespace Pwasaas/Util/OrderDate/Index/getOrderDateValuesFrom */
export const getOrderDateValuesFrom = (item) => Object.entries(AVAILABLE_DAYS).reduce(
    (prev, [index, value]) => {
        const keyValues = ORDER_DATE_FIELDS.reduce(
            (prevDay, fieldIndex) => {
                const timeValue = item[`${index }_${ fieldIndex}`];

                return item[`${index }_allowed`]
                    ? {
                        ...prevDay,
                        [fieldIndex]: timeValue
                            ? timeValue.replace(/,/g, ':')
                            : DEFAULT_TIME
                    }
                    : { ...prevDay };
            }, {}
        );

        return (!prev[index] && Object.keys(keyValues).length)
            ? { ...prev, [value]: { ...keyValues } }
            : { ...prev };
    }, {}
);

/** @namespace Pwasaas/Util/OrderDate/Index/removeStaticValuesFromAndReturn */
export const removeStaticValuesFromAndReturn = (itemToChange) => {
    const availableFields = [...ORDER_DATE_FIELDS, 'allowed'];
    const omitSingle = (key, { [key]: _, ...obj }) => obj;

    // eslint-disable-next-line fp/no-let
    let item = itemToChange;

    Object.keys(AVAILABLE_DAYS).forEach((day) => {
        availableFields.forEach((field) => {
            const dayField = `${day }_${ field}`;

            if (!Object.prototype.hasOwnProperty.call(item, dayField)) {
                return;
            }

            item = omitSingle(dayField, item);
        });
    });

    return item;
};

import { LOGIN } from 'Component/Header/Header.config';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isEnabledForceLogin: state.SaasConfigReducer.config?.enable_force_login
    };
};

export const onSignOut = (args, callback, instance) => {
    const { isEnabledForceLogin } = instance.props;

    if (isEnabledForceLogin) {
        window.location = `${window.location.origin }/${ LOGIN}`;
    }

    return callback(args);
};

export default {
    'Route/MyAccount/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/MyAccount/Container': {
        'member-function': {
            onSignOut
        }
    }
};

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/Newsletter/Query/NewsletterQuery */
export class NewsletterQuery {
    getSubscribeToNewsletterMutation(quoteId) {
        return new Field('subscribeToNewsletter')
            .addArgument('cart_id', 'String', quoteId);
    }
}

export default new NewsletterQuery();

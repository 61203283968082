import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './MenuAside.style';

/** @namespace Pwasaas/Component/MenuAside/Component/MenuAsideComponent */
export class MenuAsideComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired,
        widgetType: PropTypes.string.isRequired
    };

    render() {
        const {
            content,
            widgetType
        } = this.props;

        if (!content) {
            return null;
        }

        return (
            <div
              block="MenuAside"
              elem="Container"
              mods={ {
                  type: widgetType || false
              } }
            >
                <Html
                  content={ content }
                  itemMix={ {
                      block: 'MenuAside',
                      elem: 'Content'
                  } }
                />
            </div>
        );
    }
}

export default MenuAsideComponent;

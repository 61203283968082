import FreeShippingThreshold from 'Component/FreeShippingThreshold';

export class CartPageComponent {
    renderTotals(args, callback, instance) {
        const { totals } = instance.props;

        return (
            <>
            { callback(...args) }
            <FreeShippingThreshold totals={ totals } elem="Page" />
            </>
        );
    }
}

const {
    renderTotals
} = new CartPageComponent();

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderTotals
        }
    }
};

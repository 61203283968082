import CompleteOrderReducer from '../store/CompleteOrder/CompleteOrder.reducer';

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    CompleteOrderReducer
});

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

import { getInitialState } from 'Store/SaasConfig/SaasConfig.reducer';

export const FORCE_DISABLE_POSTCODE_CHECK = 'FORCE_DISABLE_POSTCODE_CHECK';

export const forceDisablePostcodeCheck = (isForcedDisablePostcodeCheck) => ({
    type: FORCE_DISABLE_POSTCODE_CHECK,
    isForcedDisablePostcodeCheck
});

export const _getInitialState = (args, callback, context) => ({
    // eslint-disable-next-line react/destructuring-assignment
    ...callback.apply(context, args),
    isForcedDisablePostcodeCheck: false
});

export const SaasConfigReducer = (args, callback) => {
    const [state = getInitialState(), action] = args;

    const {
        isForcedDisablePostcodeCheck,
        type
    } = action;

    switch (type) {
    case FORCE_DISABLE_POSTCODE_CHECK:
        return {
            ...state,
            isForcedDisablePostcodeCheck
        };

    default:
        return callback(...args);
    }
};

export default {
    'Pwasaas/Store/SaasConfig/Reducer/getInitialState': {
        function: _getInitialState
    },
    'Pwasaas/Store/SaasConfig/Reducer/SaasConfigReducer': {
        function: SaasConfigReducer
    }
};

import {
    mapStateToProps,
    ProductPageContainer as ProductActionsContainer
} from './ProductPage.container.plugin';

const {
    containerProps
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    }
};

import { Field } from 'Util/Query';

export class AddCurrencyCustomerToQueryPlugin {
    _getCustomerFields = (args, callback) => ([
        ...callback(args),
        new Field('customer_currency')
            .addFieldList([
                'pay_currency',
                'display_currency'
            ])
    ]);
}

const {
    _getCustomerFields
} = new AddCurrencyCustomerToQueryPlugin();

export const config = {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields
        }
    }
};

export default config;

import AddAllToCart from 'Component/AddAllToCart';
import Loader from 'Component/Loader';
import history from 'Util/History';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
export const addAllToCartButton = (props) => <AddAllToCart { ...props } />;

export class ProductListComponent {
    render = (args, callback, instance) => {
        const RenderedComponent = callback(instance, args) || {};
        const { isAddAllToCart, isWidget } = instance.props;
        const { state } = history.location;

        if (
            !isAddAllToCart
            || isWidget
            || !state
        ) {
            return RenderedComponent;
        }

        const { props: { children = [], className } = {} } = RenderedComponent;

        if (!children.length) {
            return null;
        }

        const {
            isLoading,
            isLoadingAddToCart,
            isPageLoading
        } = instance.props;

        const isDisabled = isLoading || isPageLoading;

        const addButton = addAllToCartButton({
            ...instance.props,
            isDisabled
        });

        return (
            <div className={ `${className } ProductList_hasButton` }>
                <Loader isLoading={ isLoadingAddToCart } />
                { addButton }
                { children }
                { addButton }
            </div>
        );
    };
}

const {
    render
} = new ProductListComponent();

export default {
    'Component/ProductList/Component': {
        'member-function': {
            render
        }
    }
};

import { lazy } from 'react';

export const SHOW_ALL_PRODUCTS_BY_ATTRIBUTE = 'ShowAllProductsByAttribute';

export const ShowAllProductsByAttributeWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */ '../component/ShowAllProductsByAttributeWidget'
));

export const getWidgetRenderMap = (args, callback) => ({
    ...callback(...args),
    [SHOW_ALL_PRODUCTS_BY_ATTRIBUTE]: {
        component: ShowAllProductsByAttributeWidget
    }
});

export default {
    'Pwasaas/Util/Widget/getWidgetRenderMap': {
        function: getWidgetRenderMap
    }
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isGiftCardEnabled: state.ConfigReducer.allow_order || '0'
    };
};

export class CartPageContainer {
    containerProps = (args, callback, instance) => {
        const { isGiftCardEnabled } = instance.props;

        return {
            ...callback(...args),
            isGiftCardEnabled
        };
    };
}

const {
    containerProps
} = new CartPageContainer();

export default {
    'Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CartPage/Container': {
        'member-function': {
            containerProps
        }
    }
};

export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
export const UPDATE_ORDERLIST = 'UPDATE_ORDERLIST';
export const UPDATE_STATED_ORDER_LIST = 'UPDATE_STATED_ORDER_LIST';
export const UPDATE_USER_CAN_EDIT_ORDERLIST = 'UPDATE_USER_CAN_EDIT_ORDERLIST';

/** @namespace Pwasaas/Store/OrderList/Action/setLoadingStatus */
export const setLoadingStatus = (isLoading) => ({
    type: UPDATE_LOADING_STATUS,
    isLoading
});

/** @namespace Pwasaas/Store/OrderList/Action/updateOrderList */
export const updateOrderList = (items) => ({
    type: UPDATE_ORDERLIST,
    items
});

/** @namespace Pwasaas/Store/OrderList/Action/updateStatedOrderList */
export const updateStatedOrderList = (items) => ({
    type: UPDATE_STATED_ORDER_LIST,
    items
});

/** @namespace Pwasaas/Store/OrderList/Action/updateUserCanEdit */
export const updateUserCanEdit = (isEditable) => ({
    type: UPDATE_USER_CAN_EDIT_ORDERLIST,
    isEditable
});

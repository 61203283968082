import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import {
    ADD_TO_CART,
    BUNDLE_ITEMS,
    CUSTOMIZABLE_OPTIONS,
    SHOW_PRODUCT_STOCK_STATUS,
    SHOW_THUMBNAIL_RELATED_PRODUCT_LIST,
    TIER_PRICES
} from 'Component/ProductActions/ProductActions.config';

import ProductAttributeIcons from '../component/ProductAttributeIcons';

import '../style/ProductPage.manual.style';

export class ProductPageComponent {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        isProductIconsEnabled: PropTypes.bool.isRequired,
        productSKU: PropTypes.string.isRequired,
        updateIsProductIconsEnabled: PropTypes.func.isRequired
    });

    /** TODO: Refactor productActionsFilter to prevent plugin dependencies */
    renderProductPageContent = (args, callback, instance) => {
        const result = callback(...args);
        const [keepResult = false] = args;

        if (keepResult) {
            return result;
        }

        const { isProductIconsEnabled } = instance.props;
        const productActionFilter = {
            show: false,
            types: [
                SHOW_THUMBNAIL_RELATED_PRODUCT_LIST,
                SHOW_PRODUCT_STOCK_STATUS,
                ADD_TO_CART,
                CUSTOMIZABLE_OPTIONS,
                TIER_PRICES,
                BUNDLE_ITEMS
            ]
        };

        return (
            <>
                { this.renderProductPageContentItems(result, productActionFilter, isProductIconsEnabled) }
                { this.renderProductAttributeIcons(instance) }
            </>
        );
    };

    render = (args, callback, instance) => {
        const renderData = callback(...args);
        const { isProductIconsEnabled } = instance.props;

        if (!isProductIconsEnabled) {
            return renderData;
        }

        const {
            props,
            props: {
                children: renderedChildElement
            }
        } = renderData;

        const {
            props: childProps,
            props: {
                className = ''
            } = {}
        } = renderedChildElement;

        const mainTag = cloneElement(renderedChildElement, {
            ...childProps,
            className: `${className} ${className}_hasThreeColumns`
        });

        return cloneElement(renderData, {
            ...props,
            children: mainTag
        });
    };

    renderProductAttributeIcons(instance) {
        const {
            isProductIconsEnabled,
            productSKU,
            updateIsProductIconsEnabled
        } = instance.props;

        if (!productSKU) {
            return null;
        }

        const productActionFilter = {
            show: true,
            types: [
                SHOW_THUMBNAIL_RELATED_PRODUCT_LIST,
                SHOW_PRODUCT_STOCK_STATUS,
                ADD_TO_CART,
                CUSTOMIZABLE_OPTIONS,
                TIER_PRICES
            ]
        };

        if (isProductIconsEnabled) {
            productActionFilter.types.push(BUNDLE_ITEMS);
        }

        return (
            <ProductAttributeIcons
              productSKU={ productSKU }
              onSuccess={ updateIsProductIconsEnabled }
            >
                { this.renderProductPageContentItems(
                    instance.renderProductPageContent(true),
                    productActionFilter,
                    isProductIconsEnabled,
                    true
                ) }
            </ProductAttributeIcons>
        );
    }

    /** Add filter which types should render in ProductActions */
    renderProductPageContentItems(
        result,
        filter,
        isEnabled,
        shouldRenderProductActions = false
    ) {
        if (!isEnabled) {
            return result;
        }

        const { props, props: { children: baseChildren } } = result;
        const lastChild = baseChildren[baseChildren.length - 1];
        const productActions = cloneElement(lastChild, {
            ...lastChild.props,
            disableRenderMobileTitle: !!shouldRenderProductActions,
            filter
        });

        return cloneElement(result, {
            ...props,
            children: shouldRenderProductActions
                ? productActions
                : [...baseChildren.slice(0, baseChildren.length - 1), productActions]
        });
    }
}

const {
    render,
    renderProductPageContent,
    staticPropTypes
} = new ProductPageComponent();

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render,
            renderProductPageContent
        },
        'static-member': {
            propTypes: staticPropTypes
        }
    }
};

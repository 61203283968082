import ProductLabelReducer from '../store/ProductLabel/ProductLabel.reducer';

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    ProductLabelReducer
});

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

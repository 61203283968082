/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MixType } from 'Type/Common';
import { AttributeType } from 'Type/ProductList';

import SaasProductTitleBlock from './SaasProductTitleBlock.component';

/** @namespace Pwasaas/Component/SaasProductTitleBlock/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    productViewAttributes: _state.SaasConfigReducer.productViewAttributes
});

/** @namespace Pwasaas/Component/SaasProductTitleBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/SaasProductTitleBlock/Container/SaasProductTitleBlockContainer */
export class SaasProductTitleBlockContainer extends PureComponent {
    static propTypes = {
        attributes: AttributeType,
        isHeading: PropTypes.bool,
        mix: MixType,
        name: PropTypes.string,
        productViewAttributes: PropTypes.objectOf(PropTypes.string).isRequired
    };

    static defaultProps = {
        isHeading: false,
        mix: {},
        name: '',
        attributes: {}
    };

    _validateLabels(
        attributes,
        attribute_code
    ) {
        if (!Object.prototype.hasOwnProperty.call(attributes, attribute_code)) {
            return null;
        }

        return attributes[attribute_code];
    }

    _isSelectAttribute(attribute_type, attribute_options) {
        return attribute_type === 'select' && Object.keys(attribute_options).length;
    }

    _getSubAttributeLabel() {
        const {
            attributes,
            productViewAttributes: {
                sub_text: attribute_code,
                show_sub_text: showLabel
            } = {}
        } = this.props;

        const {
            attribute_label: label = '',
            attribute_value: value = '',
            attribute_type = '',
            attribute_options = {}
        } = this._validateLabels(
            attributes,
            attribute_code
        ) || {};

        if (this._isSelectAttribute(attribute_type, attribute_options)) {
            const {
                [value]: {
                    label: labelValue = ''
                } = {}
            } = attribute_options;

            return {
                label,
                showLabel,
                value: labelValue
            };
        }

        return {
            label,
            showLabel,
            value
        };
    }

    _getSuperAttributeLabel() {
        const {
            attributes,
            productViewAttributes: {
                super_text: attribute_code,
                show_super_text: showLabel
            } = {}
        } = this.props;

        const {
            attribute_label: label = '',
            attribute_value: value = '',
            attribute_type = '',
            attribute_options = {}
        } = this._validateLabels(
            attributes,
            attribute_code
        ) || {};

        if (this._isSelectAttribute(attribute_type, attribute_options)) {
            const {
                [value]: {
                    label: labelValue = ''
                } = {}
            } = attribute_options;

            return {
                label,
                showLabel,
                value: labelValue
            };
        }

        return {
            label,
            showLabel,
            value
        };
    }

    renderToLowerCaseOrCapatilized(match, value) {
        const first = match[0].substring(0, 1);
        if (first.toLowerCase() === first) {
            return value.toLowerCase();
        }

        return value;
    }

    _getAttributeValue(attribute) {
        const {
            attribute_value,
            attribute_type,
            attribute_options = {}
        } = attribute;

        if (this._isSelectAttribute(attribute_type, attribute_options)) {
            const { [attribute_value]: { label } } = attribute_options;

            return label;
        }

        return attribute_value;
    }

    _getHighlightProductName() {
        const {
            attributes,
            name,
            productViewAttributes: { highlight, enable_highlight } = {}
        } = this.props;

        if (enable_highlight !== '1') {
            return name;
        }

        if (!Object.prototype.hasOwnProperty.call(attributes, highlight)) {
            return name;
        }

        const attribute_value = this._getAttributeValue(attributes[highlight]);
        const valueToLowerCase = attribute_value.toLowerCase().replace('/ /g', '-');
        const regex = new RegExp(attribute_value, 'g');
        const match = name.match(regex);
        const matchToLowerCase = match ? match[0].toLowerCase().replace('/ /g', '-') : null;

        if (matchToLowerCase !== valueToLowerCase) {
            return name;
        }

        const newName = name.replace(
            attribute_value,
            `<span class="ProductActions-TitleHighlight">
                ${this.renderToLowerCaseOrCapatilized(match, attribute_value)}
            </span>`
        );

        return newName;
    }

    containerProps = () => {
        const { isHeading, mix } = this.props;

        return {
            isHeading,
            mix,
            name: this._getHighlightProductName(),
            subAttributeLabel: this._getSubAttributeLabel(),
            superAttributeLabel: this._getSuperAttributeLabel()
        };
    };

    render() {
        return (
            <SaasProductTitleBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaasProductTitleBlockContainer);

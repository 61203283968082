import { updateProductPrice } from './Product.reducer.plugin';

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        setCalculatedPrice: (value) => dispatch(updateProductPrice(value))
    };
};

export class SurfacePriceCalculationContainer {
    componentDidUpdate = (args, callback, instance) => {
        const [, { calculatedPrice: oldPrice }] = args;
        const { calculatedPrice } = instance.state;
        const { setCalculatedPrice } = instance.props;

        if (oldPrice !== calculatedPrice) {
            setCalculatedPrice(calculatedPrice === '0.00'
                ? null
                : calculatedPrice);
        }

        return callback(...args);
    };
}

const {
    componentDidUpdate
} = new SurfacePriceCalculationContainer();

export default {
    'Pwasaas/Component/SurfacePriceCalculation/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Pwasaas/Component/SurfacePriceCalculation/Container/SurfacePriceCalculationContainer': {
        'member-function': {
            componentDidUpdate
        }
    }
};

import PropTypes from 'prop-types';

import { NOT_REDIRECTING_ROUTES, ROUTE_LOGIN } from 'Component/Router/Router.custom.config';
import { updateMasterAccount } from 'Store/SaasConfig/SaasConfig.action';
import { SaasConfigType } from 'Type/Store';
import { CSSCustom } from 'Util/CSS/CSS.custom';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export const KiyohDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Kiyoh/Kiyoh.dispatcher'
);

export const SaasConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasConfig/SaasConfig.dispatcher'
);

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        enableForceLogin: state.SaasConfigReducer.config?.enable_force_login || false,
        fontConfig: state.SaasConfigReducer.fontConfig,
        isAuthTokenExpired: state.MyAccountReducer.isAuthTokenExpired,
        isSignedIn: state.MyAccountReducer.isSignedIn,
        masterAccount: state.SaasConfigReducer.masterAccount,
        cookieLink: state.ConfigReducer.cookie_link
    };
};

export const mapDispatchToProps = ([dispatch], callback) => {
    const result = {
        ...callback(dispatch),
        clearMasterAccount: () => dispatch(updateMasterAccount(''))
    };
    const { init } = result;

    result.init = (...args) => {
        SaasConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        SaasMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        KiyohDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        init(...args);
    };

    return result;
};

export class RouterContainer {
    historyListener;

    staticPropTypes = (originalProps) => ({
        ...originalProps,
        clearMasterAccount: PropTypes.func.isRequired,
        enableForceLogin: PropTypes.bool.isRequired,
        fontConfig: SaasConfigType.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isAuthTokenExpired: PropTypes.bool,
        masterAccount: PropTypes.string
    });

    defaultProps = (originalProps) => ({
        ...originalProps,
        masterAccount: '',
        isAuthTokenExpired: false
    });

    componentDidMount = (args, callback, instance) => {
        const result = callback(...args);

        this.handleCustomConfigSettings(instance);
        this.handleRedirection(instance);

        return result;
    };

    componentDidUpdate = (args, callback, instance) => {
        const [prevProps] = args;
        const {
            enableForceLogin,
            fontConfig
        } = instance.props;
        const {
            enableForceLogin: prevEnableForceLogin,
            fontConfig: prevConfig
        } = prevProps;

        if (JSON.stringify(fontConfig) !== JSON.stringify(prevConfig)) {
            this.handleCustomConfigSettings(instance);
        }

        if (enableForceLogin !== prevEnableForceLogin && typeof this.historyListener !== 'function') {
            this.handleRedirection(instance);
        }

        return callback(...args);
    };

    componentWillUnmount = (args, callback) => {
        const result = callback(...args);

        if (typeof this.historyListener === 'function') {
            this.historyListener();
        }

        return result;
    };

    containerProps = (args, callback, instance) => {
        const { isSignedIn } = instance.props;

        return {
            ...callback(...args),
            isSignedIn
        };
    };

    handleCustomConfigSettings(instance) {
        const {
            fontConfig: {
                google_font_enabled = false,
                google_font_primary = '',
                google_font_primary_weights = ''
            }
        } = instance.props;

        CSSCustom.setRootFontFamily({
            google_font_primary,
            google_font_primary_weights
        }, google_font_enabled);
    }

    handleRedirection(instance) {
        const { enableForceLogin } = instance.props;

        if (!enableForceLogin) {
            return;
        }

        const { search = '' } = window.location;
        const token = new URLSearchParams(search).get('token');

        if (token) {
            return;
        }

        this.redirectUser(instance);
        this.handleClearMasterAccount(instance);

        this.historyListener = history.listen((location) => {
            this.redirectUser(instance, location);
            this.handleClearMasterAccount(instance);
        });
    }

    redirectUser(
        instance,
        location = window.location
    ) {
        const { isSignedIn } = instance.props;

        if (isSignedIn) {
            return;
        }

        const { pathname } = location;

        if (NOT_REDIRECTING_ROUTES.includes(pathname)) {
            return;
        }

        const { cookieLink } = instance.props;

        if (pathname === cookieLink) {
            return;
        }

        history.push({ pathname: appendWithStoreCode(ROUTE_LOGIN) });
    }

    handleClearMasterAccount(instance) {
        const {
            clearMasterAccount,
            masterAccount,
            isAuthTokenExpired,
            isSignedIn
        } = instance.props;

        if (masterAccount && (isAuthTokenExpired || !isSignedIn)) {
            clearMasterAccount();
        }
    }
}

const {
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
    containerProps,
    defaultProps,
    staticPropTypes
} = new RouterContainer();

export default {
    'Component/Router/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            componentWillUnmount,
            containerProps
        },
        'static-member': {
            defaultProps,
            propTypes: staticPropTypes
        }
    }
};

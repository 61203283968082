import { DATE_OF_BIRTH } from 'Component/CheckoutDateOfBirth/CheckoutDateOfBirth.config';

export class CheckoutBillingContainer {
    _getAddress = (args, callback) => {
        const result = callback(...args);
        const omitSingle = (key, { [key]: _, ...obj }) => obj;

        if (!Object.prototype.hasOwnProperty.call(result, DATE_OF_BIRTH)) {
            return result;
        }

        return omitSingle(DATE_OF_BIRTH, result);
    };
}

const {
    _getAddress
} = new CheckoutBillingContainer();

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getAddress
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MenuAside from 'Component/MenuAside';
import MenuBanner from 'Component/MenuBanner';
import MenuContent from 'Component/MenuContent';
import MenuLink from 'Component/MenuLink';
import { DeviceType } from 'Type/Device';
import {
    MenuBannerType,
    MenuItemType
} from 'Type/SaasMenu';

import './MenuNavigation.style';

/** @namespace Pwasaas/Component/MenuNavigation/Component/MenuNavigationComponent */
export class MenuNavigationComponent extends PureComponent {
    static propTypes = {
        activeNavigationItem: MenuItemType.isRequired,
        bannerItem: MenuBannerType.isRequired,
        device: DeviceType.isRequired,
        handleOnClose: PropTypes.func.isRequired,
        handlePrevious: PropTypes.func.isRequired,
        isWithHistory: PropTypes.bool.isRequired,
        isWithIcons: PropTypes.bool.isRequired,
        items: PropTypes.arrayOf(MenuItemType).isRequired
    };

    renderCloseButton() {
        const { device, handleOnClose } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <button
              block="MenuNavigation"
              elem="CloseButton"
              onClick={ handleOnClose }
              aria-label={ __('Close') }
            />
        );
    }

    renderPreviousButton() {
        const {
            handlePrevious,
            isWithHistory
        } = this.props;

        if (!isWithHistory) {
            return null;
        }

        return (
            <button
              block="MenuNavigation"
              elem="PrevButton"
              onClick={ handlePrevious }
              aria-label={ __('Back to the menu') }
            >
                { __('Back to the menu') }
            </button>
        );
    }

    renderContent() {
        const { items = [] } = this.props;

        return (
            <>
                { this.renderPreviousButton() }
                <MenuContent
                  isNavigationContent
                  items={ items }
                />
            </>
        );
    }

    renderNavigationItems = (item) => {
        const { id } = item;

        return (
            <MenuLink
              isNavigationItem
              key={ id }
              item={ item }
              mix={ {
                  block: 'MenuNavigation',
                  elem: 'MenuItem'
              } }
            />
        );
    };

    renderNavigationIconItems = (item) => {
        const { icon_image, id } = item;

        if (!icon_image) {
            return null;
        }

        return (
            <MenuLink
              isNavigationItem
              key={ id }
              item={ item }
              itemMods={ { isIcon: true } }
            />
        );
    };

    renderNavigationMainItems = (item) => {
        const { activeNavigationItem } = this.props;

        return (
            <MenuLink
              isNavigationItem
              key={ item.id }
              item={ item }
              itemMods={ {
                  isActive: activeNavigationItem.id === item.id
              } }
              mix={ {
                  block: 'MenuNavigation',
                  elem: 'MenuItem'
              } }
            />
        );
    };

    renderNavigationBanner() {
        const {
            bannerItem = {},
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <div
              block="MenuNavigation"
              elem="Banner"
            >
                <MenuBanner
                  isMainBanner
                  item={ bannerItem }
                />
            </div>
        );
    }

    renderNavigationMobile() {
        const {
            isWithIcons,
            items
        } = this.props;

        if (!isWithIcons) {
            return this.renderContent();
        }

        return (
            <>
                <div
                  block="MenuNavigation"
                  elem="MobileView"
                >
                    { items.map(this.renderNavigationIconItems) }
                </div>
                <MenuAside />
            </>
        );
    }

    renderNavigation() {
        const {
            device,
            items
        } = this.props;

        if (device.isMobile) {
            return this.renderNavigationMobile();
        }

        return (
            <>
                <div
                  block="MenuNavigation"
                  elem="DesktopView"
                >
                    { items.map(this.renderNavigationMainItems) }
                </div>
                { this.renderContent() }
            </>
        );
    }

    render() {
        const {
            device,
            items
        } = this.props;

        if (!items.length) {
            return null;
        }

        return (
            <div
              block="MenuNavigation"
              elem="Wrapper"
              mods={ {
                  type: device.isMobile ? 'mobile' : false
              } }
            >
                { this.renderCloseButton() }
                { this.renderNavigationBanner() }
                { this.renderNavigation() }
            </div>
        );
    }
}

export default MenuNavigationComponent;

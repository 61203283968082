import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { customerType } from 'Type/Account';

import NewsletterCheckbox from './NewsletterCheckbox.component';

/** @namespace Pwasaas/Component/NewsletterCheckbox/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/NewsletterCheckbox/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    isShowNewsletter: state.SaasConfigReducer.config?.checkout_show_newsletter || false,
    isShowNewsletterChecked: state.SaasConfigReducer.config?.checkout_newsletter_check || false
});

/** @namespace Pwasaas/Component/NewsletterCheckbox/Container/NewsletterCheckboxContainer */
export class NewsletterCheckboxContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        isShowNewsletter: PropTypes.bool,
        isShowNewsletterChecked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        isShowNewsletter: false
    };

    state = {
        isChecked: this.shouldBeChecked()
    };

    containerFunctions = {
        handleOnChangeCheckbox: this.handleOnChangeCheckbox.bind(this)
    };

    containerProps = () => {
        const { isShowNewsletter } = this.props;
        const { isChecked } = this.state;

        return { isChecked, isShowNewsletter };
    };

    handleOnChangeCheckbox() {
        const { onChange } = this.props;
        const { isChecked } = this.state;

        return this.setState({ isChecked: !isChecked }, () => {
            const { isChecked } = this.state;

            onChange(isChecked);
        });
    }

    shouldBeChecked() {
        const { customer, isShowNewsletterChecked } = this.props;

        if (customer && customer.is_subscribed) {
            return true;
        }

        return isShowNewsletterChecked;
    }

    render() {
        return (
            <NewsletterCheckbox
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCheckboxContainer);

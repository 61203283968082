/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import StoreDetailWidgetFactory from 'Component/StoreDetailWidgetFactory';
import * as StoreDetailWidgets from 'Component/StoreDetailWidgetFactory/StoreDetailWidgetFactory.config';

export class InterceptStoreDetailWidgetsInHtmlPlugin {
    replaceWidget = (args, callback, instance) => {
        const [{ attribs }] = args;

        if (Object.values(StoreDetailWidgets).includes(attribs.type)) {
            return (
                <StoreDetailWidgetFactory
                  { ...instance.attributesToProps(attribs) }
                />
            );
        }

        return callback(...args);
    };
}

const {
    replaceWidget
} = new InterceptStoreDetailWidgetsInHtmlPlugin();

export default {
    'Component/Html/Component': {
        'member-function': {
            replaceWidget
        }
    }
};

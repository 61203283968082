/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
import { AVAILABLE_BLOG_ROUTES } from 'Component/Router/Router.custom.config';
import { getCustomRouteCollection } from 'Util/Saas';

export const BlogPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "blog" */ 'Route/BlogPage'
));

export const BlogRouteSwitch = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "blogswitch" */ 'Route/BlogRouteSwitch'
));

/** @namespace Pwasaas/Component/Router/Component/RouterComponent */
export class RouterComponent {
    switchItemsType = (originalMember) => [
        ...originalMember,
        {
            component: <Route
              exact
              path={ getCustomRouteCollection(AVAILABLE_BLOG_ROUTES) }
              render={ (props) => <BlogPage { ...props } /> }
            />,
            position: 85
        },
        {
            component: <Route
              path={ getCustomRouteCollection(AVAILABLE_BLOG_ROUTES, [':subUrl?', ':detailUrl?']) }
              render={ (props) => <BlogRouteSwitch { ...props } /> }
            />,
            position: 86
        }
    ];
}

const {
    switchItemsType
} = new RouterComponent();

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: switchItemsType
        }
    }
};

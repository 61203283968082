/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductAttributeValue from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

/** @namespace Pwasaas/Component/ProductAttributeValue/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    navigation: state.NavigationReducer[TOP_NAVIGATION_TYPE],
    richSnippets: state.SaasConfigReducer.richSnippets?.product || {}
});

/** @namespace Pwasaas/Component/ProductAttributeValue/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/ProductAttributeValue/Container/ProductAttributeValueContainer */
export class ProductAttributeValueContainer extends PureComponent {
    static propTypes = {
        richSnippets: PropTypes.objectOf({
            brand: PropTypes.string,
            gtin: PropTypes.string,
            mpn: PropTypes.string,
            sku: PropTypes.string
        }).isRequired
    };

    containerProps = () => {
        const { richSnippets, ...otherProps } = this.props;

        return {
            ...otherProps,
            richSnippets: {
                brand: richSnippets.brand,
                sku: richSnippets.sku
            }
        };
    };

    /**
     * SEO content from rich snippets
     */
    render() {
        return (
            <ProductAttributeValue
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributeValueContainer);

import { ADDITION } from 'Component/SaasCheckoutForm/SaasCheckoutForm.config';

export class MyAccountAddressFormComponent {
    getAddressFields = (args, callback, instance) => {
        const { isForcedDisablePostcodeCheck, isPostcodeCheckEnabled } = instance.props;
        const streets = callback(...args);

        if (isPostcodeCheckEnabled && !isForcedDisablePostcodeCheck && streets[ADDITION]) {
            streets[ADDITION].placeholder = __('Unit');
        }

        return streets;
    };
}

const {
    getAddressFields
} = new MyAccountAddressFormComponent();

export default {
    'Component/MyAccountAddressForm/Component': {
        'member-function': {
            getAddressFields
        }
    }
};

import SurfacePriceCalculation from 'Component/SurfacePriceCalculation';

export class ProductActionsComponent {
    renderAddToCartWrapper = (args, callback, instance) => {
        const cartWrapper = callback(...args);
        const {
            setQuantity,
            isSurfacePriceCalculationEnabled,
            product: {
                attributes: {
                    enable_surface_price_calculation: {
                        attribute_value: enable_surface_price_calculation = ''
                    } = {}
                } = {}
            } = {}
        } = instance.props;

        if (isSurfacePriceCalculationEnabled && enable_surface_price_calculation === '1') {
            return (
                <>
                    <div
                      block="ProductActions"
                      elem="SurfacePriceCalculation"
                    >
                        <SurfacePriceCalculation setQuantityOnCalculate={ setQuantity } />
                    </div>
                { cartWrapper }
                </>
            );
        }

        return cartWrapper;
    };

    renderAddToCartAndQuantity = (args, callback, instance) => {
        const {
            isSurfacePriceCalculationEnabled,
            product: {
                attributes: {
                    enable_surface_price_calculation: {
                        attribute_value: enable_surface_price_calculation = ''
                    } = {}
                } = {}
            } = {}
        } = instance.props;

        if (isSurfacePriceCalculationEnabled && enable_surface_price_calculation === '1') {
            return instance.renderAddToCart();
        }

        return callback(...args);
    };
}

const {
    renderAddToCartWrapper,
    renderAddToCartAndQuantity
} = new ProductActionsComponent();

export default {
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-function': {
            renderAddToCartWrapper,
            renderAddToCartAndQuantity
        }
    }
};

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { MixType } from 'Type/Common';

import {
    DELIVERY_DAY,
    DELIVERY_TIME
} from './OrderDateSelect.config';

import './OrderDateSelect.style';

/** @namespace Pwasaas/Component/OrderDateSelect/Component/OrderDateSelectComponent */
export class OrderDateSelectComponent extends PureComponent {
    static propTypes = {
        availableDateRange: PropTypes.arrayOf({
            date: PropTypes.string,
            timeRange: PropTypes.arrayOf(PropTypes.string)
        }).isRequired,
        handleSetDeliveryDay: PropTypes.func.isRequired,
        handleSetDeliveryTime: PropTypes.func.isRequired,
        headingTitle: PropTypes.string.isRequired,
        mix: MixType.isRequired,
        isShowTime: PropTypes.bool.isRequired,
        selectedDate: PropTypes.shape({
            selectedDay: PropTypes.string,
            selectedTime: PropTypes.string
        }).isRequired
    };

    fieldMap = {
        [DELIVERY_DAY]: {
            customValidation: {
                formButton: SHIPPING_STEP,
                formPortals: [
                    'CheckoutGuestForm',
                    'CheckoutAddressForm',
                    'CheckoutDeliveryOptions'
                ],
                values: ['notEmpty']
            },
            placeholder: __('Select a date'),
            type: 'date'
        },
        [DELIVERY_TIME]: {
            placeholder: __('Select a time'),
            type: 'select'
        }
    };

    getOrderDateField = (field, options) => (
        <Field
          { ...this.fieldMap[field] }
          { ...options }
          key={ field }
          id={ field }
          name={ field }
        />
    );

    renderTimeSelect() {
        const {
            availableDateRange,
            handleSetDeliveryTime,
            selectedDate: {
                selectedDay = '',
                selectedTime = ''
            },
            isShowTime
        } = this.props;

        if (!isShowTime || !selectedDay) {
            return null;
        }

        const { timeRange = [] } = availableDateRange.find(
            ({ date }) => selectedDay === date
        ) || {};

        const selectOptions = timeRange.map(
            (time, index) => ({
                id: `o${ index}`,
                value: time.value,
                label: time.label
            })
        );

        const options = {
            isDisabled: !selectedDay,
            mix: {
                block: 'OrderDateSelect',
                elem: 'DeliveryTime'
            },
            onChange: handleSetDeliveryTime,
            selectOptions,
            value: selectedTime || selectOptions[0]?.value || ''
        };

        return this.getOrderDateField(DELIVERY_TIME, options);
    }

    renderDeliveryDateSelect() {
        const {
            availableDateRange = [],
            handleSetDeliveryDay,
            headingTitle,
            selectedDate: {
                selectedDay = ''
            },
            isShowTime
        } = this.props;

        const options = {
            availableDateRange,
            onChange: handleSetDeliveryDay,
            value: selectedDay
        };

        return (
            <>
                <h2
                  block="Checkout"
                  elem="Heading"
                >
                    { headingTitle }
                </h2>
                <div
                  block="CheckoutDeliveryOption"
                  elem="DeliveryDate"
                  mods={ { isSingle: (!isShowTime || !selectedDay) } }
                >
                    { this.getOrderDateField(DELIVERY_DAY, options) }
                    { this.renderTimeSelect() }
                </div>
            </>
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <div
              block="OrderDateSelect"
              mix={ mix }
            >
                { this.renderDeliveryDateSelect() }
            </div>
        );
    }
}

export default OrderDateSelectComponent;

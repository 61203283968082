export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isShowGroupedProductPricesInCategory:
            state.SaasConfigReducer.config?.show_grouped_product_prices_in_category || false
    };
};

export const containerProps = (args, callback, instance) => {
    const { isShowGroupedProductPricesInCategory } = instance.props;

    return {
        ...callback(...args),
        isShowGroupedProductPricesInCategory
    };
};

export default {
    'Component/ProductCard/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps
        }
    }
};

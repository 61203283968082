export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isFreeShippingThresholdEnabled: state.SaasConfigReducer.config?.free_shipping_enabled || false
});

export class CartOverlayContainer {
    containerProps = (args, callback, {
        props: {
            isFreeShippingThresholdEnabled
        }
    }) => ({
        ...callback(...args),
        isFreeShippingThresholdEnabled
    });
}

const {
    containerProps
} = new CartOverlayContainer();

export default {
    'Component/CartOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartOverlay/Container': {
        'member-function': {
            containerProps
        }
    }
};

import { formatPrice } from 'Util/Price';
import { MIN_MAX, PRICE_LABEL_MAP } from 'Util/Price/Price.config';

export const renderMaxPrice = (instance) => {
    const {
        price: {
            minimum_price: {
                regular_price
            } = {}
        } = {},
        label
    } = instance.props;

    if (!label || label !== PRICE_LABEL_MAP[MIN_MAX]) {
        return null;
    }

    const regularPrice = formatPrice(regular_price.value, regular_price.currency);

    return <span block="ProductPrice" elem="MinMax">{ regularPrice }</span>;
};

export class ProductPriceComponent {
    render(args, callback, instance) {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix
        } = instance.props;

        if (!final_price || !regular_price) {
            return instance.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0 } }
              mix={ mix }
              aria-label={ `Product price: ${formattedFinalPrice}` }
            >
                { renderMaxPrice(instance) }
                { instance.renderOldPrice() }
                { instance.renderCurrentPrice() }
                { instance.renderSubPrice() }
                { instance.renderSchema() }
            </p>
        );
    }
}

const {
    render
} = new ProductPriceComponent();

export default {
    'Component/ProductPrice/Component': {
        function: renderMaxPrice,
        'member-function': {
            render
        }
    }
};

import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';

/** @namespace Pwasaas/Plugin/MageMacleanAdditionalEmails/Component/MyAccountAdditionalEmailsForm/Component/MyAccountAdditionalEmailsFormComponent */
export class MyAccountAdditionalEmailsFormComponent extends FieldForm {
    static propTypes = {
        onSave: PropTypes.func
    };

    static defaultProps = {
        onSave: () => {}
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        return {
            name: {
                validation: ['notEmpty'],
                label: __('Name')
            },
            email: {
                validation: ['notEmpty', 'email'],
                label: __('Email')
            }
        };
    }

    onFormSuccess = (fields) => {
        const { onSave } = this.props;

        onSave(fields);
    };

    renderActions() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'MyAccount', elem: 'Button' } }
              mods={ { isHollow: true } }
            >
                { __('Save email') }
            </button>
        );
    }
}

export default MyAccountAdditionalEmailsFormComponent;

export const methodTitleMapping = {
    multisafepay_ideal: 'iDeal'
};

export class MyAccountOrderPopupContainer {
    containerProps = (args, callback) => {
        const result = callback(...args);
        const { payment_info: { additional_information = {}, method } = {} } = result?.order || {};
        if (!additional_information.method_title) {
            additional_information.method_title = methodTitleMapping[method] || '';
        }

        return result;
    };
}

const {
    containerProps
} = new MyAccountOrderPopupContainer();

export default {
    'Component/MyAccountOrderPopup/Container': {
        'member-function': {
            containerProps
        }
    }
};

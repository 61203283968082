export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isProductCardWithAttributes: state.SaasConfigReducer.config?.attributes_visible_on_default_listing || false
});

export class ProductListContainer {
    containerProps = (args, callback, { props: { isProductCardWithAttributes } }) => ({
        ...callback(...args),
        isProductCardWithAttributes
    });
}

const {
    containerProps
} = new ProductListContainer();

export default {
    'Component/ProductList/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductList/Container': {
        'member-function': {
            containerProps
        }
    }
};

export const LOGO_POSITION_CENTER = 'center';
export const LOGO_POSITION_LEFT = 'left';
export const SAAS_MENU_ID = 'saas-menu';
export const SUCCESS_PAGE = 'success-page';
export const TOP_LINKS_MAP = [
    {
        title: __('FAQ'),
        href: '/veelgestelde-vragen'
    },
    {
        title: __('Customer service'),
        href: '/contact'
    }
];

export class PopupContainer {
    containerProps = (args, callback, instance) => {
        const { clickOutside = false } = instance.props;

        return {
            ...callback(...args),
            clickOutside
        };
    };
}

const {
    containerProps
} = new PopupContainer();

export default {
    'Component/Popup/Container': {
        'member-function': {
            containerProps
        }
    }
};

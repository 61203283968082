import { SALES_PROMOTION_PAGE } from '../route/SalesPromotionPage/SalesPromotionPage.config';

import '../style/PageHeader.manual.style';

export class PageHeaderComponent {
    activePages = (originalMember) => [
        ...originalMember,
        SALES_PROMOTION_PAGE
    ];
}

const {
    activePages
} = new PageHeaderComponent();

export default {
    'Pwasaas/Component/PageHeader/Component/PageHeaderComponent': {
        'member-property': {
            activePages
        }
    }
};

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import Link from 'Component/Link';
import { MixType } from 'Type/Common';

import { MAX_SCORE } from './KiyohSnippet.config';

import './KiyohSnippet.style';

/** @namespace Pwasaas/Component/KiyohSnippet/Component/KiyohSnippetComponent */
export class KiyohSnippetComponent extends PureComponent {
    static propTypes = {
        avgRating: PropTypes.number.isRequired,
        isEnabled: PropTypes.bool.isRequired,
        mix: MixType.isRequired,
        setReference: PropTypes.func.isRequired,
        totalReviews: PropTypes.number.isRequired,
        reviewUsUrl: PropTypes.string
    };

    static defaultProps = {
        reviewUsUrl: ''
    };

    refRatingStars = createRef();

    renderKiyohReviewCount() {
        const { totalReviews } = this.props;

        return (
            <>
                <span itemProp="ratingCount">{ totalReviews }</span>
                { ' ' }
                { __('Reviews') }
            </>
        );
    }

    renderKiyohLink() {
        const {
            reviewUsUrl
        } = this.props;

        if (reviewUsUrl) {
            return (
                <Link to={ reviewUsUrl } target="_blank">
                    { this.renderKiyohReviewCount() }
                </Link>
            );
        }

        return this.renderKiyohReviewCount();
    }

    renderTotalReviews() {
        return (
            <div
              block="KiyohSnippet"
              elem="Reviews"
            >
                { this.renderKiyohLink() }
            </div>
        );
    }

    renderRatingStars() {
        const { setReference } = this.props;

        return (
            <div
              block="KiyohSnippet"
              elem="RatingStars"
              ref={ setReference(this.refRatingStars) }
            />
        );
    }

    renderTotalScore() {
        const { avgRating } = this.props;

        return (
            <div
              block="KiyohSnippet"
              elem="TotalScore"
            >
                <span>{ avgRating }</span>
                <span
                  block="KiyohSnippet"
                  elem="BestRating"
                >
                    <span itemProp="bestRating">
                        { MAX_SCORE }
                    </span>
                </span>
            </div>
        );
    }

    render() {
        const {
            isEnabled,
            mix,
            totalReviews
        } = this.props;

        if (!isEnabled || !totalReviews) {
            return null;
        }

        return (
            <div
              block="KiyohSnippet"
              mix={ mix }
              itemProp="ratingValue"
            >
                <div
                  block="KiyohSnippet"
                  elem="Left"
                >
                    { this.renderTotalReviews() }
                    { this.renderRatingStars() }
                </div>
                <div
                  block="KiyohSnippet"
                  elem="Right"
                >
                    { this.renderTotalScore() }
                </div>
            </div>
        );
    }
}

export default KiyohSnippetComponent;

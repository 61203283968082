import { lazy } from 'react';

export const SEARCH_WIDGET = 'SearchWidget';

export const SearchWidget = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "search-widget" */ '../component/SearchWidget')
);

export const getWidgetRenderMap = (args, callback) => ({
    ...callback(...args),
    [SEARCH_WIDGET]: {
        component: SearchWidget
    }
});

export default {
    'Pwasaas/Util/Widget/getWidgetRenderMap': {
        function: getWidgetRenderMap
    }
};

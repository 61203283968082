import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import { updateMasterAccount } from 'Store/SaasConfig/SaasConfig.action';
import { setAuthorizationToken } from 'Util/Auth';
import history from 'Util/History';
import { getStore } from 'Util/Store';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        requestCustomerData: () => MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
        ),
        updateCustomerSignInStatus: (status) => dispatch(updateCustomerSignInStatus(status))
    };
};

export const addRequestCustomerDataBefore = async (args, callback, instance) => {
    /* TODO: This is a dirty fix.
       We need to move the login part of this app to specific places where it is needed,
       not everywhere.
    */

    const { pathname } = history.location;
    if (pathname === '/customer/account/createPassword/') {
        callback.apply(instance, args);

        return;
    }

    const { search = '' } = window.location;
    const token = new URLSearchParams(search).get('token');

    if (!token) {
        callback.apply(instance, args);

        return;
    }

    const {
        requestCustomerData,
        updateCustomerSignInStatus
    } = instance.props;

    setAuthorizationToken(token);
    updateCustomerSignInStatus(true);
    await requestCustomerData();

    // handle master account param
    // is supposed to be in LoginAsCustomer package
    // but both alter history, so this does not work in seperate file
    const masterAccount = new URLSearchParams(search).get('masteraccount');

    if (masterAccount) {
        const { dispatch } = getStore();
        dispatch(updateMasterAccount(masterAccount));
    }

    history.replace({ search: '' });
    callback.apply(instance, args);
};

export default {
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/Router/Container': {
        'member-function': {
            initializeApplication: addRequestCustomerDataBefore
        }
    }
};

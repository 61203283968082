/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import ProductVisualBundleItems from '../component/ProductVisualBundleItems/ProductVisualBundleItems.component';

export class ProductBundleItemsContainer {
    render = (args, callback, instance) => {
        const { isVisualBundleSelector } = instance.props;

        if (!isVisualBundleSelector) {
            return callback(...args);
        }

        return (
            <ProductVisualBundleItems
              { ...instance.containerFunctions }
              { ...instance.containerProps() }
            />
        );
    };
}

const {
    render
} = new ProductBundleItemsContainer();

export default {
    'Pwasaas/Component/ProductBundleItems/Container/ProductBundleItemsContainer': {
        'member-function': {
            render
        }
    }
};

import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.manual.style';

/** @namespace Pwasaas/Component/FieldSelect/Component/FieldSelectComponent */
export class FieldSelectComponent extends SourceFieldSelect {}

export default FieldSelectComponent;

import PropTypes from 'prop-types';

export const staticPropTypes = (originalProps) => ({
    ...originalProps,
    ariaLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ]),
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ]),
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ])
});

export default {
    'Component/Field/Component': {
        'static-member': {
            propTypes: staticPropTypes
        }
    },
    'Component/Field/Container': {
        'static-member': {
            propTypes: staticPropTypes
        }
    },
    'Component/FieldInput/Container': {
        'static-member': {
            propTypes: staticPropTypes
        }
    }
};

export class ProductActionsComponent {
    renderMobileTitle = (args, callback, instance) => {
        const { disableRenderMobileTitle } = instance.props;

        if (disableRenderMobileTitle) {
            return null;
        }

        return callback(...args);
    };
}

const {
    renderMobileTitle
} = new ProductActionsComponent();

export default {
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-function': {
            renderMobileTitle
        }
    }
};

import PropTypes from 'prop-types';

export const QtySelectOptionsType = PropTypes.shape({
    default: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
});

export const ProductQtyConfigType = PropTypes.shape({
    defaultQty: QtySelectOptionsType.isRequired,
    quantity_dropdown: PropTypes.bool.isRequired,
    quantity_set: PropTypes.arrayOf(QtySelectOptionsType).isRequired
});

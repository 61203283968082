import { Field } from '@scandipwa/scandipwa/src/util/Query';

import BannerQuery from 'Query/Banner.query';

export class BlogQuery {
    _getBlogCategoryFields = (args, callback) => [
        ...callback(...args),
        this._getBannerGroupField()
    ];

    _getBannerGroupField() {
        return new Field('banner_group')
            .addFieldList(BannerQuery._getBannerFields());
    }
}

const {
    _getBlogCategoryFields
} = new BlogQuery();

export default {
    'Pwasaas/Query/Blog/Query/BlogQuery': {
        'member-function': {
            _getBlogCategoryFields
        }
    }
};

import { cloneElement } from 'react';

import CheckoutDateOfBirth from 'Component/CheckoutDateOfBirth';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';

export class CheckoutAddressBookComponent {
    renderSignedInContent = (args, callback, instance) => {
        const {
            isDateOfBirthEnabled,
            selectedAddressId
        } = instance.props;
        const result = callback(...args);

        if (!isDateOfBirthEnabled || (!selectedAddressId && isDateOfBirthEnabled)) {
            return result;
        }

        const { props, props: { children } } = result;

        return cloneElement(result, {
            ...props,
            children: [
                ...children,
                this.renderCheckoutDateOfBirth(instance)
            ]
        });
    };

    renderCheckoutDateOfBirth(instance) {
        const { isBilling, onShippingEstimationFieldsChange, isSubmitted } = instance.props;

        if (isBilling) {
            return null;
        }

        return (
            <CheckoutDateOfBirth
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              address={ {} }
              id={ SHIPPING_STEP }
              isSubmitted={ isSubmitted }
            />
        );
    }
}

const {
    renderSignedInContent
} = new CheckoutAddressBookComponent();

export default {
    'Component/CheckoutAddressBook/Component': {
        'member-function': {
            renderSignedInContent
        }
    }
};

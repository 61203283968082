import { TEXTAREA } from 'Component/ProductInformation/ProductInformation.config';
import { getFilteredSelectedAttributes } from 'Util/Product';

export const _getAttributesWithValues = (args, callback) => getFilteredSelectedAttributes(
    callback(...args),
    TEXTAREA,
    false
);

export default {
    'Component/ProductAttributes/Container': {
        'member-function': {
            _getAttributesWithValues
        }
    }
};

import {
    mapDispatchToProps,
    mapStateToProps,
    setBlogPageHeader
} from '../util/PageHeader';

export class BlogRouteSwitchContainer {
    setPageInfoByUrl = (args, callback, instance) => {
        callback(...args).then(
            () => {
                const {
                    activePage: {
                        page = {}
                    },
                    navigationState = {},
                    updatePageHeader = () => {}
                } = instance.props;

                /** Disable blog page header if is post page */
                if (page.postId) {
                    updatePageHeader();

                    return;
                }

                setBlogPageHeader(
                    page,
                    navigationState,
                    updatePageHeader
                );
            }
        );
    };

    componentWillUnmount = (args, callback, instance) => {
        const { updatePageHeader = () => {} } = instance.props;

        updatePageHeader();

        return callback(...args);
    };
}

const {
    componentWillUnmount,
    setPageInfoByUrl
} = new BlogRouteSwitchContainer();

export default {
    'Pwasaas/Route/BlogRouteSwitch/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Route/BlogRouteSwitch/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Pwasaas/Route/BlogRouteSwitch/Container/BlogRouteSwitchContainer': {
        'member-function': {
            componentWillUnmount,
            setPageInfoByUrl
        }
    }
};

import { cloneElement } from 'react';

export class ProductActionsComponent {
    renderBundleItems = (args, callback, instance) => {
        const { isVisualBundleSelector } = instance.props;
        const result = callback(...args);

        if (!result) {
            return null;
        }

        const { props, props: { children: ProductBundleItems } } = result;
        const { props: childProps } = ProductBundleItems;

        return cloneElement(result, {
            ...props,
            children: cloneElement(ProductBundleItems, {
                ...childProps,
                isVisualBundleSelector
            })
        });
    };
}

const {
    renderBundleItems
} = new ProductActionsComponent();

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderBundleItems
        }
    }
};

import { cloneElement } from 'react';

import '../style/Ideal.manual.style';

export const isMultisafepayPayment = (paymentCode) => paymentCode && (paymentCode.includes('multisafepay_')
    || paymentCode === 'multisafepay');

export class CheckoutPaymentsComponent {
    renderContent = (args, callback, instance) => {
        const { hasError } = instance.state;

        if (hasError) {
            return callback(...args);
        }

        return (
            <>
                { instance.renderHeading() }
                <ul block="CheckoutPayments" elem="Methods">
                    { instance.renderPayments() }
                </ul>
            </>
        );
    };

    renderPayment = (args, callback, instance) => {
        const { code } = args[0];
        const result = callback(...args);
        const { isSelected } = result.props;

        if (isMultisafepayPayment(code)) {
            return cloneElement(result, {
                ...result.props,
                children: instance.renderSelectedPayment(isSelected)
            });
        }

        return result;
    };

    renderSelectedPayment = (args, callback) => {
        const [isSelected] = args;

        if (!isSelected) {
            return null;
        }

        return callback(...args);
    };
}

const {
    renderContent,
    renderPayment,
    renderSelectedPayment
} = new CheckoutPaymentsComponent();

export default {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderContent,
            renderPayment: {
                position: 150,
                implementation: renderPayment
            },
            renderSelectedPayment
        }
    }
};

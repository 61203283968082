export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isForcedDisablePostcodeCheck: state.SaasConfigReducer.isForcedDisablePostcodeCheck,
    isPostcodeCheckEnabled: state.SaasConfigReducer.config?.postcode_check_enabled || false
});

export default {
    'Component/MyAccountAddressForm/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

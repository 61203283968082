import { cloneElement } from 'react';

import { formatPrice } from 'Util/Price';
import { getFinalPrice } from 'Util/Price/GetPrice';

import UnitPrice from '../component/UnitPrice';

export class ProductActionsComponent {
    renderPriceWithSchema = (args, callback, instance) => {
        const { showUnitPrice } = instance.props;
        const result = callback(...args);

        // quick fix if result is null from component
        if (!result) {
            return null;
        }

        if (!showUnitPrice) {
            return result;
        }

        const { props: { children } } = result;

        return cloneElement(result, {
            ...result.props,
            children: [
                ...children,
                this.renderUnitPrice(instance)
            ]
        });
    };

    renderUnitPrice(instance) {
        const {
            displayTaxInPrice,
            product: {
                attributes = {},
                price_range: price,
                price_range: {
                    minimum_price: {
                        final_price: {
                            currency
                        } = {}
                    } = {}
                } = {},
                price_tiers = []
            } = {},
            unitPriceAttribute
        } = instance.props;

        const productPrice = {
            price,
            price_tiers,
            displayTaxInPrice
        };

        if (!attributes[unitPriceAttribute]) {
            return null;
        }

        const finalPrice = getFinalPrice(productPrice, false);
        const qty = attributes[unitPriceAttribute].attribute_value;
        const finalPricePerQty = formatPrice(finalPrice / qty, currency);

        return (
            <UnitPrice
              price={ finalPricePerQty }
              mix={ {
                  block: 'ProductCard',
                  elem: 'UnitPrice'
              } }
            />
        );
    }
}

const {
    renderPriceWithSchema
} = new ProductActionsComponent();

export default {
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-function': {
            renderPriceWithSchema
        }
    }
};

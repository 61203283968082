export class BlogPageComponent {
    renderTitle = (args, callback, instance) => {
        const { isPageHeaderVisible } = instance.props;

        if (isPageHeaderVisible) {
            return null;
        }

        return callback(...args);
    };
}

const {
    renderTitle
} = new BlogPageComponent();

export default {
    'Pwasaas/Route/BlogPage/Component/BlogPageComponent': {
        'member-function': {
            renderTitle
        }
    }
};

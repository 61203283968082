import NewsletterCheckbox from 'Component/NewsletterCheckbox';

export class CheckoutBillingComponent {
    renderTermsAndConditions = (args, callback, instance) => {
        const RenderedComponent = callback(...args);
        const { onNewsletterCheckboxChange } = instance.props;

        return (
            <>
                <NewsletterCheckbox onChange={ onNewsletterCheckboxChange } />
                { RenderedComponent }
            </>
        );
    };
}

const {
    renderTermsAndConditions
} = new CheckoutBillingComponent();

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderTermsAndConditions
        }
    }
};

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import nl from 'date-fns/locale/nl';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { registerLocale } from 'react-datepicker';

import { getFormattedDate } from 'Util/OrderDate';

import SaasDatepicker from './SaasDatepicker.component';

registerLocale('nl', nl);
/** @namespace Pwasaas/Component/SaasDatepicker/Container/SaasDatepickerContainer */
export class SaasDatepickerContainer extends PureComponent {
    static propTypes = {
        availableDateRange: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            timeRange: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string
            }))
        })),
        onChange: PropTypes.func,
        placeholder: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.string
        ]),
        value: PropTypes.string.isRequired
    };

    static defaultProps = {
        availableDateRange: [],
        onChange: () => {},
        placeholder: __('Select a date')
    };

    containerFunctions = {
        handleOnChange: this.handleOnChange.bind(this)
    };

    state = {
        selectedDate: ''
    };

    componentDidMount() {
        const { value } = this.props;

        if (value) {
            this.setState({
                selectedDate: new Date(value)
            });
        }
    }

    containerProps = () => {
        const {
            props: {
                availableDateRange,
                placeholder
            },
            state: { selectedDate }
        } = this;

        const availableDates = availableDateRange.map(({ date }) => new Date(date));

        return {
            availableDates,
            locale: nl,
            placeholder,
            selectedDate,
            dateFormat: 'd MMMM yyyy'
        };
    };

    handleOnChange(selectedDate) {
        const { onChange } = this.props;

        this.setState({ selectedDate }, () => {
            onChange(getFormattedDate(new Date(selectedDate)));
        });
    }

    render() {
        return (
            <SaasDatepicker
              { ...this.containerFunctions }
              { ...this.containerProps() }
              { ...this.props }
            />
        );
    }
}

export default SaasDatepickerContainer;

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { UPDATE_SAASLAYOUT } from './SaasLayout.action';

export const LOCAL_STORE_SAAS_LAYOUT = 'saas_layout';

/** @namespace Pwasaas/Plugin/SaasLayout/Store/SaasLayout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...BrowserDatabase.getItem(LOCAL_STORE_SAAS_LAYOUT) || {}
});

/** @namespace Pwasaas/Plugin/SaasLayout/Store/SaasLayout/Reducer/SaasLayoutReducer */
export const SaasLayoutReducer = (state = getInitialState(), action) => {
    const {
        saasLayout,
        type
    } = action;

    switch (type) {
    case UPDATE_SAASLAYOUT:
        BrowserDatabase.setItem(
            saasLayout,
            LOCAL_STORE_SAAS_LAYOUT,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            ...saasLayout
        };

    default:
        return state;
    }
};

export default SaasLayoutReducer;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { stringify } from 'rebem-classname';
import SwiperCore, {
    Autoplay,
    Navigation,
    Pagination
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ChildrenType, MixType } from 'Type/Common';

import { DEFAULT_AUTOPLAY_DURATION } from './SaasSlider.config';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './SaasSlider.style.scss';

SwiperCore.use([Autoplay, Navigation, Pagination]);

/** @namespace Pwasaas/Component/SaasSlider/Component/SaasSliderComponent */
export class SaasSliderComponent extends PureComponent {
    static propTypes = {
        autoplayDuration: PropTypes.string,
        children: ChildrenType.isRequired,
        mix: MixType,
        onInit: PropTypes.func,
        slidesPerView: PropTypes.number.isRequired,
        space: PropTypes.number,
        isAutoplay: PropTypes.bool,
        isNavigation: PropTypes.bool,
        isPagination: PropTypes.bool
    };

    static defaultProps = {
        autoplayDuration: DEFAULT_AUTOPLAY_DURATION,
        isAutoplay: false,
        isNavigation: false,
        isPagination: false,
        onInit: () => {},
        space: 0,
        mix: {}
    };

    renderSwiperSlides = (child, i) => (
        <SwiperSlide key={ i }>
            { child }
        </SwiperSlide>
    );

    render() {
        const {
            autoplayDuration,
            children = [],
            mix,
            onInit,
            space,
            slidesPerView,
            isAutoplay,
            isNavigation,
            isPagination
        } = this.props;

        if (!children.length) {
            return null;
        }

        const className = `${stringify({
            block: 'SaasSlider',
            elem: 'Container'
        })} ${stringify(mix)}`;

        return (
            <Swiper
              autoplay={ isAutoplay ? { delay: autoplayDuration } : false }
              className={ className }
              spaceBetween={ space }
              slidesPerView={ slidesPerView }
              navigation={ isNavigation }
              onInit={ onInit }
              pagination={ isPagination }
            >
                { children.map(this.renderSwiperSlides) }
            </Swiper>
        );
    }
}

export default SaasSliderComponent;

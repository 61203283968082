import { isSignedIn } from 'Util/Auth';

export const HIDE_ADD_TO_CART_KEY = 'hide_add_to_cart_button';
export const HIDE_PRICE_KEY = 'hide_price';

/** @namespace Pwasaas/Plugin/HidePriceAndActions/Util/HidePrice/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isShowPricesOnlyWhenSignedIn: state.SaasConfigReducer.config?.show_prices_only_when_signed_in || false
    };
};

/** @namespace Pwasaas/Plugin/HidePriceAndActions/Util/HidePrice/containerProps */
export const containerProps = (args, callback, instance) => {
    const { isShowPricesOnlyWhenSignedIn } = instance.props;

    return {
        ...callback(...args),
        isShowPricesOnlyWhenSignedIn
    };
};

/** @namespace Pwasaas/Plugin/HidePriceAndActions/Util/HidePrice/removeElementByAttribute */
export const removeElementByAttribute = ({ args, callback, instance }, attributeCode) => {
    const {
        getAttribute,
        isShowPricesOnlyWhenSignedIn,
        product: {
            attributes = {}
        } = {}
    } = instance.props;

    if (isShowPricesOnlyWhenSignedIn && !isSignedIn()) {
        return null;
    }

    const targetAttribute = typeof getAttribute === 'function'
        ? getAttribute(attributeCode) || {}
        : attributes[attributeCode] || {};

    // attribute value is send as string, not boolean
    if (targetAttribute.attribute_value === '1') {
        return null;
    }

    return callback(instance, args);
};

/** @namespace Pwasaas/Plugin/HidePriceAndActions/Util/HidePrice/removeActionsFrom */
export const removeActionsFrom = (
    args,
    callback,
    instance
) => removeElementByAttribute({ args, callback, instance }, HIDE_ADD_TO_CART_KEY);

/** @namespace Pwasaas/Plugin/HidePriceAndActions/Util/HidePrice/removePriceFrom */
export const removePriceFrom = (
    args,
    callback,
    instance
) => removeElementByAttribute({ args, callback, instance }, HIDE_PRICE_KEY);

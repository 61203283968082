import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';

import './NewsletterCheckbox.style';

/** @namespace Pwasaas/Component/NewsletterCheckbox/Component/NewsletterCheckboxComponent */
export class NewsletterCheckboxComponent extends PureComponent {
    static propTypes = {
        isChecked: PropTypes.bool.isRequired,
        isShowNewsletter: PropTypes.bool.isRequired,
        handleOnChangeCheckbox: PropTypes.func.isRequired
    };

    render() {
        const { isChecked, isShowNewsletter, handleOnChangeCheckbox } = this.props;

        if (!isShowNewsletter) {
            return null;
        }

        return (
            <div
              block="CheckoutBilling"
              elem="Newsletter"
            >
                <Field
                  id="newsletter"
                  name="newsletter"
                  type="checkbox"
                  value="newsletter"
                  mix={ { block: 'CheckoutBilling', elem: 'Newsletter-Checkbox' } }
                  checked={ isChecked }
                  onChange={ handleOnChangeCheckbox }
                />
                <label
                  block="CheckoutBilling"
                  elem="NewsletterLabel"
                  htmlFor="newsletter"
                >
                    { __('I subscribe to the newsletter') }
                </label>
            </div>
        );
    }
}

export default NewsletterCheckboxComponent;

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/AttributeImage/Query/AttributeImageQuery */
export class AttributeImageQuery {
    getQuery({
        sku,
        attribute,
        alias = 'attributeImage'
    }) {
        if (!sku || !attribute) {
            throw new Error('Missing arguments `product SKU or attribute code`');
        }

        return new Field('attributeImage')
            .addFieldList([
                'label',
                'icon_url'
            ])
            .addArgument('sku', 'String!', sku)
            .addArgument('attribute', 'String!', attribute)
            .setAlias(alias);
    }
}

export default new AttributeImageQuery();

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    COMPANY,
    EDIT_FIELDS,
    LASTNAME,
    OPTIONAL,
    REQUIRED
} from 'Component/SaasCheckoutForm/SaasCheckoutForm.config';
import { MyAccountAddressForm } from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';
import { getSelectedFormProps } from 'Util/Saas';
/**
 * Todo: Merge the my account address form with the SaasCheckoutForm module, and use this as the parent class
 * Remove this entire file when ready
 */
/** @namespace Pwasaas/Component/MyAccountAddressForm/Component/MyAccountAddressFormComponent */
export class MyAccountAddressFormComponent extends MyAccountAddressForm {
    /**
     * Use same company field condition data structure as SaasCheckoutForm
     */
    companyFieldConditions = [
        {
            handler: (item) => [OPTIONAL, REQUIRED].includes(item),
            prop: '*',
            selector: 'props.companyShow'
        },
        {
            handler: (item) => item === REQUIRED,
            prop: 'validation',
            selector: 'props.companyShow'
        }
    ];

    /**
     * Add company field based on condition and add postcode at end of field collection
     */
    get fieldMap() {
        const { checkoutFormFields } = this.props;

        const fieldMap = Object.entries(super.fieldMap).reduce(
            (acc, [key, value]) => {
                const current = {
                    ...acc,
                    [key]: value
                };

                if (key !== LASTNAME) {
                    return current;
                }

                const editField = {
                    ...EDIT_FIELDS[COMPANY],
                    enabled: checkoutFormFields[COMPANY]?.enabled || false
                };

                const {
                    enabled,
                    ...selectedFieldProps
                } = getSelectedFormProps(
                    this.companyFieldConditions,
                    {},
                    editField,
                    this
                );

                if (!enabled) {
                    return current;
                }

                return {
                    ...current,
                    [COMPANY]: selectedFieldProps
                };
            }, {}
        );

        return {
            ...fieldMap,
            postcode: {
                ...super.fieldMap.postcode,
                validation: ['notEmpty', 'postcode']
            }
        };
    }
}

export default MyAccountAddressFormComponent;

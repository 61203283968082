/**
 * Add empty region due to invalid region lookup
 * There is a mismatch in abbreviations between Magento and api.zippopotam.us
 */

export class ConfigReducer {
    getCountryData = (args, callback) => {
        const data = callback(...args);

        const { countries } = data;

        if (!Array.isArray(countries)) {
            return data;
        }

        countries.forEach(({ available_regions }) => {
            if (!Array.isArray(available_regions)) {
                return;
            }

            if (available_regions[0].id === -1) {
                return;
            }

            available_regions.unshift({
                code: '',
                id: -1,
                name: ''
            });
        });

        data.countries = countries;

        return data;
    };
}

const {
    getCountryData
} = new ConfigReducer();

export default {
    'Store/Config/Reducer': {
        function: getCountryData
    }
};

import { HEIGHT_CALCULATION_DELAY } from '../../route/SalesPromotionPage/SalesPromotionPage.config';

export const INDEX_REGEX = /(\d{1,2}$)/g;

/** @namespace Pwasaas/Plugin/SalesPromotion/Util/SalesPromotion/Index/getBaseFieldName */
export const getBaseFieldName = (name) => name.replace(INDEX_REGEX, '').replace(/_$/, '');

/** @namespace Pwasaas/Plugin/SalesPromotion/Util/SalesPromotion/Index/getSortedFormFields */
export const getSortedFormFields = (fields) => Object.fromEntries(
    Object.entries(fields).sort(
        ([, { position: a }], [, { position: b }]) => a - b
    )
);

/** @namespace Pwasaas/Plugin/SalesPromotion/Util/SalesPromotion/Index/getFormattedFieldName */
export const getFormattedFieldName = (name) => {
    const currentIndex = name.match(INDEX_REGEX);

    if (!currentIndex) {
        return name.concat('_1');
    }

    return name.replace(INDEX_REGEX, `${Number(currentIndex) + 1}`);
};

/** @namespace Pwasaas/Plugin/SalesPromotion/Util/SalesPromotion/Index/setDocumentStyling */
export const setDocumentStyling = (
    isPageHeaderVisible,
    sidebarContent,
    sidebarHeadingRef
) => {
    const rootElement = document.documentElement;

    if (!sidebarContent) {
        rootElement.style.setProperty(
            '--sales-promotion-page-sidebar-heading-height', '0px'
        );

        rootElement.classList.remove('pageHasSidebar');

        return;
    }

    if (isPageHeaderVisible) {
        const { current } = sidebarHeadingRef;

        if (current) {
            setTimeout(() => {
                rootElement.style.setProperty(
                    '--sales-promotion-page-sidebar-heading-height', `${current.clientHeight}px`
                );
            }, HEIGHT_CALCULATION_DELAY);
        }
    }

    rootElement.classList.add('pageHasSidebar');
};

import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';

import ProductCard from './ProductCard.component';

export {
    mapDispatchToProps
};

/** @namespace Pwasaas/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    showAddToCart: state.SaasConfigReducer.config?.show_add_to_cart_product_card ?? 1,
    cardListAttributes: state.SaasConfigReducer.config?.card_list_attributes || ''
});

/** @namespace Pwasaas/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    render() {
        const { cardListAttributes, ...otherProps } = this.props;

        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductCard
                      // TODO: ...otherProps should be removed / check all plugins that passes props to product card component
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...otherProps }
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                      listAttributes={ cardListAttributes.split(',') }
                      registerSharedElement={ registerSharedElement }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);

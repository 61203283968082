/**
 * Remove variants_plp from ProductList query field list
 */

export const _getVariantsField = (args, callback, instance) => {
    const { isPlp = false } = instance.options;

    if (!isPlp) {
        return callback(...args);
    }

    return null;
};

export default {
    'Query/ProductList': {
        'member-function': {
            _getVariantsField
        }
    }
};

import { BLOG_DETAIL_PAGE } from 'Route/BlogDetailPage/BlogDetailPage.config';
import { getFilteredStoreData } from 'Util/Store';

import {
    SET_IS_LOADING,
    UPDATE_ACTIVE_PAGE,
    UPDATE_ACTIVE_ROOT,
    UPDATE_CATEGORY_POSTS,
    UPDATE_CURRENT_RELATED_POSTS,
    UPDATE_POST_RELATED_PRODUCTS,
    UPDATE_POST_TAGS,
    UPDATE_ROOT_CATEGORIES
} from './Blog.action';

/** @namespace Pwasaas/Store/Blog/Reducer/getFilteredCategoryTree */
export const getFilteredCategoryTree = (categories) => {
    const mappedIds = categories.reduce(
        (acc, category, index) => {
            acc[category.category_id] = index;

            return acc;
        }, {}
    );

    return categories.reduce((acc, category) => {
        const parentItem = categories[mappedIds[Number(category.parent_id)]] || {};

        parentItem.categories = {
            ...(parentItem.categories || {}),
            [category.url_key]: {
                ...getFilteredStoreData(category)
            }
        };

        const { parent_id, url_key } = parentItem;

        if (parent_id === '0') {
            return {
                ...acc,
                [url_key]: {
                    ...getFilteredStoreData(parentItem)
                }
            };
        }

        return acc;
    }, {});
};

/** @namespace Pwasaas/Store/Blog/Reducer/getFilteredCategoryWithPostItems */
export const getFilteredCategoryWithPostItems = (category, items) => ({
    ...category,
    posts: items.reduce(
        (acc, item) => {
            const { url_key } = item;

            return {
                ...acc,
                [url_key]: getFilteredStoreData(item)
            };
        }, {}
    )
});

/** @namespace Pwasaas/Store/Blog/Reducer/getFilteredRelatedPosts */
export const getFilteredRelatedPosts = (relatedPosts) => relatedPosts.map(
    (post) => getFilteredStoreData(post)
);

/** @namespace Pwasaas/Store/Blog/Reducer/getFilteredPostTags */
export const getFilteredPostTags = (tags, statedTags) => tags.reduce(
    (acc, tag) => {
        if (statedTags.some(({ tagId }) => tagId === tag.tag_id)) {
            return acc;
        }

        return [
            ...acc,
            getFilteredStoreData(tag)
        ];
    }, []
);

/** @namespace Pwasaas/Store/Blog/Reducer/getInitialState */
export const getInitialState = () => ({
    activePage: {},
    activeRoot: {},
    categoryTree: {},
    currentRelatedPosts: [],
    currentRelatedProducts: [],
    isLoading: false,
    postTags: [],
    settings: {}
});

/** @namespace Pwasaas/Store/Blog/Reducer/BlogReducer */
export const BlogReducer = (state = getInitialState(), action) => {
    const {
        activePage = {},
        activeRoot = {},
        categories: {
            items = []
        } = {},
        isLoading,
        relatedProducts = [],
        relatedPosts = [],
        settings = {},
        tags = [],
        type,
        postItems
    } = action;

    switch (type) {
    case SET_IS_LOADING:
        return {
            ...state,
            isLoading
        };

    case UPDATE_ACTIVE_PAGE:
        return {
            ...state,
            activePage
        };

    case UPDATE_ACTIVE_ROOT:
        return {
            ...state,
            activeRoot
        };

    case UPDATE_CATEGORY_POSTS:
        if (state.activePage.type !== BLOG_DETAIL_PAGE) {
            return state;
        }

        return {
            ...state,
            activePage: {
                ...state.activePage,
                page: getFilteredCategoryWithPostItems(state.activePage.page, postItems)
            }
        };

    case UPDATE_CURRENT_RELATED_POSTS:
        return {
            ...state,
            currentRelatedPosts: getFilteredRelatedPosts(relatedPosts)
        };

    case UPDATE_POST_RELATED_PRODUCTS:
        return {
            ...state,
            currentRelatedProducts: relatedProducts
        };

    case UPDATE_POST_TAGS:
        return {
            ...state,
            postTags: [
                ...state.postTags,
                ...getFilteredPostTags(tags, state.postTags)
            ]
        };

    case UPDATE_ROOT_CATEGORIES:
        const categoryTree = getFilteredCategoryTree(items);

        return {
            ...state,
            categoryTree,
            settings
        };

    default:
        return state;
    }
};

export default BlogReducer;

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isThumbnailRelatedProductEnabled: state.SaasConfigReducer.config?.thumbnail_related_products_enabled || false
});

export class ProductSectionNavigationContainer {
    containerProps = (args, callback, { props: { isThumbnailRelatedProductEnabled } }) => ({
        ...callback(...args),
        isThumbnailRelatedProductEnabled
    });
}

const {
    containerProps
} = new ProductSectionNavigationContainer();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/ProductSectionNavigation/Container/ProductSectionNavigationContainer': {
        'member-function': {
            containerProps
        }
    }
};

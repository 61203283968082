/**
 * Remove date_of_birth field from SaveAddressInformation mutation input
 */

import { DATE_OF_BIRTH } from 'Component/CheckoutDateOfBirth/CheckoutDateOfBirth.config';

export class CheckoutContainer {
    prepareAddressInformation = (args, callback) => {
        const result = callback(...args);
        const {
            shipping_address = {},
            billing_address = {}
        } = result;

        const omitSingle = (key, obj) => {
            // eslint-disable-next-line no-prototype-builtins
            if (!obj.hasOwnProperty(key)) {
                return obj;
            }
            // eslint-disable-next-line no-unused-vars
            const { [key]: _, ...rest } = obj;
            return rest;
        };

        if (Object.prototype.hasOwnProperty.call(shipping_address, DATE_OF_BIRTH)) {
            result.shipping_address = omitSingle(DATE_OF_BIRTH, shipping_address);
        }

        if (Object.prototype.hasOwnProperty.call(billing_address, DATE_OF_BIRTH)) {
            result.billing_address = omitSingle(DATE_OF_BIRTH, billing_address);
        }

        return result;
    };
}

const {
    prepareAddressInformation
} = new CheckoutContainer();

export const config = {
    'Route/Checkout/Container': {
        'member-function': {
            prepareAddressInformation
        }
    }
};

export default config;

import { getStore } from 'Util/Store';

export const containerProps = (args, callback) => {
    /** Get option from Util/Store directly due to missing of mapStateToProps */
    const { show_prices_only_when_signed_in = false } = getStore().getState().SaasConfigReducer.config || {};

    return {
        ...callback(...args),
        isShowPricesOnlyWhenSignedIn: show_prices_only_when_signed_in
    };
};

export default {
    'Component/GroupedProductsItem/Container': {
        'member-function': {
            containerProps
        }
    }
};

export const SET_ACTIVE_INFO_WINDOW = 'SET_ACTIVE_INFO_WINDOW';
export const SET_ACTIVE_STORE_PAGE = 'SET_ACTIVE_STORE_PAGE';
export const SET_FILTER_IS_LOADING = 'SET_FILTER_IS_LOADING';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION';
export const UPDATE_FILTER_LIST = 'UPDATE_FILTER_LIST';
export const UPDATE_MAIN_PAGE = 'UPDATE_MAIN_PAGE';
export const UPDATE_PICKUP_STORE = 'UPDATE_PICKUP_STORE';
export const UPDATE_STORE_LOCATORS = 'UPDATE_STORE_LOCATORS';
export const UPDATE_STORE_SELECT_LIST = 'UPDATE_STORE_SELECT_LIST';
export const UPDATE_ZIP_CODE = 'UPDATE_ZIP_CODE';

/** @namespace Pwasaas/Store/StoreLocator/Action/setActiveInfoWindow */
export const setActiveInfoWindow = (activeInfoWindow) => ({
    type: SET_ACTIVE_INFO_WINDOW,
    activeInfoWindow
});

/** @namespace Pwasaas/Store/StoreLocator/Action/setActiveStorePage */
export const setActiveStorePage = (storePage) => ({
    type: SET_ACTIVE_STORE_PAGE,
    storePage
});

/** @namespace Pwasaas/Store/StoreLocator/Action/setIsLoading */
export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    isLoading
});

/** @namespace Pwasaas/Store/StoreLocator/Action/setFilterIsLoading */
export const setFilterIsLoading = (filterIsLoading) => ({
    type: SET_FILTER_IS_LOADING,
    filterIsLoading
});

/** @namespace Pwasaas/Store/StoreLocator/Action/updateMainPage */
export const updateMainPage = (mainPage) => ({
    type: UPDATE_MAIN_PAGE,
    mainPage
});

/** @namespace Pwasaas/Store/StoreLocator/Action/updateStoreLocators */
export const updateStoreLocators = (stores) => ({
    type: UPDATE_STORE_LOCATORS,
    stores
});

/** @namespace Pwasaas/Store/StoreLocator/Action/updateStoreFilterList */
export const updateStoreFilterList = (storeFilterList) => ({
    type: UPDATE_FILTER_LIST,
    storeFilterList
});

/** @namespace Pwasaas/Store/StoreLocator/Action/updateStoreSelectList */
export const updateStoreSelectList = (storeSelectList) => ({
    type: UPDATE_STORE_SELECT_LIST,
    storeSelectList
});

/** @namespace Pwasaas/Store/StoreLocator/Action/updateZipCode */
export const updateZipCode = (zipCode) => ({
    type: UPDATE_ZIP_CODE,
    zipCode
});

/** @namespace Pwasaas/Store/StoreLocator/Action/updateCurrentLocation */
export const updateCurrentLocation = (currentLocation) => ({
    type: UPDATE_CURRENT_LOCATION,
    currentLocation
});

/** @namespace Pwasaas/Store/StoreLocator/Action/updatePickupStore */
export const updatePickupStore = (pickupStore) => ({
    type: UPDATE_PICKUP_STORE,
    pickupStore
});

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    updateActiveNavigationItem,
    updateIsToggled
} from 'Store/SaasMenu/SaasMenu.action';
import { MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { MenuItemModsType, MenuItemType } from 'Type/SaasMenu';

import MenuLink from './MenuLink.component';

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

/** @namespace Pwasaas/Component/MenuLink/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Pwasaas/Component/MenuLink/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeMenu(dispatch)
    ),
    openMenu: (item) => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.openMenu(dispatch, item)
    ),
    setActiveNavigationItem: (item) => dispatch(updateActiveNavigationItem(item)),
    setIsNotToggled: () => dispatch(updateIsToggled(false))
});

/** @namespace Pwasaas/Component/MenuLink/Container/MenuLinkContainer */
export class MenuLinkContainer extends PureComponent {
    static propTypes = {
        closeMenu: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        isNavigationItem: PropTypes.bool,
        item: MenuItemType.isRequired,
        itemMods: MenuItemModsType,
        mix: MixType,
        onItemClick: PropTypes.func,
        onItemEnter: PropTypes.func,
        openMenu: PropTypes.func.isRequired,
        setActiveNavigationItem: PropTypes.func.isRequired,
        setIsNotToggled: PropTypes.func.isRequired
    };

    static defaultProps = {
        isNavigationItem: false,
        itemMods: {
            isActive: false,
            isLink: false,
            isTitle: false
        },
        mix: {},
        onItemClick: () => {},
        onItemEnter: () => {}
    };

    containerFunctions = {
        handleItemClick: this.handleItemClick.bind(this),
        handleItemEnter: this.handleItemEnter.bind(this)
    };

    containerProps = () => {
        const {
            device,
            item,
            itemMods,
            mix
        } = this.props;

        return {
            device,
            item,
            itemMods,
            mix
        };
    };

    handleItemClick() {
        const {
            device,
            isNavigationItem,
            item,
            onItemClick,
            setActiveNavigationItem,
            setIsNotToggled
        } = this.props;

        if (!device.isMobile) {
            setIsNotToggled();
            return;
        }
        if (isNavigationItem) {
            setActiveNavigationItem(item);

            return;
        }

        onItemClick(item);
    }

    handleItemEnter(e) {
        const {
            device,
            isNavigationItem,
            item,
            item: {
                childs = [],
                level
            },
            openMenu,
            setActiveNavigationItem,
            setIsNotToggled
        } = this.props;

        if (device.isMobile) {
            return;
        }

        if (!childs || !childs.length) {
            return;
        }

        if (isNavigationItem && level <= 2) {
            setActiveNavigationItem(item);

            return;
        }

        if (level > 1) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        setIsNotToggled();
        openMenu(item);
    }

    handleItemLeave() {
        const {
            closeMenu,
            device,
            isNavigationItem
        } = this.props;

        if (device.isMobile) {
            return;
        }

        if (isNavigationItem) {
            return;
        }

        closeMenu();
    }

    render() {
        return (
            <MenuLink
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuLinkContainer);

import ProductBrandLogo from '../component/ProductBrandLogo';

export class ProductActionsComponent {
    renderProductTitle = (args, callback, instance) => {
        const result = callback(...args);
        const { hasBrandLogo } = instance.props;

        if (!hasBrandLogo) {
            return result;
        }

        return (
            <>
                { result }
                { this.renderBrandLogo(instance) }
            </>
        );
    };

    renderBrandLogo(instance) {
        const {
            brandLogoAttribute,
            product: { sku }
        } = instance.props;

        if (!sku) {
            return null;
        }

        return (
            <ProductBrandLogo
              brandAttribute={ brandLogoAttribute }
              sku={ sku }
            />
        );
    }
}

const {
    renderProductTitle
} = new ProductActionsComponent();

export default {
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-function': {
            renderProductTitleBlock: renderProductTitle
        }
    }
};

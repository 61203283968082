import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { UPDATE_SAASTHEME } from './SaasTheme.action';

export const LOCAL_STORE_SAAS_THEME = 'saas_theme';

/** @namespace Pwasaas/Plugin/SaasTheme/Store/SaasTheme/Reducer/getInitialState */
export const getInitialState = () => ({
    ...BrowserDatabase.getItem(LOCAL_STORE_SAAS_THEME) || {}
});

/** @namespace Pwasaas/Plugin/SaasTheme/Store/SaasTheme/Reducer/SaasThemeReducer */
export const SaasThemeReducer = (state = getInitialState(), action) => {
    const {
        saasTheme,
        type
    } = action;

    switch (type) {
    case UPDATE_SAASTHEME:
        BrowserDatabase.setItem(
            saasTheme,
            LOCAL_STORE_SAAS_THEME,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            ...saasTheme
        };

    default:
        return state;
    }
};

export default SaasThemeReducer;

import ExceedStockPopup from '../component/ExceedStockPopup/ExceedStockPopup.container';

export class CartPageComponent {
    renderExceedQtyPopup() {
        return (
            <ExceedStockPopup />
        );
    }

    render = (args, callback, instance) => {
        const { show_exceed_qty_popup } = instance.props;

        if (!show_exceed_qty_popup) {
            return (
                { ...callback(...args) }
            );
        }

        return (
            <>
                { callback(args) }
                { this.renderExceedQtyPopup() }
            </>
        );
    };
}

const {
    render
} = new CartPageComponent();

export default {
    'Pwasaas/Route/CartPage/Component/CartPageComponent': {
        'member-function': {
            render
        }
    }
};

export class ProductActionsContainer {
    containerProps = (args, callback, { props: { disableRenderMobileTitle = false } }) => ({
        ...callback(...args),
        disableRenderMobileTitle
    });
}

const {
    containerProps
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    }
};

import { mapStateToProps } from './CategoryProductList.container.plugin';

export class ProductCardContainer {
    containerProps = (args, callback, instance) => {
        const { listItemView } = instance.props;

        return {
            ...callback(...args),
            listItemView
        };
    };
}

const {
    containerProps
} = new ProductCardContainer();

export default {
    'Component/ProductCard/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps
        }
    }
};

import CmsBlockQuery from 'Query/CmsBlock.query';
import { fetchQuery } from 'Util/Request';

import {
    SHOW_ALL_PRODUCTS_BY_ATTRIBUTE,
    STATIC_BLOCK_WIDGET_IDENTIFIER
} from '../ShowAllProductsByAttribute.config';

export class ProductActionsContainer {
    state = (originalMember) => ({
        ...originalMember,
        isShowAllEnabled: true,
        showAllProductsWidget: ''
    });

    componentDidMount = (args, callback, instance) => {
        this.setShowAllProductsByAttributeBlock(instance);

        callback(...args);
    };

    containerProps = (args, callback, {
        state: {
            isShowAllEnabled,
            showAllProductsWidget
        }
    }) => ({
        ...callback(...args),
        isShowAllEnabled,
        showAllProductsWidget
    });

    getRenderTypes = (args, callback) => ([
        ...callback(...args),
        SHOW_ALL_PRODUCTS_BY_ATTRIBUTE
    ]);

    setShowAllProductsByAttributeBlock(instance) {
        const query = CmsBlockQuery.getQuery({ identifiers: [STATIC_BLOCK_WIDGET_IDENTIFIER] });

        fetchQuery(query).then(
            (result) => {
                if (!result) {
                    return;
                }

                const { cmsBlocks: { items = [] } } = result;
                const { content } = items.length ? items[0] : {};

                instance.setState({
                    isShowAllEnabled: true,
                    showAllProductsWidget: content || ''
                });
            },
            () => {
                instance.setState({ isShowAllEnabled: true });
            }
        );
    }
}

const {
    componentDidMount,
    containerProps,
    getRenderTypes,
    state
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container': {
        'member-function': {
            componentDidMount,
            containerProps
        },
        'member-property': {
            state
        }
    },
    'Pwasaas/Component/ProductActions/Container/ProductActionsContainer': {
        'member-function': {
            getRenderTypes
        }
    }
};

import CmsBlock from 'Component/CmsBlock';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        cmsBlock: state.SaasConfigReducer.config.cms_block_success_page
    };
};

export class AddStaticBlockToSuccessPagePlugin {
    renderTitle = (args, callback, instance) => {
        const { cmsBlock } = instance.props;

        if (cmsBlock || cmsBlock !== '0') {
            return null;
        }

        return callback(instance, args);
    };

    render = (args, callback, instance) => {
        const { cmsBlock } = instance.props;
        const result = callback(instance, args);

        if (!cmsBlock || cmsBlock === '0') {
            return result;
        }

        return <CmsBlock identifier={ cmsBlock } />;
    };
}

const {
    render,
    renderTitle
} = new AddStaticBlockToSuccessPagePlugin();

export default {
    'Pwasaas/Route/SuccessPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/CheckoutSuccess/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render
        }
    },
    'Pwasaas/Route/SuccessPage/Component/SuccessPageComponent': {
        'member-function': {
            renderTitle
        }
    }
};

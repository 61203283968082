import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { HistoryType } from 'Type/Common';
import { LocationType } from 'Type/Router';
import { getQueryParam, setQueryParams } from 'Util/Url';

import CategoryPriceRange from './CategoryPriceRange.component';
import { MAX_DEFAULT_PRICE_RANGE } from './CategoryPriceRange.config';

/** @namespace Pwasaas/Component/CategoryPriceRange/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selectedFilter: state.ProductListInfoReducer.selectedFilter,
    maxPriceSliderValue: state.SaasConfigReducer.config?.category_setting_for_max_price_slider_value
        || MAX_DEFAULT_PRICE_RANGE

});

/** @namespace Pwasaas/Component/CategoryPriceRange/Container/CategoryPriceRangeContainer */
export class CategoryPriceRangeContainer extends PureComponent {
    static propTypes = {
        selectedFilter: PropTypes.shape({
            priceRange: PropTypes.shape({
                min: PropTypes.number,
                max: PropTypes.number
            })
        }).isRequired,
        history: HistoryType.isRequired,
        location: LocationType.isRequired,
        maxPriceSliderValue: PropTypes.number.isRequired
    };

    config = {
        defaultPriceRange: {
            min: 0,
            max: MAX_DEFAULT_PRICE_RANGE
        },
        maxPriceSliderValue: MAX_DEFAULT_PRICE_RANGE
    };

    containerFunctions = {
        updatePriceRange: this.updatePriceRange.bind(this)
    };

    containerProps = () => ({
        minPriceValue: this._getMinPriceValue(),
        maxPriceValue: this._getMaxPriceValue(),
        priceValue: this._getPriceRangeValue()
    });

    _getMinPriceValue() {
        const { defaultPriceRange: { min } } = this.config;

        return min;
    }

    _getMaxPriceValue() {
        const { maxPriceSliderValue } = this.props;

        return maxPriceSliderValue;
    }

    _getPriceRangeValue() {
        const { min, max } = this._getPriceRangeFromUrl();

        return {
            min: min || this._getMinPriceValue(),
            max: max || this._getMaxPriceValue()
        };
    }

    _getPriceRangeFromUrl() {
        const { location } = this.props;
        const min = +getQueryParam('priceMin', location);
        const max = +getQueryParam('priceMax', location);
        return { min, max };
    }

    updatePriceRange(priceRange) {
        const { location, history } = this.props;

        setQueryParams({
            priceMax: priceRange.max,
            priceMin: priceRange.min,
            page: ''
        }, location, history);
    }

    render() {
        return (
            <CategoryPriceRange
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

/** @namespace Pwasaas/Component/CategoryPriceRange/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CategoryPriceRangeContainer)
);

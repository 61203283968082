import PropTypes from 'prop-types';

export class ProductPageContainer {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        productSKU: PropTypes.string
    });

    defaultProps = (originalProps) => ({
        ...originalProps,
        productSKU: ''
    });

    state = (originalMember) => ({
        ...originalMember,
        isProductIconsEnabled: false
    });

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        updateIsProductIconsEnabled: this.updateIsProductIconsEnabled.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const { productSKU } = instance.props;
        const { isProductIconsEnabled } = instance.state;

        return {
            ...callback(...args),
            isProductIconsEnabled,
            productSKU
        };
    };

    updateIsProductIconsEnabled(isProductIconsEnabled) {
        this.setState({ isProductIconsEnabled });
    }
}

const {
    containerFunctions,
    containerProps,
    defaultProps,
    state,
    staticPropTypes
} = new ProductPageContainer();

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions,
            state
        },
        'static-member': {
            defaultProps,
            propTypes: staticPropTypes
        }
    }
};

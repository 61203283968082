import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import FreeShippingThreshold from './FreeShippingThreshold.component';

/** @namespace Pwasaas/Component/FreeShippingThreshold/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isFreeShippingThresholdEnabled: state.SaasConfigReducer.config?.free_shipping_enabled || false,
    freeShippingTextWithAmount: state.SaasConfigReducer.config?.free_shipping_text_with_amount || '',
    freeShippingAmount: state.SaasConfigReducer.config?.free_shipping_amount || 0,
    freeShippingText: state.SaasConfigReducer.config?.free_shipping_text || '',
    isFreeShippingShowIcon: state.SaasConfigReducer.config?.free_shipping_show_icon || false
});

/** @namespace Pwasaas/Component/FreeShippingThreshold/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/FreeShippingThreshold/Container/FreeShippingThresholdContainer */
export class FreeShippingThresholdContainer extends PureComponent {
    static propTypes = {
        isFreeShippingThresholdEnabled: PropTypes.bool.isRequired,
        freeShippingText: PropTypes.string.isRequired,
        freeShippingTextWithAmount: PropTypes.number.isRequired,
        freeShippingAmount: PropTypes.string.isRequired,
        isFreeShippingShowIcon: PropTypes.bool.isRequired,
        totals: PropTypes.shape().isRequired,
        elem: PropTypes.string
    };

    static defaultProps = {
        elem: ''
    };

    containerProps = () => {
        const {
            isFreeShippingThresholdEnabled,
            freeShippingText,
            freeShippingTextWithAmount,
            freeShippingAmount,
            isFreeShippingShowIcon,
            totals,
            elem
        } = this.props;

        return {
            isFreeShippingThresholdEnabled,
            freeShippingText,
            freeShippingTextWithAmount,
            freeShippingAmount,
            isFreeShippingShowIcon,
            totals,
            elem
        };
    };

    render() {
        return (
            <FreeShippingThreshold
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeShippingThresholdContainer);

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { EXCEED_STOCK_POPUP_ID } from './ExceedStockPopup.config';

import './ExceedStockPopup.style.scss';

/** @namespace Pwasaas/Plugin/ExceedStockPopup/Component/ExceedStockPopup/Component/ExceedStockPopupComponent */
export class ExceedStockPopupComponent extends PureComponent {
    static propTypes = {
        exceed_qty_content: PropTypes.string.isRequired,
        qty: PropTypes.number
    };

    static defaultProps = {
        qty: 0
    };

    renderContent() {
        const { exceed_qty_content, qty } = this.props;
        const content = exceed_qty_content.replace('[x]', qty);

        return (
            <div block="ExceedStockPopup" elem="ContentWrapper">
                <h3 block="ExceedStockPopup" elem="Title">
                    { __('Quantity not available!') }
                </h3>
                <p block="ExceedStockPopup" elem="Content">
                    { content }
                </p>
            </div>
        );
    }

    render() {
        return (
            <Popup
              id={ EXCEED_STOCK_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'ExceedStockPopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default ExceedStockPopupComponent;

/** @namespace Pwasaas/Util/Attribute/Index/getHiddenAttributeCodes */
export const getHiddenAttributeCodes = () => [];

/** @namespace Pwasaas/Util/Attribute/Index/getVisibleProductAttributes */
export const getVisibleProductAttributes = (
    attributes
) => Object.values(attributes).filter(({ attribute_code }) => !getHiddenAttributeCodes().includes(attribute_code));

/** @namespace Pwasaas/Util/Attribute/Index/ProductAttribute */
export class ProductAttribute {
    static getValue(attribute) {
        const { attribute_type } = attribute;

        switch (attribute_type) {
        case 'select':
            return this._getSelectAttribute(attribute);
        case 'boolean':
            return this._getBooleanAttribute(attribute);
        case 'text':
            return this._getTextAttribute(attribute);
        case 'multiselect':
            return this._getMultiSelectAttribute(attribute);
        case 'media_image':
            return this._getImageAttribute(attribute);
        case 'textarea':
            return this._getTextAreaAttribute(attribute);
        case 'number':
            return this._getNumericAttribute(attribute);
        default:
            return null;
        }
    }

    static _getSelectAttribute(attribute) {
        const {
            attribute_options: options,
            attribute_value: value
        } = attribute;

        const { labelText } = this._getOptionLabel(value, options);

        return this._getStringValue(labelText || __('N/A'));
    }

    static _getBooleanAttribute(attribute) {
        const { attribute_value } = attribute;

        return this._getStringValue(+attribute_value ? __('Yes') : __('No'));
    }

    static _getTextAttribute(attribute) {
        const { attribute_value } = attribute;

        return this._getStringValue(attribute_value);
    }

    static _getTextAreaAttribute(attribute) {
        const { attribute_value } = attribute;

        return attribute_value;
    }

    static _getMultiSelectAttribute(attribute) {
        const {
            attribute_options: options,
            attribute_value
        } = attribute;

        const labelsArray = attribute_value.split(',').reduce((labels, value) => {
            const { label } = this._getOptionLabel(value, options);

            if (label) {
                labels.push(label);
            }

            return labels;
        }, []);

        return this._getStringValue(labelsArray.length ? labelsArray.join(', ') : __('N/A'));
    }

    static _getImageAttribute(attribute) {
        const { attribute_value } = attribute;

        if (!attribute_value || attribute_value === 'no_selection') {
            return '';
        }

        return `/media/catalog/product/${attribute_value}`;
    }

    static _getNumericAttribute(attribute) {
        const { attribute_value } = attribute;

        return this._getStringValue(parseFloat(attribute_value).toFixed(2));
    }

    static _getOptionLabel(value, options) {
        if (options) {
            const optionValues = options[value];
            if (optionValues) {
                const { label } = optionValues;

                return { ...optionValues, labelText: label };
            }
        }

        return {};
    }

    static _getStringValue(value, label) {
        return label || value || __('N/A');
    }
}

export default ProductAttribute;

export class CategoryQuery {
    _getDefaultFields = (args, callback) => ([
        ...callback(args),
        'tablet_image',
        'mobile_image',
        'thumbnail'
    ]);
}

const {
    _getDefaultFields
} = new CategoryQuery();

export const config = {
    'Query/Category': {
        'member-function': {
            _getDefaultFields
        }
    }
};

export default config;

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/CustomerNotes/Query/CustomerNotesQuery */
export class CustomerNotesQuery {
    getSaveCustomerNotesMutation(customerNote, quoteId) {
        return new Field('addCustomerNotesToCart')
            .addArgument('customer_notes', 'String', customerNote)
            .addArgument('cart_id', 'String', quoteId);
    }
}

export default new CustomerNotesQuery();

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import MenuButton from 'Component/MenuButton';
import MenuContent from 'Component/MenuContent';
import MenuLink from 'Component/MenuLink';
import MenuNavigation from 'Component/MenuNavigation';
import { MENU_OVERLAY_KEY } from 'Component/MenuRegular/MenuRegular.config';
import Overlay from 'Component/Overlay';
import { MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/SaasMenu';

import {
    MENU_ITEM_DEFAULT,
    MENU_ITEM_TOGGLE
} from './MenuAdvanced.config';

import './MenuAdvanced.style';

/** @namespace Pwasaas/Component/MenuAdvanced/Component/MenuAdvancedComponent */
export class MenuAdvancedComponent extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired,
        activeType: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        handleCloseMenu: PropTypes.func.isRequired,
        handleMouseLeave: PropTypes.func.isRequired,
        items: PropTypes.arrayOf(
            MenuItemType
        ).isRequired,
        mix: MixType.isRequired,
        toggleItems: PropTypes.arrayOf(
            MenuItemType
        ).isRequired
    };

    renderMap = {
        [MENU_ITEM_DEFAULT]: {
            component: MenuContent
        },
        [MENU_ITEM_TOGGLE]: {
            component: MenuNavigation
        }
    };

    renderButton = (item, index) => {
        const { device } = this.props;

        if (device.isMobile && index > 0) {
            return null;
        }

        return (
            <MenuButton
              key={ item.id }
              item={ item }
            />
        );
    };

    renderMainMenu = (item) => {
        const {
            activeMenuItem,
            device
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <div
              block="MenuAdvanced"
              elem="MainItem"
              key={ item.id }
            >
                <MenuLink
                  item={ item }
                  itemMods={ {
                      isLink: true,
                      isActive: activeMenuItem === item
                  } }
                  mix={ {
                      block: 'MenuAdvanced',
                      elem: 'Link',
                      mods: {}
                  } }
                />
            </div>
        );
    };

    renderMainLevel() {
        const {
            items,
            toggleItems
        } = this.props;

        if (!items.length && !toggleItems.length) {
            return null;
        }

        return (
            <div
              block="MenuAdvanced"
              elem="MainItemList"
            >
                { toggleItems.map(this.renderButton) }
                { items.map(this.renderMainMenu) }
            </div>
        );
    }

    renderSubContent() {
        const {
            activeMenuItem: {
                childs = []
            },
            activeType,
            device,
            handleCloseMenu
        } = this.props;

        if (!childs || !childs.length) {
            return null;
        }

        const {
            component: SubContentComponent
        } = this.renderMap[activeType]
            || this.renderMap[MENU_ITEM_DEFAULT];

        return (
            <ClickOutside
              onClick={ handleCloseMenu }
            >
                <Overlay
                  id={ MENU_OVERLAY_KEY }
                  mix={ {
                      block: 'MenuAdvanced',
                      elem: 'Overlay'
                  } }
                  isRenderInPortal={ false }
                  isStatic={ device.isMobile }
                >
                    <SubContentComponent />
                </Overlay>
            </ClickOutside>
        );
    }

    render() {
        const {
            handleMouseLeave,
            mix
        } = this.props;

        return (
            <div
              block="MenuAdvanced"
              elem="Wrapper"
              mix={ mix }
              onMouseLeave={ handleMouseLeave }
            >
                { this.renderMainLevel() }
                { this.renderSubContent() }
            </div>
        );
    }
}

export default MenuAdvancedComponent;

export class StoreQuery {
    _getSaasConfigFields = (args, callback) => ([
        ...callback(args),
        'free_shipping_enabled',
        'free_shipping_text_with_amount',
        'free_shipping_amount',
        'free_shipping_text',
        'free_shipping_show_icon'
    ]);
}

const {
    _getSaasConfigFields
} = new StoreQuery();

export default {
    'Pwasaas/Query/Store/Query/StoreQuery': {
        'member-function': {
            _getSaasConfigFields
        }
    }
};

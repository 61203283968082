import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AttributeImageQuery from 'Query/AttributeImage.query';
import { showNotification } from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';

import ProductBrandLogo from './ProductBrandLogo.component';

/** @namespace Pwasaas/Plugin/ProductBrandLogo/Component/ProductBrandLogo/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Pwasaas/Plugin/ProductBrandLogo/Component/ProductBrandLogo/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Pwasaas/Plugin/ProductBrandLogo/Component/ProductBrandLogo/Container/ProductBrandLogoContainer */
export class ProductBrandLogoContainer extends DataContainer {
    static propTypes = {
        brandAttribute: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        sku: PropTypes.string.isRequired
    };

    state = {
        brandLogo: {}
    };

    componentDidMount() {
        const {
            sku,
            brandAttribute: attribute
        } = this.props;

        const options = {
            attribute,
            sku,
            alias: 'brandLogo'
        };

        this.fetchData(
            [AttributeImageQuery.getQuery(options)],
            this._setStateData,
            this._handleError
        );
    }

    containerProps = () => {
        const { brandLogo } = this.state;

        return { brandLogo };
    };

    _handleError = (error) => {
        const { showNotification } = this.props;

        showNotification('error', __('Something went wrong!'), error);
    };

    _setStateData = ({ brandLogo }) => {
        this.setState({ brandLogo });
    };

    render() {
        return (
            <ProductBrandLogo
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBrandLogoContainer);

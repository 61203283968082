import { Field } from 'Util/Query';

export class CategoryQuery {
    _getDefaultFields = (args, callback) => ([
        ...callback(args),
        this._getShopInShopField()
    ]);

    _getShopInShopField() {
        return new Field('shop_in_shop')
            .addFieldList(this._getShopInShopFields());
    }

    _getShopInShopFields() {
        return [
            'is_shop',
            'header_logo_src',
            'logo_width',
            'logo_height',
            'buttons_color',
            'buttons_background_color',
            'primary_base_color',
            'primary_dark_color',
            'primary_light_color',
            'secondary_base_color',
            'secondary_dark_color',
            'secondary_light_color'
        ];
    }
}

const {
    _getDefaultFields
} = new CategoryQuery();

export const config = {
    'Query/Category': {
        'member-function': {
            _getDefaultFields
        }
    }
};

export default config;

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { MenuBannerType } from 'Type/SaasMenu';

import './MenuBanner.style';

/** @namespace Pwasaas/Component/MenuBanner/Component/MenuBannerComponent */
export class MenuBannerComponent extends PureComponent {
    static propTypes = {
        customTitle: PropTypes.string,
        item: MenuBannerType,
        isMainBanner: PropTypes.bool,
        onBannerClick: PropTypes.func
    };

    static defaultProps = {
        customTitle: '',
        isMainBanner: false,
        item: {},
        onBannerClick: () => {}
    };

    renderMainBanner() {
        const {
            customTitle,
            item: {
                image,
                title
            }
        } = this.props;

        return (
            <div
              block="MenuBanner"
              elem="Main"
              mods={ {
                  isImage: !!image
              } }
              style={ {
                  backgroundImage: image ? `url(${image})` : ''
              } }
            >
                <figcaption
                  block="MenuBanner"
                  elem="MainTitle"
                >
                    { title ?? customTitle }
                </figcaption>
            </div>
        );
    }

    render() {
        const {
            item: {
                link,
                image,
                title
            },
            isMainBanner,
            onBannerClick
        } = this.props;

        if (isMainBanner) {
            return this.renderMainBanner();
        }

        return (
            <div
              block="MenuBanner"
              elem="Container"
            >
                <Link
                  block="MenuBanner"
                  elem="Item"
                  style={ { backgroundImage: image ? `url(${image})` : '' } }
                  to={ link }
                  tabIndex="0"
                  onClick={ onBannerClick }
                >
                    <figcaption
                      block="MenuBanner"
                      elem="ItemTitle"
                    >
                        { title }
                    </figcaption>
                </Link>
            </div>
        );
    }
}

export default MenuBannerComponent;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import { ProductType } from 'Type/ProductList';

import CompleteOrderProductList from '../CompleteOrderProductList';
import CompleteOrderTotals from '../CompleteOrderTotals';

import './CompleteOrder.manual.style';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrder/Component/CompleteOrderComponent */
export class CompleteOrderComponent extends PureComponent {
    static propTypes = {
        availableItems: PropTypes.arrayOf(ProductType).isRequired,
        currencyCode: PropTypes.string.isRequired,
        currentQuantity: PropTypes.number.isRequired,
        displayTaxInPrice: PropTypes.string.isRequired,
        handleItemChange: PropTypes.func.isRequired,
        product: ProductType.isRequired,
        selected: PropTypes.arrayOf(ProductType).isRequired
    };

    render() {
        const {
            availableItems,
            currencyCode,
            currentQuantity,
            displayTaxInPrice,
            handleItemChange,
            product,
            selected
        } = this.props;

        if (!availableItems.length) {
            return null;
        }

        return (
            <ContentWrapper
              wrapperMix={ {
                  block: 'CompleteOrder',
                  elem: 'Wrapper'
              } }
              mix={ { block: 'ProductCompleteOrder' } }
              label="Complete order"
            >
                <div
                  block="CompleteOrder"
                  elem="Content"
                >
                    <h2>{ __('Complete your order') }</h2>
                    <CompleteOrderProductList
                      onSelect={ handleItemChange }
                      products={ availableItems }
                      selectedIds={ selected.map(({ id }) => id) }
                    />
                    <CompleteOrderTotals
                      currencyCode={ currencyCode }
                      currentQuantity={ currentQuantity }
                      displayTaxInPrice={ displayTaxInPrice }
                      product={ product }
                      selected={ selected }
                    />
                </div>
            </ContentWrapper>
        );
    }
}

export default CompleteOrderComponent;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateActiveMenuItem } from 'Store/SaasMenu/SaasMenu.action';
import { MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/SaasMenu';
import { getBannerItem } from 'Util/Saas';

import MenuRegular from './MenuRegular.component';
import {
    FIRST_MENU_ID,
    MENU_MAIN_ITEM_LEVEL
} from './MenuRegular.config';

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

/** @namespace Pwasaas/Component/MenuRegular/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeMenuItem: state.SaasMenuReducer.activeMenuItem,
    device: state.ConfigReducer.device,
    history: state.SaasMenuReducer.history,
    isToggled: state.SaasMenuReducer.isToggled,
    items: state.SaasMenuReducer.header.items || []
});

/** @namespace Pwasaas/Component/MenuRegular/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeMenu(dispatch)
    ),
    setActiveMenuItem: (item) => dispatch(updateActiveMenuItem(item))
});

/** @namespace Pwasaas/Component/MenuRegular/Container/MenuRegularContainer */
export class MenuRegularContainer extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired,
        closeMenu: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        history: PropTypes.arrayOf(MenuItemType).isRequired,
        isToggled: PropTypes.bool.isRequired,
        items: PropTypes.arrayOf(
            MenuItemType
        ).isRequired,
        mix: MixType,
        setActiveMenuItem: PropTypes.func.isRequired
    };

    static defaultProps = {
        mix: {}
    };

    containerFunctions = {
        handleCloseMenu: this.handleCloseMenu.bind(this),
        handleMouseLeave: this.handleMouseLeave.bind(this),
        handlePrevious: this.handlePrevious.bind(this)
    };

    containerProps = () => {
        const {
            activeMenuItem,
            device,
            items,
            mix
        } = this.props;

        const bannerItem = this._getBannerItem();
        const isWithHistory = this._getIsWithHistory();
        const toggleItem = this._getToggleItem();

        return {
            activeMenuItem,
            bannerItem,
            device,
            isWithHistory,
            items,
            mix,
            toggleItem
        };
    };

    _getBannerItem() {
        const {
            activeMenuItem,
            activeMenuItem: {
                mega_setting: {
                    description = ''
                } = {}
            }
        } = this.props;

        if (!Object.keys(activeMenuItem).length) {
            return {};
        }

        return getBannerItem(activeMenuItem, description);
    }

    _getIsWithHistory() {
        const {
            activeMenuItem: {
                id
            },
            device,
            history
        } = this.props;

        return device.isMobile && history.length > 1 && id !== FIRST_MENU_ID;
    }

    _getToggleItem() {
        const {
            device,
            items
        } = this.props;

        if (items.length && device.isMobile) {
            return {
                ...items[0],
                id: FIRST_MENU_ID,
                childs: items.map((item) => ({
                    ...item,
                    level: MENU_MAIN_ITEM_LEVEL + 1
                })),
                title: __('Menu')
            };
        }

        return {};
    }

    handleCloseMenu() {
        const { closeMenu } = this.props;

        closeMenu();
    }

    handleMouseLeave() {
        const {
            closeMenu,
            device,
            isToggled
        } = this.props;

        if (isToggled || device.isMobile) {
            return;
        }

        closeMenu();
    }

    handlePrevious() {
        const {
            activeMenuItem,
            history,
            setActiveMenuItem
        } = this.props;

        const previousItem = history[history.length - 2] || {};

        if (activeMenuItem.id === previousItem.id) {
            setActiveMenuItem({});

            return;
        }

        setActiveMenuItem(previousItem);
    }

    render() {
        return (
            <MenuRegular
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuRegularContainer);

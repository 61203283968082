export const MAX_STORES_RESULT = 20;
export const MAX_SEARCH_RESULT = 3;
export const MIN_ZIPCODE_LENGTH = 4;
export const MAX_ZIPCODE_LENGTH = 6;
export const MAP_STYLES = [
    {
        featureType: 'all',
        stylers: [
            { saturation: -100 }
        ]
    }
];

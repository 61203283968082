/* eslint-disable max-lines */
// import { RIGHT_POSITION } from 'Component/SaasProductLabels/SaasProductLabels.config';
import { cloneElement } from 'react';

import { _getTierQty1, getHiveFormattedFinalPrice } from 'Util/Price/GetPrice';
import { getStore } from 'Util/Store';

export const DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX = 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX';
export class DisableDiscountOnFirstTierPricePlugin {
    _fixPriceTiersDiscountOnProduct = (product) => {
        const productToEdit = product;
        const {
            price_range,
            price_tiers
            /**
             * TODO: Figure out what productLabels has to do with discount on tier pricing
             */
            // productLabels = []
        } = productToEdit;

        const {
            ConfigReducer: {
                priceTaxDisplay: {
                    product_price_display_type: displayTaxInPrice = ''
                } = {}
            } = {}
        } = getStore().getState();

        const CUSTOM_DIVIDER = 100;

        if (price_range && price_tiers[0]) {
            const {
                minimum_price: {
                    discount,
                    final_price,
                    final_price_excl_tax,
                    regular_price,
                    regular_price_excl_tax
                }
            } = price_range;

            const price_tier = _getTierQty1(price_tiers);

            if (!price_tier) {
                return product;
            }

            if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
                if (price_tier.final_price.value < regular_price_excl_tax.value) {
                    /**
                     * TODO: Figure out what productLabels has to do with discount on tier pricing
                     */
                    // if (productLabels.length) {
                    //     productToEdit.productLabels = productLabels.filter(
                    //         ({ position }) => position !== RIGHT_POSITION
                    //     );
                    // }

                    const discountAmount = price_tier.final_price.value - final_price_excl_tax.value;
                    discount.amount_off = discountAmount;
                    discount.percent_off = discountAmount / (price_tier.final_price.value / CUSTOM_DIVIDER);

                    regular_price_excl_tax.value = price_tier.final_price.value;
                }

                return productToEdit;
            }

            if (price_tier.final_price.value < regular_price.value) {
                /**
                 * TODO: Figure out what productLabels has to do with discount on tier pricing
                 */
                // if (productLabels.length) {
                //     productToEdit.productLabels = productLabels.filter(
                //         ({ position }) => position !== RIGHT_POSITION
                //     );
                // }

                const discountAmount = price_tier.final_price.value - final_price.value;
                discount.amount_off = discountAmount;
                discount.percent_off = discountAmount / (price_tier.final_price.value / CUSTOM_DIVIDER);

                regular_price.value = price_tier.final_price.value;
            }
        }

        return productToEdit;
    };

    fixPriceTiersDiscount = (args, callback, instance) => {
        const [{ products, products: { items: oldItems } }, ...otherArgs] = args;
        const items = oldItems.map(this._fixPriceTiersDiscountOnProduct);

        const data = {
            products: {
                ...products,
                items
            }
        };

        return callback.apply(instance, [data, ...otherArgs]);
    };

    fixMostOrderedPriceTiersDiscount = (args, callback, instance) => {
        const [{ orderList: oldList }, ...otherArgs] = args;

        const orderList = oldList.reduce(
            (acc, item) => [
                ...acc,
                {
                    ...item,
                    product: this._fixPriceTiersDiscountOnProduct(item.product)
                }
            ], []
        );

        const data = {
            orderList
        };

        return callback.apply(instance, [data, ...otherArgs]);
    };

    containerProps = (args, callback, instance) => {
        const { displayTaxInPrice } = instance.props;

        return {
            ...callback(...args),
            displayTaxInPrice
        };
    };

    /**
     * Add isProductCard prop to product price loaded from productcard
     */
    renderProductPrice = (args, callback, instance) => {
        const RenderedComponent = callback(instance, args);

        if (!RenderedComponent) {
            return null;
        }

        const { props: parentProps, props: { children: baseChildren = {} } } = RenderedComponent;

        const { props: baseProps } = baseChildren;

        const props = {
            ...baseProps,
            isProductCard: true
        };

        const children = {
            ...baseChildren,
            props
        };

        return cloneElement(RenderedComponent, {
            ...parentProps,
            children
        });
    };

    getFormattedFinalPrice = (args, callback, instance) => {
        const { isProductCard } = instance.props;

        return getHiveFormattedFinalPrice(instance.props, isProductCard);
    };

    renderOldPrice = (args, callback, instance) => {
        const result = callback(...args);
        const {
            displayTaxInPrice = '',
            price: {
                minimum_price: {
                    final_price: {
                        value: minimalFinalPriceValue
                    } = {},
                    final_price_excl_tax: {
                        value: minimalFinalPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            price_tiers,
            isSchemaRequired,
            variantsCount,
            roundedRegularPrice
        } = instance.props;

        if (!price_tiers.length) {
            return result;
        }

        const price_tier = _getTierQty1(price_tiers);

        if (!price_tier) {
            return result;
        }

        if (
            (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
                && price_tier.final_price.value > minimalFinalPriceExclTaxValue)
                    || price_tier.final_price.value > minimalFinalPriceValue
        ) {
            return (
                <del
                  block="ProductPrice"
                  elem="HighPrice"
                  aria-label={ __('Old product price') }
                  itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
                >
                    { roundedRegularPrice }
                </del>
            );
        }

        return result;
    };
}

const {
    getFormattedFinalPrice,
    fixPriceTiersDiscount,
    fixMostOrderedPriceTiersDiscount,
    containerProps,
    renderOldPrice,
    renderProductPrice

} = new DisableDiscountOnFirstTierPricePlugin();

export default {
    'Store/ProductList/Dispatcher': {
        'member-function': {
            onSuccess: fixPriceTiersDiscount
        }
    },
    'Store/Product/Dispatcher': {
        'member-function': {
            onSuccess: fixPriceTiersDiscount
        }
    },
    'Store/LinkedProducts/Dispatcher': {
        'member-function': {
            onSuccess: fixPriceTiersDiscount
        }
    },
    'Pwasaas/Store/OrderList/Dispatcher/OrderListDispatcher': {
        'member-function': {
            _updateOrderListItems: fixMostOrderedPriceTiersDiscount
        }
    },
    'Component/ProductListWidget/Container': {
        'member-function': {
            updateProductListItems: fixPriceTiersDiscount
        }
    },
    'Component/ProductPrice/Container': {
        'member-function': {
            containerProps,
            getFormattedFinalPrice
        }
    },
    'Component/ProductPrice/Component': {
        'member-function': {
            renderOldPrice
        }
    },
    'Pwasaas/Component/SaasSliderWidget/Container/SaasSliderWidgetContainer': {
        'member-function': {
            onProductListSuccess: fixPriceTiersDiscount
        }
    },
    'Component/ProductCard/Component': {
        'member-function': {
            renderProductPrice
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updatePickupStore } from 'Store/StoreLocator/StoreLocator.action';
import { MixType } from 'Type/Common';

import StoreLocatorSelect from './StoreLocatorSelect.component';

export const StoreLocatorDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/StoreLocator/StoreLocator.dispatcher'
);

/** @namespace Pwasaas/Component/StoreLocatorSelect/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    pickupStore: state.StoreLocatorReducer.pickupStore,
    storeSelectList: state.StoreLocatorReducer.storeSelectList
});

/** @namespace Pwasaas/Component/StoreLocatorSelect/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fetchStoreList: () => StoreLocatorDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestStoreLocators(dispatch)
    ),
    updatePickupStore: (value) => dispatch(updatePickupStore(value))
});

/** @namespace Pwasaas/Component/StoreLocatorSelect/Container/StoreLocatorSelectContainer */
export class StoreLocatorSelectContainer extends PureComponent {
    static propTypes = {
        fetchStoreList: PropTypes.func.isRequired,
        mix: MixType,
        pickupStore: PropTypes.number.isRequired,
        storeSelectList: PropTypes.shape([]).isRequired,
        updatePickupStore: PropTypes.func.isRequired
    };

    static defaultProps = {
        mix: {}
    };

    containerFunctions = {
        getDefaultValues: this.getDefaultValues.bind(this)
    };

    async componentDidMount() {
        const { fetchStoreList } = this.props;

        await fetchStoreList();
    }

    containerProps = () => {
        const {
            mix,
            pickupStore,
            storeSelectList
        } = this.props;

        const { handleSetPickupStore } = this;

        return {
            handleSetPickupStore,
            mix,
            pickupStore,
            storeSelectList
        };
    };

    getDefaultValues([key, props]) {
        const {
            type = 'text',
            onChange = () => {},
            ...otherProps
        } = props;

        return {
            ...otherProps,
            key,
            name: key,
            id: key,
            type,
            onChange
        };
    }

    handleSetPickupStore = (value) => {
        const {
            updatePickupStore
        } = this.props;

        updatePickupStore(value);
    };

    render() {
        return (
            <StoreLocatorSelect
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocatorSelectContainer);

import { cloneElement } from 'react';

/**
 * Add is_anchor to category to show filters on search page
 */
export const renderFilterOverlay = (args, callback, instance) => {
    const RenderedComponent = callback(instance, args);

    if (!RenderedComponent) {
        return null;
    }

    const { props } = RenderedComponent;
    const { category } = instance.props;

    category.is_anchor = true;

    return cloneElement(RenderedComponent, {
        ...props,
        category
    });
};

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            renderFilterOverlay
        }
    }
};

export class StoreQuery {
    _getSaasConfigFields = (args, callback) => ([
        ...callback(args),
        'additional_emails_enabled'
    ]);
}

const {
    _getSaasConfigFields
} = new StoreQuery();

export default {
    'Pwasaas/Query/Store/Query/StoreQuery': {
        'member-function': {
            _getSaasConfigFields
        }
    }
};

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import MenuAside from 'Component/MenuAside';
import MenuBanner from 'Component/MenuBanner';
import MenuColumn from 'Component/MenuColumn';
import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/UberMenu';
import { getBannerItem } from 'Util/Saas';

import {
    CONTENT_TYPE_BANNER,
    CONTENT_TYPE_DEFAULT
} from './MenuContent.config';

import './MenuContent.style';

/** @namespace Pwasaas/Component/MenuContent/Component/MenuContentComponent */
export class MenuContentComponent extends PureComponent {
    static propTypes = {
        contentType: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        handleCloseMenu: PropTypes.func.isRequired,
        items: PropTypes.arrayOf(MenuItemType).isRequired
    };

    renderMap = {
        [CONTENT_TYPE_BANNER]: {
            renderContent: () => this.renderBanners()
        },
        [CONTENT_TYPE_DEFAULT]: {
            renderContent: () => this.renderColumns()
        }
    };

    renderColumns() {
        const {
            device,
            items
        } = this.props;

        return (
            <div
              block="MenuContent"
              elem="Columns"
              mods={ {
                  type: device.isMobile ? 'mobile' : false
              } }
            >
                { items.map((item) => (
                    <MenuColumn
                      key={ item.id }
                      item={ item }
                    />
                )) }
            </div>
        );
    }

    renderBanners() {
        const {
            handleCloseMenu,
            items
        } = this.props;

        return (
            <div
              block="MenuContent"
              elem="Banners"
            >
                { items.map((item) => (
                    <MenuBanner
                      key={ item.id }
                      item={ getBannerItem(item) }
                      onBannerClick={ handleCloseMenu }
                    />
                )) }
            </div>
        );
    }

    render() {
        const {
            contentType,
            device,
            items = []
        } = this.props;

        if (!items.length) {
            return null;
        }

        const { renderContent } = this.renderMap[contentType];

        return (
            <div
              block="MenuContent"
              elem="Container"
              mods={ {
                  isDesktop: !device.isMobile
              } }
            >
                { renderContent() }
                <MenuAside />
            </div>
        );
    }
}

export default MenuContentComponent;

import { cloneElement } from 'react';

export class MyAccountMyOrdersComponent {
    renderOrderHeadingRow = (args, callback = () => {}, instance) => {
        const RenderedComponent = callback(instance, args);
        const { isReorderEnabled } = instance.props;

        if (!isReorderEnabled) {
            return RenderedComponent;
        }

        const { props, props: { children: baseChildren = [] } } = RenderedComponent;
        const children = [...baseChildren,
            <th>{ __('Actions') }</th>
        ];

        return cloneElement(RenderedComponent, {
            ...props,
            children
        });
    };
}

const {
    renderOrderHeadingRow
} = new MyAccountMyOrdersComponent();

export default {
    'Component/MyAccountMyOrders/Component': {
        'member-function': {
            renderOrderHeadingRow
        }
    }
};

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { UPDATE_PRODUCT_LABELS } from './ProductLabel.action';

export const PRODUCT_LABELS = 'product_labels';

const {
    notAvailable,
    productLabels
} = BrowserDatabase.getItem(PRODUCT_LABELS) || {
    notAvailable: [],
    productLabels: {}
};

/** @namespace Pwasaas/Plugin/ProductLabel/Store/ProductLabel/Reducer/getInitialState */
export const getInitialState = () => ({
    notAvailable,
    productLabels
});

/** @namespace Pwasaas/Plugin/ProductLabel/Store/ProductLabel/Reducer/ProductLabelReducer */
export const ProductLabelReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_PRODUCT_LABELS:
        const { productLabelList, notAvailable } = action;
        const productLabels = {
            ...state.productLabels,
            ...productLabelList
        };

        BrowserDatabase.setItem(
            {
                notAvailable,
                productLabels
            },
            PRODUCT_LABELS,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            notAvailable,
            productLabels
        };

    default:
        return state;
    }
};

export default ProductLabelReducer;

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { getFilteredStoreData } from 'Util/Store';

import { UPDATE_KIYOH } from './Kiyoh.action';

export const KIYOH = 'kiyoh';

export const {
    page,
    widget
} = BrowserDatabase.getItem('kiyoh') || {
    page: {},
    widget: {}
};

/** @namespace Pwasaas/Store/Kiyoh/Reducer/getInitialState */
export const getInitialState = () => ({
    page,
    widget
});

/** @namespace Pwasaas/Store/Kiyoh/Reducer/KiyohReducer */
export const KiyohReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_KIYOH:
        const {
            saasKiyoh: {
                page = {},
                widget = {}
            } = {}
        } = action;

        const result = {
            page: getFilteredStoreData(page),
            widget: getFilteredStoreData(widget)
        };

        BrowserDatabase.setItem(
            { ...result },
            KIYOH,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            ...result
        };

    default:
        return state;
    }
};

export default KiyohReducer;

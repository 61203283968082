export class ProductListQuery {
    _getProductInterfaceFields = (args, callback) => {
        const [isVariant, isForLinkedProducts] = args;

        return [
            ...callback(isVariant, isForLinkedProducts),
            'grouped_product_url'
        ];
    };
}

const {
    _getProductInterfaceFields
} = new ProductListQuery();

export const config = {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields
        }
    }
};

export default config;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { BlockListType } from 'Type/CMS';
import { ChildrenType } from 'Type/Common';

import './SalesPromotionPage.style';

/** @namespace Pwasaas/Plugin/SalesPromotion/Route/SalesPromotionPage/Component/SalesPromotionPageComponent */
export class SalesPromotionPageComponent extends PureComponent {
    static propTypes = {
        isBreadcrumbsActive: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        page: BlockListType.isRequired,
        sidebarContent: ChildrenType.isRequired,
        sidebarHeading: PropTypes.shape({
            current: PropTypes.instanceOf(Element)
        }).isRequired
    };

    renderSidebarContent() {
        const { sidebarContent } = this.props;

        return (
            <div
              block="SalesPromotionPage"
              elem="SidebarContent"
            >
                <Html content={ sidebarContent } />
            </div>
        );
    }

    renderHeading() {
        const {
            page: {
                content_heading,
                title
            },
            sidebarContent,
            sidebarHeading
        } = this.props;

        return (
            <div
              block="SalesPromotionPage"
              elem="Heading"
              ref={ sidebarHeading }
              mods={ { inSidebar: !!sidebarContent } }
            >
                <h1>{ title }</h1>
                <span>{ content_heading || '' }</span>
            </div>
        );
    }

    renderSidebar() {
        const { sidebarContent } = this.props;

        if (!sidebarContent) {
            return this.renderHeading();
        }

        return (
            <div
              block="SalesPromotionPage"
              elem="Sidebar"
            >
                { this.renderHeading() }
                { this.renderSidebarContent() }
            </div>
        );
    }

    renderMainContent() {
        const { page: { content } } = this.props;

        if (!content || typeof content !== 'string') {
            return null;
        }

        return (
            <div
              block="SalesPromotionPage"
              elem="MainContent"
            >
                <Html content={ content } />
            </div>
        );
    }

    renderContent() {
        const { sidebarContent } = this.props;

        return (
            <div
              block="SalesPromotionPage"
              elem="Content"
              mods={ { hasTwoColumns: !!sidebarContent } }
            >
                { this.renderSidebar() }
                { this.renderMainContent() }
            </div>
        );
    }

    render() {
        const {
            isBreadcrumbsActive,
            isLoading
        } = this.props;

        return (
            <main
              block="SalesPromotionPage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
            >
                <ContentWrapper
                  isRow
                  wrapperMix={ { block: 'SalesPromotionPage', elem: 'Wrapper' } }
                  label="Sales promotion page"
                >
                    <Loader
                      isLoading={ isLoading }
                      isTransparent={ false }
                    />
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SalesPromotionPageComponent;

import GiftCard from 'Component/GiftCard';

export class CartPageComponent {
    renderDiscountCode = (args, callback, instance) => {
        const RenderedComponent = callback(instance, args);

        const { isGiftCardEnabled } = instance.props;

        if (isGiftCardEnabled === '1') {
            return (
                <>
                    <GiftCard
                      mix={ {
                          block: 'CartPage',
                          elem: 'GiftCard'
                      } }
                    />
                    { RenderedComponent }
                </>
            );
        }

        return RenderedComponent;
    };
}

const {
    renderDiscountCode
} = new CartPageComponent();

export default {
    'Pwasaas/Route/CartPage/Component/CartPageComponent': {
        'member-function': {
            renderDiscountCode
        }
    }
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        menuType: state.SaasMenuReducer.header?.config?.menu_type || ''
    };
};

export class HeaderContainer {
    containerProps = (args, callback, instance) => {
        const { menuType } = instance.props;

        return {
            ...callback(args),
            menuType
        };
    };
}

const {
    containerProps
} = new HeaderContainer();

export default {
    'Component/Header/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Header/Container': {
        'member-function': {
            containerProps
        }
    }
};

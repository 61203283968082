export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    showUnitPrice: state.SaasConfigReducer.config?.unit_price_show_on_plp || false,
    unitPriceAttribute: state.SaasConfigReducer.config?.unit_price_attribute || '',
    displayTaxInPrice: state.ConfigReducer.priceTaxDisplay?.product_price_display_type
});

export class ProductCardContainer {
    containerProps = (args, callback, {
        props: {
            showUnitPrice,
            unitPriceAttribute,
            displayTaxInPrice
        }
    }) => ({
        ...callback(...args),
        showUnitPrice,
        unitPriceAttribute,
        displayTaxInPrice
    });
}

const {
    containerProps
} = new ProductCardContainer();

export default {
    'Component/ProductCard/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps
        }
    }
};

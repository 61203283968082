import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.manual.style';

/** @namespace Pwasaas/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    /** This method (hidePopupAndGoBack) was abused by some other feature and was causing
     * the escape key to trigger a backwards navigation event. The method was imported here, and it
     * works just with its default behaviour without the need for additional changes. */
    hidePopupAndGoBack = () => {
        this.hidePopUp();
    };
}

export default PopupComponent;

import PropTypes from 'prop-types';

export const ProductQuantityType = PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.number
});

export const SaasConfigType = PropTypes.shape({
    google_font_enabled: PropTypes.bool,
    google_font_primary: PropTypes.string,
    google_font_primary_weights: PropTypes.string,
    secret: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    show_age_check: PropTypes.string,
    text_age_check: PropTypes.string,
    enable_force_login: PropTypes.bool
});

export const StoreHoursType = PropTypes.shape({
    monday: PropTypes.string,
    tuesday: PropTypes.string,
    wednesday: PropTypes.string,
    thursday: PropTypes.string,
    friday: PropTypes.string,
    saturday: PropTypes.string,
    sunday: PropTypes.string
});

export const StoreSocialType = PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    pinterest: PropTypes.string,
    linkedin: PropTypes.string,
    snapchat: PropTypes.string,
    twitter: PropTypes.string,
    youtube: PropTypes.string
});

export const SiteUspType = PropTypes.shape({
    icon: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.node
        )
    ])
});

export const SiteUspItemsType = PropTypes.arrayOf(SiteUspType);

export const ProductUspType = PropTypes.shape({
    icon: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.node
        )
    ])
});

export const ProductUspItemsType = PropTypes.arrayOf(ProductUspType);

export const ExpectedDeliveryType = PropTypes.shape({
    all: PropTypes.arrayOf(PropTypes.string),
    day: PropTypes.string,
    time: PropTypes.string
});

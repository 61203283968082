/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { updateUserCanEdit } from 'Store/OrderList/OrderList.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { OrderListItemType } from 'Type/OrderList';
import DataContainer from 'Util/Request/DataContainer';

import MyAccountOrderList from './MyAccountOrderList.component';
import { ORDER_LIST_SEARCH } from './MyAccountOrderList.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const OrderListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/OrderList/OrderList.dispatcher'
);

/** @namespace Pwasaas/Component/MyAccountOrderList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    items: state.OrderListReducer.items,
    isLoading: state.OrderListReducer.isLoading,
    productConfig: state.SaasConfigReducer.product,
    device: state.ConfigReducer.device
});

/** @namespace Pwasaas/Component/MyAccountOrderList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    initializeOrderList: (options) => OrderListDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    setUserCanEdit: (isEditable) => dispatch(updateUserCanEdit(isEditable)),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Pwasaas/Component/MyAccountOrderList/Container/MyAccountOrderListContainer */
export class MyAccountOrderListContainer extends DataContainer {
    static propTypes = {
        addProductToCart: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        isEditingAllowed: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        initializeOrderList: PropTypes.func.isRequired,
        items: PropTypes.arrayOf(OrderListItemType).isRequired,
        showNotification: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        isEditingAllowed: true
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            cartSelection: [],
            isAllSelected: false,
            isSearchActive: false,
            searchCriteria: ''
        };
    }

    componentDidMount() {
        const {
            isEditingAllowed,
            initializeOrderList,
            setUserCanEdit
        } = this.props;

        setUserCanEdit(isEditingAllowed);
        initializeOrderList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { items = [] } = this.props;
        const { items: prevItems = [] } = prevProps;
        const { searchCriteria } = prevState;

        if (items !== prevItems && searchCriteria !== '') {
            this.hideSearchOverlay();
        }
    }

    containerFunctions = {
        addAllToCart: this.addAllToCart.bind(this),
        getIsCartSelected: this.getIsCartSelected.bind(this),
        handleClearSearchField: this.handleClearSearchField.bind(this),
        handleToggleAll: this.handleToggleAll.bind(this),
        hideActiveOverlay: this.props.hideActiveOverlay,
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        updateCartSelection: this.updateCartSelection.bind(this)
    };

    containerProps = () => {
        const {
            isLoading,
            items: orderItems = [],
            productConfig,
            device
        } = this.props;

        const {
            cartSelection,
            isAllSelected,
            isSearchActive,
            searchCriteria
        } = this.state;

        const isAddToCartDisabled = cartSelection.length === 0;

        return {
            cartSelection,
            isAllSelected,
            isAddToCartDisabled,
            isLoading,
            isSearchActive,
            orderItems,
            searchCriteria,
            productConfig,
            device
        };
    };

    addAllToCart() {
        const { cartSelection } = this.state;
        const {
            addProductToCart,
            showNotification
        } = this.props;

        Promise.all(
            cartSelection.map(async ({ product, qty: quantity }) => {
                const options = {
                    product,
                    quantity
                };

                const result = await addProductToCart(options);

                return result;
            })
        ).then(
            /** @namespace Pwasaas/Component/MyAccountOrderList/Container/all/then */
            () => {
                showNotification('success', __('Products added to cart!'));
                this.clearSelectedProducts();
            }
        );
    }

    clearSelectedProducts() {
        this.setState({
            cartSelection: [],
            isAllSelected: false
        });
    }

    getIsCartSelected(productId) {
        const { cartSelection } = this.state;

        return !!cartSelection.find(({ product: { id } }) => id === productId);
    }

    handleClearSearchField() {
        this.setState({ isSearchActive: false, searchCriteria: '' });
    }

    handleToggleAll() {
        const { items } = this.props;

        this.setState(
            ({ isAllSelected: wasAllSelected }) => ({
                cartSelection: !wasAllSelected ? items : [],
                isAllSelected: !wasAllSelected
            })
        );
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        this.setState({ isSearchActive: false, searchCriteria: '' });

        document.activeElement.blur();

        if (activeOverlay === ORDER_LIST_SEARCH) {
            hideActiveOverlay();
        }
    }

    onSearchOutsideClick() {
        const { activeOverlay } = this.props;

        if (activeOverlay === ORDER_LIST_SEARCH) {
            this.hideSearchOverlay();
        }
    }

    onSearchBarFocus() {
        const { activeOverlay, showOverlay } = this.props;
        this.setState({ isSearchActive: true });

        if (activeOverlay === '') {
            showOverlay(ORDER_LIST_SEARCH);
        }
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    updateCartSelection(item) {
        this.setState(({ cartSelection: prevSelection }) => {
            const { product: { id: productId } } = item;
            const selectItem = prevSelection.find(({ product: { id } }) => id === productId);

            if (selectItem) {
                const cartSelection = selectItem.qty === item.qty
                    ? prevSelection.filter(({ product: { id } }) => id !== productId)
                    : prevSelection.map((prevItem) => ({
                        ...prevItem,
                        qty: productId === prevItem.product.id
                            ? item.qty
                            : prevItem.qty
                    }));

                return {
                    cartSelection
                };
            }

            return {
                cartSelection: [item, ...prevSelection]
            };
        });
    }

    render() {
        return (
            <MyAccountOrderList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderListContainer);

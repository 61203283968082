import NewsletterQuery from 'Query/Newsletter.query';
import {
    getAuthorizationToken,
    isSignedIn
} from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import {
    fetchMutation
} from 'Util/Request';

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isShowNewsletter: state.SaasConfigReducer.config?.checkout_show_newsletter || false,
    isShowNewsletterChecked: state.SaasConfigReducer.config?.checkout_newsletter_check || false
});

export class CheckoutBillingContainer {
    state = (originalMember) => ({
        ...originalMember,
        isChecked: false
    });

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onNewsletterCheckboxChange: this.onNewsletterCheckboxChange.bind(instance)
    });

    componentDidMount = (args, callback, instance) => {
        const { isShowNewsletter, isShowNewsletterChecked: isChecked } = instance.props;

        if (isShowNewsletter) {
            instance.setState({ isChecked });
        }

        callback(...args);
    };

    onBillingSuccess = async (args, callback, instance) => {
        const { isShowNewsletter } = instance.props;
        const { isChecked } = instance.state;

        if (isChecked && isShowNewsletter) {
            const cartId = isSignedIn() ? getAuthorizationToken() : getGuestQuoteId();
            await fetchMutation(NewsletterQuery.getSubscribeToNewsletterMutation(cartId));
        }

        return callback(...args);
    };

    onNewsletterCheckboxChange(isChecked) {
        this.setState({ isChecked });
    }
}

const {
    componentDidMount,
    containerFunctions,
    onBillingSuccess,
    state
} = new CheckoutBillingContainer();

export default {
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidMount,
            onBillingSuccess
        },
        'member-property': {
            containerFunctions,
            state
        }
    }
};

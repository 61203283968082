import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MyAccountCustomerTableRow.style';

/** @namespace Pwasaas/Component/MyAccountCustomerTableRow/Component/MyAccountCustomerTableRowComponent */
export class MyAccountCustomerTableRowComponent extends PureComponent {
     static propTypes = {
         company: PropTypes.string.isRequired,
         email: PropTypes.string.isRequired,
         firstname: PropTypes.string.isRequired,
         handleOnClick: PropTypes.func.isRequired,
         lastname: PropTypes.string.isRequired
     };

     render() {
         const {
             company,
             email,
             firstname,
             handleOnClick,
             lastname
         } = this.props;

         return (
             <tr block="MyAccountCustomerTableRow">
                 <td>{ company }</td>
                 <td>{ firstname }</td>
                 <td>{ lastname }</td>
                 <td>{ email }</td>
                 <td>
                    <button
                      block="Button"
                      mix={ { block: 'MyAccountCustomerTableRow', elem: 'Button' } }
                      onClick={ handleOnClick }
                    >
                            { __('Sign in') }
                    </button>
                 </td>
             </tr>
         );
     }
}

export default MyAccountCustomerTableRowComponent;

import PropTypes from 'prop-types';

export const BlogPostTagType = PropTypes.shape({
    name: PropTypes.string,
    tagId: PropTypes.number,
    type: PropTypes.string
});

export const BlogPostType = PropTypes.shape({
    authorId: PropTypes.number,
    canonicalUrl: PropTypes.string,
    categories: PropTypes.string,
    commentCount: PropTypes.number,
    commentsEnabled: PropTypes.string,
    createdAt: PropTypes.string,
    displayShortContent: PropTypes.string,
    fullContent: PropTypes.string,
    gridClass: PropTypes.string,
    isFeatured: PropTypes.bool,
    listThumbnail: PropTypes.string,
    listThumbnailAlt: PropTypes.string,
    metaDescription: PropTypes.string,
    metaTags: PropTypes.string,
    metaTitle: PropTypes.string,
    notifyOnEnable: PropTypes.string,
    postId: PropTypes.number,
    postThumbnail: PropTypes.string,
    postThumbnailAlt: PropTypes.string,
    publishedAt: PropTypes.string,
    relatedPostIds: PropTypes.string,
    shortContent: PropTypes.string,
    status: PropTypes.string,
    tagIds: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    urlKey: PropTypes.string,
    userDefinePublish: PropTypes.string,
    views: PropTypes.string
});

export const BlogCategoryType = PropTypes.shape({
    categoryId: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    urlKey: PropTypes.string,
    sortOrder: PropTypes.string,
    storeId: PropTypes.number,
    metaTitle: PropTypes.string,
    metaTags: PropTypes.string,
    metaDescription: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    parentId: PropTypes.string,
    path: PropTypes.string,
    posts: PropTypes.objectOf(BlogPostType),
    level: PropTypes.string,
    postCount: PropTypes.number
});

import { AUTH_TOKEN, CUSTOMER } from 'Component/MyAccountLoginAsCustomer/MyAccountLoginAsCustomer.config';
import BrowserDatabase from 'Util/BrowserDatabase';

export const addRedirectCustomerAfter = (args, callback, instance) => {
    const { store_base_url: redirectUrl = '' } = BrowserDatabase.getItem(CUSTOMER) || {};

    if (redirectUrl === '') {
        callback.apply(instance, args);
        return;
    }

    const redirect = { url: redirectUrl };

    if (redirect.url.slice(redirect.url.length - 1) === '/') {
        redirect.url = redirect.url.slice(0, -1);
    }

    if (window.location.origin === redirect.url || window.location.origin === 'http://localhost:3000') {
        callback.apply(instance, args);
        return;
    }

    BrowserDatabase.deleteItem(AUTH_TOKEN);
    BrowserDatabase.deleteItem(CUSTOMER);
    window.location = redirect.url;
};

export default {
    'Component/Router/Container': {
        'member-function': {
            initializeApplication: addRedirectCustomerAfter
        }
    }
};

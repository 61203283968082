/**
 * Plugin to add company to address fields query.
 * Field will be available in B2B scandi module
 * @namespace Pwasaas/Plugin/CoreFix/CustomerCompanyData/Query/MyAccount/Query/MyAccountQuery
 */
export class MyAccountQuery {
    _getAddressFields = (args, callback) => ([
        ...callback(...args),
        'company'
    ]);
}

const {
    _getAddressFields
} = new MyAccountQuery();

export default {
    'Query/MyAccount': {
        'member-function': {
            _getAddressFields
        }
    }
};

import { getInitialState } from 'Store/Product/Product.reducer';

export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA';
export const UPDATE_PRODUCT_PRICE = 'UPDATE_PRODUCT_PRICE';
export const UPDATE_PRODUCT_OPTIONS = 'UPDATE_PRODUCT_OPTIONS';
export const UPDATE_DEFAULT_DATA = 'UPDATE_DEFAULT_DATA';
export const mageWorksDefaults = {
    mageworx: {
        defaultData: {
            options: [],
            price: null
        },
        hiddenOptions: [],
        hiddenValues: []
    }
};

export const setDefaultData = (defaults) => ({
    type: SET_DEFAULT_DATA,
    defaults
});

export const updateProductPrice = (productPrice) => ({
    type: UPDATE_PRODUCT_PRICE,
    productPrice
});

export const updateProductOptions = ({
    options,
    hiddenOptions,
    hiddenValues
}) => ({
    type: UPDATE_PRODUCT_OPTIONS,
    hiddenOptions,
    hiddenValues,
    options
});

export const updateDefaultData = () => ({
    type: UPDATE_DEFAULT_DATA
});

export const _getInitialState = (args, callback, context) => ({
    // eslint-disable-next-line react/destructuring-assignment
    ...callback.apply(context, args),
    ...mageWorksDefaults
});

export const ProductReducer = (args, callback) => {
    const [state = getInitialState(), action] = args;

    const {
        defaults,
        options,
        hiddenOptions,
        hiddenValues,
        productPrice,
        type
    } = action;

    switch (type) {
    case SET_DEFAULT_DATA:
        return {
            ...state,
            mageworx: {
                ...state.mageworx,
                defaultData: {
                    ...defaults
                }
            }
        };

    case UPDATE_PRODUCT_PRICE:
        if (!productPrice) {
            return state;
        }

        return {
            ...state,
            product: {
                ...state.product,
                price_range: {
                    ...state.product.price_range,
                    ...productPrice
                }
            }
        };

    case UPDATE_PRODUCT_OPTIONS:
        return {
            ...state,
            product: {
                ...state.product,
                options
            },
            mageworx: {
                ...state.mageworx,
                hiddenOptions,
                hiddenValues
            }
        };

    case UPDATE_DEFAULT_DATA:
        const {
            mageworx: {
                defaultData = {}
            }
        } = state;

        return {
            ...state,
            product: {
                ...state.product,
                options: defaultData.options,
                price_range: {
                    ...state.product.price_range,
                    ...defaultData.price
                }
            },
            ...mageWorksDefaults
        };

    default:
        return callback(...args);
    }
};

export default {
    'Store/Product/Reducer/getInitialState': {
        function: _getInitialState
    },
    'Store/Product/Reducer': {
        function: ProductReducer
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MyAccountCustomerTableRow from 'Component/MyAccountCustomerTableRow';
import { CustomerType } from 'Type/Customer';

import { TABLE_COL_SPAN } from './MyAccountLoginAsCustomer.config';

import './MyAccountLoginAsCustomer.style';

/** @namespace Pwasaas/Component/MyAccountLoginAsCustomer/Component/MyAccountLoginAsCustomerComponent */
export class MyAccountLoginAsCustomerComponent extends PureComponent {
    static propTypes = {
        customerSearchResults: PropTypes.arrayOf(CustomerType).isRequired,
        handleChange: PropTypes.func.isRequired,
        handleLoginAs: PropTypes.func.isRequired,
        onSearchEnterPress: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired
    };

    renderRow = (customer, index) => {
        const { handleLoginAs } = this.props;

        return (
            <MyAccountCustomerTableRow
              key={ index }
              customer={ customer }
              onLogin={ handleLoginAs }
            />
        );
    };

    renderCustomerHeadingRow() {
        return (
            <tr>
                <th>{ __('Company') }</th>
                <th>{ __('First name') }</th>
                <th>{ __('Last name') }</th>
                <th>{ __('Email') }</th>
                <th>{ __('Action') }</th>
            </tr>
        );
    }

    renderNoResult() {
        return (
            <tr block="MyAccountLoginAsCustomer" elem="NoResult">
                { /* eslint-disable-next-line no-magic-numbers */ }
                <td colSpan={ TABLE_COL_SPAN }>{ __('No customers were found that match your request.') }</td>
            </tr>
        );
    }

    renderCustomerListResultsRows() {
        const { customerSearchResults = [] } = this.props;

        if (!customerSearchResults.length) {
            return this.renderNoResult();
        }

        return customerSearchResults.map(this.renderRow);
    }

    renderTable() {
        const {
            customerSearchResults = [],
            searchCriteria = ''
        } = this.props;

        if (!customerSearchResults.length && searchCriteria === '') {
            return null;
        }

        return (
            <table block="MyAccountLoginAsCustomer" elem="Table">
                <thead>
                    { this.renderCustomerHeadingRow() }
                </thead>
                <tbody>
                    { this.renderCustomerListResultsRows() }
                </tbody>
            </table>
        );
    }

    renderSearch() {
        const {
            handleChange,
            onSearchEnterPress,
            searchCriteria
        } = this.props;

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <input
                  id="search-field"
                  block="SearchField"
                  elem="Input"
                  onChange={ handleChange }
                  onKeyDown={ onSearchEnterPress }
                  value={ searchCriteria }
                  autoComplete="off"
                  aria-label={ __('Search') }
                  placeholder={ __('Search') }
                />
                <div
                  block="SearchField"
                  elem="SearchIcon"
                  role="button"
                  tabIndex="0"
                  aria-label={ __('Search') }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                        <path
                          // eslint-disable-next-line max-len
                          d="M29.462 27.9l-7.474-7.47a.688.688 0 00-.492-.2h-.6a11.93 11.93 0 10-.672.678v.592a.712.712 0 00.2.492l7.476 7.47a.7.7 0 00.985 0l.574-.574a.7.7 0 00.003-.988zm-17.411-5.651a10.2 10.2 0 1110.2-10.2 10.19 10.19 0 01-10.2 10.2z"
                          fill="#272323"
                        />
                    </svg>
                </div>
            </div>
        );
    }

    renderSearchContent() {
        return (
            <div
              block="MyAccountLoginAsCustomer"
              elem="Search"
            >
                <h3
                  block="MyAccountLoginAsCustomer"
                  elem="SearchHeading"
                >
                    { __('Find a customer') }
                </h3>
                <div block="SearchField">
                    <div block="SearchField" elem="Wrapper">
                    { this.renderSearch() }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="MyAccountLoginAsCustomer">
                { this.renderSearchContent() }
                { this.renderTable() }
            </div>
        );
    }
}

export default MyAccountLoginAsCustomerComponent;

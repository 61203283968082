/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';

import FieldInput from 'Component/FieldInput';
import SaasDatepicker from 'Component/SaasDatepicker/SaasDatepicker.container';
import SaasInputSelectField from 'Component/SaasInputSelectField';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';

import {
    CHECKBOX_TYPE,
    DATE_TYPE,
    EMAIL_TYPE,
    FILE_TYPE,
    HIDDEN_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SAAS_INPUT_SELECT_TYPE,
    SELECT_TYPE,
    TEXTAREA_TYPE
} from './Field.config';

import './Field.override.manual.style';

/** @namespace Pwasaas/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    static propTypes = {
        ...this.propTypes,
        message: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({})
        ])
    };

    renderDatePicker() {
        const {
            availableDateRange,
            id,
            name,
            onDateChange = () => {},
            placeholder,
            formRef,
            validation,
            value
        } = this.props;

        return (
            <SaasDatepicker
              availableDateRange={ availableDateRange }
              onChange={ onDateChange }
              placeholder={ placeholder }
              value={ value }
              customInput={ (
                <FieldInput
                  isLabelWithArrow
                  id={ id }
                  formRef={ formRef }
                  name={ name }
                  validation={ validation }
                  type="text"
                  skipValue
                />
              ) }
            />
        );
    }

    renderInputSelectField() {
        const {
            isSelect,
            max,
            onChange,
            selectOptions,
            value
        } = this.props;

        return (
            <SaasInputSelectField
              isSelect={ isSelect }
              max={ max }
              onChange={ onChange }
              selectOptions={ selectOptions }
              value={ value }
            />
        );
    }

    renderCheckbox() {
        const {
            id,
            onChangeCheckbox,
            label,
            subLabel,
            disabled
        } = this.props;

        return (
            <label htmlFor={ id } block="Field" elem="CheckboxLabel">
                <FieldInput
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                  isDisabled={ disabled }
                />
                <div block="input-control" />
                <span>
                    { label }
                    { subLabel && (
                        <span block="Field" elem="SubLabel">
                            { ` (${subLabel})` }
                        </span>
                    ) }
                </span>
            </label>
        );
    }

    renderTypeHidden() {
        return (
            <FieldInput
              { ...this.props }
              type="hidden"
            />
        );
    }

    /**
     * Add date and saas input type
     */
    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case EMAIL_TYPE:
            return this.renderTypeEmail();
        case FILE_TYPE:
            return this.renderFile();
        case DATE_TYPE:
            return this.renderDatePicker();
        case SAAS_INPUT_SELECT_TYPE:
            return this.renderInputSelectField();
        case HIDDEN_TYPE:
            return this.renderTypeHidden();
        default:
            return this.renderTypeText();
        }
    }
}

export default FieldComponent;

export const containerProps = (args, callback, instance) => {
    const { isDiscountHidden } = instance.props;

    return {
        ...callback(...args),
        isDiscountHidden
    };
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;
    const { hide_cart_discount_field: isDiscountHidden = false } = state.SaasConfigReducer.config;

    return {
        ...callback(state),
        isDiscountHidden
    };
};

export const removeDiscountInputFrom = (args, callback, instance) => {
    const { isDiscountHidden } = instance.props;

    if (isDiscountHidden) {
        return null;
    }

    return callback(instance, args);
};

export default {
    'Route/CartPage/Container': {
        'member-function': {
            containerProps
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutOrderSummary/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CartPage/Component': {
        'member-function': {
            renderDiscountCode: removeDiscountInputFrom
        }
    },
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderCoupon: removeDiscountInputFrom
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderCartCoupon: removeDiscountInputFrom
        }
    }
};

import {
    clearItems,
    updateCurrentQty
} from '../store/CompleteOrder/CompleteOrder.action';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isCompleteOrderEnabled: state.SaasConfigReducer.config?.complete_order_enabled || false,
        completeOrderItems: state.CompleteOrderReducer.selected
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        clearItems: () => dispatch(clearItems()),
        clearCurrentQuantity: () => dispatch(updateCurrentQty(1))
    };
};

export class AddToCartContainer {
    afterAddToCart = (args, callback, instance) => {
        const {
            addProduct,
            completeOrderItems,
            isCompleteOrderEnabled
        } = instance.props;

        if (!isCompleteOrderEnabled) {
            callback(...args);

            return;
        }

        if (!completeOrderItems.length) {
            callback(...args);

            return;
        }

        Promise.all(completeOrderItems.map(async (item) => {
            const {
                quantity,
                selectOptions: {
                    configurableVariantIndex,
                    productOptionsData = {}
                },
                ...product
            } = item;

            await addProduct({
                product: configurableVariantIndex > 0
                    ? { ...product, configurableVariantIndex }
                    : product,
                quantity,
                productOptionsData
            });
        })).then(
            () => {
                const {
                    clearItems,
                    clearCurrentQuantity
                } = instance.props;

                clearItems();
                clearCurrentQuantity();
                callback(...args);
            }
        );
    };
}

const {
    afterAddToCart
} = new AddToCartContainer();

export default {
    'Component/AddToCart/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/AddToCart/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/AddToCart/Container': {
        'member-function': {
            afterAddToCart
        }
    }
};

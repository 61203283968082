import { MY_DOWNLOADABLE } from 'Type/Account';

export const tabMap = (originalTabMap) => Object.entries(originalTabMap).reduce(
    (newTabs, [type, tabInfo]) => {
        if (type !== MY_DOWNLOADABLE) {
            return {
                ...newTabs,
                [type]: tabInfo
            };
        }

        return newTabs;
    }, {}
);

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    }
};

import PropTypes from 'prop-types';

export class FieldContainer {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        onKeyDown: PropTypes.func
    });

    defaultProps = (originalProps) => ({
        ...originalProps,
        onKeyDown: () => {}
    });

    containerFunctions = (originalMap, instance) => ({
        ...originalMap,
        onKeyDown: this.onKeyDown.bind(instance)
    });

    onKeyDown(event) {
        const { onKeyDown } = this.props;

        onKeyDown(event);
    }
}

const {
    containerFunctions,
    defaultProps,
    staticPropTypes
} = new FieldContainer();

export default {
    'Component/Field/Container': {
        'member-property': {
            containerFunctions
        },
        'static-member': {
            defaultProps,
            propTypes: staticPropTypes
        }
    }
};

/* eslint-disable max-lines */
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import SaasDate, {
    getAvailableDays,
    getFirstDeliveryDayOption,
    getProductUsp,
    getSiteUsp
} from 'Util/Saas';
import {
    DEFAULT_DELIVER_DAY_COUNT,
    DEFAULT_DELIVERY_TIME_IN_MINUTES,
    DEFAULT_PREPARATION_TIME_IN_MINUTES,
    DEFAULT_TIME_INTERVAL_IN_MINUTES,
    LOCAL_STORE_SAAS_CONFIG,
    MASTER_ACCOUNT,
    WEEK_DAYS
} from 'Util/Saas/Saas.config';

import {
    UPDATE_MASTER_ACCOUNT,
    UPDATE_SAASCONFIG
} from './SaasConfig.action';

export const DEFAULT_QTY_LENGTH = 10;

/** @namespace Pwasaas/Store/SaasConfig/Reducer/filterData */
export const filterData = (config) => Object.entries(config).reduce(
    (acc, [key, value]) => (value !== null ? { ...acc, [key]: value } : acc),
    {}
);

/** @namespace Pwasaas/Store/SaasConfig/Reducer/filterCatalogData */
export const filterCatalogData = (data) => {
    const { filter, product } = data;

    if (!product) {
        return {};
    }

    const {
        show_subcategory_filter = false
    } = filterData(filter);

    const {
        quantity_dropdown = false,
        quantity_set = Array.from({ length: DEFAULT_QTY_LENGTH },
            (_, i) => ({
                default: i === 0,
                id: `cp${ i}`,
                label: `${i + 1}`,
                value: i + 1
            }))
    } = filterData(product);

    const defaultQty = quantity_set.find((item) => item.default) || quantity_set[0];

    const catalog = {
        ...data,
        filter: {
            ...filter,
            show_subcategory_filter
        },
        product: {
            ...product,
            defaultQty,
            quantity_dropdown,
            quantity_set
        }
    };

    return catalog;
};

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getNumberWithDefault */
export const getNumberWithDefault = (attributeValue, defaultValue, showTime = '1') => {
    if (!attributeValue || showTime === '0') {
        return defaultValue;
    }

    const value = Number(attributeValue);

    if (Number.isNaN(value)) {
        return defaultValue;
    }

    return value;
};

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getParsedDateValues */
export const getParsedDateValues = (deliveryData, { days_to_deliver: daysToDeliver }) => {
    if (!deliveryData.time) {
        return {};
    }

    const currentDate = new SaasDate();
    const delivery = {
        all: getAvailableDays(deliveryData),
        counter: 0,
        day: '',
        time: currentDate.setTimeFormat('HH:mm:ss').getTime()
    };

    while (!delivery.day) {
        const { all, time } = delivery;

        const theDay = currentDate.getDateWithRange(delivery.counter, 'days');
        const option = getFirstDeliveryDayOption(all, theDay, time, daysToDeliver);
        const i18nValue = option || WEEK_DAYS[option] || '';

        delivery.day = i18nValue.toLowerCase();
        delivery.counter++;
    }

    return {
        all: delivery.all,
        day: delivery.day,
        time: delivery.time
    };
};

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getFontConfigData */
export const getFontConfigData = (config = {}) => Object.keys(config).reduce(
    (fonts, key) => {
        if (key.includes('_font')) {
            return {
                ...fonts,
                [key]: config[key]
            };
        }

        return fonts;
    }, {}
);

export const {
    catalog,
    config,
    fontConfig,
    store,
    uspItems,
    productUsp
} = BrowserDatabase.getItem(LOCAL_STORE_SAAS_CONFIG) || {
    catalog: {},
    config: {},
    fontConfig: {},
    store: {},
    uspItems: [],
    productUsp: []
};

export const masterAccount = BrowserDatabase.getItem(MASTER_ACCOUNT) || '';

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getHolidaysData */
export const getHolidaysData = (base, state) => (base || state.holidays || []);

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getRichSnippetData */
export const getRichSnippetData = (base, state) => (base || state.richSnippets || {});

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getOrderDatesData */
export const getOrderDatesData = (base, state) => (base || state.orderDates || {});

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getUspData */
export const getUspData = ({ items = [] }, state) => (getSiteUsp(items[0]) || state.uspItems || []);

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getProductUspData */
export const getProductUspData = ({ items = [] }, state) => (getProductUsp(items[0]) || state.productUsp || []);

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getExpectedDeliveryData */
export const getExpectedDeliveryData = ({ expectedDelivery = {} }, state, config) => (
    getParsedDateValues(expectedDelivery, config) || state.expectedDelivery || {}
);

/** @namespace Pwasaas/Store/SaasConfig/Reducer/getInitialState */
export const getInitialState = () => ({
    ...store,
    ...catalog,
    config,
    fontConfig,
    masterAccount,
    isLoading: false,
    uspItems,
    productUsp
});

/** @namespace Pwasaas/Store/SaasConfig/Reducer/SaasConfigReducer */
export const SaasConfigReducer = (state = getInitialState(), action) => {
    const {
        type,
        masterAccount,
        saasConfig: {
            catalog = {},
            config = {},
            config: {
                days_to_deliver,
                show_time,
                time_to_deliver,
                time_to_preparation,
                time_interval
            } = {},
            holidays,
            orderDates,
            richSnippets = {},
            store = {},
            siteUsp = {},
            productUsp = {}
        } = {}
    } = action;

    switch (type) {
    case UPDATE_SAASCONFIG:
        const catalogData = filterCatalogData(catalog);
        const storeData = filterData(store);
        const filteredConfig = {
            ...filterData(config),
            days_to_deliver: getNumberWithDefault(days_to_deliver, DEFAULT_DELIVER_DAY_COUNT),
            time_to_deliver: getNumberWithDefault(time_to_deliver, DEFAULT_DELIVERY_TIME_IN_MINUTES, show_time),
            time_to_preparation: getNumberWithDefault(
                time_to_preparation,
                DEFAULT_PREPARATION_TIME_IN_MINUTES,
                show_time
            ),
            time_interval: getNumberWithDefault(time_interval, DEFAULT_TIME_INTERVAL_IN_MINUTES, show_time)
        };
        const expectedDelivery = getExpectedDeliveryData(
            store,
            state,
            filteredConfig
        );

        const result = {
            ...state,
            ...catalogData,
            ...storeData,
            config: {
                ...state.config,
                ...filteredConfig
            },
            holidays: getHolidaysData(holidays, state),
            richSnippets: getRichSnippetData(richSnippets, state),
            fontConfig: getFontConfigData(filteredConfig),
            orderDates: getOrderDatesData(orderDates, state),
            uspItems: getUspData(siteUsp, state),
            productUsp: getProductUspData(productUsp, state),
            expectedDelivery
        };

        BrowserDatabase.setItem(
            {
                catalog: catalogData,
                config: result.config,
                fontConfig: result.fontConfig,
                store: {
                    ...storeData,
                    holidays: result.holidays,
                    richSnippets: result.richSnippets,
                    orderDates: result.orderDates,
                    expectedDelivery
                },
                uspItems: result.uspItems,
                productUsp: result.productUsp
            },
            LOCAL_STORE_SAAS_CONFIG,
            ONE_MONTH_IN_SECONDS
        );

        return result;

    case UPDATE_MASTER_ACCOUNT:
        BrowserDatabase.setItem(
            masterAccount,
            MASTER_ACCOUNT,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            masterAccount
        };

    default:
        return state;
    }
};

export default SaasConfigReducer;

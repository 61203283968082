export const _getAttributeFields = (args, callback) => {
    const [isVariant] = args;

    return [
        ...callback(isVariant),
        'attribute_position'
    ];
};

export default {
    'Query/ProductList': {
        'member-function': {
            _getAttributeFields
        }
    }
};

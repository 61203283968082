export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isDateOfBirthEnabled: state.SaasConfigReducer.config.date_of_birth_enabled_in_checkout || false
    };
};

export class CheckoutAddressBookContainer {
    containerProps = (args, callback, instance) => {
        const { isDateOfBirthEnabled } = instance.props;

        return {
            ...callback(...args),
            isDateOfBirthEnabled
        };
    };
}

const {
    containerProps
} = new CheckoutAddressBookContainer();

export default {
    'Component/CheckoutAddressBook/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            containerProps
        }
    }
};

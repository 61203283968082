import ReviewQuery from 'Query/Review.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

export class ReviewDispatcher {
    submitProductReview = (args, _, instance) => {
        const [dispatch, options] = args;
        return fetchMutation(ReviewQuery.getAddProductReviewMutation(
            instance.prepareReviewData(options)
        )).then(
            () => dispatch(showNotification('success', __('You submitted your review for moderation.'))),
            () => dispatch(showNotification('error', __('Error submitting review!')))
        );
    };
}

const {
    submitProductReview
} = new ReviewDispatcher();

export default {
    'Store/Review/Dispatcher': {
        'member-function': {
            submitProductReview
        }
    }
};

import PropTypes from 'prop-types';

export const BackItemType = PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
});

export const MegaSettingType = PropTypes.shape({
    sub_content_type: PropTypes.string,
    column_number: PropTypes.number,
    column_wrapper_width: PropTypes.number,
    column_x_width: PropTypes.string,
    column_default_width: PropTypes.number,
    column_width_type: PropTypes.number,
    rich_content: PropTypes.string,
    description: PropTypes.string,
    visibility: PropTypes.string
});

export const MenuItemTypeNode = {
    id: PropTypes.number,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ]),
    show_title: PropTypes.number,
    path: PropTypes.string,
    parent: PropTypes.number,
    level: PropTypes.number,
    link: PropTypes.string,
    link_type: PropTypes.string,
    icon_image: PropTypes.string,
    is_group: PropTypes.number,
    additional_class: PropTypes.string,
    mega_settings: PropTypes.arrayOf(MegaSettingType)
};

export const MenuItemType = PropTypes.shape(MenuItemTypeNode);

MenuItemTypeNode.childs = PropTypes.arrayOf(MenuItemType);

export const MenuType = PropTypes.shape({
    menu_key: PropTypes.string,
    menu_title: PropTypes.string,
    back_item: BackItemType,
    items: PropTypes.arrayOf(MenuItemType)
});

export const MenuBannerType = PropTypes.shape({
    link: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ])
});

export const MenuItemModsType = PropTypes.shape({
    isActive: PropTypes.bool,
    isIcon: PropTypes.bool,
    isLink: PropTypes.bool,
    isTitle: PropTypes.bool
});

import { sortAttributeOptions } from 'Util/Saas';

export const filterAndSortFliterValues = (args, callback, instance) => {
    const [{ attribute_options = [] }] = args;

    return callback.apply(instance, [{
        ...args[0],
        attribute_values: sortAttributeOptions(attribute_options)
    }]);
};

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderDropdown: filterAndSortFliterValues
        }
    }
};

import MyAccountOrderList from 'Component/MyAccountOrderList';
import { MY_ORDER_LIST } from 'Component/MyAccountOrderList/MyAccountOrderList.config';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isOrderListEnabled: state.SaasConfigReducer.config.order_list_enabled || false
    };
};

export class AddMyOrderListToAccountPlugin {
    currentInstance = {};

    containerProps = (args, callback, instance) => {
        const { isOrderListEnabled } = instance.props;

        this.currentInstance = instance;

        return {
            ...callback(...args),
            isOrderListEnabled
        };
    };

    renderMap = (originalMember, instance) => {
        const { isOrderListEnabled } = instance.props;

        if (!isOrderListEnabled) {
            return originalMember;
        }

        return {
            ...originalMember,
            [MY_ORDER_LIST]: MyAccountOrderList
        };
    };

    tabMap = (originalTabMap) => {
        if (!this.currentInstance.props) {
            return originalTabMap;
        }

        const { isOrderListEnabled } = this.currentInstance.props;

        return isOrderListEnabled
            ? {
                ...originalTabMap,
                [MY_ORDER_LIST]: {
                    url: '/my-order-list',
                    name: __('My Order List')
                }
            }
            : originalTabMap;
    };
}

const {
    containerProps,
    renderMap,
    tabMap
} = new AddMyOrderListToAccountPlugin();

export const config = {
    'Route/MyAccount/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/MyAccount/Container': {
        'member-function': {
            containerProps
        },
        'static-member': {
            tabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap
        }
    }
};

export default config;

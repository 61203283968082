export class ProductCustomizableOptionContainer {
    componentDidUpdate = (args, callback, instance) => {
        const {
            isOptionsEnabled,
            option: { data },
            productOptionsData: { productOptions = [] }
        } = instance.props;
        const result = callback(...args);

        if (!isOptionsEnabled) {
            return result;
        }

        const [prevProps, prevState] = args;
        const { option: { data: prevData } } = prevProps;
        const { selectedDropdownValue: prevValue } = prevState;

        if (data !== prevData) {
            this.setDefaultOptionValue(data, instance);

            return result;
        }

        if (!productOptions.length && prevValue !== 0) {
            instance.setState({
                selectedDropdownValue: 0
            });
        }

        return result;
    };

    renderOptionLabel = (args, callback) => {
        const [, , price] = args;
        const result = callback(...args);

        return price < 0 ? result.replace('+ ', '') : result;
    };

    setDefaultOptionValue(data, instance) {
        const optionValues = Array.isArray(data) ? data : [data];

        optionValues.forEach(({ option_type_id, is_default }) => {
            if (is_default !== '1') {
                return;
            }

            instance.setDropdownValue(option_type_id);
        });
    }
}

const {
    componentDidUpdate,
    renderOptionLabel
} = new ProductCustomizableOptionContainer();

export default {
    'Component/ProductCustomizableOption/Container': {
        'member-function': {
            componentDidUpdate,
            renderOptionLabel
        }
    }
};

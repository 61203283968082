import { formatPrice } from 'Util/Price';

const CUSTOM_DIVIDER = 100;

// TODO: Solved. Deze is overschreven omdat de callback functie niet gebruikt werd, waardoor de originele functie nooit werd uitgevoerd.
// TODO: Onderstaande functie herschrijven.
export const getDropdownOptionsBundle = (args, callback, instance) => {
    const {
        price_range: {
            minimum_price: {
                discount: {
                    percent_off: percentOff = 0
                } = {}
            } = {}
        } = {},
        currencyCode
    } = instance.props;

    const [values] = args;

    return values.reduce((acc, {
        id,
        label,
        quantity,
        can_change_quantity,
        product
    }) => {
        const value = product?.price_range?.minimum_price?.final_price?.value || 0;

        const finalPrice = value - (value * (percentOff / CUSTOM_DIVIDER));
        const displayPrice = `+ ${formatPrice(finalPrice, currencyCode)}`;

        const dropdownLabel = !can_change_quantity
            ? `${ quantity } x ${ label } ${ displayPrice }`
            : `${ label } + ${ displayPrice }`;

        acc.push({
            id,
            name: label,
            value: id,
            label: dropdownLabel
        });

        return acc;
    }, []);
};

export default {
    'Component/ProductBundleItem/Container': {
        'member-function': {
            getDropdownOptions: getDropdownOptionsBundle
        }
    }
};

import SalesPromotionPage from '../route/SalesPromotionPage';
import { SALES_PROMOTION_PAGE } from '../route/SalesPromotionPage/SalesPromotionPage.config';

export class CmsPageComponent {
    render = (args, callback, instance) => {
        const renderedCmsPage = callback(...args);
        const { page: { page_layout } } = instance.props;

        if (page_layout !== SALES_PROMOTION_PAGE) {
            return renderedCmsPage;
        }

        const {
            isBreadcrumbsActive,
            isLoading,
            page
        } = instance.props;

        return (
            <SalesPromotionPage
              isBreadcrumbsActive={ isBreadcrumbsActive }
              isLoading={ isLoading }
              page={ page }
            />
        );
    };
}

const {
    render
} = new CmsPageComponent();

export default {
    'Route/CmsPage/Component': {
        'member-function': {
            render
        }
    }
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;
    const {
        show_pdf_download: isPDFDownloadVisible = false,
        plugincompany_productpdf_enabled: isPluginCompanyProductPDFEnabled = false
    } = state.SaasConfigReducer.config;

    return {
        ...callback(state),
        isPDFDownloadVisible,
        isPluginCompanyProductPDFEnabled
    };
};

export const addIsPDFDownloadVisibleTo = (args, callback, instance) => {
    const {
        isPDFDownloadVisible,
        isPluginCompanyProductPDFEnabled
    } = instance.props;

    return {
        ...callback(...args),
        isPDFDownloadVisible,
        isPluginCompanyProductPDFEnabled
    };
};

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps: addIsPDFDownloadVisibleTo
        }
    }
};

export const YOUTUBE_FORMAT = new RegExp('(?:https?//)?www.youtube.com/watch\\?v=(\\w+([-]?\\w+)*)');

export class VideoPopupComponent {
    _renderVideoContent = (args, callback, instance) => {
        const {
            payload: {
                media: {
                    video_content: { video_url } = {}
                } = {}
            }
        } = instance.props;

        if (!video_url) {
            return null;
        }

        const [, youtubeId] = YOUTUBE_FORMAT.exec(video_url);

        if (youtubeId) {
            return instance._renderYoutubeVideo(youtubeId);
        }

        return callback(...args);
    };
}

const {
    _renderVideoContent
} = new VideoPopupComponent();

export default {
    'Component/VideoPopup/Component': {
        'member-function': {
            _renderVideoContent
        }
    }
};

import {
    MENU_ITEM_TOGGLE,
    MENU_TYPE_ADVANCED
} from 'Component/MenuAdvanced/MenuAdvanced.config';
import { MENU_TYPE_REGULAR } from 'Component/MenuRegular/MenuRegular.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import {
    UPDATE_ACTIVE_MENU_ITEM,
    UPDATE_ACTIVE_NAVIGATION_ITEM,
    UPDATE_FOOTER_MENU,
    UPDATE_HEADER_MENU,
    UPDATE_IS_TOGGLED
} from './SaasMenu.action';

export const SAAS_MENU = 'saas_menu';

/** @namespace Pwasaas/Store/SaasMenu/Reducer/filterHeaderData */
export const filterHeaderData = (menu) => {
    const {
        config: {
            menu_type = MENU_TYPE_REGULAR
        },
        items
    } = menu;

    return {
        ...menu,
        items: items.filter(
            ({ link_type }) => link_type !== MENU_ITEM_TOGGLE
        ),
        toggleItems: menu_type === MENU_TYPE_ADVANCED
            ? items.filter(({ link_type }) => link_type === MENU_ITEM_TOGGLE)
            : []
    };
};

/** @namespace Pwasaas/Store/SaasMenu/Reducer/setAndReturnHistoryData */
export const setAndReturnHistoryData = (history, activeItem) => {
    if (!activeItem.id) {
        if (history.length) {
            history.pop();
        }

        return history;
    }

    return [
        ...history.filter(({ id }) => id !== activeItem.id),
        activeItem
    ];
};

export const {
    footer,
    header
} = BrowserDatabase.getItem(SAAS_MENU) || {
    footer: {},
    header: {}
};

/** @namespace Pwasaas/Store/SaasMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    activeMenuItem: {},
    activeNavigationItem: {},
    footer,
    header,
    history: [],
    isOverlayActive: false,
    isToggled: false
});

/** @namespace Pwasaas/Store/SaasMenu/Reducer/SaasMenuReducer */
export const SaasMenuReducer = (state = getInitialState(), action) => {
    const {
        activeMenuItem,
        activeNavigationItem,
        footer,
        headerMenu,
        isToggled,
        type
    } = action;

    switch (type) {
    case UPDATE_HEADER_MENU:
        const header = filterHeaderData(headerMenu);

        BrowserDatabase.setItem(
            {
                ...BrowserDatabase.getItem(SAAS_MENU),
                header
            },
            SAAS_MENU,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            header
        };

    case UPDATE_ACTIVE_MENU_ITEM:
        return {
            ...state,
            activeMenuItem,
            history: setAndReturnHistoryData(
                state.history,
                activeMenuItem
            )
        };

    case UPDATE_ACTIVE_NAVIGATION_ITEM:
        return {
            ...state,
            activeNavigationItem,
            history: setAndReturnHistoryData(
                state.history,
                activeNavigationItem
            )
        };

    case UPDATE_IS_TOGGLED:
        return {
            ...state,
            isToggled
        };

    case UPDATE_FOOTER_MENU:
        BrowserDatabase.setItem(
            {
                ...BrowserDatabase.getItem(SAAS_MENU),
                footer
            },
            SAAS_MENU,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            footer
        };

    default:
        return state;
    }
};

export default SaasMenuReducer;

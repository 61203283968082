import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MixType } from 'Type/Common';
import { KiyohWidgetType } from 'Type/Kiyoh';
import CSS from 'Util/CSS';

import KiyohSnippet from './KiyohSnippet.component';
import {
    MAX_PERCENTAGE,
    MAX_SCORE
} from './KiyohSnippet.config';

/** @namespace Pwasaas/Component/KiyohSnippet/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    widget: state.KiyohReducer.widget
});

/** @namespace Pwasaas/Component/KiyohSnippet/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/KiyohSnippet/Container/KiyohSnippetContainer */
export class KiyohSnippetContainer extends PureComponent {
    static propTypes = {
        mix: MixType,
        widget: KiyohWidgetType.isRequired
    };

    static defaultProps = {
        mix: {}
    };

    containerFunctions = {
        setReference: this.setReference.bind(this)
    };

    containerProps = () => {
        const {
            mix,
            widget: {
                isEnabled = false,
                avgRating = 0,
                totalReviews = 0,
                reviewUsUrl = ''
            }
        } = this.props;

        return {
            isEnabled,
            mix,
            avgRating,
            totalReviews,
            reviewUsUrl
        };
    };

    setReference(childRef) {
        const {
            widget: {
                avgRating = 0
            }
        } = this.props;

        CSS.setVariable(
            childRef,
            'kiyoh-score-percentage',
            `${Math.round((MAX_PERCENTAGE / MAX_SCORE) * avgRating)}%`
        );

        return childRef;
    }

    render() {
        return (
            <KiyohSnippet
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KiyohSnippetContainer);

import { cloneElement } from 'react';

export class ProductCustomizableOptionsComponent {
    renderContent = (args, callback, instance) => {
        const { isOptionsEnabled } = instance.props;
        const result = callback(...args);

        if (!isOptionsEnabled) {
            return result;
        }

        return result.map((ProductCustomizableOption) => {
            const { props, props: { type_id } } = ProductCustomizableOption;

            return cloneElement(ProductCustomizableOption, {
                ...props,
                key: type_id,
                isOptionsEnabled
            });
        });
    };
}

const {
    renderContent
} = new ProductCustomizableOptionsComponent();

export default {
    'Component/ProductCustomizableOptions/Component': {
        'member-function': {
            renderContent
        }
    }
};

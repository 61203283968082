import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX } from 'Component/ProductPrice/ProductPrice.config';
import { ProductType } from 'Type/ProductList';
import { formatPrice } from 'Util/Price';

import CompleteOrderTotals from './CompleteOrderTotals.component';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrderTotals/Container/CompleteOrderTotalsContainer */
export class CompleteOrderTotalsContainer extends PureComponent {
    static propTypes = {
        currencyCode: PropTypes.string.isRequired,
        currentQuantity: PropTypes.number.isRequired,
        displayTaxInPrice: PropTypes.string.isRequired,
        product: ProductType.isRequired,
        selected: PropTypes.arrayOf(ProductType).isRequired
    };

    containerProps = () => ({
        items: this.getItems(),
        priceTaxLabel: this.getPriceTaxLabel(),
        totalPrice: this.getTotalPrice()
    });

    getItems() {
        const { selected } = this.props;

        return [
            this.getProductWithQuantity(),
            ...selected
        ].map((item) => ({
            options: item.selectOptions?.labels || [],
            price: this.getFormattedPrice(this.getItemPriceValue(item)),
            quantity: item.quantity || 1,
            title: item.name
        }));
    }

    getTotalPrice() {
        const { selected } = this.props;
        const totalPrice = [
            this.getProductWithQuantity(),
            ...selected
        ].reduce(
            (totalPrice, item) => totalPrice + this.getItemPriceValue(item), 0
        );

        return this.getFormattedPrice(totalPrice);
    }

    getProductWithQuantity() {
        const { currentQuantity, product } = this.props;

        return {
            ...product,
            quantity: currentQuantity
        };
    }

    getItemPriceValue(item) {
        const { displayTaxInPrice } = this.props;
        const {
            price_range: {
                minimum_price: {
                    final_price: {
                        value: minimalPriceValue
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            quantity = 1
        } = item;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return quantity * minimalPriceExclTaxValue;
        }

        return quantity * minimalPriceValue;
    }

    getFormattedPrice(value) {
        const { currencyCode } = this.props;

        return formatPrice(value, currencyCode);
    }

    getPriceTaxLabel() {
        const { displayTaxInPrice } = this.props;

        return displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
            ? __('Excl. tax')
            : __('Incl. tax');
    }

    render() {
        return (
            <CompleteOrderTotals
              { ...this.containerProps() }
            />
        );
    }
}

export default CompleteOrderTotalsContainer;

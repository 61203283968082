import { SHOW_THUMBNAIL_RELATED_PRODUCT_LIST } from 'Component/ProductActions/ProductActions.config';

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isThumbnailRelatedProductEnabled: state.SaasConfigReducer.config?.thumbnail_related_products_enabled || false
});

export class ProductActionsContainer {
    containerProps = (args, callback, { props: { isThumbnailRelatedProductEnabled } }) => ({
        ...callback(...args),
        isThumbnailRelatedProductEnabled
    });

    getRenderTypes = (args, callback) => ([
        ...callback(...args),
        SHOW_THUMBNAIL_RELATED_PRODUCT_LIST
    ]);
}

const {
    containerProps,
    getRenderTypes
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps,
            getRenderTypes
        }
    }
};

import { CUSTOMER } from 'Component/MyAccountLoginAsCustomer/MyAccountLoginAsCustomer.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getStore } from 'Util/Store';

export const formatPrice = (args, callback) => {
    const { ConfigReducer = {} } = getStore().getState();
    const {
        currencyData: {
            current_currency_code
        } = {}
    } = BrowserDatabase.getItem('config') || ConfigReducer;

    const [price, currency = current_currency_code] = args;

    const {
        customer_currency: {
            display_currency = ''
        } = {}
    } = BrowserDatabase.getItem(CUSTOMER) || {};

    if (display_currency) {
        const language = navigator.languages ? navigator.languages[0] : navigator.language;

        return new Intl.NumberFormat(language, { style: 'currency', currency: display_currency }).format(price);
    }

    return callback(price, currency);
};

export const config = {
    'Util/Price/formatPrice': {
        function: formatPrice
    }
};

export default config;

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { getElementHeight } from '@scandipwa/scandipwa/src/util/CSS';

import SaasHeader from 'Component/SaasHeader/SaasHeader.component.js';
import { LOGO_POSITION_LEFT } from 'Component/SaasHeader/SaasHeader.config';
import { setHeaderLogoDimension } from 'Util/Saas';

import { HeaderType } from '../type/SaasLayout';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        header: state.SaasLayoutReducer.header || {}
    };
};

export class HeaderContainer {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        header: HeaderType.isRequired
    });

    componentDidMount = (args, callback, instance) => {
        window.addEventListener('scroll', this.setScrollRootClass.bind(instance));

        callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [{
            logo_height: prevLogoHeight,
            logo_width: prevLogoWidth
        }] = args;
        const {
            logo_height,
            logo_width
        } = instance.props;

        if (
            prevLogoHeight !== logo_height
                || prevLogoWidth !== logo_width
        ) {
            setHeaderLogoDimension(logo_width, logo_height, true);
        }

        callback(...args);
    };

    componentWillUnmount = (args, callback) => {
        window.removeEventListener('scroll', () => {});

        callback(...args);
    };

    containerProps = (args, callback, instance) => {
        const {
            header: {
                logo_position: logoPosition = LOGO_POSITION_LEFT,
                is_menu_position_bottom: isMenuPositionBottom = false,
                is_show_currency_switcher: isShowCurrencySwitcher = false,
                is_show_store_switcher: isShowStoreSwitcher = false,
                is_usp_sticky: isUspSticky = false
            } = {}
        } = instance.props;

        const { isUspActive } = callback(...args);

        this.setRootClass(isMenuPositionBottom, 'hasBottomMenu');
        this.setRootClass(!isUspActive, 'hasNoUsp');
        this.setRootClass(!isShowCurrencySwitcher, 'hasNoCurrencySwitcher');

        return {
            ...callback(...args),
            logoPosition,
            isMenuPositionBottom,
            isShowCurrencySwitcher,
            isShowStoreSwitcher,
            isUspSticky,
            menuMix: {
                block: 'SaasHeader',
                elem: 'NavMenu',
                mods: { isMenuPositionBottom }
            }
        };
    };

    render = (args, callback, instance) => {
        const {
            header: {
                is_default_scandi: isDefaultScandi = false
            }
        } = instance.props;

        if (isDefaultScandi) {
            return callback(...args);
        }

        return (
            <SaasHeader
              { ...instance.containerProps() }
              { ...instance.containerFunctions }
            />
        );
    };

    setScrollRootClass() {
        const posY = getElementHeight('StoreNotice');
        const htmlClasses = document.documentElement.classList;
        const scrollClass = 'isScrolled';

        if (window.scrollY > posY) {
            if (!htmlClasses.contains(scrollClass)) {
                htmlClasses.add(scrollClass);
            }

            return;
        }

        htmlClasses.remove(scrollClass);
    }

    setRootClass(shouldAdd, value) {
        if (shouldAdd) {
            document.documentElement.classList.add(value);

            return;
        }

        document.documentElement.classList.remove(value);
    }
}

const {
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
    containerProps,
    render,
    staticPropTypes
} = new HeaderContainer();

export default {
    'Component/Header/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Header/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            componentWillUnmount,
            containerProps,
            render
        },
        'static-member': {
            staticPropTypes
        }
    }
};

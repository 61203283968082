/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { ContentWrapper } from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.style';

/** @namespace Pwasaas/Component/ContentWrapper/Component/ContentWrapperComponent */
export class ContentWrapperComponent extends ContentWrapper {
     static propTypes = {
         ...ContentWrapper.propTypes,
         className: PropTypes.string,
         isFluid: PropTypes.bool,
         isRow: PropTypes.bool
     };

     static defaultProps = {
         ...ContentWrapper.defaultProps,
         className: '',
         isFluid: false,
         isRow: false
     };

     renderContentWrapper() {
         const {
             children,
             className,
             isFluid,
             isRow,
             wrapperMix
         } = this.props;

         return (
             <div
               block="ContentWrapper"
               className={ className }
               mods={ { isRow, isFluid } }
               mix={ wrapperMix }
             >
                 { children }
             </div>
         );
     }
}

export default ContentWrapperComponent;

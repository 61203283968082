/**
 * Show the current product price on plp when there are no config product variants
 */

import ProductPrice from 'Component/ProductPrice';
import { CONFIGURABLE } from 'Util/Product';

export class ProductCardComponent {
    renderProductPrice = (args, callback, instance) => {
        const {
            product: {
                type_id,
                price_range,
                price_tiers
            }
        } = instance.props;

        if (type_id !== CONFIGURABLE) {
            return callback(...args);
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <ProductPrice
                  price={ price_range }
                  price_tiers={ price_tiers }
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                  label={ instance.renderProductTypePriceBadge() }
                />
            </div>
        );
    };
}

const {
    renderProductPrice
} = new ProductCardComponent();

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderProductPrice
        }
    }
};

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isShowQuantityProductCard: state.SaasConfigReducer.config?.show_quantity_product_card || false
});

export class ProductCardContainer {
    containerProps = (args, callback, { props: { isShowQuantityProductCard } }) => ({
        ...callback(...args),
        isShowQuantityProductCard
    });
}

const {
    containerProps
} = new ProductCardContainer();

export default {
    'Component/ProductCard/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps
        }
    }
};

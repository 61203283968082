/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import ClickOutside from 'Component/ClickOutside';
import ContentWrapper from 'Component/ContentWrapper';
import CurrencySwitcher from 'Component/CurrencySwitcher';
import { CART_OVERLAY, CUSTOMER_WISHLIST } from 'Component/Header/Header.config';
import KiyohSnippet from 'Component/KiyohSnippet';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import MenuAdvanced from 'Component/MenuAdvanced';
import MenuRegular from 'Component/MenuRegular';
import OfflineNotice from 'Component/OfflineNotice';
import SiteUsp from 'Component/SiteUsp';
import StoreSwitcher from 'Component/StoreSwitcher';
import Header from 'SourceComponent/Header/Header.component';
import { MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { isSignedIn } from 'Util/Auth';
import { isCrawler, isSSR } from 'Util/Browser';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { setHeaderLogoDimension } from 'Util/Saas';

import {
    LOGO_POSITION_CENTER,
    LOGO_POSITION_LEFT,
    SAAS_MENU_ID
} from './SaasHeader.config';

import './SaasHeader.style';

/** @namespace Pwasaas/Component/SaasHeader/Component/SaasHeaderComponent */
export class SaasHeaderComponent extends Header {
    static propTypes = {
        ...this.propTypes,
        logoPosition: PropTypes.string,
        menuMix: MixType,
        isUspSticky: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        logoPosition: LOGO_POSITION_LEFT,
        menuMix: {
            block: 'SaasHeader',
            elem: 'NavMenu',
            mods: {}
        }
    };

    renderMap = {
        ...this.renderMap,
        close: () => null,
        share: () => null,
        logo: () => null,
        compare: () => null,
        search: () => null,
        title: () => null,
        clear: () => null
    };

    renderNavigationMap = {
        logo: this.renderSaasHeaderNavLogo.bind(this),
        menu: this.renderMenu.bind(this),
        navList: this.renderSaasHeaderNavList.bind(this)
    };

    renderNavigationOrder = {
        [LOGO_POSITION_CENTER]: ['menu', 'logo', 'navList'],
        [LOGO_POSITION_LEFT]: ['logo', 'menu', 'navList']
    };

    renderBackButton(isVisible = false) {
        const { device, onBackButtonClick, showMyAccountLogin } = this.props;

        if (device.isMobile && !showMyAccountLogin) {
            return null;
        }

        return (
            <button
              key="back"
              block="SaasHeader"
              elem="Button"
              mods={ { type: 'back', isVisible } }
              onClick={ onBackButtonClick }
              aria-label="Go back"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            />
        );
    }

    renderAccount(isVisible = false) {
        const {
            onMyAccountOutsideClick,
            isCheckout
        } = this.props;

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMyAccountOutsideClick }
              key="account"
            >
                <div
                  aria-label="My account"
                  block="SaasHeader"
                  elem="MyAccount"
                >
                    { this.renderAccountButton(isVisible) }
                    { this.renderAccountOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderMinicart(isVisible = false) {
        const {
            onMinicartOutsideClick,
            isCheckout
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMinicartOutsideClick }
              key="minicart"
            >
                <div
                  block="SaasHeader"
                  elem="Button"
                  mods={ { isVisible, type: 'minicart' } }
                >
                    { this.renderMinicartButton() }
                    { this.renderMinicartOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderAccountButton(isVisible) {
        const {
            onMyAccountButtonClick,
            showMyAccountLogin
        } = this.props;

        return (
            <button
              block="SaasHeader"
              elem="MyAccountWrapper"
              mods={ { isActive: showMyAccountLogin } }
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              aria-label={ __('Account') }
              id="myAccount"
            >
                <div
                  block="SaasHeader"
                  elem="Button"
                  mods={ { isVisible, type: 'account' } }
                >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                    >
                    <path
                      data-name="user-circle-light (1)"
                      // eslint-disable-next-line max-len
                      d="M14.5 0A14.5 14.5 0 1029 14.5 14.5 14.5 0 0014.5 0zm7.484 24.65a12.536 12.536 0 01-14.968 0v-.8a3.745 3.745 0 013.742-3.742c.649 0 1.608.667 3.742.667s3.087-.667 3.742-.667a3.745 3.745 0 013.742 3.742zm1.789-1.608a5.6 5.6 0 00-5.531-4.8c-1.2 0-1.777.667-3.742.667s-2.538-.667-3.742-.667a5.6 5.6 0 00-5.531 4.8 12.629 12.629 0 1118.546 0zM14.5 6.548a5.145 5.145 0 105.145 5.145A5.145 5.145 0 0014.5 6.548zm0 8.419a3.274 3.274 0 113.274-3.274 3.276 3.276 0 01-3.274 3.275z"
                      fill="#272323"
                    />
                    </svg>
                </div>
            </button>
        );
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <button
              block="SaasHeader"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              onClick={ onMinicartButtonClick }
              aria-label={ __('Cart') }
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="31.732" height="28.137"><path d="M30.409 3.517H7.122l-.459-2.441A1.321 1.321 0 005.364 0H.661A.66.66 0 000 .659V1.1a.66.66 0 00.661.659H5l3.852 20.5a3.525 3.525 0 105.662.607h8a3.526 3.526 0 105.511-.762 1.322 1.322 0 00-1.283-1H10.427l-.661-3.517h18.256a1.321 1.321 0 001.294-1.05L31.7 5.105a1.32 1.32 0 00-1.291-1.588zM13.222 24.62a1.763 1.763 0 11-1.763-1.759 1.763 1.763 0 011.763 1.759zm12.34 1.759a1.759 1.759 0 111.763-1.759 1.763 1.763 0 01-1.763 1.758zm2.1-10.551H9.436L7.453 5.276h22.414z" fill="#272323" /></svg>
                { this.renderMinicartItemsQty() }
            </button>
        );
    }

    renderMobileHeaderUsp() {
        const { device, isUspActive, isUspSticky: isSticky } = this.props;

        if (!device.isMobile || !isUspActive) {
            return null;
        }

        return (
            <div
              block="SaasHeader"
              elem="MobileUsps"
              mods={ { isSticky } }
            >
                <SiteUsp isSlider />
            </div>
        );
    }

    renderHeaderBottom() {
        const { device, isUspSticky } = this.props;

        if (!device.isMobile) {
            return (
                <SiteUsp
                  isHeader
                  isSticky={ isUspSticky }
                >
                    <KiyohSnippet
                      mix={ {
                          block: 'SaasHeader',
                          elem: 'Kiyoh'
                      } }
                    />
                </SiteUsp>
            );
        }

        return (
            <div
              block="SaasHeader"
              elem="Bottom"
            >
                <ContentWrapper
                  isNotSection
                  wrapperMix={ { block: 'SaasHeader', elem: 'BottomContent' } }
                  label=""
                >
                    { this.renderSearchField(true) }
                </ContentWrapper>
            </div>
        );
    }

    /** Override render method to add logo dimensions to :root selector */
    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width
        } = this.props;

        if (logo_height && logo_width) {
            setHeaderLogoDimension(logo_width, logo_height);
        }

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        return (
            <Logo
              src={ logoSrc }
              alt={ logo_alt }
              title={ logo_alt }
            />
        );
    }

    renderStoreSwitcher() {
        return (
            <div block="SaasHeader" elem="Switcher">
                <StoreSwitcher />
            </div>
        );
    }

    renderCurrencySwitcher() {
        return (
            <div block="SaasHeader" elem="CurrencySwitcher">
                <CurrencySwitcher />
            </div>
        );
    }

    renderMenu() {
        const {
            menuMix,
            menuType
        } = this.props;

        switch (menuType) {
        case 'regular':
            return (
                <MenuRegular
                  id={ SAAS_MENU_ID }
                  mix={ menuMix }
                />
            );
        case 'advanced':
            return (
                <MenuAdvanced
                  id={ SAAS_MENU_ID }
                  mix={ menuMix }
                />
            );
        default:
            return super.renderMenu();
        }
    }

    renderSaasHeaderNavList() {
        const { device } = this.props;

        return (
            <div
              block="SaasHeader"
              elem="NavLinks"
            >
                { device.isMobile ? null : this.renderSearchField() }
                { this.renderNavigationState() }
                { this.renderStoreSwitcher() }
            </div>
        );
    }

    renderSaasHeaderNavLogo() {
        return (
            <div
              block="SaasHeader"
              elem="NavLogo"
            >
                <Link
                  to="/"
                  aria-label="Go to homepage"
                  tabIndex={ 0 }
                  block="SaasHeader"
                  elem="LogoWrapper"
                  key="logo"
                >
                    { this.renderLogoImage() }
                </Link>
            </div>
        );
    }

    renderSaasHeaderNav() {
        const {
            device,
            logoPosition
        } = this.props;

        if (device.isMobile) {
            return (
                <nav block="SaasHeader" elem="Nav">
                    { this.renderMenu() }
                    { this.renderSaasHeaderNavLogo() }
                    { this.renderSaasHeaderNavList() }
                </nav>
            );
        }

        return (
            <nav
              block="SaasHeader"
              elem="Nav"
              mods={ { isLogoCenter: logoPosition === LOGO_POSITION_CENTER } }
            >
                { this.renderNavigationOrder[logoPosition].map((renderKey) => this.renderNavigationMap[renderKey]()) }
            </nav>
        );
    }

    renderSaasHeader() {
        const {
            navigationState: {
                name,
                isHiddenOnMobile = false
            },
            isCheckout
        } = this.props;

        return (
            <header
              block="SaasHeader"
              mods={ { name, isHiddenOnMobile, isCheckout } }
              mix={ { block: 'FixedElement', elem: 'Top' } }
              ref={ this.logoRef }
            >
                { this.renderCurrencySwitcher() }
                { this.renderSaasHeaderNav() }
                { this.renderHeaderBottom() }
                { this.renderMobileHeaderUsp() }
            </header>
        );
    }

    render() {
        const {
            props: {
                isUspActive,
                device
            },
            stateMap
        } = this;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <section
              block="SaasHeader"
              elem="Wrapper"
              mods={ {
                  isPrerendered: isSSR() || isCrawler(),
                  noUsp: !isUspActive
              } }
            >
                { this.renderSaasHeader() }
                <OfflineNotice />
            </section>
        );
    }
}

export default SaasHeaderComponent;

import { lazy } from 'react';

export const SALES_PROMOTION_COUNTDOWN = 'CountdownWidget';
export const SALES_PROMOTION_FORM = 'SignUpFormWidget';

export const SalesPromotionCountdownWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */ '../component/SalesPromotionCountdownWidget'
));

export const SalesPromotionFormWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */ '../component/SalesPromotionFormWidget'
));

export const getWidgetRenderMap = (args, callback) => ({
    ...callback(...args),
    [SALES_PROMOTION_COUNTDOWN]: {
        component: SalesPromotionCountdownWidget
    },
    [SALES_PROMOTION_FORM]: {
        component: SalesPromotionFormWidget
    }
});

export default {
    'Pwasaas/Util/Widget/getWidgetRenderMap': {
        function: getWidgetRenderMap
    }
};

import PropTypes from 'prop-types';

export const ButtonsType = PropTypes.shape({
    buttons_background_color: PropTypes.string,
    buttons_color: PropTypes.string
});

export const ColorsType = PropTypes.shape({
    primary_base_color: PropTypes.string,
    primary_dark_color: PropTypes.string,
    primary_light_color: PropTypes.string,
    secondary_base_color: PropTypes.string,
    secondary_dark_color: PropTypes.string,
    secondary_light_color: PropTypes.string,
    primary_error_color: PropTypes.string,
    secondary_error_color: PropTypes.string
});

export const HeaderType = PropTypes.shape({
    header_search_background_color_mobile: PropTypes.string,
    header_background_color: PropTypes.string,
    header_icons_color: PropTypes.string,
    header_color: PropTypes.string
});

export const USPsType = PropTypes.shape({
    usps_background_color: PropTypes.string,
    usps_icon_color: PropTypes.string,
    usps_color: PropTypes.string
});

export const DropdownMenuType = PropTypes.shape({
    dropdown_menu_background_color: PropTypes.string,
    dropdown_menu_color: PropTypes.string
});

export const MenuType = PropTypes.shape({
    menu_background_color: PropTypes.string,
    menu_color: PropTypes.string
});

export const StoreNoticeType = PropTypes.shape({
    store_notice_background_color: PropTypes.string,
    store_notice_color: PropTypes.string
});

export const SubcategoryType = PropTypes.shape({
    subcategory_button_color: PropTypes.string,
    subcategory_button_label_color: PropTypes.string
});

export const FooterType = PropTypes.shape({
    footer_column_content_color: PropTypes.string,
    footer_column_title_color: PropTypes.string,
    footer_content_background_color: PropTypes.string,
    footer_content_border_color: PropTypes.string,
    footer_copyright_background_color: PropTypes.string,
    footer_column_title_font_size: PropTypes.string,
    footer_column_title_font_weight: PropTypes.string,
    footer_column_title_text_transform: PropTypes.string,
    footer_content_background_color_mobile: PropTypes.string,
    footer_column_content_background_color_mobile: PropTypes.string,
    footer_column_title_font_size_mobile: PropTypes.string,
    footer_column_title_text_transform_mobile: PropTypes.string,
    footer_column_newsletter_color_mobile: PropTypes.string,
    footer_column_newsletter_background_color_mobile: PropTypes.string
});

export const ThemeType = PropTypes.shape({
    buttons: ButtonsType,
    colors: ColorsType,
    header: HeaderType,
    usps: USPsType,
    menu: MenuType,
    storeNotice: StoreNoticeType,
    subcategory: SubcategoryType,
    dropdownMenu: DropdownMenuType,
    footer: FooterType
});

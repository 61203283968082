import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ProductAttributes from 'Component/ProductAttributes';
import ProductInformation from 'Component/ProductInformation';
import ProductLinks from 'Component/ProductLinks';
import ProductReviews from 'Component/ProductReviews';
import SaasScrollToLink from 'Component/SaasScrollToLink';
import {
    RELATED as PRODUCT_RELATED,
    UPSELL as PRODUCT_UPSELL
} from 'Store/LinkedProducts/LinkedProducts.reducer';
import { ProductType } from 'Type/ProductList';

import {
    PRODUCT_INFORMATION,
    SCROLL_DURATION,
    SCROLL_OFFSET
} from './ProductSectionNavigation.config';

import './ProductSectionNavigation.style';

/** @namespace Pwasaas/Component/ProductSectionNavigation/Component/ProductSectionNavigationComponent */
export class ProductSectionNavigationComponent extends PureComponent {
    static propTypes = {
        dataSource: ProductType.isRequired,
        getFilteredNavigation: PropTypes.func.isRequired,
        getFilteredSections: PropTypes.func.isRequired,
        hasLinkedProducts: PropTypes.objectOf(PropTypes.bool).isRequired,
        isAttributesTabEmpty: PropTypes.bool.isRequired,
        isDetailsLoaded: PropTypes.bool.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired
    };

    /** TODO: This still needs an in dept refactor (product page itself is already done)
     * Keep in mind it affects plugins as well
     * */
    sectionMap = {
        [PRODUCT_INFORMATION]: {
            name: __('Information'),
            render: (name) => this.renderInformationSection(name)
        },
        [PRODUCT_RELATED]: {
            name: __('Recommended for you'),
            render: (name, type) => this.renderProductLinks(name, type),
            type: PRODUCT_RELATED
        },
        [PRODUCT_UPSELL]: {
            name: __('You might also like'),
            render: (name, type) => this.renderProductLinks(name, type),
            type: PRODUCT_UPSELL
        }
    };

    renderProductInformation() {
        const {
            dataSource,
            parameters,
            isDetailsLoaded
        } = this.props;

        return (
            <ProductInformation
              product={ { ...dataSource, parameters } }
              areDetailsLoaded={ isDetailsLoaded }
            />
        );
    }

    renderProductAttributes() {
        const {
            dataSource,
            isAttributesTabEmpty,
            isDetailsLoaded,
            parameters
        } = this.props;

        if (isAttributesTabEmpty) {
            return null;
        }

        return (
            <ProductAttributes
              product={ { ...dataSource, parameters } }
              areDetailsLoaded={ isDetailsLoaded }
            />
        );
    }

    renderInformationSection(name) {
        return (
            <div
              block="ProductSectionNavigation"
              elem="ItemInner"
              mods={ { info_button: true } }
              id={ name.replace(' ', '-').toLowerCase() }
            >
                 { this.renderProductInformation() }
                 { this.renderProductAttributes() }
            </div>
        );
    }

    renderProductLinks(name, type) {
        const { hasLinkedProducts, isDetailsLoaded } = this.props;

        if (!hasLinkedProducts[type]) {
            return null;
        }

        return (
            <div
              block="ProductSectionNavigation"
              elem="ItemInner"
              id={ name.replace(' ', '-').toLowerCase() }
            >
                <ProductLinks
                  linkType={ type }
                  title={ name }
                  areDetailsLoaded={ isDetailsLoaded }
                />
            </div>
        );
    }

    renderReviews(name) {
        const {
            dataSource,
            isDetailsLoaded
        } = this.props;

        return (
           <div
             block="ProductSectionNavigation"
             elem="ItemReviews"
             id={ name.replace(' ', '-').toLowerCase() }
           >
               <ProductReviews
                 product={ dataSource }
                 areDetailsLoaded={ isDetailsLoaded }
               />
           </div>
        );
    }

    renderScrollToLink = ({ name }) => (
        <SaasScrollToLink
          name={ name || '' }
          to={ name.replace(' ', '-').toLowerCase() }
          mix={ {
              block: 'ProductSectionNavigation',
              elem: 'NavItem'
          } }
          key={ name }
          isSmooth
          offset={ SCROLL_OFFSET }
          duration={ SCROLL_DURATION }
        />
    );

    renderSectionNavigationItems(sectionMap) {
        const { getFilteredNavigation } = this.props;

        return (
            <div
              block="ProductSectionNavigation"
              elem="Nav"
            >
                { getFilteredNavigation(sectionMap).map(this.renderScrollToLink) }
            </div>
        );
    }

    renderSectionContent = (section, i) => (
            <ContentWrapper
              key={ section.props.id || i }
              label="Product section navigation"
              wrapperMix={ {
                  block: 'ProductSectionNavigation',
                  elem: 'Wrapper'
              } }
            >
                <div
                  block="ProductSectionNavigation"
                  elem="Item"
                >
                    { section }
                </div>
            </ContentWrapper>
    );

    renderSectionContentItems(sectionMap) {
        const { getFilteredSections } = this.props;

        return (
            <div
              block="ProductSectionNavigation"
              elem="Items"
            >
                { getFilteredSections(sectionMap).map(this.renderSectionContent) }
            </div>
        );
    }

    render() {
        return (
            <div block="ProductSectionNavigation">
                { this.renderSectionNavigationItems(this.sectionMap) }
                { this.renderSectionContentItems(this.sectionMap) }
            </div>
        );
    }
}

export default ProductSectionNavigationComponent;

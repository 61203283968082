export const translateFilterFix = (args, callback, instance) => {
    const [option] = args;

    option.attribute_label = (option.attribute_label === 'Category') ? __('Category') : option.attribute_label;
    option.attribute_label = (option.attribute_label === 'Price') ? __('Price') : option.attribute_label;

    return callback.apply(instance, [option]);
};

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderDropdownOrSwatch: translateFilterFix
        }
    }
};

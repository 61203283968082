import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Loader from 'Component/Loader';
import OrderListItem from 'Component/OrderListItem';
import SearchField from 'Component/SearchField';
import { DeviceType } from 'Type/Device';
import { OrderListItemType } from 'Type/OrderList';

import './MyAccountOrderList.style';

/** @namespace Pwasaas/Component/MyAccountOrderList/Component/MyAccountOrderListComponent */
export class MyAccountOrderListComponent extends PureComponent {
    static propTypes = {
        addAllToCart: PropTypes.func.isRequired,
        cartSelection: PropTypes.arrayOf(OrderListItemType).isRequired,
        device: DeviceType.isRequired,
        handleClearSearchField: PropTypes.func.isRequired,
        handleToggleAll: PropTypes.func.isRequired,
        isAllSelected: PropTypes.bool.isRequired,
        isAddToCartDisabled: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSearchActive: PropTypes.bool.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        orderItems: PropTypes.arrayOf(OrderListItemType).isRequired,
        searchCriteria: PropTypes.string.isRequired,
        updateCartSelection: PropTypes.func.isRequired
    };

    renderItem = (item) => {
        const {
            cartSelection,
            updateCartSelection
        } = this.props;

        const { product: { id: productId } } = item;
        const isSelected = cartSelection.some(({ product: { id } }) => id === productId);

        return (
            <OrderListItem
              key={ productId }
              item={ item }
              isSelected={ isSelected }
              updateCartSelection={ updateCartSelection }
            />
        );
    };

    renderOrderListTableHead() {
        const { isAllSelected, handleToggleAll } = this.props;

        return (
            <div
              block="MyAccountOrderList"
              elem="TableHeadWrapper"
            >
                <p
                  block="MyAccountOrderList"
                  elem="TableHead"
                  aria-hidden
                >
                    <span>
                        <Field
                          id="selectAllProducts"
                          name="selectAllProducts"
                          type="checkbox"
                          checked={ isAllSelected }
                          mix={ { block: 'MyAccountOrderList', elem: 'SelectAll' } }
                          onChange={ handleToggleAll }
                        />
                        { __('Select all') }
                    </span>
                    <span>{ __('Item') }</span>
                    <span>{ __('qty') }</span>
                    <span>{ __('Unit price') }</span>
                </p>
            </div>
        );
    }

    renderOrderListItems() {
        const { orderItems } = this.props;

        if (!orderItems.length) {
            return (
                <p
                  block="MyAccountOrderList"
                  elem="Empty"
                >
                    { __('There are no products in your order list.') }
                </p>
            );
        }

        return (
            <>
                { this.renderOrderListTableHead() }
                <div
                  block="MyAccountOrderList"
                  elem="Items"
                  aria-label="List of items in order list"
                >
                    { orderItems.map(this.renderItem) }
                </div>
            </>
        );
    }

    renderSearchContent() {
        const {
            isSearchActive,
            handleClearSearchField,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            searchCriteria
        } = this.props;

        return (
            <div
              block="MyAccountOrderList"
              elem="Search"
            >
                <h3
                  block="MyAccountOrderList"
                  elem="SearchHeading"
                >
                    { __('Find a product') }
                </h3>
                <SearchField
                  key="order-list-search"
                  isActive={ isSearchActive }
                  searchCriteria={ searchCriteria }
                  onSearchOutsideClick={ onSearchOutsideClick }
                  onSearchBarFocus={ onSearchBarFocus }
                  onSearchBarChange={ onSearchBarChange }
                  onClearSearchButtonClick={ handleClearSearchField }
                />
            </div>
        );
    }

    renderAddAllToCart() {
        const {
            addAllToCart,
            isAddToCartDisabled
        } = this.props;

        return (
            <button
              block="Button"
              mix={ { block: 'MyAccountOrderList', elem: 'Button' } }
              onClick={ addAllToCart }
              disabled={ isAddToCartDisabled }
            >
              { __('Add Selected to Cart') }
            </button>
        );
    }

    renderActionBar(isBottom = false) {
        const { orderItems, device: { isMobile } } = this.props;

        if (!orderItems.length) {
            return null;
        }

        if (isBottom && isMobile) {
            return null;
        }

        return (
            <div block="MyAccountOrderList" elem="ActionBar" mods={ { isBottom } }>
                { this.renderAddAllToCart() }
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="MyAccountOrderList">
                <Loader isLoading={ isLoading } />

                { this.renderSearchContent() }
                { this.renderOrderListItems() }
                { this.renderActionBar() }
                { this.renderActionBar(true) }
            </div>
        );
    }
}

export default MyAccountOrderListComponent;

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        type: state.OverlayReducer.activeOverlay
    };
};

export class SearchOverlayContainer {
    containerProps = (args, callback, instance) => {
        const { type } = instance.props;

        return {
            ...callback(...args),
            type
        };
    };
}

export const {
    containerProps
} = new SearchOverlayContainer();

export default {
    'Component/SearchOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/SearchOverlay/Container': {
        'member-function': {
            containerProps
        }
    }
};

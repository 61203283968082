import { PRODUCT_REVIEWS } from 'Component/ProductSectionNavigation/ProductSectionNavigation.config';

export class ProductSectionNavigationComponent {
    sectionMap = (originalMember, instance) => {
        const { isReviewEnabled } = instance.props;

        if (!isReviewEnabled) {
            return originalMember;
        }

        return {
            ...originalMember,
            [PRODUCT_REVIEWS]: {
                name: __('Reviews'),
                render: (name) => instance.renderReviews(name)
            }
        };
    };
}

const {
    sectionMap
} = new ProductSectionNavigationComponent();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Component/ProductSectionNavigationComponent': {
        'member-property': {
            sectionMap
        }
    }
};

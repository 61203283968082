import ClickOutside from 'Component/ClickOutside';
import { VIDEO_POPUP_ID } from 'Component/VideoPopup/VideoPopup.config';

export class PopupComponent {
    renderContent = (args, callback, instance) => {
        const { children, contentMix, id } = instance.props;

        if (id !== VIDEO_POPUP_ID) {
            return callback(...args);
        }

        return (
            <ClickOutside onClick={ instance.hidePopupAndGoBack }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { instance.renderTitle() }
                        { instance.renderCloseButton() }
                    </header>
                    { instance.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    };
}

const {
    renderContent
} = new PopupComponent();

export default {
    'Component/Popup/Component': {
        'member-function': {
            renderContent
        }
    }
};

import PropTypes from 'prop-types';

export const CheckoutFormFieldType = PropTypes.shape({
    position: PropTypes.string,
    width: PropTypes.string,
    enabled: PropTypes.bool
});

export const CheckoutFormType = PropTypes.objectOf(CheckoutFormFieldType);

export const CheckoutType = PropTypes.shape({
    form: CheckoutFormType
});

export const HeaderType = PropTypes.shape({
    logo_position: PropTypes.string,
    is_default_scandi: PropTypes.bool,
    is_menu_position_bottom: PropTypes.bool,
    is_show_currency_switcher: PropTypes.bool,
    is_show_store_switcher: PropTypes.bool
});

export const LayoutType = PropTypes.shape({
    checkout: CheckoutType,
    header: HeaderType
});

/**
 * Enable / Disable scrollToTop when navigating from Checkout Shipping page
 * to Checkout Billing Page
 */

import { SCROLL_TO_TOP_ENABLED } from './CheckoutBillingData.config';

export class CheckoutBillingContainer {
    componentDidMount = (args, callback) => {
        if (!SCROLL_TO_TOP_ENABLED) {
            return callback(...args);
        }

        window.scrollTo(0, 0);
        return callback(...args);
    };
}

const {
    componentDidMount
} = new CheckoutBillingContainer();

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidMount
        }
    }
};

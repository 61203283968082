import { ADD_TO_CART, SHOW_THUMBNAIL_RELATED_PRODUCT_LIST } from 'Component/ProductActions/ProductActions.config';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

import ThumbnailRelatedProductList from '../component/ThumbnailRelatedProductList';

export class ProductActionsComponent {
    actionsRenderMap = (originalMember, instance) => {
        const { isThumbnailRelatedProductEnabled } = instance.props;

        if (!isThumbnailRelatedProductEnabled) {
            return originalMember;
        }

        return Object.entries(originalMember).reduce(
            (renderMap, [type, render]) => {
                if (type === ADD_TO_CART) {
                    return {
                        ...renderMap,
                        [SHOW_THUMBNAIL_RELATED_PRODUCT_LIST]: this.renderThumbnailRelatedProductList.bind(instance),
                        [type]: render
                    };
                }

                return {
                    ...renderMap,
                    [type]: render
                };
            }, {}
        );
    };

    renderThumbnailRelatedProductList() {
        const {
            product: {
                product_links = []
            }
        } = this.props;

        const relatedProducts = product_links.filter(({ link_type }) => link_type === RELATED);

        if (!relatedProducts.length) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="ThumbnailRelatedProducts"
            >
                <ThumbnailRelatedProductList title={ __('Variants') } />
            </div>
        );
    }
}

const {
    actionsRenderMap
} = new ProductActionsComponent();

export default {
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-property': {
            actionsRenderMap: {
                position: 150,
                implementation: actionsRenderMap
            }
        }
    }
};

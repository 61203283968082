import { cloneElement } from 'react';

import ProductSectionNavigation from 'Component/ProductSectionNavigation';

export class ProductPageComponent {
    render = (args, callback, instance) => {
        /** TODO: Add enable/disable product section navigation? It replaces the default tabs */
        const renderData = callback(...args);
        const {
            areDetailsLoaded,
            dataSource,
            isAttributesTabEmpty,
            parameters
        } = instance.props;

        const {
            props,
            props: {
                children: renderedChildElement
            }
        } = renderData;

        const {
            props: childProps,
            props: {
                children: renderedMainContent
            } = {}
        } = renderedChildElement;

        /** Get only first child element (ContentWrapper) */
        const children = [
            (Array.isArray(renderedMainContent))
                ? renderedMainContent[0]
                : renderedMainContent,
            <ProductSectionNavigation
              dataSource={ dataSource }
              isAttributesTabEmpty={ isAttributesTabEmpty }
              isDetailsLoaded={ areDetailsLoaded }
              parameters={ parameters }
            />,
            { ...instance.renderReviewPopup() }
        ];

        const mainTag = cloneElement(renderedChildElement, {
            ...childProps,
            children
        });

        return cloneElement(renderData, {
            ...props,
            children: mainTag
        });
    };
}

const {
    render
} = new ProductPageComponent();

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render
        }
    }
};

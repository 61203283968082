import { stringify } from 'rebem-classname';

export class LinkComponent {
    render = (args, callback, instance) => {
        const {
            bemProps,
            className,
            to
        } = instance.props;

        if (typeof to === 'string' && /mailto|tel/.test(to)) {
            return instance.renderAbsolutePathLink(`${ className } ${ stringify(bemProps)}`);
        }

        return callback(...args);
    };
}

const {
    render
} = new LinkComponent();

export default {
    'Component/Link/Component': {
        'member-function': {
            render
        }
    }
};

import {
    HIDE_ADD_TO_CART_KEY,
    HIDE_PRICE_KEY
} from '../util/HidePrice';

export class ProductAttributesComponent {
    renderAttribute = (args, callback) => {
        const [{ attribute_code }] = args;

        if (attribute_code === HIDE_PRICE_KEY) {
            return null;
        }

        if (attribute_code === HIDE_ADD_TO_CART_KEY) {
            return null;
        }

        return callback(...args);
    };
}

const {
    renderAttribute
} = new ProductAttributesComponent();

export default {
    'Component/ProductAttributes/Component': {
        'member-function': {
            renderAttribute
        }
    }
};

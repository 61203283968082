import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Field from 'Component/Field';
import Image from 'Component/Image';
import ProductPrice from 'Component/ProductPrice';
import { OrderListItemType } from 'Type/OrderList';
import { QtySelectOptionsType } from 'Type/ProductQtyConfig';
import media, { PRODUCT_MEDIA } from 'Util/Media';

import './OrderListItem.style';

/** @namespace Pwasaas/Component/OrderListItem/Component/OrderListItemComponent */
export class OrderListItemComponent extends PureComponent {
    static propTypes = {
        handleChangeQuantity: PropTypes.func.isRequired,
        handleRemoveItem: PropTypes.func.isRequired,
        handleToggleItem: PropTypes.func.isRequired,
        item: OrderListItemType.isRequired,
        itemQty: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
        maxItemQuantity: PropTypes.number.isRequired,
        thumbnail: PropTypes.string.isRequired,
        isSelect: PropTypes.bool.isRequired,
        quantitySet: PropTypes.arrayOf(QtySelectOptionsType)
    };

    static defaultProps = {
        quantitySet: []
    };

    renderActions() {
        return (
            <div
              block="OrderListItem"
              elem="Actions"
            >
                { this.renderDeleteButton() }
            </div>
        );
    }

    renderDeleteButton() {
        const { handleRemoveItem } = this.props;

        return (
            <button
              block="OrderListItem"
              elem="Delete"
              aria-label="Remove item from order list"
              onClick={ handleRemoveItem }
            >
                <CloseIcon />
            </button>
        );
    }

    renderQuantityChangeField() {
        const {
            handleChangeQuantity,
            itemQty,
            maxItemQuantity,
            isSelect,
            quantitySet
        } = this.props;

        return (
            <div
              block="OrderListItem"
              elem="QuantityWrapper"
            >
                <Field
                  id="item_qty"
                  name="item_qty"
                  type="saas_input_select"
                  isControlled
                  isSelect={ isSelect }
                  selectOptions={ quantitySet }
                  max={ maxItemQuantity }
                  mix={ { block: 'OrderListItem', elem: 'Qty' } }
                  value={ itemQty }
                  onChange={ handleChangeQuantity }
                />
            </div>
        );
    }

    renderImage() {
        const {
            item: { name },
            thumbnail
        } = this.props;

        return (
            <>
            <Image
              src={ thumbnail && media(thumbnail, PRODUCT_MEDIA) }
              mix={ {
                  block: 'OrderListItem',
                  elem: 'Picture'
              } }
              ratio="custom"
              alt={ `Product ${name} thumbnail.` }
            />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderProductPrice() {
        const { item: { price_range } } = this.props;

        return (
            <div
              block="OrderListItem"
              elem="PriceWrapper"
            >
                <ProductPrice
                  price={ price_range }
                  mix={ { block: 'OrderListItem', elem: 'Price' } }
                />
            </div>
        );
    }

    renderContent() {
        const { item: { name, sku } } = this.props;

        return (
            <figcaption
              block="OrderListItem"
              elem="Content"
            >
                <div
                  block="OrderListItem"
                  elem="HeadingWrapper"
                >
                    { `${__('Item')}: ${sku}` }
                    <p
                      block="OrderListItem"
                      elem="Heading"
                    >
                        { name }
                    </p>
                </div>
                { this.renderQuantityChangeField() }
                { this.renderProductPrice() }
            </figcaption>
        );
    }

    renderSelect() {
        const {
            isSelected,
            item: {
                id,
                sku
            },
            handleToggleItem
        } = this.props;

        return (
            <div
              block="OrderListItem"
              elem="Select"
            >
                <Field
                  id={ `product[${ id }]` }
                  name={ `product[${ sku }]` }
                  type="checkbox"
                  value={ `product[${ id }]` }
                  mix={ { block: 'OrderListItem', elem: 'Checkbox' } }
                  checked={ isSelected }
                  onChange={ handleToggleItem }
                />
            </div>
        );
    }

    renderWrapperContent() {
        return (
            <figure block="OrderListItem" elem="Wrapper">
                { this.renderImage() }
                { this.renderContent() }
            </figure>
        );
    }

    render() {
        return (
            <div block="OrderListItem">
                { this.renderSelect() }
                { this.renderWrapperContent() }
                { this.renderActions() }
            </div>
        );
    }
}

export default OrderListItemComponent;

export const FILTER = 'filter';
export const PHOTO = 'photo';

/** @namespace Pwasaas/Util/Saas/CategoryFilter/sortAttributeOptions */
export const sortAttributeOptions = (options = {}) => {
    const sorted = [];

    Object.entries(options).sort((a, b) => {
        const itemA = a[1].label.toUpperCase();
        const itemB = b[1].label.toUpperCase();

        if (itemA > itemB) {
            return 1;
        }

        if (itemA < itemB) {
            return -1;
        }

        return 0;
    }).forEach((item) => {
        const { label } = item[1];

        switch (label) {
        case '-1':
        case '0':
            return;
        default:
            sorted.push(item[0]);
        }
    });

    return sorted;
};

/** @namespace Pwasaas/Util/Saas/CategoryFilter/getTransformedFilterAttributes */
export const getTransformedFilterAttributes = (props) => Object.entries(props).reduce(
    (filters, [currentKey, value]) => {
        if (!currentKey.includes(FILTER)) {
            return filters;
        }

        return {
            ...filters,
            [value]: {
                image: props[
                    Object.keys(props).find(
                        (propKey) => propKey !== currentKey
                            && propKey.includes(PHOTO)
                            && propKey.includes(currentKey.split('_')[1])
                    )
                ] || null
            }
        };
    }, {}
);

/** @namespace Pwasaas/Util/Saas/CategoryFilter/filterNumberAttributeValues */
export const filterNumberAttributeValues = (attributes) => Object.entries(attributes).reduce(
    (acc, [key, value]) => ({
        ...acc,
        [key]: {
            ...value,
            attribute_values: sortAttributeOptions(value.attribute_options)
        }
    }), {}
);

/** @namespace Pwasaas/Util/Saas/CategoryFilter/getFilterSelectOptions */
export const getFilterSelectOptions = (item) => {
    const {
        attribute_code: code,
        attribute_options: options = {},
        attribute_values: values = [],
        attribute_label: label
    } = item || {};

    return values.reduce(
        (selectOptions, key, index) => {
            if (index === 0) {
                selectOptions.push({
                    disabled: true,
                    id: null,
                    label: `-- ${__('Select')} ${label} --`,
                    value: -1
                });
            }

            const option = options[key];

            if (!option) {
                return selectOptions;
            }

            return [
                ...selectOptions,
                {
                    id: `${code}_${option.value_string}`,
                    label: `${option.label} (${option.count})`,
                    value: key
                }
            ];
        }, []
    );
};

import validationConfig from 'Component/Form/Form.config';

export class FieldContainer {
    buttonElement;

    componentDidMount = (args, callback, instance) => {
        const { customValidation = {} } = instance.props;

        if (Object.keys(customValidation).length) {
            instance.setState((prevState) => ({
                ...prevState,
                customValidation: {
                    message: null,
                    status: null
                }
            }));

            this.addButtonEventListener(instance);
        }

        callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [{ value: prevValue }] = args;
        const { value } = instance.props;

        if (!prevValue && prevValue !== value) {
            this.clearCustomValidation(instance);
        }

        callback(...args);

        if (this.buttonElement) {
            return;
        }

        const { customValidation = {} } = instance.props;

        if (!Object.keys(customValidation).length) {
            return;
        }

        this.addButtonEventListener(instance);
    };

    compontentWillUnmount = (args, callback) => {
        if (this.buttonElement) {
            this.buttonElement.removeEventListener('click');
            this.buttonElement = null;
        }

        callback(...args);
    };

    containerProps = (args, callback, instance) => {
        const { customValidationStatus } = instance.props;

        const {
            customValidation: {
                message = null,
                status = null
            } = {},
            validationMessage,
            validationStatus
        } = instance.state;

        return {
            ...callback(...args),
            message: message ?? validationMessage,
            validationStatus: status || (customValidationStatus ?? validationStatus)
        };
    };

    addButtonEventListener(instance) {
        const {
            customValidation: {
                formButton,
                formPortals = []
            }
        } = instance.props;

        this.buttonElement = document.querySelector(`button[form="${ formButton }"]`);

        if (!this.buttonElement) {
            return;
        }

        this.buttonElement.addEventListener('click', (e) => {
            const validationRule = this.customValidate(instance);
            const isValid = !validationRule.validate;

            instance.setState({
                customValidation: {
                    message: validationRule.message,
                    status: isValid
                }
            }, () => {
                if (isValid) {
                    return;
                }

                e.preventDefault();

                if (!formPortals.length) {
                    return;
                }

                const {
                    portalsObservers: {
                        [formButton]: portalObserver = {}
                    } = {}
                } = window.formPortalCollector || {};

                const invalidFields = formPortals.reduce(
                    (fields, portal) => {
                        const getFormData = portalObserver[portal];

                        if (!getFormData) {
                            return fields;
                        }

                        const { invalidFields = [] } = getFormData();

                        return [
                            ...fields,
                            ...invalidFields
                        ];
                    }, []
                );

                if (invalidFields.length) {
                    e.stopPropagation();
                }
            });
        });
    }

    customValidate(instance) {
        const { customValidation: { values } } = instance.props;
        const { value } = instance.state;

        const rule = values.find((rule) => {
            const validationRules = validationConfig[rule];

            if (!validationRules) {
                return false;
            }

            return !validationRules.validate({ value });
        });

        return validationConfig[rule] || {};
    }

    clearCustomValidation(instance) {
        instance.setState({
            customValidation: {
                message: null,
                status: true
            }
        });
    }
}

const {
    componentDidMount,
    componentDidUpdate,
    compontentWillUnmount,
    containerProps
} = new FieldContainer();

export default {
    'Component/Field/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            compontentWillUnmount,
            containerProps
        }
    }
};

import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import Html from 'Component/Html';

import '../style/ProductSectionNavigation.manual.style';

export class ProductSectionNavigationComponent {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        productBanner: PropTypes.string.isRequired
    });

    renderReviews = (args, callback, instance) => {
        const renderData = callback(...args);
        const { productBanner } = instance.props;

        if (!productBanner) {
            return renderData;
        }

        const { props, props: { className = '', children: renderedChild } } = renderData;

        const children = [
            renderedChild,
            <Html content={ productBanner } />
        ];

        return cloneElement(renderData, {
            ...props,
            children,
            className: `${className} ${className}_hasTwoColumns`
        });
    };
}

const {
    renderReviews,
    staticPropTypes
} = new ProductSectionNavigationComponent();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Component/ProductSectionNavigationComponent': {
        'member-function': {
            renderReviews
        },
        'static-member': {
            propTypes: staticPropTypes
        }
    }
};

export class CmsPageQueryPlugin {
    _getPageFields = (args, callback) => ([
        ...callback(args),
        'page_image',
        'short_description'
    ]);
}

const {
    _getPageFields
} = new CmsPageQueryPlugin();

export const config = {
    'Query/CmsPage': {
        'member-function': {
            _getPageFields
        }
    }
};

export default config;

export const OrderListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/OrderList/OrderList.dispatcher'
);

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    const {
        defaultQty: {
            value = 1
        } = {}
    } = state.SaasConfigReducer.product || {};

    return {
        ...callback(state),
        orderListItems: state.OrderListReducer.items,
        defaultQtyValue: value
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        addProductToOrderList: (item) => OrderListDispatcher.then(
            ({ default: dispatcher }) => dispatcher.addProductToList(item, dispatch)
        )
    };
};

export class SearchItemContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        handleAddToOrderList: this.handleAddToOrderList.bind(instance)
    });

    containerProps = (args, callback, instance) => ({
        ...callback(...args),
        isAlreadyAddedInOrderList: this._getItemIsAdded(instance),
        type: this._getSearchOverlayType(instance)
    });

    _getItemIsAdded(instance) {
        const {
            orderListItems,
            product: { id: productId }
        } = instance.props;

        if (!orderListItems) {
            return false;
        }

        return !!orderListItems.find(({ product: { id } }) => id === productId);
    }

    _getSearchOverlayType(instance) {
        const { type = '' } = instance.props;

        return type;
    }

    async handleAddToOrderList() {
        const {
            addProductToOrderList,
            defaultQtyValue,
            product
        } = this.props;

        const newItem = {
            product,
            qty: defaultQtyValue
        };

        await addProductToOrderList(newItem);
    }
}

const {
    containerFunctions,
    containerProps
} = new SearchItemContainer();

export default {
    'Component/SearchItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/SearchItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/SearchItem/Container': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions
        }
    }
};

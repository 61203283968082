import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { updatePageHeaderContent } from 'Store/PageHeader/PageHeader.action';

/** @namespace Pwasaas/Plugin/BlogPageHeader/Util/PageHeader/Index/mapStateToProps */
export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isPageHeaderVisible: state.PageHeaderReducer.isVisible,
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState
});

/** @namespace Pwasaas/Plugin/BlogPageHeader/Util/PageHeader/Index/mapDispatchToProps */
export const mapDispatchToProps = ([dispatch], callback) => ({
    ...callback(dispatch),
    updatePageHeader: (data) => dispatch(updatePageHeaderContent(data))
});

/** @namespace Pwasaas/Plugin/BlogPageHeader/Util/PageHeader/Index/setBlogPageHeader */
export const setBlogPageHeader = (
    page,
    navigationState,
    updatePageHeader
) => {
    const {
        bannerGroup: {
            items = []
        } = {},
        name = ''
    } = page;

    if (!items.length) {
        updatePageHeader({});

        return;
    }

    const [{
        button_title: buttonTitle,
        button_url: buttonUrl,
        content = '',
        content_subtitle = '',
        image = ''
    }] = items;

    const description = `${content_subtitle ? `<h2>${content_subtitle}</h2>` : '' }`;

    if (content) {
        description.concat(`<p>${content}</p>`);
    }

    updatePageHeader({
        buttonTitle,
        buttonUrl,
        currentState: navigationState,
        description,
        name,
        image,
        isVisible: true
    });
};

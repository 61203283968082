export class ProductListPageComponent {
    containerProps = (args, callback, instance) => {
        const { isProductCardWithAttributes: isWithAttributes } = instance.props;

        return {
            ...callback(...args),
            isWithAttributes
        };
    };
}

const {
    containerProps
} = new ProductListPageComponent();

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            containerProps
        }
    }
};

export const LIST_ITEM_VIEW_COLUMN = 'column';

export const mapStateToProps = (args, callback) => {
    const [store] = args;

    const {
        card_display: listItemView = LIST_ITEM_VIEW_COLUMN
    } = store.SaasConfigReducer.config || {};

    return {
        ...callback(store),
        listItemView
    };
};

export class CategoryProductListContainer {
    containerProps = (args, callback, instance) => {
        const { listItemView } = instance.props;
        const propsResult = callback(args);

        if (!listItemView) {
            return propsResult;
        }

        const { mix = {}, ...otherProps } = propsResult;

        return {
            ...otherProps,
            mix: {
                ...mix,
                mods: {
                    ...mix.mods,
                    listItemView
                }
            }
        };
    };
}

const {
    containerProps
} = new CategoryProductListContainer();

export default {
    'Component/CategoryProductList/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CategoryProductList/Container': {
        'member-function': {
            containerProps
        }
    }
};

export const _getCustomizableProductFragmentOptionsFields = (args, callback) => [
    ...callback(...args),
    'disabled'
];

export const _getCustomizableSelectionValueFields = (args, callback) => [
    ...callback(...args),
    'is_default'
];

export default {
    'Query/ProductList': {
        'member-function': {
            _getCustomizableProductFragmentOptionsFields,
            _getCustomizableSelectionValueFields
        }
    }
};

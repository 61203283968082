import CategoryPriceRange from 'Component/CategoryPriceRange';

export class CategoryConfigurableAttributesComponent {
    renderConfigurableOption = (args, callback, _instance) => {
        const [option, index] = args;

        switch (option.attribute_code) {
        case 'price':
            return <CategoryPriceRange key={ index } />;
        default:
            return callback(...args);
        }
    };
}

const {
    renderConfigurableOption
} = new CategoryConfigurableAttributesComponent();

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderConfigurableOption
        }
    }
};

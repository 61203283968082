export const UPDATE_PAGE_HEADER_CONTENT = 'UPDATE_PAGE_HEADER_CONTENT';
export const UPDATE_PAGE_HEADER_WIDGET = 'UPDATE_PAGE_HEADER_WIDGET';
export const TOGGLE_PAGE_HEADER = 'TOGGLE_PAGE_HEADER';

/** @namespace Pwasaas/Store/PageHeader/Action/updatePageHeaderContent */
export const updatePageHeaderContent = (content) => ({
    type: UPDATE_PAGE_HEADER_CONTENT,
    content
});

/** @namespace Pwasaas/Store/PageHeader/Action/updatePageHeaderWidget */
export const updatePageHeaderWidget = (widget) => ({
    type: UPDATE_PAGE_HEADER_WIDGET,
    widget
});

/** @namespace Pwasaas/Store/PageHeader/Action/togglePageHeader */
export const togglePageHeader = (isVisible) => ({
    type: TOGGLE_PAGE_HEADER,
    isVisible
});

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/SaasMenu';

import MenuContent from './MenuContent.component';
import {
    CONTENT_TYPE_BANNER,
    CONTENT_TYPE_CUSTOM,
    CONTENT_TYPE_DEFAULT
} from './MenuContent.config';

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

/** @namespace Pwasaas/Component/MenuContent/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeMenuItem: state.SaasMenuReducer.activeMenuItem,
    activeNavigationItem: state.SaasMenuReducer.activeNavigationItem,
    device: state.ConfigReducer.device
});

/** @namespace Pwasaas/Component/MenuContent/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeMenu(dispatch)
    )
});

/** @namespace Pwasaas/Component/MenuContent/Container/MenuContentContainer */
export class MenuContentContainer extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired,
        activeNavigationItem: MenuItemType.isRequired,
        device: DeviceType.isRequired,
        closeMenu: PropTypes.func.isRequired,
        isNavigationContent: PropTypes.bool
    };

    static defaultProps = {
        isNavigationContent: false
    };

    containerFunctions = {
        handleCloseMenu: this.onCloseMenu.bind(this)
    };

    containerProps = () => {
        const { device } = this.props;

        const contentType = this._getContentType();
        const items = this._getContentItems(contentType);

        return {
            contentType,
            device,
            items
        };
    };

    _getActiveItem() {
        const {
            isNavigationContent,
            activeMenuItem,
            activeNavigationItem
        } = this.props;

        return isNavigationContent
            ? activeNavigationItem
            : activeMenuItem;
    }

    _getContentItems(contentType) {
        const { childs = [] } = this._getActiveItem();

        if (!childs) {
            return [];
        }

        if (contentType === CONTENT_TYPE_DEFAULT) {
            return childs;
        }

        return childs.filter(({
            mega_setting: {
                rich_content
            }
        }) => rich_content && rich_content.length > 0);
    }

    _getContentType() {
        const {
            childs = [],
            mega_setting: {
                sub_content_type
            } = {}
        } = this._getActiveItem();

        /**
         * TODO: Remove this code after expanding UberMenu backend with correct attribute naming for:
         * - Widgets
         * - Menu banner items (current: rich_content)
         * - Submenu content (expand with banner, etc.)
        */
        if (!childs || sub_content_type !== CONTENT_TYPE_CUSTOM) {
            return CONTENT_TYPE_DEFAULT;
        }

        return childs.reduce(
            (type, {
                mega_setting: {
                    rich_content
                }
            }) => {
                if (!rich_content) {
                    return type;
                }

                return CONTENT_TYPE_BANNER;
            }, CONTENT_TYPE_DEFAULT
        );
    }

    onCloseMenu() {
        const { closeMenu } = this.props;

        closeMenu();
    }

    render() {
        return (
            <MenuContent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContentContainer);

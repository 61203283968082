import BannerQuery from 'Query/Banner.query';
import { Field } from 'Util/Query';

export class CategoryQueryPlugin {
    _getDefaultFields = (args, callback) => [
        ...callback(args),
        this._getBannerGroupField()
    ];

    _getBannerGroupField() {
        return new Field('banner_group')
            .addFieldList(BannerQuery._getBannerFields());
    }
}

const {
    _getDefaultFields
} = new CategoryQueryPlugin();

export const config = {
    'Query/Category': {
        'member-function': {
            _getDefaultFields
        }
    }
};

export default config;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductPrice from 'Component/ProductPrice';
import SaasSlider from 'Component/SaasSlider';
import { PriceType } from 'Type/ProductList';

import './ThumbnailRelatedProductList.style';

/** @namespace Pwasaas/Plugin/ThumbnailRelatedProductList/Component/ThumbnailRelatedProductList/Component/ThumbnailRelatedProductListComponent */
export class ThumbnailRelatedProductListComponent extends PureComponent {
    static propTypes = {
        isSlider: PropTypes.bool.isRequired,
        numberOfProductsToDisplay: PropTypes.number.isRequired,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                price_range: PriceType,
                thumbnail: PropTypes.string,
                url: PropTypes.string
            })
        ).isRequired,
        title: PropTypes.string.isRequired
    };

    renderItem = (item) => {
        const {
            id,
            name,
            price_range,
            thumbnail,
            url
        } = item;

        return (
            <div
              block="ThumbnailRelatedProductList"
              elem="ItemWrapper"
              key={ id }
            >
                <Link
                  block="ThumbnailRelatedProductList"
                  elem="Item"
                  to={ url }
                  title={ name }
                >
                <Image
                  src={ thumbnail }
                  mix={ {
                      block: 'ThumbnailRelatedProductList',
                      elem: 'Image'
                  } }
                  ratio="custom"
                  alt={ name }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
                <div
                  block="ThumbnailRelatedProductList"
                  elem="ProductTitle"
                >
                    { name }
                </div>
                <div
                  block="ThumbnailRelatedProductList"
                  elem="PriceLabel"
                >
                    <ProductPrice price={ price_range } />
                </div>
                </Link>
            </div>
        );
    };

    renderItemSlider(items) {
        const { numberOfProductsToDisplay } = this.props;

        return (
            <SaasSlider
              isNavigation
              mix={ {
                  block: 'ThumbnailRelatedProductList',
                  elem: 'Slider'
              } }
              slidesPerView={ numberOfProductsToDisplay }
            >
                { items }
            </SaasSlider>
        );
    }

    renderItems() {
        const {
            isSlider,
            products
        } = this.props;
        const items = products.map(this.renderItem);

        if (isSlider) {
            return this.renderItemSlider(items);
        }

        return items;
    }

    renderThumbnailWrapper() {
        const { isSlider } = this.props;

        return (
            <div
              block="ThumbnailRelatedProductList"
              elem="Wrapper"
              mods={ {
                  isSlider,
                  isStatic: !isSlider
              } }
            >
                { this.renderItems() }
            </div>
        );
    }

    render() {
        const { products, title } = this.props;

        if (!products.length) {
            return null;
        }

        return (
            <div block="ThumbnailRelatedProductList">
                <div block="ThumbnailRelatedProductList" elem="Title">
                    { title }
                </div>
                { this.renderThumbnailWrapper() }
            </div>
        );
    }
}

export default ThumbnailRelatedProductListComponent;

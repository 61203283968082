import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { customerType } from 'Type/Account';
import { MixType } from 'Type/Common';

import CustomerNotesTextArea from './CustomerNotesTextArea.component';

/** @namespace Pwasaas/Component/CustomerNotesTextArea/Container/CustomerNotesTextAreaContainer */
export class CustomerNotesTextAreaContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        customerNote: PropTypes.string.isRequired,
        mix: MixType,
        updateCustomerNote: PropTypes.func.isRequired
    };

    static defaultProps = {
        mix: {}
    };

    containerFunctions = {
        handleSetCustomerNote: this.handleSetCustomerNote.bind(this)
    };

    containerProps = () => {
        const {
            customerNote,
            mix
        } = this.props;

        return { customerNote, mix };
    };

    handleSetCustomerNote(value) {
        const { updateCustomerNote } = this.props;

        updateCustomerNote(value);
    }

    render() {
        return (
            <CustomerNotesTextArea
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default CustomerNotesTextAreaContainer;

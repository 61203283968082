export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isAddAllToCart: state.CategoryReducer?.category?.show_category_order_button || false,
        isLoadingAddToCart: state.ProductListInfoReducer.isLoading
    };
};

export class ProductListContainer {
    containerProps = (args, callback, instance) => {
        const {
            isAddAllToCart,
            isLoadingAddToCart
        } = instance.props;

        /**
         * These props are not passed by default to component
         */
        const {
            filter = {},
            pageSize,
            search = ''
        } = instance.props;

        return {
            ...callback(...args),
            isAddAllToCart,
            isLoadingAddToCart,
            filter,
            pageSize,
            search
        };
    };
}

const {
    containerProps
} = new ProductListContainer();

export default {
    'Component/ProductList/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductList/Container': {
        'member-function': {
            containerProps
        }
    }
};

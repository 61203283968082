/**
 * Add search icon clickable for mobile devices
 */
import { cloneElement } from 'react';

export class SearchFieldComponent {
    onIconClick = (args, callback, instance) => {
        const { device } = instance.props;

        if (device.isMobile) {
            instance.onSearchEnterPress({ key: 'Enter' });

            return;
        }

        callback(...args);
    };

    renderSearchIcon = (args, callback, instance) => {
        const { device } = instance.props;
        const result = callback(...args);

        if (device.isMobile) {
            return cloneElement(result, {
                ...result.props,
                onClick: instance.onIconClick
            });
        }

        return result;
    };

    renderSearch = (args, callback, instance) => {
        const {
            searchCriteria,
            isActive,
            isSooqrEnabled
        } = instance?.props;

        if (!isSooqrEnabled) {
            return callback(...args);
        }

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <input
                  id="sooqr-search-field"
                  ref={ instance.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onChange={ instance.handleChange }
                  onKeyDown={ instance.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  placeholder={ __('Search products') }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                { instance.renderSearchIcon() }
            </div>
        );
    };
}

const {
    onIconClick,
    renderSearchIcon,
    renderSearch
} = new SearchFieldComponent();

export default {
    'Component/SearchField/Component': {
        'member-function': {
            onIconClick,
            renderSearchIcon,
            renderSearch
        }
    }
};

import PropTypes from 'prop-types';

import {
    MAX_ZIPCODE_LENGTH,
    MIN_ZIPCODE_LENGTH
} from 'Component/StoreLocator/StoreLocator.config';
import StoreLocatorSelect from 'Component/StoreLocatorSelect';
import { showNotification } from 'Store/Notification/Notification.action';
import StoreLocatorReducer from 'Store/StoreLocator/StoreLocator.reducer';
import getStore from 'Util/Store';

export const StoreLocatorDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/StoreLocator/StoreLocator.dispatcher'
);

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    StoreLocatorReducer
});

export const savePickupStoreBefore = async (args, callback, instance) => {
    await StoreLocatorDispatcher.then(
        ({ default: dispatcher }) => {
            const { dispatch, getState } = getStore();
            const { StoreLocatorReducer: { pickupStore } } = getState();

            return dispatcher.savePickupStore(pickupStore).then(
                /** @namespace Pwasaas/Plugin/Feature/StoreLocator/StoreLocatorPluginStore/savePickupStore/then */
                () => callback.apply(instance, args),
                /** @namespace Pwasaas/Plugin/Feature/StoreLocator/StoreLocatorPluginStore/savePickupStore/then */
                (error) => dispatch(showNotification('error', 'Error saving pickup store!', error))
            );
        }
    );
};

export const updateLocationAfter = async (postcode) => {
    await StoreLocatorDispatcher.then(
        ({ default: dispatcher }) => {
            const { dispatch } = getStore();

            dispatcher.requestLocation({ postcode: postcode.slice(0, MAX_ZIPCODE_LENGTH) }, dispatch);
        }
    );
};

export const addNewSelectOptionsTo = (args, callback, instance) => {
    const originalProps = callback(...args);

    if (Object.prototype.hasOwnProperty.call(originalProps, 'selectOptions')) {
        const {
            selectOptions: originalSelectOptions,
            sortBy
        } = instance.props;
        const selectOptionList = sortBy ? instance.sortSelectOptions() : originalSelectOptions;
        const omitSingle = (key, { [key]: _, ...obj }) => obj;
        const newProps = omitSingle('selectOptions', originalProps);

        return {
            ...newProps,
            selectOptions: selectOptionList
        };
    }

    return originalProps;
};

export const addSelectComponentBefore = (args, callback = () => {}, instance) => {
    const RenderedComponent = callback(instance, args);
    const { props: { children = [], className = '' } } = RenderedComponent;

    const { getState } = getStore();
    const { SaasConfigReducer: { config: { storelocator_enabled_in_checkout } } } = getState();

    if (!storelocator_enabled_in_checkout) {
        return RenderedComponent;
    }

    // eslint-disable-next-line fp/no-let
    let block = '';

    // TODO: OrderDateSelect is added first. Because of this, RenderedComponent has children items. What to do with order position?
    if (children.length) {
        const { props: { className: childClassName = '' } = {} } = children[children.length - 1] || {};
        block = childClassName ? childClassName.split('-', 1)[0] : '';
    } else {
        block = className ? className.split('-', 1)[0] : '';
    }

    const mix = {
        block,
        elem: 'StoreSelect',
        mods: {}
    };

    return (
        <>
            <StoreLocatorSelect mix={ mix } />
            { RenderedComponent }
        </>
    );
};

export const addSortByPropTo = (originalProps) => ({
    ...originalProps,
    sortBy: PropTypes.bool
});

export const addDefaultSortBy = (originalProps) => ({
    ...originalProps,
    sortBy: false
});

export const onChange = (args, callback, instance) => {
    const [key, value] = args;

    if (key === 'postcode' && value.length >= MIN_ZIPCODE_LENGTH) {
        updateLocationAfter(value.replace(/ /g, ''));
    }

    return callback(...args, instance);
};

export const config = {
    'Store/Index/getReducers': {
        function: getStaticReducers
    },
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            onChange
        }
    },
    'Component/CheckoutDeliveryOptions/Component': {
        'member-function': {
            render: addSelectComponentBefore
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: savePickupStoreBefore
        }
    },
    'Component/FieldSelect/Container': {
        'member-function': {
            containerProps: addNewSelectOptionsTo
        },
        'static-member': {
            defaultProps: addDefaultSortBy,
            propTypes: addSortByPropTo
        }
    }
};

export default config;

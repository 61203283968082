import { cloneElement, lazy } from 'react';

export const SubcategoryNavigation = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "subcat_navi" */ 'Component/SubcategoryNavigation'
));

/**
 * Render subcategory navigation
 */
export const renderedNavigation = (id) => <SubcategoryNavigation category_id={ id } />;

/**
 * Add subcategory navigation to render
 */
export const render = (args, callback, instance) => {
    const RenderedComponent = callback(instance, args);
    const { props: { children: child } } = RenderedComponent;
    const { category, filterConfig: { show_subcategory_filter: show = false } = {} } = instance.props;

    if (!show) {
        return RenderedComponent;
    }

    if (!child) {
        return RenderedComponent;
    }

    const { props } = child;

    const divElement = cloneElement(child, {
        ...props,
        children: [
            renderedNavigation(category),
            ...props.children
        ]
    });

    return cloneElement(RenderedComponent, {
        ...props,
        children: divElement
    });
};

export default {
    'Component/CategoryFilterOverlay/Component': {
        'member-function': {
            render
        }
    }
};

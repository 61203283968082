import { CONTENT_TYPE_CUSTOM } from 'Component/MenuContent/MenuContent.config';

/** @namespace Pwasaas/Util/Saas/Menu/getBannerItem */
export const getBannerItem = (item, defaultImage = '') => {
    const {
        link,
        mega_setting: {
            description = '',
            rich_content = '',
            sub_content_type = ''
        } = {},
        title
    } = item;

    if (rich_content && sub_content_type === CONTENT_TYPE_CUSTOM) {
        return {
            link,
            image: rich_content,
            title
        };
    }

    return {
        link,
        image: description || defaultImage,
        title
    };
};

import { cloneElement } from 'react';

import CustomerNotesTextArea from 'Component/CustomerNotesTextArea';

export class CheckoutDeliveryOptionsComponent {
    render = (args, callback, instance) => {
        const RenderedComponent = callback(instance, args);
        const {
            customerNote,
            handleCustomerNote,
            isCustomerNoteEnabled
        } = instance.props;

        if (!isCustomerNoteEnabled) {
            return RenderedComponent;
        }

        const { props, props: { className, children: baseChildren } } = RenderedComponent;
        const block = className ? className.split('-', 1)[0] : '';
        const mix = {
            block,
            elem: 'CustomerNotes',
            mods: {}
        };

        const children = [
            ...baseChildren,
            <CustomerNotesTextArea
              customerNote={ customerNote }
              mix={ mix }
              updateCustomerNote={ handleCustomerNote }
            />
        ];

        return cloneElement(RenderedComponent, {
            ...props,
            children
        });
    };
}

const {
    render
} = new CheckoutDeliveryOptionsComponent();

export default {
    'Component/CheckoutDeliveryOptions/Component': {
        'member-function': {
            render
        }
    }
};

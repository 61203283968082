export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        config: state.SaasConfigReducer.config
    };
};

export default {
    'Component/MyAccountAddressBook/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MyAccountAddressTable/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutAddressBook/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MyAccountCustomerTable/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { GUEST_QUOTE_ID } from 'Util/Cart/Token';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export default {
    'Util/Token/setGuestQuoteId': {
        function: (args) => {
            const [token] = args;

            BrowserDatabase.setItem({
                token,
                isCustomerToken: isSignedIn()
            }, GUEST_QUOTE_ID, ONE_MONTH_IN_SECONDS);
        }
    }
};

/** TODO: Merge Video.js and CmsPage.js as one transformer util */

export const CMS_PAGE = 'cmsPage';
export const IDENTIFIER = 'identifier';

/** @namespace Pwasaas/Util/Saas/CmsPage/getCmsPageWidgetKeyData */
export const getCmsPageWidgetKeyData = (key) => {
    const stringKey = key.split('_').pop();
    const currentIndex = stringKey.match(/(\d+)/)[0];
    const newKey = stringKey.slice(currentIndex.length).toLowerCase();

    return {
        currentIndex,
        key: newKey || IDENTIFIER
    };
};

/** @namespace Pwasaas/Util/Saas/CmsPage/getTransformedCmsPageWidgetData */
export const getTransformedCmsPageWidgetData = (props) => Object.entries(props).reduce(
    (cmsPages = [], [currentKey, value]) => {
        if (currentKey.includes(CMS_PAGE)) {
            const { currentIndex, key } = getCmsPageWidgetKeyData(currentKey);
            const cmsPageItem = cmsPages.find(({ index }) => index === currentIndex);

            /** TODO: Remove these strange values from response */
            if (!value || value === 0 || value === '.') {
                return cmsPages;
            }

            if (!cmsPageItem) {
                return [
                    ...cmsPages,
                    {
                        index: currentIndex,
                        [key]: value
                    }
                ];
            }

            cmsPageItem[key] = value;
        }

        return cmsPages;
    }, []
);

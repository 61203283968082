import PropTypes from 'prop-types';

import { ProductType } from 'Type/ProductList';

export const OrderListItemType = PropTypes.shape({
    product: ProductType,
    qty: PropTypes.string
});

export const OrderListType = PropTypes.shape({
    isEditing: PropTypes.bool,
    items: PropTypes.arrayOf(OrderListItemType)
});

import htmlReactParser from 'html-react-parser';
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import SaasSlider from 'Component/SaasSlider';
import { ChildrenType, MixType } from 'Type/Common';
import { SiteUspItemsType } from 'Type/Store';

import './SiteUsp.style';

/** @namespace Pwasaas/Component/SiteUsp/Component/SiteUspComponent */
export class SiteUspComponent extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        fallbackIcon: PropTypes.string.isRequired,
        isHeader: PropTypes.bool,
        isSlider: PropTypes.bool,
        isSticky: PropTypes.bool.isRequired,
        items: SiteUspItemsType.isRequired,
        mix: MixType,
        title: PropTypes.string
    };

    static defaultProps = {
        isHeader: false,
        isSlider: false,
        mix: {},
        title: ''
    };

    state = {
        itemsPerSlide: 1
    };

    renderUspSlider() {
        const { itemsPerSlide } = this.state;
        const { isSlider, items } = this.props;
        const customMix = { block: 'SiteUsp', elem: 'List', mods: { isSlider } };

        return (
            <SaasSlider
              isAutoplay
              mix={ customMix }
              slidesPerView={ itemsPerSlide }
            >
                { items.map(this.renderUsp) }
            </SaasSlider>
        );
    }

    renderUsp = (item, i) => {
        const { fallbackIcon, isSlider } = this.props;
        const { icon, content } = item;
        const contentToParse = typeof content === 'string' ? content : content.join('');

        return (
            <li
              block="SiteUsp"
              elem="Item"
              key={ i }
              mods={ { isSlide: isSlider } }
            >
                <i
                  block="SiteUsp"
                  elem="Icon"
                  className={ icon || fallbackIcon }
                />
                <span
                  block="SiteUsp"
                  elem="Content"
                >
                    { htmlReactParser(contentToParse) }
                </span>
            </li>
        );
    };

    renderHeaderUsp() {
        const {
            children,
            items,
            isSticky
        } = this.props;

        return (
            <div
              block="SaasHeader"
              elem="Usps"
              mods={ { isSticky } }
            >
                <ContentWrapper
                  isNotSection
                  label=""
                >
                    <ul
                      block="SiteUsp"
                      elem="List"
                    >
                        { items.map(this.renderUsp) }
                    </ul>
                    { children }
                </ContentWrapper>
            </div>
        );
    }

    renderTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h3
              block="SiteUsp"
              elem="Title"
            >
                <Html content={ title } />
            </h3>
        );
    }

    renderDefault() {
        const {
            items,
            mix
        } = this.props;

        return (
            <>
                { this.renderTitle() }
                <ul
                  block="SiteUsp"
                  elem="List"
                  mix={ mix }
                >
                    { items.map(this.renderUsp) }
                </ul>
            </>
        );
    }

    render() {
        const {
            isHeader,
            isSlider,
            items
        } = this.props;

        if (!items.length) {
            return null;
        }

        if (isSlider) {
            return this.renderUspSlider();
        }

        if (isHeader) {
            return this.renderHeaderUsp();
        }

        return this.renderDefault();
    }
}

export default SiteUspComponent;

/**
 * Add filterconfig to props
 */
export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        filterConfig: state.SaasConfigReducer.filter
    };
};

/**
 * Add filterconfig and category to props
 * @todo: find other way to add category id then location state
 */
export const containerProps = (args, callback, instance) => {
    const {
        filterConfig,
        location: {
            state: {
                category
            } = {}
        } = {}
    } = instance.props;

    return {
        ...callback(...args),
        category,
        filterConfig
    };
};

export default {
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/CategoryFilterOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import { StoreSwitcher } from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

import './StoreSwitcher.override.manual.style';

/** @namespace Pwasaas/Component/StoreSwitcher/Component/StoreSwitcherComponent */
export class StoreSwitcherComponent extends StoreSwitcher {
    /**
     * Replace store label with flag
     */
    renderDesktopStoreSwitcher() {
        const {
            storeList,
            onStoreSwitcherOutsideClick,
            onStoreSwitcherClick,
            isOpened,
            currentStoreCode
        } = this.props;

        const mods = { isOpen: isOpened };

        return (
            <div block="StoreSwitcher">
                <ClickOutside onClick={ onStoreSwitcherOutsideClick }>
                    <button
                      block="StoreSwitcher"
                      elem="Title"
                      mods={ mods }
                      onClick={ onStoreSwitcherClick }
                    >
                        { /* TODO: Add a setting to add an image to StoreSelector or not */ }
                        <img src={ `/media/flags/${currentStoreCode}.jpg` } alt="" />
                        <ChevronIcon direction={ isOpened ? TOP : BOTTOM } />
                    </button>

                    <div block="StoreSwitcher" elem="StoreList" mods={ mods }>
                        { storeList.map(this.renderStoreList) }
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default StoreSwitcherComponent;

import { createRef } from 'react';

import { getElementHeight } from 'Util/CSS';

export class ProductActionsContainer {
    actionsRef = createRef();

    wrapperRef = createRef();

    componentDidMount = (args, callback, { props }) => {
        const { device: { isMobile } } = props;

        if (!isMobile) {
            window.addEventListener('scroll', this.setFixedElementClass.bind(this));
        }

        callback(...args);
    };

    componentWillUnmount = (args, callback, { props }) => {
        const { device: { isMobile } } = props;

        if (!isMobile) {
            window.removeEventListener('scroll', this.setFixedElementClass.bind(this));
        }

        callback(...args);
    };

    containerProps = (args, callback) => ({
        ...callback(...args),
        actionsRef: this.actionsRef,
        wrapperRef: this.wrapperRef
    });

    setFixedElementClass() {
        const actionsRef = this.actionsRef?.current;
        const wrapperRef = this.wrapperRef?.current;

        if (!wrapperRef || !actionsRef) {
            return;
        }

        const totalHeight = getElementHeight('SaasHeader') - getElementHeight('ProductActions-AddToCartWrapper');

        if (wrapperRef.getBoundingClientRect().top < totalHeight) {
            actionsRef.classList.add('FixedElement-Bottom');

            return;
        }

        actionsRef.classList.remove('FixedElement-Bottom');
    }
}

const {
    componentDidMount,
    componentWillUnmount,
    containerProps
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container': {
        'member-function': {
            componentDidMount,
            componentWillUnmount,
            containerProps
        }
    }
};

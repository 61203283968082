import { cloneElement } from 'react';

export class CheckoutShippingComponent {
    renderDelivery = (args, callback, instance) => {
        const CheckoutDeliveryOptions = callback(...args);
        const {
            customerNote,
            handleCustomerNote,
            isCustomerNoteEnabled
        } = instance.props;

        if (!isCustomerNoteEnabled) {
            return CheckoutDeliveryOptions;
        }

        return cloneElement(CheckoutDeliveryOptions, {
            ...CheckoutDeliveryOptions.props,
            customerNote,
            handleCustomerNote
        });
    };
}

const {
    renderDelivery
} = new CheckoutShippingComponent();

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderDelivery
        }
    }
};

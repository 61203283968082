import htmlReactParser from 'html-react-parser';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import BrowserDatabase from 'Util/BrowserDatabase';

import {
    LOCAL_STORE_SAAS_CONFIG,
    MAPPED_SAAS_CONFIG_KEY_STRINGS
} from './Saas.config';

export const HEADER_LOGO_ELEMENT = 'header_logo';

/** @namespace Pwasaas/Util/Saas/Config/getConfigValue */
export const getConfigValue = (obj = {}, path) => path.split('.')
    .reduce((objKey, childKey) => objKey[childKey] || '', obj);

/** @namespace Pwasaas/Util/Saas/Config/getValueFromDomNode */
export const getValueFromDomNode = (nodes) => nodes.map((node) => {
    const { type, props } = node;

    if (!type || !props) {
        return node;
    }

    if (!MAPPED_SAAS_CONFIG_KEY_STRINGS.includes(type)) {
        return renderToStaticMarkup(node);
    }

    const {
        value = '.',
        children = '',
        tag = ''
    } = props;

    const saasConfig = BrowserDatabase.getItem(LOCAL_STORE_SAAS_CONFIG);
    const data = {
        value: saasConfig && saasConfig[type]
            ? getConfigValue(saasConfig[type], value)
            : ''
    };

    if (!data.value && typeof children === 'string') {
        data.value = children;
    }

    return tag ? `<${tag}>${data.value}</${tag}>` : data.value;
});

/** @namespace Pwasaas/Util/Saas/Config/getParsedUspData */
export const getParsedUspData = (item) => {
    if (!item) {
        return null;
    }

    const { content } = item;

    if (!content) {
        return null;
    }

    const parsedContent = htmlReactParser(content);
    const isParsedArray = Array.isArray(parsedContent);

    if (!isParsedArray && !React.isValidElement(parsedContent)) {
        return null;
    }

    const elementCollection = isParsedArray
        ? parsedContent
        : [parsedContent];

    const items = elementCollection.filter(({ type }) => type === 'li');

    /**
     * @todo: if it's nested in an ul tag, get children of that
     */
    if (!items.length) {
        return null;
    }

    return items.map(({ props }) => {
        const { icon, children } = props;

        return {
            icon,
            content: typeof children === 'string'
                ? children
                : getValueFromDomNode(Array.isArray(children) ? children : [children])
        };
    });
};

/** @namespace Pwasaas/Util/Saas/Config/setHeaderLogoDimension */
export const setHeaderLogoDimension = (width, height, remove = false) => {
    const headerLogo = document.getElementById(HEADER_LOGO_ELEMENT);

    if (headerLogo && remove) {
        headerLogo.outerHTML = '';
    }

    if (!headerLogo) {
        const styleItem = document.createElement('style');
        styleItem.id = HEADER_LOGO_ELEMENT;
        styleItem.media = 'all';
        styleItem.textContent = `
                :root {
                    --header-logo-height: ${height}px;
                    --header-logo-width: ${width}px;
                }   
            `;
        document.head.appendChild(styleItem);
    }
};

/** @namespace Pwasaas/Util/Saas/Config/getSiteUsp */
export const getSiteUsp = (item) => getParsedUspData(item);

/** @namespace Pwasaas/Util/Saas/Config/getProductUsp */
export const getProductUsp = (item) => getParsedUspData(item);

/** @namespace Pwasaas/Util/Saas/Config/getFileName */
export const getFileName = (name) => `${name.trim().replace(' ', '_').replace('/', '')}.pdf`;

/** @namespace Pwasaas/Util/Saas/Config/getPDFDownloadUrl */
export const getPDFDownloadUrl = (product, isPluginCompanyProductPDFEnabled) => {
    if (isPluginCompanyProductPDFEnabled) {
        return `/productpdf/download/file/id/${product.id}/name/${getFileName(product.name)}`;
    }

    return `/catalogproductpdf?id=${product.id}`;
};

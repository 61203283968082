/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import AgeCheckPopup from 'Component/AgeCheckPopup';
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

export class AgeCheckPopupComponent {
    beforeItemsType = (originalMember) => [
        {
            component: <AgeCheckPopup />,
            position: 40
        },
        ...originalMember
    ];
}

const {
    beforeItemsType
} = new AgeCheckPopupComponent();

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: beforeItemsType
        }
    }
};

import { Field } from 'Util/Query';

export class StoreQuery {
    _getSaasConfigFields = (args, callback) => ([
        ...callback(args),
        'surface_price_calculation_enabled',
        this._getSalesOrderUnitLossField()
    ]);

    _getSalesOrderUnitLossField() {
        return new Field('surface_price_calculation_sales_order_unit_loss')
            .addFieldList(['label', 'value']);
    }
}

const {
    _getSaasConfigFields
} = new StoreQuery();

export default {
    'Pwasaas/Query/Store/Query/StoreQuery': {
        'member-function': {
            _getSaasConfigFields
        }
    }
};

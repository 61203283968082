import history from 'Util/History';

export class AddToCartContainer {
    validateSimpleProduct = (args, callback, instance) => {
        const {
            product: {
                url
            },
            productOptionsData
        } = instance.props;

        const validateCustomizableOptions = instance.validateCustomizableOptions(productOptionsData);

        if (validateCustomizableOptions === false) {
            history.push(url);
        }

        return callback.apply(instance, args);
    };
}

const {
    validateSimpleProduct
} = new AddToCartContainer();

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            validateSimpleProduct
        }
    }
};

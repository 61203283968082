import {
    NotificationList as SourceNotificationList
} from 'SourceComponent/NotificationList/NotificationList.component';

import './NotificationList.override.manual.style';

/** @namespace Pwasaas/Component/NotificationList/Component/NotificationListComponent */
export class NotificationListComponent extends SourceNotificationList {}

export default NotificationListComponent;

/* eslint-disable max-lines */
import { fetchQuery } from 'Util/Request';

import DependencyStateQuery from '../query/DependencyState.query';
import {
    setDefaultData,
    updateDefaultData,
    updateProductOptions
} from './Product.reducer.plugin';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        defaultOptions: state.ProductReducer.mageworx?.defaultData?.options,
        defaultPrice: state.ProductReducer.mageworx?.defaultData?.price,
        hiddenOptions: state.ProductReducer.mageworx?.hiddenOptions,
        hiddenValues: state.ProductReducer.mageworx?.hiddenValues,
        isOptionsEnabled: state.SaasConfigReducer.config?.mageworx_enable_options || false,
        productPrice: state.ProductReducer.product?.price_range,
        productSku: state.ProductReducer.product?.sku
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        setDefaultData: (data) => dispatch(setDefaultData(data)),
        updateDefaultData: () => dispatch(updateDefaultData()),
        updateProductOptions: (options) => dispatch(updateProductOptions(options))
    };
};

export class ProductCustomizableOptionsContainer {
    componentDidUpdate = async (args, callback, instance) => {
        const result = callback(...args);
        const [prevProps, prevState] = args;

        const {
            defaultOptions,
            defaultPrice,
            isOptionsEnabled,
            productSku
        } = instance.props;

        const {
            defaultOptions: prevDefaultOptions,
            defaultPrice: prevDefaultPrice,
            productSku: prevSku
        } = prevProps;

        if (
            !isOptionsEnabled
                || defaultOptions !== prevDefaultOptions
                || defaultPrice !== prevDefaultPrice
        ) {
            return result;
        }

        this._initializeDefaultData(instance);

        if (productSku !== prevSku) {
            await this._setDependencyStateData(instance);

            return result;
        }

        const { selectedDropdownOptions = [] } = instance.state;
        const { selectedDropdownOptions: prevSelectedDropdownOptions } = prevState;

        if (selectedDropdownOptions !== prevSelectedDropdownOptions) {
            await this._setDependencyStateData(instance);

            return result;
        }

        return result;
    };

    componentWillUnmount = (args, callback, instance) => {
        const { updateDefaultData = () => {} } = instance.props;

        updateDefaultData();

        return callback(...args);
    };

    containerProps = (args, callback, instance) => {
        const { isOptionsEnabled } = instance.props;

        return {
            ...callback(...args),
            isOptionsEnabled
        };
    };

    _setDependencyStateData(instance) {
        const {
            defaultOptions = [],
            options: currentOptions,
            productSku,
            updateProductOptions
        } = instance.props;
        const { selectedDropdownOptions } = instance.state;
        const selectedValues = selectedDropdownOptions.map(({ option_value }) => option_value.toString());

        return fetchQuery(DependencyStateQuery.getQuery(productSku, selectedValues.toString())).then(
            ({
                dependencyState: {
                    hidden_options: hiddenOptions,
                    hidden_values: hiddenValues
                }
            }) => {
                const options = this._getVisibleCustomizableOptions(
                    defaultOptions.length ? defaultOptions : currentOptions,
                    hiddenOptions,
                    hiddenValues
                );

                updateProductOptions({
                    options,
                    hiddenOptions,
                    hiddenValues
                });
            },
            (errors = []) => {
                const message = errors[0]?.message || '';

                if (message.includes('Internal server error')) {
                    return;
                }

                const selectedDropdownOptions = [];

                defaultOptions.forEach((option) => {
                    const { data = [], option_id } = option;
                    const optionValues = Array.isArray(data)
                        ? data
                        : [data];
                    const { option_type_id: option_value } = optionValues.find(
                        ({ is_default }) => is_default === '1'
                    ) || {};

                    if (!option_value) {
                        return;
                    }

                    selectedDropdownOptions.push({ option_id, option_value });
                });

                instance.setState({ selectedDropdownOptions });
            }
        );
    }

    _getVisibleCustomizableOptions = (
        options,
        hiddenOptions = [],
        hiddenValues = []
    ) => options.reduce(
        (acc, option) => {
            const { data, option_id, disabled } = option;

            if (
                disabled === '0'
                    && !hiddenOptions.includes(option_id)
            ) {
                acc.push({
                    ...option,
                    data: data.filter(({ option_type_id }) => !hiddenValues.includes(option_type_id))
                });
            }

            return acc;
        }, []
    );

    _initializeDefaultData(instance) {
        const {
            setDefaultData,
            defaultOptions = [],
            defaultPrice,
            options = [],
            productPrice = 0
        } = instance.props;

        if ((!defaultOptions.length && options.length) && (!defaultPrice && productPrice)) {
            setDefaultData({
                price: productPrice,
                options
            });
        }
    }
}

const {
    containerProps,
    componentDidUpdate,
    componentWillUnmount
} = new ProductCustomizableOptionsContainer();

export default {
    'Pwasaas/Component/ProductCustomizableOptions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/ProductCustomizableOptions/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductCustomizableOptions/Container': {
        'member-function': {
            containerProps,
            componentDidUpdate,
            componentWillUnmount
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common';

import {
    IN_BACKORDER_LABEL,
    IN_STOCK_LABEL,
    OUT_OF_STOCK_LABEL
} from './ProductStock.config';

import './ProductStock.manual.style';

/** @namespace Pwasaas/Plugin/ShowProductStock/Component/ProductStock/Component/ProductStockComponent */
export class ProductStockComponent extends PureComponent {
    static propTypes = {
        isWithIcon: PropTypes.bool,
        isWithLabel: PropTypes.bool,
        status: PropTypes.string.isRequired,
        mix: MixType
    };

    static defaultProps = {
        isWithIcon: false,
        isWithLabel: true,
        mix: {}
    };

    statusMap = {
        [IN_BACKORDER_LABEL]: {
            icon: 'fas fa-pause-circle',
            value: __('In backorder')
        },
        [IN_STOCK_LABEL]: {
            icon: 'fas fa-check-circle',
            value: __('In stock')
        },
        [OUT_OF_STOCK_LABEL]: {
            icon: 'fas fa-times-circle',
            value: __('Out of stock')
        }
    };

    renderStatusIcon() {
        const { isWithIcon, status } = this.props;

        if (!isWithIcon) {
            return null;
        }

        return (
            <i
              block="ProductStock"
              elem="Icon"
              className={ this.statusMap[status].icon }
              mods={ { type: status } }
            />
        );
    }

    renderStatusLabel() {
        const { isWithLabel } = this.props;

        if (!isWithLabel) {
            return null;
        }

        return <span>{ __('Stock status: ') }</span>;
    }

    render() {
        const { status = '', mix } = this.props;

        if (!this.statusMap[status]) {
            return null;
        }

        return (
            <p
              block="ProductStock"
              elem="Label"
              mix={ mix }
            >
                { this.renderStatusLabel() }
                { this.renderStatusIcon() }
                { this.statusMap[status].value }
            </p>
        );
    }
}

export default ProductStockComponent;

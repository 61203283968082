import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/RequestAccount/Query/RequestAccountQuery */
export class RequestAccountQuery {
    getMutation(data) {
        return new Field('requestAccount')
            .addArgument('companyData', 'RequestAccountInput!', data);
    }
}

export default new RequestAccountQuery();

import Image from 'Component/Image';
import {
    CHECKBOX,
    MULTI,
    RADIO,
    SELECT
} from 'Component/ProductBundleItem/ProductBundleItem.config';
import SaasSlider from 'Component/SaasSlider';
import {
    ProductBundleItem as SourceProductBundleItem
} from 'SourceComponent/ProductBundleItem/ProductBundleItem.component';

import { SLIDES_PER_VIEW } from './ProductVisualBundleItem.config';

import './ProductVisualBundleItem.style';

/** @namespace Pwasaas/Plugin/VisualBundleSelector/Component/ProductVisualBundleItem/Component/ProductVisualBundleItemComponent */
export class ProductVisualBundleItemComponent extends SourceProductBundleItem {
    renderMap = {
        [CHECKBOX]: this.renderSwatchValues.bind(this),
        [MULTI]: this.renderSwatchValues.bind(this),
        [RADIO]: this.renderSwatchValues.bind(this),
        [SELECT]: this.renderSwatchValues.bind(this)
    };

    renderSwatchOption = (item) => {
        if (!item.product) {
            return '';
        }

        const {
            currencyCode,
            handleSwatchSelect,
            renderOptionLabel,
            selectedDropdownValue
        } = this.props;

        const {
            id,
            label,
            finalOptionPrice,
            price,
            price_type,
            product: {
                name,
                thumbnail: {
                    url: swatchUrl = ''
                } = {}
            }
        } = item;

        const priceLabel = renderOptionLabel(price_type, finalOptionPrice, price, currencyCode);
        const optionLabel = label || name || '';

        return (
            <div
              block="ProductVisualBundleItem"
              elem="SwatchOptionWrapper"
              key={ id }
            >
                <div
                  block="ProductVisualBundleItem"
                  elem="SwatchOption"
                  mods={ { isSelected: selectedDropdownValue === id } }
                  role="button"
                  onKeyDown={ null }
                  tabIndex="0"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleSwatchSelect(item) }
                >
                <Image
                  src={ swatchUrl }
                  mix={ {
                      block: 'ProductVisualBundleItem',
                      elem: 'Swatch'
                  } }
                  ratio="custom"
                  alt={ optionLabel }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ optionLabel }
                  src={ swatchUrl }
                />
                <div
                  block="ProductVisualBundleItem"
                  elem="PriceLabel"
                >
                    { priceLabel }
                </div>
                </div>
            </div>
        );
    };

    renderSwatchSlider(swatchOptions) {
        return (
            <SaasSlider
              isNavigation
              mix={ {
                  block: 'ProductVisualBundleItem',
                  elem: 'Slider'
              } }
              slidesPerView={ SLIDES_PER_VIEW }
            >
                { swatchOptions }
            </SaasSlider>
        );
    }

    renderSwatchOptions() {
        const {
            isSlider,
            option: { options }
        } = this.props;
        const swatchOptions = options.map(this.renderSwatchOption);

        if (isSlider) {
            return this.renderSwatchSlider(swatchOptions);
        }

        return swatchOptions;
    }

    renderSwatchWrapper() {
        const { isSlider } = this.props;

        return (
            <div
              block="ProductVisualBundleItem"
              elem="SwatchWrapper"
              mods={ {
                  isSlider,
                  isStatic: !isSlider
              } }
            >
                { this.renderSwatchOptions() }
            </div>
        );
    }

    renderSelectedValue() {
        const { option: { options }, selectedDropdownValue } = this.props;
        const { label } = options.find(({ id }) => selectedDropdownValue === id) || {};

        if (!label) {
            return null;
        }

        return (
            <div
              block="ProductVisualBundleItem"
              elem="Selected"
            >
                { label }
            </div>
        );
    }

    renderSwatchValues() {
        const { option: { required } } = this.props;

        return (
            <>
                { this.renderSwatchWrapper() }
                { this.renderRequired(required) }
                { this.renderSelectedValue() }
            </>
        );
    }

    render() {
        const { optionType } = this.props;
        const render = this.renderMap[optionType];

        if (!render) {
            return null;
        }

        const { option: { required, title } } = this.props;

        return (
            <div block="ProductVisualBundleItem" elem="Wrapper">
                <div block="ProductVisualBundleItem" elem="Title">
                    { `${title}${required ? ' *' : ''}` }
                </div>
                { render() }
            </div>
        );
    }
}

export default ProductVisualBundleItemComponent;

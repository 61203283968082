import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { isCrawler, isSSR } from 'Util/Browser';
import history from 'Util/History';

export class AddAutoReloadOnAppUpdatePlugin {
    componentDidMount = (args, callback, instance) => {
        const { showPopup, goToPreviousHeaderState, device } = instance.props;

        if (isCrawler() || isSSR()) {
            // disable popup for crawlers so page content is not blocked and page is scrollable
            return;
        }

        const { serviceWorker: { controller } = {} } = navigator;

        if (!controller) {
            return;
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                const { location: { pathname } } = history;

                /** Prevent auto reload when user is in checkout */
                if (pathname.indexOf(CHECKOUT_URL) === -1) {
                    window.location.reload();
                }

                showPopup({
                    title: __('New version available!')
                });

                if (device.isMobile) {
                    goToPreviousHeaderState();
                }
            });
        }
    };
}

const {
    componentDidMount
} = new AddAutoReloadOnAppUpdatePlugin();

export default {
    'Component/NewVersionPopup/Container': {
        'member-function': {
            componentDidMount
        }
    }
};

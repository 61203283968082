import { GOOGLE_FONT_URL } from './CSS.config';

/* @namespace Pwasaas/Util/CSS/Custom/CSSCustom */
export class CSSCustom {
    static setRootColorVariables(elements) {
        Object.keys(elements).forEach((elemKey) => {
            document.documentElement.style.setProperty(`--imported_${elemKey}`, `#${elements[elemKey]}`);
        });
    }

    static setRootFontFamily(elements, isEnabled) {
        if (Object.keys(elements).length < 1 || !isEnabled) {
            document.documentElement.style.setProperty(
                '--imported_google_font_primary', '"var(--fallback-font-family)", sans-serif'
            );

            return;
        }

        const link = document.createElement('link');
        const fontKey = Object.keys(elements)[0];
        const fontName = Object.values(elements)[0];

        // eslint-disable-next-line fp/no-let
        let styleValue = '';

        if (Object.keys(elements).length > 1) {
            styleValue = `:${ Object.values(elements)[1]}`;
        }

        const url = `${`${GOOGLE_FONT_URL}&family=${fontName + styleValue}`}&display=swap`;
        link.rel = 'stylesheet';
        link.href = url;
        document.getElementsByTagName('head')[0].appendChild(link);
        document.documentElement.style.setProperty(
            // eslint-disable-next-line max-len
            `--imported_${fontKey}`, `"${fontName.replace(/\+/g, ' ')}", "var(--fallback-font-family)", sans-serif`
        );
    }

    static addBodyClass(className) {
        const { body } = document;

        body.classList.add(className);
    }

    static removeBodyClass(className) {
        const { body } = document;

        body.classList.remove(className);
    }
}

export default CSSCustom;

/** @namespace Pwasaas/Plugin/SaasInputSelectField/Util/ProductConfig/Index/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        productConfig: state.SaasConfigReducer.product
    };
};

/** @namespace Pwasaas/Plugin/SaasInputSelectField/Util/ProductConfig/Index/ProductConfigSetQuantity */
export class ProductConfigSetQuantity {
    containerProps = (args, callback, instance) => {
        const { showQtyInputField } = instance.state;

        const {
            productConfig: {
                quantity_dropdown: isSelect,
                quantity_set: quantitySet = []
            } = {}
        } = instance.props;

        const { quantity } = instance.state;

        return {
            ...callback(...args),
            isSelect,
            quantity,
            quantitySet,
            showQtyInputField
        };
    };

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        setQuantity: this.setQuantity.bind(instance)
    });

    state = (originalMember, instance) => {
        const {
            productConfig: {
                defaultQty: {
                    value = 1
                } = {}
            } = {}
        } = instance.props;

        return {
            ...originalMember,
            quantity: value,
            showQtyInputField: true
        };
    };

    setQuantity(value) {
        const {
            productConfig: {
                isSelect
            } = {}
        } = this.props;

        const quantity = isSelect
            ? value
            : +value;

        this.setState({
            quantity
        });
    }
}

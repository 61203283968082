import { PRODUCT_REVIEWS } from 'Route/ProductPage/ProductPage.config';

export class ProductPageComponent {
     tabMap = (originalMember, instance) => {
         const { isReviewEnabled } = instance.props;

         return {
             ...originalMember,
             [PRODUCT_REVIEWS]: {
                 ...originalMember[PRODUCT_REVIEWS],
                 shouldTabRender: () => isReviewEnabled === true
             }
         };
     };
}

const {
    tabMap
} = new ProductPageComponent();

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap
        }
    }
};

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer';

import StoreNotice from './StoreNotice.component';
import { STORE_NOTICE_BLOCK_IDENTIFIER } from './StoreNotice.config';

/** @namespace Pwasaas/Component/StoreNotice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    isStoreNoticeSticky: state.SaasLayoutReducer?.header?.is_store_notice_sticky || false
});

/** @namespace Pwasaas/Component/StoreNotice/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Pwasaas/Component/StoreNotice/Container/StoreNoticeContainer */
export class StoreNoticeContainer extends DataContainer {
    static propTypes = {
        isStoreNoticeSticky: PropTypes.bool.isRequired
    };

    state = {
        content: '',
        isEnabled: false
    };

    componentDidMount() {
        this._getCmsBlock();
    }

    componentDidUpdate() {
        this._checkForNotice();
        this._checkForSticky();
    }

    containerProps = () => {
        const {
            props: {
                isStoreNoticeSticky
            },
            state: {
                content,
                isEnabled
            }
        } = this;

        return {
            content,
            isEnabled,
            isStoreNoticeSticky
        };
    };

    _getCmsBlock() {
        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [STORE_NOTICE_BLOCK_IDENTIFIER] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }
                const {
                    content,
                    disabled
                } = items.reduce((obj, item) => obj[item]);

                if (disabled) {
                    return;
                }

                this.setState({
                    content,
                    isEnabled: true
                });
            }
        );
    }

    _checkForNotice() {
        const { isEnabled } = this.state;

        if (isEnabled) {
            document.documentElement.classList.add('isNoticeVisible');

            return;
        }

        document.documentElement.classList.remove('isNoticeVisible');
    }

    _checkForSticky() {
        const { isStoreNoticeSticky } = this.props;

        if (isStoreNoticeSticky) {
            document.documentElement.classList.add('isStoreNoticeSticky');

            return;
        }

        document.documentElement.classList.remove('isStoreNoticeSticky');
    }

    render() {
        return (
            <StoreNotice
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreNoticeContainer);

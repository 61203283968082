import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        headerLogo: state.ConfigReducer.header_logo_src,
        name: state.MetaReducer.default_title || '',
        url: state.MetaReducer.canonical_url || ''
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        headerLogo,
        name,
        url
    } = instance.props;

    return {
        ...callback(args),
        headerLogo,
        name,
        url
    };
};

export class AddOrganizationScopeToRouterPlugin {
    render = (args, callback, instance) => {
        const RenderedComponent = callback.apply(instance, args);

        return (
            <>
                { RenderedComponent }
                { this.renderJsonLdSchemaScope(instance) }
            </>
        );
    };

    renderJsonLdSchemaScope(instance) {
        const {
            headerLogo,
            name,
            url
        } = instance.props;

        const logo = headerLogo ? media(headerLogo, LOGO_MEDIA) : null;

        const schemaData = {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name,
            url,
            logo
        };

        return (
            <script type="application/ld+json">
                { JSON.stringify(schemaData) }
            </script>
        );
    }
}

const {
    render
} = new AddOrganizationScopeToRouterPlugin();

export default {
    'Component/Router/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Router/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/Router/Component': {
        'member-function': {
            render
        }
    }
};

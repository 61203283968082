import { getStore } from 'Util/Store';

export class AddShortcutIconToHeadPlugin {
    addFaviconBefore = (_, callback, instance) => {
        const result = callback.call(instance);
        const {
            ConfigReducer: {
                head_shortcut_icon = ''
            }
        } = getStore().getState();

        if (head_shortcut_icon) {
            this.setFavicon(head_shortcut_icon);
        }

        return result;
    };

    setFavicon(src) {
        const links = Array.from(document.getElementsByTagName('link'));

        const link = links.find(({ rel }) => rel === 'shortcut icon');

        if (!link) {
            return;
        }

        link.href = `/media/favicon/${src}`;
    }
}

const {
    addFaviconBefore
} = new AddShortcutIconToHeadPlugin();

export const config = {
    'Component/App/Component': {
        'member-function': {
            renderRouter: addFaviconBefore
        }
    }
};

export default config;

import PropTypes from 'prop-types';

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

import './Loader.override.manual.style';

/** @namespace Pwasaas/Component/Loader/Component/LoaderComponent */
export class LoaderComponent extends SourceLoader {
    static propTypes = {
        ...this.propTypes,
        isFixed: PropTypes.bool,
        isTransparent: PropTypes.bool
    };

    static defaultProps = {
        isFixed: false,
        isTransparent: true
    };

    /**
     * Add fixed and transparent mods
     */
    render() {
        const {
            isFixed,
            isLoading,
            isTransparent
        } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div
              block="Loader"
              mods={ {
                  isFixed,
                  isTransparent
              } }
            >
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default LoaderComponent;

import { THEME_KEYS_VARIABLE_IMPORT } from '../SaasTheme.config';
import { ThemeType } from '../type/SaasTheme';

export const STYLE_TAG_ID = 'saas-theme-variables';
export const SaasThemeDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/SaasTheme/SaasTheme.dispatcher'
);

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const result = callback(...args);
    const { init } = result;

    result.init = (...args) => {
        SaasThemeDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.handleData(dispatch);
            }
        );
        init(...args);
    };

    return result;
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        themeSettings: state.SaasThemeReducer || {}
    };
};

export class RouterContainer {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        themeSettings: ThemeType.isRequired
    });

    componentDidMount = (args, callback, instance) => {
        const { themeSettings } = instance.props;

        this._setCSSVariables(themeSettings);

        return callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [prevProps] = args;
        const { themeSettings } = instance.props;
        const { themeSettings: prevSettings } = prevProps;

        if (JSON.stringify(themeSettings) !== JSON.stringify(prevSettings)) {
            this._setCSSVariables(themeSettings);
        }

        return callback(...args);
    };

    _setCSSVariables(themeSettings) {
        const currentStyleItem = document.getElementById(STYLE_TAG_ID);

        if (currentStyleItem !== null) {
            currentStyleItem.remove();
        }

        if (!Object.keys(themeSettings).length) {
            return;
        }

        const textContent = [];

        textContent.push(':root {');

        Object.entries(themeSettings).forEach(
            ([themeKey, value]) => {
                if (!THEME_KEYS_VARIABLE_IMPORT.includes(themeKey)) {
                    return;
                }

                Object.entries(value).forEach(
                    ([key, value]) => {
                        textContent.push(`\t--imported_${key}: ${value};`);
                    }
                );
            }
        );

        textContent.push('}');

        const newStyleItem = document.createElement('style');

        newStyleItem.id = STYLE_TAG_ID;
        newStyleItem.media = 'all';
        newStyleItem.textContent = textContent.join('\n');

        const firstStyleElement = document.head.getElementsByTagName('style');

        if (firstStyleElement.length) {
            document.head.insertBefore(newStyleItem, firstStyleElement[0]);

            return;
        }

        document.head.appendChild(newStyleItem);
    }
}

const {
    componentDidMount,
    componentDidUpdate,
    staticPropTypes
} = new RouterContainer();

export default {
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/Router/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        },
        'static-member': {
            propTypes: staticPropTypes
        }
    }
};

/**
 * Todo: this is a fix for Scandi 5.0.6, it is fixed in 5.1.1
 */

import { SHOW_MORE_FIX_ENABLED } from './ExpandableContentShowMore.config';

export class ExpandableContentShowMoreComponent {
    componentDidUpdate = (args, callback, instance) => {
        if (!SHOW_MORE_FIX_ENABLED) {
            return callback(...args);
        }

        const [prevProps] = args;
        const { children: prevChildren } = prevProps;
        const { children: nextChildren } = instance.props;
        const { expandableRef } = instance;

        if (prevChildren !== nextChildren) {
            if (expandableRef.current) {
                // eslint-disable-next-line react/no-did-update-set-state
                instance.setState({ isOpen: true }, () => {
                    expandableRef.current.style.height = 'auto';
                });
            }
        } else {
            return callback(...args);
        }

        const { isExpanding } = instance.state;

        if (isExpanding) {
            const ONE_SECOND_IN_MS = 1000;
            const transitionDurationCSStoMS = window
                .getComputedStyle(expandableRef.current)
                .getPropertyValue('transition-duration')
                .slice(0, -1) * ONE_SECOND_IN_MS;

            setTimeout(() => instance.setState({ isExpanding: false }),
                transitionDurationCSStoMS);
        }

        if (nextChildren !== prevChildren) {
            instance.getExpandableContentHeight();
        }

        return null;
    };
}

const {
    componentDidUpdate
} = new ExpandableContentShowMoreComponent();

export default {
    'Component/ExpandableContentShowMore/Component': {
        'member-function': {
            componentDidUpdate
        }
    }
};

import ProductStock from '../component/ProductStock/ProductStock.component';
import { getStockStatus, OUT_OF_STOCK } from '../util/ProductStock';

export const renderAddToWrapper = (args, callback, instance) => {
    const {
        backorders,
        product: {
            stock_status,
            stock_item
        }
    } = instance.props;

    if (stock_status === OUT_OF_STOCK) {
        return (
            <ProductStock
              isWithIcon
              isWithLabel={ false }
              mix={ {
                  block: 'ProductActions',
                  elem: 'ProductStock ProductCardStock'
              } }
              status={ getStockStatus(stock_item, backorders, stock_status) }
            />
        );
    }

    return callback(instance, args);
};

export default {
    'Pwasaas/Component/ProductCard/Component/ProductCardComponent': {
        'member-function': {
            renderAddToWrapper
        }
    }
};

import { cloneElement } from 'react';

import { formatPrice } from 'Util/Price';
import { getFinalPrice } from 'Util/Price/GetPrice';

import UnitPrice from '../component/UnitPrice';

import '../style/ProductCard.manual.style';

export class ProductCardComponent {
    renderProductPrice = (args, callback, instance) => {
        const { showUnitPrice } = instance.props;
        const result = callback(...args);

        if (!showUnitPrice) {
            return result;
        }

        return (
            <>
                { result }
                { this.renderUnitPrice(instance) }
            </>
        );
    };

    renderUnitPrice(instance) {
        const {
            displayTaxInPrice,
            product: {
                attributes = {},
                price_range: price,
                price_range: {
                    minimum_price: {
                        final_price: {
                            currency
                        } = {}
                    } = {}
                } = {},
                price_tiers = []
            } = {},
            unitPriceAttribute
        } = instance.props;

        const productPrice = {
            price,
            price_tiers,
            displayTaxInPrice
        };

        if (!attributes[unitPriceAttribute]) {
            return null;
        }

        const finalPrice = getFinalPrice(productPrice, true);
        const qty = attributes[unitPriceAttribute].attribute_value;
        const finalPricePerQty = formatPrice(finalPrice / qty, currency);

        return (
            <UnitPrice
              price={ finalPricePerQty }
              mix={ {
                  block: 'ProductCard',
                  elem: 'UnitPrice'
              } }
            />
        );
    }

    render = (args, callback, instance) => {
        const { showUnitPrice } = instance.props;
        const result = callback(...args);

        if (!showUnitPrice) {
            return result;
        }

        const { className } = result.props;

        return cloneElement(result, {
            ...result.props,
            className: `${className} ProductCard_hasUnitPrice`
        });
    };
}

const {
    render,
    renderProductPrice
} = new ProductCardComponent();

export default {
    'Pwasaas/Component/ProductCard/Component/ProductCardComponent': {
        'member-function': {
            render,
            renderProductPrice
        }
    }
};

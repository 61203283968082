import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import { IN_STOCK } from 'Component/ProductCard/ProductCard.config';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import {
    CONFIGURABLE,
    validateProductQuantity
} from 'Util/Product';

import CompleteOrderProductCard from './CompleteOrderProductCard.component';
import { ON_ADD, ON_EDIT, ON_REMOVE } from './CompleteOrderProductCard.config';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrderProductCard/Container/CompleteOrderProductCardContainer */
export class CompleteOrderProductCardContainer extends ProductCardContainer {
    static propTypes = {
        ...ProductCardContainer.propTypes,
        isSelected: PropTypes.bool.isRequired,
        onSelectChange: PropTypes.func.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleAddToTotals: this.onAddToTotals.bind(this),
        handleRemoveFromTotals: this.onRemoveFromTotals.bind(this)
    };

    state = {
        ...this.state,
        quantity: 1
    };

    configurableOptionsRef = createRef();

    componentDidUpdate(_, prevState) {
        const { parameters: prevParams, quantity: prevQuantity } = prevState;
        const { parameters, quantity } = this.state;
        const { isSelected, onSelectChange } = this.props;

        if (
            isSelected
                && (prevParams !== parameters || quantity !== prevQuantity)
        ) {
            onSelectChange(
                this.getSelectItem(),
                ON_EDIT
            );
        }
    }

    getIsSelected() {
        const { isSelected } = this.props;

        return isSelected;
    }

    getSelectItem() {
        const { product } = this.props;
        const { quantity } = this.state;

        return {
            ...product,
            quantity,
            selectOptions: this.getSelectItemOptions()
        };
    }

    getSelectItemOptions() {
        const { product: { configurable_options, type_id } } = this.props;
        const { configurableVariantIndex, parameters } = this.state;
        const options = {};

        if (type_id === CONFIGURABLE) {
            options.configurableVariantIndex = configurableVariantIndex;
        }

        if (Object.keys(parameters).length) {
            options.labels = Object.entries(parameters).map(
                ([key, value]) => {
                    const {
                        attribute_label: label,
                        attribute_options: options
                    } = configurable_options[key];

                    return `${label}: ${options[value].label}`;
                }
            );
        }

        return {
            ...options,
            parameters
        };
    }

    onAddToTotals() {
        const {
            onSelectChange,
            product: { type_id }
        } = this.props;

        if (type_id === CONFIGURABLE && !this.validateConfigurableProduct()) {
            return;
        }

        onSelectChange(
            this.getSelectItem(),
            ON_ADD
        );
    }

    onRemoveFromTotals() {
        const {
            onSelectChange,
            product
        } = this.props;

        onSelectChange(product, ON_REMOVE);
    }

    validateConfigurableProduct() {
        const {
            product: {
                variants = []
            },
            showNotification
        } = this.props;
        const { configurableVariantIndex, quantity } = this.state;

        if (configurableVariantIndex < 0 || !variants[configurableVariantIndex]) {
            this.onProductError();

            return false;
        }

        const { stock_status: configurableStock, stock_item } = variants[configurableVariantIndex];

        if (configurableStock !== IN_STOCK) {
            showNotification('info', __('Sorry! The selected product option is out of stock!'));

            return false;
        }

        const [validationStatus, message] = validateProductQuantity(quantity, stock_item);

        if (validationStatus === false) {
            showNotification('info', message);

            return false;
        }

        return true;
    }

    onProductError() {
        const { current } = this.configurableOptionsRef;

        current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });

        current.classList.remove('animate');
        // eslint-disable-next-line no-unused-expressions
        current.offsetWidth; // trigger a DOM reflow
        current.classList.add('animate');
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <CompleteOrderProductCard
                      { ...this.containerProps() }
                      { ...this.containerFunctions }
                      configurableOptionsRef={ this.configurableOptionsRef }
                      isSelected={ this.getIsSelected() }
                      registerSharedElement={ registerSharedElement }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteOrderProductCardContainer);

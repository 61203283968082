/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { formatPrice } from '@scandipwa/scandipwa/src/util/Price';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { SELECT_TYPE, TEXT_TYPE } from 'Component/Field/Field.config';

import './SurfacePriceCalculation.style';

/** @namespace Pwasaas/Component/SurfacePriceCalculation/Component/SurfacePriceCalculationComponent */
export class SurfacePriceCalculationComponent extends PureComponent {
    static propTypes = {
        getSelectOptions: PropTypes.func.isRequired,
        handleSelectedValue: PropTypes.func.isRequired,
        handleOnlyNumbersOnChange: PropTypes.func.isRequired,
        numberValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        squareMeterPerBox: PropTypes.number.isRequired,
        productPrice: PropTypes.number.isRequired,
        productPricePerUnit: PropTypes.number.isRequired,
        productCurrency: PropTypes.string.isRequired,
        orderUnit: PropTypes.string.isRequired,
        selectedValue: PropTypes.string.isRequired,
        calculatedBoxes: PropTypes.number.isRequired,
        calculatedSurface: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired
    };

    get fieldMap() {
        const {
            handleOnlyNumbersOnChange,
            numberValue,
            handleSelectedValue,
            selectedValue
        } = this.props;

        return {
            surface: {
                isControlled: true,
                label: __('Number of m²'),
                onChange: handleOnlyNumbersOnChange,
                type: TEXT_TYPE,
                value: numberValue
            },
            salesOrderUnitLoss: {
                isControlled: true,
                label: __('Cutting loss'),
                onChange: handleSelectedValue,
                selectOptions: [],
                value: selectedValue,
                type: SELECT_TYPE
            }
        };
    }

    getDefaultValues([key, props]) {
        const { getSelectOptions } = this.props;
        const {
            type = 'text',
            ...otherProps
        } = props;

        if (type === SELECT_TYPE) {
            return {
                ...otherProps,
                key,
                name: key,
                id: key,
                type,
                selectOptions: getSelectOptions()
            };
        }

        return {
            ...otherProps,
            key,
            name: key,
            id: key,
            type
        };
    }

    renderField = (fieldEntry) => {
        const {
            id = null,
            isControlled = null,
            key = null,
            label = null,
            name = null,
            onChange = () => {},
            selectOptions = null,
            type = 'text',
            value = null
        } = this.getDefaultValues(fieldEntry);

        return (
            <Field
              id={ id }
              isControlled={ isControlled }
              key={ key }
              label={ label }
              name={ name }
              onChange={ onChange }
              selectOptions={ selectOptions }
              type={ type }
              value={ value }
            />
        );
    };

    renderInfoBlock() {
        const {
            orderUnit,
            productCurrency,
            productPrice,
            productPricePerUnit,
            squareMeterPerBox
        } = this.props;

        const formattedPrice = formatPrice(productPricePerUnit, productCurrency);
        const formattedCalculationPrice = formatPrice(productPrice, productCurrency);

        return (
            <div block="SurfacePriceCalculation" elem="Info">
                { __('Price per m²: %s (Per %s %s m²)',
                    formattedPrice,
                    orderUnit.toLowerCase(),
                    squareMeterPerBox) }
                <br />
                { __('Price per %s: %s (Price per m² x content %s',
                    orderUnit.toLowerCase(),
                    formattedCalculationPrice,
                    orderUnit.toLowerCase()) }
                { ` = ${formattedPrice} x ${squareMeterPerBox} m²)` }
            </div>
        );
    }

    renderCalculatedBlock() {
        const {
            calculatedBoxes,
            calculatedSurface
        } = this.props;

        return (
            <div block="SurfacePriceCalculation" elem="Calculation">
                { `${__('Total surface:')} ${calculatedSurface} m²` }
                <br />
                { `${__('Quantity:')} ${calculatedBoxes}` }
            </div>
        );
    }

    render() {
        return (
            <div block="SurfacePriceCalculation">
                { this.renderInfoBlock() }
                <div block="SurfacePriceCalculation" elem="Input">
                    { Object.entries(this.fieldMap).map(this.renderField) }
                </div>
                { this.renderCalculatedBlock() }
            </div>
        );
    }
}

export default SurfacePriceCalculationComponent;

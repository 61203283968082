export const _getStockItemFields = (args, callback) => [
    ...callback(...args),
    'qty'
];

export default {
    'Query/ProductList': {
        'member-function': {
            _getStockItemFields
        }
    }
};

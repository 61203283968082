export class ConfigQuery {
    _getStoreConfigFields = (args, callback) => ([
        ...callback(args),
        'allow_items',
        'allow_order'
    ]);
}

const {
    _getStoreConfigFields
} = new ConfigQuery();

export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};

export default config;

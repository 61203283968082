import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MenuBanner from 'Component/MenuBanner';
import MenuButton from 'Component/MenuButton';
import MenuContent from 'Component/MenuContent';
import MenuLink from 'Component/MenuLink';
import Overlay from 'Component/Overlay';
import { MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import {
    MenuBannerType,
    MenuItemType
} from 'Type/SaasMenu';

import { MENU_OVERLAY_KEY } from './MenuRegular.config';

import './MenuRegular.style';

/** @namespace Pwasaas/Component/MenuRegular/Component/MenuRegularComponent */
export class MenuRegularComponent extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired,
        bannerItem: MenuBannerType.isRequired,
        device: DeviceType.isRequired,
        handleCloseMenu: PropTypes.func.isRequired,
        handleMouseLeave: PropTypes.func.isRequired,
        handlePrevious: PropTypes.func.isRequired,
        isWithHistory: PropTypes.bool.isRequired,
        items: PropTypes.arrayOf(
            MenuItemType
        ).isRequired,
        mix: MixType.isRequired,
        toggleItem: MenuItemType.isRequired
    };

    renderMainMenu = (item) => {
        const {
            activeMenuItem,
            device
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <div
              block="MenuRegular"
              elem="MainItem"
              key={ item.id }
            >
                <MenuLink
                  item={ item }
                  itemMods={ {
                      isLink: true,
                      isActive: activeMenuItem === item
                  } }
                  mix={ {
                      block: 'MenuRegular',
                      elem: 'Link',
                      mods: {}
                  } }
                />
            </div>
        );
    };

    renderCloseButton() {
        const { device, handleCloseMenu } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <button
              block="MenuRegular"
              elem="CloseButton"
              onClick={ handleCloseMenu }
              aria-label={ __('Close') }
            />
        );
    }

    renderPreviousButton() {
        const {
            handlePrevious,
            isWithHistory
        } = this.props;

        if (!isWithHistory) {
            return null;
        }

        return (
            <button
              block="MenuRegular"
              elem="PrevButton"
              onClick={ handlePrevious }
              aria-label={ __('Back to the menu') }
            >
                { __('Back to the menu') }
            </button>
        );
    }

    renderMainBanner() {
        const {
            bannerItem = {},
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <div
              block="MenuRegular"
              elem="Banner"
            >
                <MenuBanner
                  isMainBanner
                  item={ bannerItem }
                />
            </div>
        );
    }

    renderToggleButton() {
        const { toggleItem } = this.props;

        if (!Object.keys(toggleItem).length) {
            return null;
        }

        return (
            <MenuButton item={ toggleItem } />
        );
    }

    renderMainLevel() {
        const { items } = this.props;

        if (!items.length) {
            return null;
        }

        return (
            <div
              block="MenuRegular"
              elem="MainItemList"
            >
                { this.renderToggleButton() }
                { items.map(this.renderMainMenu) }
            </div>
        );
    }

    renderContent() {
        const {
            activeMenuItem: {
                childs = []
            },
            device
        } = this.props;

        if (!childs || !childs.length) {
            return null;
        }

        return (
            <Overlay
              id={ MENU_OVERLAY_KEY }
              mix={ {
                  block: 'MenuRegular',
                  elem: 'Overlay'
              } }
              isRenderInPortal={ false }
              isStatic={ device.isMobile }
            >
                { this.renderCloseButton() }
                { this.renderMainBanner() }
                { this.renderPreviousButton() }
                <MenuContent />
            </Overlay>
        );
    }

    render() {
        const {
            handleMouseLeave,
            mix
        } = this.props;

        return (
            <div
              block="MenuRegular"
              elem="Wrapper"
              mix={ mix }
              onMouseLeave={ handleMouseLeave }
            >
                { this.renderMainLevel() }
                { this.renderContent() }
            </div>
        );
    }
}

export default MenuRegularComponent;

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

import SalesPromotionQuery from '../query/SalesPromotion.query';

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    activePromotions: state.CheckoutReducer.activePromotions || [],
    pickupStore: state.StoreLocatorReducer?.pickupStore || null,
    shippingFields: state.CheckoutReducer.shippingFields,
    email: state.CheckoutReducer.email || ''
});

export const mapDispatchToProps = ([dispatch], callback) => ({
    ...callback(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export class CheckoutBillingContainer {
    candidateInputKeys = {
        address: 'street',
        candidate_name: 'firstname lastname',
        city: 'city',
        date_of_birth: 'date_of_birth',
        email: 'guest_email',
        phone: 'telephone',
        postcode: 'postcode'
    };

    state = (originalMember) => ({
        ...originalMember,
        promotionSubscribers: {}
    });

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        handlePromotionCheckboxChange: this.onPromotionCheckBoxChange.bind(instance)
    });

    componentDidMount = (args, callback, instance) => {
        const { activePromotions } = instance.props;

        if (activePromotions.length) {
            this.setPromotionSubscribersData(instance);
        }

        callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [prevProps] = args;
        const { activePromotions: prevPromotions } = prevProps;
        const { activePromotions } = instance.props;

        if (JSON.stringify(prevPromotions) !== JSON.stringify(activePromotions)
        ) {
            this.setPromotionSubscribersData(instance);
        }

        callback(...args);
    };

    containerProps = (args, callback, {
        state: {
            promotionSubscribers
        },
        props: {
            activePromotions
        }
    }) => ({
        ...callback(...args),
        activePromotions,
        promotionSubscribers
    });

    onBillingSuccess = (args, callback, instance) => {
        const { showNotification } = instance.props;
        const { promotionSubscribers = {} } = instance.state;
        const subscribedIds = Object.keys(promotionSubscribers).filter((id) => promotionSubscribers[id]);

        if (!subscribedIds.length) {
            callback(...args);

            return;
        }

        const fields = Object.entries(this.candidateInputKeys).reduce((acc, [key, value]) => {
            const joinedValues = value.slice().split(' ').filter((n) => n);

            if (joinedValues.length > 1) {
                const fieldValue = joinedValues.reduce(
                    (string, value) => `${string} ${ this._getFieldValue('', value, instance) || ''}`, ''
                );

                return {
                    ...acc,
                    [key]: fieldValue.trim()
                };
            }

            const fieldValue = this._getFieldValue(key, value, instance);

            if (!fieldValue) {
                return acc;
            }

            return {
                ...acc,
                [key]: Array.isArray(fieldValue)
                    ? fieldValue.join(' ')
                    : fieldValue
            };
        }, {});

        fetchMutation(SalesPromotionQuery.getMutation(this._getOtherInputFields(fields, instance), false)).then(
            () => showNotification('success', __('You successfully joined the action!'))
        ).finally(
            () => callback(...args)
        );
    };

    _getFieldValue = (key, value, instance) => {
        const {
            customer,
            shippingAddress,
            shippingFields
        } = instance.props;

        return shippingAddress[key] || shippingAddress[value]
            || customer[key] || customer[value]
            || shippingFields[key] || shippingFields[value]
            || instance.props[key];
    };

    _getOtherInputFields(fields, instance) {
        const { isChecked = false, promotionSubscribers } = instance.state;
        const { pickupStore } = instance.props;
        const subscribedIds = Object.keys(promotionSubscribers).filter((id) => promotionSubscribers[id]);

        return {
            ...fields,
            codes: [],
            is_newsletter_subscriber: isChecked,
            is_store_subscriber: false,
            promotion_ids: subscribedIds,
            store_locator_id: pickupStore
        };
    }

    onPromotionCheckBoxChange(promotionId) {
        this.setState(({ promotionSubscribers: prevSubscribers }) => ({
            promotionSubscribers: {
                ...prevSubscribers,
                [promotionId]: !prevSubscribers[promotionId]
            }
        }));
    }

    setPromotionSubscribersData(instance) {
        const { activePromotions } = instance.props;

        instance.setState({
            promotionSubscribers: activePromotions.reduce((acc, promotion) => ({
                ...acc,
                [promotion.sales_promotion_id]: false
            }), {})
        });
    }
}

const {
    componentDidMount,
    componentDidUpdate,
    containerFunctions,
    containerProps,
    onBillingSuccess,
    state
} = new CheckoutBillingContainer();

export default {
    'Component/CheckoutBilling/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            containerProps,
            onBillingSuccess
        },
        'member-property': {
            containerFunctions,
            state
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { MixType } from 'Type/Common';

import './CustomerReferenceTextArea.style';

/** @namespace Pwasaas/Component/CustomerReferenceTextArea/Component/CustomerReferenceTextAreaComponent */
export class CustomerReferenceTextAreaComponent extends PureComponent {
    static propTypes = {
        field: PropTypes.string.isRequired,
        handleSetCustomerReference: PropTypes.func.isRequired,
        mix: MixType.isRequired,
        value: PropTypes.string.isRequired
    };

    renderCustomerReferenceTextArea() {
        const {
            field,
            handleSetCustomerReference,
            value
        } = this.props;

        return (
            <Field
              id={ field }
              label={ __('Reference') }
              mix={ { block: 'CustomerReferenceTextArea', elem: 'Field' } }
              name={ field }
              onChange={ handleSetCustomerReference }
              placeholder={ __('Reference...') }
              type="textarea"
              value={ value }
            />
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <div
              block="CustomerReferenceTextArea"
              mix={ mix }
            >
                { this.renderCustomerReferenceTextArea() }
            </div>
        );
    }
}

export default CustomerReferenceTextAreaComponent;

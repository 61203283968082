import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CustomerType } from 'Type/Customer';

import MyAccountCustomerTableRow from './MyAccountCustomerTableRow.component';

/** @namespace Pwasaas/Component/MyAccountCustomerTableRow/Container/MyAccountCustomerTableRowContainer */
export class MyAccountCustomerTableRowContainer extends PureComponent {
    static propTypes = {
        customer: CustomerType.isRequired,
        onLogin: PropTypes.func
    };

    static defaultProps = {
        onLogin: () => {}
    };

    containerFunctions = {
        handleOnClick: this.handleOnClick.bind(this)
    };

    containerProps = () => {
        const {
            customer: {
                company,
                email,
                firstname,
                lastname
            }
        } = this.props;

        return {
            company,
            email,
            firstname,
            lastname
        };
    };

    handleOnClick() {
        const {
            customer: {
                email = ''
            },
            onLogin
        } = this.props;

        onLogin(email);
    }

    render() {
        return (
            <MyAccountCustomerTableRow
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default MyAccountCustomerTableRowContainer;

export class CartItemContainer {
    _getProductLinkTo = (args, callback, instance) => {
        const result = callback(...args);
        const {
            item: {
                product: {
                    grouped_product_url: groupedProductUrl
                }
            }
        } = instance.props;

        if (groupedProductUrl) {
            result.pathname = groupedProductUrl;
        }

        return result;
    };
}

const {
    _getProductLinkTo
} = new CartItemContainer();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            _getProductLinkTo
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BlogCategoryType } from 'Type/Blog';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';

import PageHeader from './PageHeader.component';

/** @namespace Pwasaas/Component/PageHeader/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    buttonTitle: state.PageHeaderReducer.buttonTitle,
    buttonUrl: state.PageHeaderReducer.buttonUrl,
    currentState: state.PageHeaderReducer.currentState,
    description: state.PageHeaderReducer.description,
    detailImage: state.PageHeaderReducer.detailImage,
    device: state.ConfigReducer.device,
    image: state.PageHeaderReducer.image,
    isVisible: state.PageHeaderReducer.isVisible,
    name: state.PageHeaderReducer.name,
    widget: state.PageHeaderReducer.widget
});

/** @namespace Pwasaas/Component/PageHeader/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/PageHeader/Container/PageHeaderContainer */
export class PageHeaderContainer extends PureComponent {
    static propTypes = {
        buttonTitle: PropTypes.string.isRequired,
        buttonUrl: PropTypes.string.isRequired,
        currentState: PropTypes.shape({}).isRequired,
        description: PropTypes.string.isRequired,
        detailImage: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        image: PropTypes.string.isRequired,
        isVisible: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        widget: PropTypes.shape({
            item: PropTypes.oneOfType([
                BlogCategoryType,
                ProductType
            ]),
            type: PropTypes.string
        }).isRequired
    };

    containerProps = () => {
        const {
            buttonTitle,
            buttonUrl,
            currentState,
            description,
            detailImage,
            device,
            image,
            isVisible,
            name,
            widget
        } = this.props;

        return {
            buttonTitle,
            buttonUrl,
            currentState,
            description,
            detailImage,
            device,
            image,
            isVisible,
            name,
            widget
        };
    };

    render() {
        return (
            <PageHeader
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeaderContainer);

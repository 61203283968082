/** @namespace Pwasaas/Plugin/RemoveFilterOptions/Config/RemoveFilterOptionsConfig */
export class RemoveFilterWhenOptionsAreLessThanTwoPlugin {
    removeFilterContentFrom = (args, callback, instance) => {
        const [{ attribute_options = {} }] = args;

        if (Object.keys(attribute_options).length < 2) {
            return null;
        }

        return callback.apply(instance, args);
    };
}

const {
    removeFilterContentFrom
} = new RemoveFilterWhenOptionsAreLessThanTwoPlugin();

export const config = {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderDropdownOrSwatch: removeFilterContentFrom
        }
    }
};

export default config;

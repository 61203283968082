import { MAX_ZIPCODE_LENGTH } from 'Component/StoreLocator/StoreLocator.config';

import { POSTCODE } from '../../SalesPromotion/component/SalesPromotionFormWidget/SalesPromotionFormWidget.config';

export const STORE_LOCATOR_ID = 'store_locator_id';
export const IS_STORE_SUBSCRIBER = 'is_store_subscriber';

export const StoreLocatorDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/StoreLocator/StoreLocator.dispatcher'
);

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        storeSelectList: state.StoreLocatorReducer.storeSelectList
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        fetchStores: () => StoreLocatorDispatcher.then(
            ({ default: dispatcher }) => dispatcher.requestStoreLocators(dispatch)
        ),
        requestLocation: (postcode) => StoreLocatorDispatcher.then(
            ({ default: dispatcher }) => dispatcher.requestLocation({ postcode }, dispatch)
        )
    };
};
export class SalesPromotionFormWidgetContainer {
    componentDidMount = (args, callback, instance) => {
        const {
            fetchStores,
            storeSelectList
        } = instance.props;

        if (storeSelectList.length) {
            return callback(...args);
        }

        return fetchStores().then(
            () => callback(...args)
        );
    };

    fieldMap = (originalMap, instance) => ({
        ...originalMap,
        [POSTCODE]: {
            ...originalMap[POSTCODE],
            onChange: this.onPostcodeChange.bind(instance)
        },
        [STORE_LOCATOR_ID]: {
            type: 'select',
            label: __('Store'),
            position: 620,
            validation: ['notEmpty'],
            validateSeparately: false,
            value: -1,
            optionsHandler: this.getStoreSelectList.bind(instance)
        },
        [IS_STORE_SUBSCRIBER]: {
            type: 'checkbox',
            label: __('Prefer to receive local news from a store near you? Sign up and stay informed'),
            position: 650,
            value: IS_STORE_SUBSCRIBER
        }
    });

    getStoreSelectList() {
        const { storeSelectList } = this.props;

        return storeSelectList;
    }

    onPostcodeChange(value) {
        const { requestLocation } = this.props;
        const postcode = value.replace(/ /g, '');

        if (postcode.length === MAX_ZIPCODE_LENGTH) {
            requestLocation(postcode);
        }
    }
}

const {
    componentDidMount,
    fieldMap
} = new SalesPromotionFormWidgetContainer();

export default {
    'Pwasaas/Plugin/SalesPromotion/Component/SalesPromotionFormWidget/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Plugin/SalesPromotion/Component/SalesPromotionFormWidget/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Pwasaas/Plugin/SalesPromotion/Component/SalesPromotionFormWidget/Container/SalesPromotionFormWidgetContainer': {
        'member-function': {
            componentDidMount
        },
        'member-property': {
            fieldMap
        }
    }
};

import {
    containerProps,
    mapStateToProps
} from '../util/HidePrice';

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    }
};

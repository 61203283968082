/**
 * Remove renderWhenVisible wrapper when A layout group is used to show other widgets
 */
import LayoutGroupPropType from '../util/LayoutGroupPropType';

export class WidgetFactoryComponent extends LayoutGroupPropType {
    renderWidget = (args, callback, instance) => {
        const { isNotLayoutGroup } = instance.props;
        const result = callback(...args);

        if (!isNotLayoutGroup) {
            return result;
        }

        const { props: { children: Widget } } = result;

        return Widget;
    };
}

const {
    defaultProps,
    staticPropTypes,
    renderWidget
} = new WidgetFactoryComponent();

export default {
    'Pwasaas/Component/WidgetFactory/Component/WidgetFactoryComponent': {
        'member-function': {
            renderWidget
        },
        'static-member': {
            defaultProps,
            staticPropTypes
        }
    }
};

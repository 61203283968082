import PropTypes from 'prop-types';

export const KiyohWidgetType = PropTypes.shape({
    avgRating: PropTypes.number,
    avgRatingYear: PropTypes.number,
    id: PropTypes.number,
    locationName: PropTypes.string,
    recommendation: PropTypes.number,
    storeId: PropTypes.number,
    totalReviews: PropTypes.number,
    totalReviewsYear: PropTypes.number,
    totalScore: PropTypes.number,
    totalViews: PropTypes.number
});

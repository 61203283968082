import {
    removeActionsFrom,
    removePriceFrom
} from '../util/HidePrice';

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderProductPrice: removePriceFrom
        }
    },
    'Pwasaas/Component/ProductCard/Component/ProductCardComponent': {
        'member-function': {
            renderAddToWrapper: removeActionsFrom
        }
    }
};

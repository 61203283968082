export const removeCategoryFilterAttributeBefore = (args, callback, instance) => {
    const [{ products }, dispatch, options] = args;
    const { filters } = products;

    products.filters = filters.filter(({ request_var }) => request_var !== 'category_id');

    return callback.apply(instance, [{ products }, dispatch, options]);
};

export default {
    'Store/ProductListInfo/Dispatcher': {
        'member-function': {
            onSuccess: removeCategoryFilterAttributeBefore
        }
    }
};

import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { updatePageHeaderContent } from 'Store/PageHeader/PageHeader.action';
import { getStore } from 'Util/Store';

export const PageHeaderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/PageHeader/PageHeader.dispatcher'
);

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isPageHeaderVisible: state.PageHeaderReducer.isVisible
});

export const mapDispatchToProps = ([dispatch], callback) => ({
    ...callback(dispatch),
    clearPageHeader: () => dispatch(updatePageHeaderContent()),
    requestProduct: (sku) => PageHeaderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestProduct(sku, dispatch)
    ),
    updatePageHeader: (data) => dispatch(updatePageHeaderContent(data))
});

export class CategoryPageContainer {
    componentDidMount = (args, callback, instance) => {
        const { isSearchPage = false } = instance.props;
        const result = callback(...args);

        if (!isSearchPage) {
            this._updateCategoryBanner(instance);
        }

        return result;
    };

    componentDidUpdate = (args, callback, instance) => {
        const result = callback.apply(instance, args);
        const { clearPageHeader, isSearchPage = false } = instance.props;

        if (!instance.isCurrentCategoryLoaded() || isSearchPage) {
            clearPageHeader({});

            return result;
        }

        const [{ category: { id: prevId } }] = args;
        const { category: { id } } = instance.props;

        if (id !== prevId) {
            this._updateCategoryBanner(instance);
        }

        return result;
    };

    componentWillUnmount = (args, callback, instance) => {
        const { clearPageHeader = () => {} } = instance.props;

        clearPageHeader({});

        return callback(...args);
    };

    _updateCategoryBanner(instance) {
        const {
            category: {
                id,
                banner_group,
                name
            },
            clearPageHeader,
            updatePageHeader,
            requestProduct
        } = instance.props;

        if (!banner_group || !id) {
            clearPageHeader({});

            return;
        }

        const { group_id, items = [] } = banner_group;

        if (!group_id || !items.length) {
            clearPageHeader({});

            return;
        }

        const [{
            button_title: buttonTitle,
            button_url: buttonUrl,
            content: description,
            content_title,
            detail_image: detailImage,
            image,
            product_sku: productSku
        }] = items;

        const {
            NavigationReducer: {
                [TOP_NAVIGATION_TYPE]: {
                    navigationState
                }
            }
        } = getStore().getState();

        updatePageHeader({
            buttonTitle,
            buttonUrl,
            currentState: navigationState,
            description,
            detailImage,
            name: content_title || name,
            image,
            isVisible: true
        });

        if (!productSku) {
            return;
        }

        requestProduct(productSku);
    }
}

const {
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount
} = new CategoryPageContainer();

export default {
    'Route/SearchPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/CategoryPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            componentWillUnmount
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            componentWillUnmount
        }
    }
};

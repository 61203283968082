import {
    mapStateToProps,
    ProductConfigSetQuantity
} from '../util/ProductConfig';

export class ProductActionsContainer extends ProductConfigSetQuantity {}

const {
    containerProps,
    containerFunctions,
    state
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions,
            state
        }
    }
};

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutSuccess from './CheckoutSuccess.component';

/** @namespace Pwasaas/Component/CheckoutSuccess/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Pwasaas/Component/CheckoutSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/CheckoutSuccess/Container/CheckoutSuccessContainer */
export class CheckoutSuccessContainer extends PureComponent {
    render() {
        return (
            <CheckoutSuccess
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessContainer);

export class StoreQuery {
    _getSaasConfigFields = (args, callback) => ([
        ...callback(args),
        'show_product_stock_status',
        'show_product_stock_status_only_in_stock',
        'show_product_stock_label',
        'show_product_stock_icon',
        'backorders'
    ]);
}

const {
    _getSaasConfigFields
} = new StoreQuery();

export default {
    'Pwasaas/Query/Store/Query/StoreQuery': {
        'member-function': {
            _getSaasConfigFields
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MENU_TYPE_ADVANCED } from 'Component/MenuAdvanced/MenuAdvanced.config';
import { TARGET_BLANK } from 'Component/MenuLink/MenuLink.config';
import { MENU_TYPE_REGULAR } from 'Component/MenuRegular/MenuRegular.config';
import { updateActiveMenuItem } from 'Store/SaasMenu/SaasMenu.action';
import { DeviceType } from 'Type/Device';
import { MenuItemModsType, MenuItemType } from 'Type/SaasMenu';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import MenuColumn from './MenuColumn.component';

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

/** @namespace Pwasaas/Component/MenuColumn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    config: state.SaasMenuReducer.header.config || {},
    device: state.ConfigReducer.device
});

/** @namespace Pwasaas/Component/MenuColumn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeMenu(dispatch)
    ),
    updateActiveMenuItem: (item) => dispatch(updateActiveMenuItem(item))
});

/** @namespace Pwasaas/Component/MenuColumn/Container/MenuColumnContainer */
export class MenuColumnContainer extends PureComponent {
    static propTypes = {
        closeMenu: PropTypes.func.isRequired,
        config: PropTypes.shape({
            menu_type: PropTypes.string
        }).isRequired,
        device: DeviceType.isRequired,
        item: MenuItemType.isRequired,
        itemMods: MenuItemModsType,
        updateActiveMenuItem: PropTypes.func.isRequired
    };

    static defaultProps = {
        itemMods: {
            isActive: false,
            isLink: false,
            isTitle: false
        }
    };

    state = {
        isActive: false
    };

    containerFunctions = {
        handleItemToggle: this.onItemToggle.bind(this),
        handleLinkClick: this.onLinkClick.bind(this)
    };

    containerProps = () => {
        const {
            config: {
                menu_type = ''
            },
            device,
            item,
            itemMods
        } = this.props;

        const { isActive } = this.state;

        return {
            device,
            isActive,
            isWithButton: menu_type === MENU_TYPE_ADVANCED,
            item,
            itemMods
        };
    };

    onLinkClick() {
        const {
            closeMenu,
            device,
            item: {
                link_target,
                link_type
            }
        } = this.props;

        /** Do nothing when main item is an external link (mobile fix) */
        if (
            device.isMobile
                && link_target === '_blank'
                && link_type === 'custom-link'
        ) {
            return;
        }

        closeMenu();
    }

    onItemToggle() {
        const {
            closeMenu,
            config: {
                menu_type = ''
            },
            device,
            item,
            item: {
                childs,
                link,
                link_target: target,
                parent
            },
            updateActiveMenuItem
        } = this.props;

        if (!device.isMobile) {
            this.onLinkClick();

            return;
        }

        if (!childs || !childs.length) {
            if (target === TARGET_BLANK) {
                window.open(link, '_blank').focus();

                return;
            }

            history.push(appendWithStoreCode(link));

            closeMenu();
        }

        if (
            (childs && childs.length)
                && parent === 0
                && menu_type === MENU_TYPE_REGULAR
        ) {
            updateActiveMenuItem(item);

            return;
        }

        const { isActive } = this.state;

        this.setState({ isActive: !isActive });
    }

    render() {
        return (
            <MenuColumn
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuColumnContainer);

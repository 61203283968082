import CmsBlockQuery from 'Query/CmsBlock.query';
import { fetchQuery } from 'Util/Request';

export const PRODUCT_BANNER = 'product-page-banner';

export class ProductSectionNavigationContainer {
    state = (originalMember) => ({
        ...originalMember,
        productBanner: ''
    });

    componentDidMount = (args, callback, instance) => {
        this.setProductBanner(instance);

        callback(...args);
    };

    containerProps = (args, callback, instance) => {
        const { productBanner } = instance.state;

        return {
            ...callback(...args),
            productBanner
        };
    };

    setProductBanner(instance) {
        fetchQuery(CmsBlockQuery.getQuery({ identifiers: [PRODUCT_BANNER] })).then(
            (result) => {
                if (!result) {
                    return;
                }

                const { cmsBlocks: { items = [] } } = result;
                const { content = '', disabled: isDisabled } = items.length ? items[0] : {};

                instance.setState({
                    productBanner: !isDisabled ? content : ''
                });
            }
        );
    }
}

const {
    componentDidMount,
    containerProps,
    state
} = new ProductSectionNavigationContainer();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Container/ProductSectionNavigationContainer': {
        'member-function': {
            componentDidMount,
            containerProps
        },
        'member-property': {
            state
        }
    }
};

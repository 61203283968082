/**
 * Add isShowQtyInMagazine from category
 */
export const mapStateToProps = (args, callback) => {
    const [state] = args;

    const { show_qty_in_magazine: isShowQtyInMagazine = false } = state.CategoryReducer?.category || {};

    return {
        ...callback(state),
        isShowQtyInMagazine
    };
};

/**
 * Hide add to wrapper if magazine category
 */
export const renderAddToWrapper = (args, callback, instance) => {
    const { isShowQtyInMagazine = false } = instance.props;

    if (isShowQtyInMagazine) {
        return null;
    }

    return callback(instance, args);
};

/**
 * Add quantity label if magazine category
 */
export const renderCardContent = (args, callback, instance) => {
    const {
        isShowQtyInMagazine = false,
        quantity,
        product: {
            attributes: {
                qty_in_magazine: {
                    attribute_value: qtyInMagazine = quantity
                } = {}
            } = {}
        } = {}
    } = instance.props;

    const RenderedComponent = callback(instance, args);

    if (!isShowQtyInMagazine || !qtyInMagazine) {
        return RenderedComponent;
    }

    return (
        <>
            <div
              block="ProductCard"
              elem="MagazineQty"
            >
                <span>{ qtyInMagazine }</span>
            </div>
            { RenderedComponent }
        </>
    );
};

export default {
    'Component/ProductCard/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/ProductCard/Component/ProductCardComponent': {
        'member-function': {
            renderAddToWrapper,
            renderCardContent
        }
    }
};

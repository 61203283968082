import PropTypes from 'prop-types';

import {
    SiteUspItemsType,
    StoreHoursType,
    StoreSocialType
} from 'Type/Store';

export const StoreCmsPageTypeNode = {
    identifier: PropTypes.string,
    urlKey: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    contentHeading: PropTypes.string,
    pageLayout: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string
};

export const StoreInfoTypeNode = {
    accountManager: PropTypes.string,
    address: PropTypes.string,
    cmsPageId: PropTypes.number,
    contactPerson: PropTypes.string,
    content: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    lat: PropTypes.string,
    long: PropTypes.string,
    openingHours: StoreHoursType,
    postcode: PropTypes.string,
    place: PropTypes.string,
    relationCode: PropTypes.string,
    usp: SiteUspItemsType,
    social: StoreSocialType,
    storeName: PropTypes.string,
    telephone: PropTypes.string,
    thumbnail: PropTypes.string,
    websiteUrl: PropTypes.string
};

export const StoreInfoAddress = {
    address: StoreInfoTypeNode.address,
    email: StoreInfoTypeNode.email,
    postcode: StoreInfoTypeNode.postcode,
    place: StoreInfoTypeNode.place,
    telephone: StoreInfoTypeNode.telephone,
    websiteUrl: StoreInfoTypeNode.websiteUrl
};

StoreCmsPageTypeNode.storeInfo = PropTypes.shape(StoreInfoTypeNode);

export const StoreCmsPageType = PropTypes.shape(StoreCmsPageTypeNode);

StoreInfoTypeNode.cmsPage = StoreCmsPageType;

export const StoreInfoType = PropTypes.shape(StoreInfoTypeNode);

export const StoreListType = PropTypes.arrayOf(StoreInfoType);

export const LocationType = PropTypes.shape({
    lat: PropTypes.string,
    long: PropTypes.string
});

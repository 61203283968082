import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateCheckoutCustomFields } from 'Store/CheckoutCustomFields/CheckoutCustomFields.action';
import { MixType } from 'Type/Common';

import CustomerReferenceTextArea from './CustomerReferenceTextArea.component';

/** @namespace Pwasaas/Component/CustomerReferenceTextArea/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCheckoutCustomFields: (field, value) => dispatch(updateCheckoutCustomFields(field, value))
});

/** @namespace Pwasaas/Component/CustomerReferenceTextArea/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwasaas/Component/CustomerReferenceTextArea/Container/CustomerReferenceTextAreaContainer */
export class CustomerReferenceTextAreaContainer extends PureComponent {
    static propTypes = {
        mix: MixType,
        field: PropTypes.string,
        updateCheckoutCustomFields: PropTypes.func.isRequired,
        value: PropTypes.string
    };

    static defaultProps = {
        field: '',
        mix: {},
        value: ''
    };

    containerFunctions = {
        handleSetCustomerReference: this.handleSetCustomerReference.bind(this)
    };

    containerProps = () => {
        const {
            field,
            mix,
            value
        } = this.props;

        return {
            field,
            mix,
            value
        };
    };

    handleSetCustomerReference(value) {
        const {
            field,
            updateCheckoutCustomFields
        } = this.props;

        updateCheckoutCustomFields(field, value);
    }

    render() {
        return (
            <CustomerReferenceTextArea
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReferenceTextAreaContainer);

import OrderDateSelect from 'Component/OrderDateSelect';
import { showNotification } from 'Store/Notification/Notification.action';
import OrderDateReducer from 'Store/OrderDate/OrderDate.reducer';
import getStore from 'Util/Store';

export const OrderDateDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/OrderDate/OrderDate.dispatcher'
);

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    OrderDateReducer
});

export const addSelectComponentBefore = (args, callback = () => {}, instance) => {
    const RenderedComponent = callback(instance, args);
    const { props: { className } } = RenderedComponent;

    const { getState } = getStore();
    const { SaasConfigReducer: { config: { orderdate_enabled } } } = getState();

    if (!orderdate_enabled) {
        return RenderedComponent;
    }

    const block = className ? className.split('-', 1)[0] : '';

    const mix = {
        block,
        elem: 'DeliveryDateSelect',
        mods: {}
    };

    const { selectedShippingMethod: { carrier_code, method_code } } = instance.props;

    const selectedShippingMethodCode = `${carrier_code }_${ method_code}`;

    return (
        <>
            { /* TODO: Is 'OrderDateSelect' naming correct? Maybe 'DeliveryDateSelect' would be better */ }
            <OrderDateSelect
              mix={ mix }
              selectedShippingMethodCode={ selectedShippingMethodCode }
            />
            { RenderedComponent }
        </>
    );
};

export const saveDeliveryDateBefore = async (args, callback, instance) => {
    await OrderDateDispatcher.then(
        ({ default: dispatcher }) => {
            const { dispatch, getState } = getStore();
            const { OrderDateReducer: { selectedDate } } = getState();

            return dispatcher.saveDeliveryDate(selectedDate).then(
                /** @namespace Pwasaas/Plugin/Feature/StoreLocator/StoreLocatorPluginStore/savePickupStore/then */
                () => callback.apply(instance, args),
                /** @namespace Pwasaas/Plugin/Feature/StoreLocator/StoreLocatorPluginStore/savePickupStore/then */
                (error) => dispatch(showNotification('error', 'Error saving delivery date!', error))
            );
        }
    );
};

export const config = {
    'Store/Index/getReducers': {
        function: getStaticReducers
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: saveDeliveryDateBefore
        }
    },
    'Component/CheckoutDeliveryOptions/Component': {
        'member-function': {
            render: addSelectComponentBefore
        }
    }
};

export default config;

import {
    removeActionsFrom,
    removePriceFrom
} from '../util/HidePrice';

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderPriceWithGlobalSchema: removePriceFrom
        }
    },
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-function': {
            renderAddToCartAndQuantity: removeActionsFrom
        }
    }
};

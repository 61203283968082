export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isReviewEnabled: state.SaasConfigReducer.config?.product_reviews_enabled || false
    };
};

export class ProductSectionNavigationContainer {
    containerProps = (args, callback, instance) => {
        const { isReviewEnabled } = instance.props;

        return {
            ...callback(...args),
            isReviewEnabled
        };
    };
}

const {
    containerProps
} = new ProductSectionNavigationContainer();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/ProductSectionNavigation/Container/ProductSectionNavigationContainer': {
        'member-function': {
            containerProps
        }
    }
};

/* eslint-disable max-len */
import { lazy } from 'react';

import {
    STORE_DETAIL_ADDRESS,
    STORE_DETAIL_CONTENT,
    STORE_DETAIL_GOOGLE_MAPS,
    STORE_DETAIL_IMAGE,
    STORE_DETAIL_OPENING_HOURS,
    STORE_DETAIL_SOCIAL,
    STORE_DETAIL_THUMBNAIL,
    STORE_DETAIL_USP
} from 'Component/StoreDetailWidgetFactory/StoreDetailWidgetFactory.config';

export const StoreDetailContent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailContent'));
export const StoreDetailAddress = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailAddress'));
export const StoreDetailGoogleMaps = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailGoogleMaps'));
export const StoreDetailImage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailImage'));
export const StoreDetailOpeningHours = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailOpeningHours'));
export const StoreDetailSocial = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailSocial'));
export const StoreDetailThumbnail = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailThumbnail'));
export const StoreDetailUsp = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/StoreDetailUsp'));

/** @namespace Pwasaas/Util/Widget/StoreDetailWidget/getStoreDetailWidgetRenderMap */
export const getStoreDetailWidgetRenderMap = () => ({
    [STORE_DETAIL_ADDRESS]: {
        component: StoreDetailAddress,
        props: ['address', 'email', 'postcode', 'place', 'telephone', 'websiteUrl']
    },
    [STORE_DETAIL_CONTENT]: {
        component: StoreDetailContent,
        props: ['content']
    },
    [STORE_DETAIL_GOOGLE_MAPS]: {
        component: StoreDetailGoogleMaps,
        props: ['lat', 'long', 'storeInfoId', 'storeName']
    },
    [STORE_DETAIL_IMAGE]: {
        component: StoreDetailImage,
        props: ['image', 'storeInfoId', 'storeName']
    },
    [STORE_DETAIL_OPENING_HOURS]: {
        component: StoreDetailOpeningHours,
        props: ['openingHours']
    },
    [STORE_DETAIL_SOCIAL]: {
        component: StoreDetailSocial,
        props: ['social']
    },
    [STORE_DETAIL_THUMBNAIL]: {
        component: StoreDetailThumbnail,
        props: ['thumbnail']
    },
    [STORE_DETAIL_USP]: {
        component: StoreDetailUsp,
        props: ['storeName', 'usp']
    }
});

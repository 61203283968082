import { Field } from '@scandipwa/scandipwa/src/util/Query';

import MyAdditionalEmails from '../query/MyAdditionalEmails.query';

export class MyAccountQuery {
    _getCustomerFields = (args, callback) => [
        ...callback(...args),
        this._getAdditionalEmailsField()
    ];

    _getAdditionalEmailsField() {
        return new Field('additional_emails')
            .addFieldList(MyAdditionalEmails.getAdditionalEmailFieldList());
    }
}

const {
    _getCustomerFields
} = new MyAccountQuery();

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields
        }
    }
};

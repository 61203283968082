/* eslint-disable max-lines */
import { cloneElement } from 'react';

import Field from 'Component/Field';
import Loader from 'Component/Loader';
import {
    ADDITION,
    CITY,
    HOUSENUMBER,
    POSTCODE,
    STREET
} from 'Component/SaasCheckoutForm/SaasCheckoutForm.config';

import '../style/SaasCheckoutForm.manual.style';

export class SaasCheckoutFormComponent {
    componentDidMount = (args, callback, instance) => {
        const {
            default_country,
            isPostcodeCheckEnabled,
            onForceDisablePostcodeCheck,
            onInitialDataLoad
        } = instance.props;

        if (isPostcodeCheckEnabled) {
            if (default_country !== 'NL') {
                onForceDisablePostcodeCheck(true);
            }

            const postcode = instance.fieldMap[POSTCODE]?.value || '';
            const houseNumber = instance.fieldMap[HOUSENUMBER]?.value || '';

            if (postcode.length && houseNumber.length) {
                onInitialDataLoad({
                    [POSTCODE]: postcode,
                    [HOUSENUMBER]: houseNumber
                });
            }
        }

        callback(...args);
    };

    renderField = (args, callback, instance) => {
        const { isForcedDisablePostcodeCheck, isPostcodeCheckEnabled } = instance.props;

        if (!isPostcodeCheckEnabled || isForcedDisablePostcodeCheck) {
            return callback(...args);
        }

        const {
            name,
            selectOptions: defaultOptions = [],
            value: defaultValue,
            ...defaultProps
        } = instance.getDefaultValues(args[0]);

        if (defaultProps.type === 'select') {
            defaultProps.selectOptions = this.getSelectOptions(name, defaultOptions, instance);
        }

        const { onChangeHandler } = instance.props.customFieldsOptionMap[name] || {};

        if (typeof instance.props[onChangeHandler] === 'function') {
            defaultProps.onChange = (
                inputValue
            ) => instance.props[onChangeHandler].apply(null, [name, inputValue]);
        }

        return (
            <Field
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...defaultProps }
              key={ defaultProps.key }
              name={ name }
              value={ this.getFieldValue(name, instance, defaultValue) }
            />
        );
    };

    getFieldValue = (
        fieldName,
        instance,
        defaultValue = ''
    ) => {
        const {
            postcodeInfo: {
                city = '',
                houseNumberAdditions = [],
                street = ''
            } = {}
        } = instance.props;

        switch (fieldName) {
        case CITY:
            return city;
        case STREET:
            return street;
        case ADDITION:
            return houseNumberAdditions[0] || instance.props[fieldName] || '';
        default:
            return instance.props[fieldName] || defaultValue || '';
        }
    };

    getSelectOptions = (
        fieldName,
        defaultOptions,
        instance
    ) => {
        const {
            postcodeInfo: {
                houseNumberAdditions = []
            }
        } = instance.props;

        switch (fieldName) {
        case ADDITION:
            if (houseNumberAdditions.filter((n) => !n).length === houseNumberAdditions.length) {
                return [];
            }

            return houseNumberAdditions.map((addition) => ({
                value: addition,
                id: addition || 0,
                label: addition || '--'
            }));
        default:
            return defaultOptions;
        }
    };

    onChange = (args, callback, instance) => {
        const { onPostcodeChange, isPostcodeCheckEnabled } = instance.props;
        const [key, value] = args;

        if (isPostcodeCheckEnabled && key === POSTCODE) {
            onPostcodeChange(key, value);
        }

        callback(...args);
    };

    onCountryChange = (args, callback, instance) => {
        const [countryId] = args;
        const {
            isPostcodeCheckEnabled,
            onForceDisablePostcodeCheck
        } = instance.props;

        if (isPostcodeCheckEnabled) {
            if (countryId === 'NL') {
                onForceDisablePostcodeCheck(false);
            } else {
                onForceDisablePostcodeCheck(true);
            }
        }

        return callback(...args);
    };

    renderFields = (args, callback, instance) => {
        const { isLoading, isPostcodeCheckEnabled } = instance.props;
        const result = callback(...args);

        if (!isPostcodeCheckEnabled) {
            return result;
        }

        return (
            <>
                <Loader isLoading={ isLoading } />
                { result }
            </>
        );
    };

    renderAddress(instance) {
        const {
            isCustomEdit,
            onCustomEdit,
            postcodeInfo
        } = instance.props;

        if (Object.keys(postcodeInfo).length === 0) {
            return null;
        }

        if (isCustomEdit) {
            return null;
        }

        return (
            <ul
              block="SaasCheckoutForm"
              elem="AddressInfo"
            >
                <li>
                    <strong>{ __('Delivery address') }</strong>
                    <div
                      block="SaasCheckoutForm"
                      elem="CustomEditButton"
                      role="button"
                      onClick={ onCustomEdit }
                      onKeyDown={ null }
                      tabIndex="0"
                    >
                        { __('Edit') }
                    </div>
                </li>
                <li>
                    { postcodeInfo.street }
                    &nbsp;
                    { postcodeInfo.houseNumber }
                    &nbsp;
                    { instance.props[ADDITION] || this.getFieldValue(ADDITION, instance) }
                </li>
                <li>{ `${postcodeInfo.postcode} ${postcodeInfo.city}` }</li>
            </ul>
        );
    }

    render = (args, callback, instance) => {
        const { isForcedDisablePostcodeCheck, isPostcodeCheckEnabled } = instance.props;
        const result = callback(...args);

        if (!isPostcodeCheckEnabled || isForcedDisablePostcodeCheck) {
            return result;
        }

        const { props, props: { children } } = result;
        const addressResult = this.renderAddress(instance);

        return cloneElement(result, {
            ...props,
            children: !Array.isArray(children)
                ? [children, addressResult]
                : [...children, addressResult]
        });
    };
}

const {
    componentDidMount,
    onChange,
    onCountryChange,
    render,
    renderField,
    renderFields
} = new SaasCheckoutFormComponent();

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            componentDidMount,
            onChange,
            onCountryChange,
            render,
            renderField,
            renderFields
        }
    }
};

import BlogReducer from 'Store/Blog/Blog.reducer';
import KiyohReducer from 'Store/Kiyoh/Kiyoh.reducer';
import OrderListReducer from 'Store/OrderList/OrderList.reducer';
import PageHeaderReducer from 'Store/PageHeader/PageHeader.reducer';
import SaasConfigReducer from 'Store/SaasConfig/SaasConfig.reducer';
import SaasMenuReducer from 'Store/SaasMenu/SaasMenu.reducer';

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    BlogReducer,
    KiyohReducer,
    OrderListReducer,
    PageHeaderReducer,
    SaasConfigReducer,
    SaasMenuReducer
});

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

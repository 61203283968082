import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import LinkedProductsReducer, {
    RELATED as PRODUCT_RELATED,
    UPSELL as PRODUCT_UPSELL
} from 'Store/LinkedProducts/LinkedProducts.reducer';
import { ProductType } from 'Type/ProductList';
import { withReducers } from 'Util/DynamicReducer';

import ProductSectionNavigation from './ProductSectionNavigation.component';

/** @namespace Pwasaas/Component/ProductSectionNavigation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    linkedProducts: state.LinkedProductsReducer.linkedProducts
});

/** @namespace Pwasaas/Component/ProductSectionNavigation/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/ProductSectionNavigation/Container/ProductSectionNavigationContainer */
export class ProductSectionNavigationContainer extends PureComponent {
    static propTypes = {
        dataSource: ProductType.isRequired,
        isAttributesTabEmpty: PropTypes.bool.isRequired,
        isDetailsLoaded: PropTypes.bool.isRequired,
        linkedProducts: PropTypes.objectOf(ProductType).isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired
    };

    containerFunctions = {
        getFilteredNavigation: this.getFilteredNavigation.bind(this),
        getFilteredSections: this.getFilteredSections.bind(this)
    };

    linkTypes = [
        PRODUCT_RELATED,
        PRODUCT_UPSELL
    ];

    containerProps = () => {
        const {
            dataSource,
            isAttributesTabEmpty,
            isDetailsLoaded,
            parameters
        } = this.props;

        return {
            dataSource,
            isAttributesTabEmpty,
            isDetailsLoaded,
            hasLinkedProducts: this._getHasLinkedProducts(),
            parameters
        };
    };

    _getHasLinkedProducts() {
        const { linkedProducts } = this.props;

        return {
            [PRODUCT_RELATED]: linkedProducts[PRODUCT_RELATED].total_count > 0,
            [PRODUCT_UPSELL]: linkedProducts[PRODUCT_UPSELL].total_count > 0
        };
    }

    getFilteredNavigation(sectionMap) {
        const hasLinkedProducts = this._getHasLinkedProducts();

        return Object.keys(sectionMap).reduce(
            (sections, key) => {
                if (!this.linkTypes.includes(key) || hasLinkedProducts[key]) {
                    sections.push(sectionMap[key]);
                }

                return sections;
            }, []
        );
    }

    getFilteredSections(sectionMap) {
        return this.getFilteredNavigation(sectionMap).map(
            ({ name, render, type }) => render(name, type)
        );
    }

    render() {
        return (
            <ProductSectionNavigation
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    LinkedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(ProductSectionNavigationContainer));

import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { cloneElement } from 'react';

import CustomerReferenceTextArea from 'Component/CustomerReferenceTextArea';
import CheckoutCustomFieldsReducer from 'Store/CheckoutCustomFields/CheckoutCustomFields.reducer';
import getStore from 'Util/Store';

export const CheckoutCustomFieldsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CheckoutCustomFields/CheckoutCustomFields.dispatcher'
);

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    CheckoutCustomFieldsReducer
});

export const addCheckoutCustomFieldsBefore = (args, callback = () => {}, instance) => {
    const RenderedComponent = callback(instance, args);
    const { getState } = getStore();
    const { SaasConfigReducer: { config: { customer_reference_enabled_in_checkout } } } = getState();
    const { props, props: { className, children: baseChildren } } = RenderedComponent;

    if (!customer_reference_enabled_in_checkout) {
        return RenderedComponent;
    }

    const { CheckoutCustomFieldsReducer: { value = '' } } = getState();

    const block = className ? className.split('-', 1)[0] : '';
    const mix = {
        block,
        elem: 'CustomerReference',
        mods: {}
    };

    const children = [
        ...baseChildren,
        <CustomerReferenceTextArea field="customer_reference" mix={ mix } value={ value } />
    ];

    return cloneElement(RenderedComponent, {
        ...props,
        children
    });
};

export const saveCheckoutCustomFields = async (args, callback, instance) => {
    const { getState } = getStore();
    const { SaasConfigReducer: { config: { customer_reference_enabled_in_checkout } } } = getState();

    await CheckoutCustomFieldsDispatcher.then(
        ({ default: dispatcher }) => {
            if (!customer_reference_enabled_in_checkout) {
                return callback.apply(instance, args);
            }

            const { dispatch, getState } = getStore();
            const { CheckoutCustomFieldsReducer: { field = '', value = '' } } = getState();

            return dispatcher.saveCheckoutCustomFields(field, value).then(
                () => callback.apply(instance, args),
                (error) => dispatch(showNotification('error', `Error saving custom field ${field} !`, error))
            );
        }
    );
};

export const config = {
    'Store/Index/getReducers': {
        function: getStaticReducers
    },
    'Component/CheckoutDeliveryOptions/Component': {
        'member-function': {
            render: addCheckoutCustomFieldsBefore
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: saveCheckoutCustomFields
        }
    }
};

export default config;

export const DEFAULT_DELIVER_DAY_COUNT = 1;
export const DEFAULT_DELIVERY_TIME_IN_MINUTES = 0;
export const DEFAULT_PREPARATION_TIME_IN_MINUTES = 0;
export const DEFAULT_TIME_INTERVAL_IN_MINUTES = 15;
export const MASTER_ACCOUNT = 'master_account';
export const LOCAL_STORE_SAAS_CONFIG = 'saas_config';
export const WEEK_DAYS = {
    sunday: __('Sunday'),
    monday: __('Monday'),
    tuesday: __('Tuesday'),
    wednesday: __('Wednesday'),
    thursday: __('Thursday'),
    friday: __('Friday'),
    saturday: __('Saturday')
};

export const SAAS_CONFIG_CONFIG_NODE = 'config';
export const SAAS_CONFIG_STORE_NODE = 'store';

export const MAPPED_SAAS_CONFIG_KEY_STRINGS = [
    SAAS_CONFIG_CONFIG_NODE,
    SAAS_CONFIG_STORE_NODE
];

import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';

export * from 'SourceUtil/Price/Price.config.js';
export { default } from 'SourceUtil/Price/Price.config.js';

export const TIER_PRICES = 'TIER_PRICES';
export const MIN_MAX = 'MIN_MAX';

export const PRICE_LABEL_MAP = {
    [BUNDLE]: __('Starting from'),
    [GROUPED]: __('Starting from'),
    [CONFIGURABLE]: __('As Low as'),
    [TIER_PRICES]: __('As Low as'),
    [MIN_MAX]: __('From:')
};

import {
    ADDITION,
    CITY,
    EDIT_FIELDS as ROOT_EDIT_FIELDS,
    STREET
} from 'Component/SaasCheckoutForm/SaasCheckoutForm.config';

export const ADDRESS_LINES_QTY = 2;
export const POSTCODE_LENGTH = 6;

export const EDIT_FIELDS = {
    [ADDITION]: {
        ...ROOT_EDIT_FIELDS[ADDITION] || {},
        placeholder: '',
        type: 'select',
        selectOptions: []
    },
    [CITY]: {
        ...ROOT_EDIT_FIELDS[CITY] || {},
        type: 'hidden'
    },
    [STREET]: {
        ...ROOT_EDIT_FIELDS[STREET] || {},
        type: 'hidden'
    }
};

import PropTypes from 'prop-types';

export * from 'SourceType/ProductList';

export const AttributeType = PropTypes.shape({
    attribute_code: PropTypes.string,
    attribute_type: PropTypes.string,
    attribute_value: PropTypes.string,
    attribute_label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ]),
    attribute_options: PropTypes.objectOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({})
        ]),
        value: PropTypes.string
    }))
});

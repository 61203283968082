import {
    Breadcrumb as SourceBreadcrumb
} from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.manual.style';

/** @namespace Pwasaas/Component/Breadcrumb/Component/BreadcrumbComponent */
export class BreadcrumbComponent extends SourceBreadcrumb {}

export default BreadcrumbComponent;

import { AUTH_TOKEN, CUSTOMER } from 'Component/MyAccountLoginAsCustomer/MyAccountLoginAsCustomer.config';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isRedirectCustomerEnabled: state.SaasConfigReducer.config?.redirect_customer_enabled || false
    };
};

export const onSignInSuccess = (args, callback, instance) => {
    const { isRedirectCustomerEnabled } = instance.props;

    if (!isRedirectCustomerEnabled) {
        return callback(...args);
    }

    return callback(...args).then(
        () => {
            const token = BrowserDatabase.getItem(AUTH_TOKEN);

            // no token, do not redirect
            if (!token) {
                return;
            }

            const { store_base_url: redirectUrl = '' } = BrowserDatabase.getItem(CUSTOMER) || {};

            // already on redirect url
            if (redirectUrl === window.location.origin) {
                return;
            }

            // no redirect url
            if (redirectUrl === '') {
                return;
            }

            // clear current state
            BrowserDatabase.deleteItem(AUTH_TOKEN);
            BrowserDatabase.deleteItem(CUSTOMER);

            // fix localhost redirect
            if (window.location.origin === 'http://localhost:3000') {
                window.location = `${window.location.origin }?token=${ token}`;

                return;
            }

            window.location = `${redirectUrl }?token=${ token}`;
        }
    );
};

export default {
    'Component/MyAccountSignIn/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MyAccountSignIn/Container': {
        'member-function': {
            onSignInSuccess
        }
    }
};

/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import Field from 'Component/Field';
import Loader from 'Component/Loader';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import SaasProductTitleBlock from 'Component/SaasProductTitleBlock';
import { ProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { ProductType } from 'Type/ProductList';
import { ProductQuantityType } from 'Type/Store';
import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED,
    SIMPLE
} from 'Util/Product';

import './ProductCard.override.manual.style';

/** @namespace Pwasaas/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends ProductCard {
    static propTypes = {
        ...this.propTypes,
        isSelect: PropTypes.bool.isRequired,
        isWithAttributes: PropTypes.bool,
        isWithProductTitleBlock: PropTypes.bool,
        isWithShortDescription: PropTypes.bool,
        listAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
        quantity: PropTypes.oneOfType([
            PropTypes.number,
            ProductQuantityType
        ]),
        quantitySet: PropTypes.arrayOf(ProductQuantityType),
        showQtyInputField: PropTypes.bool.isRequired,
        product: ProductType.isRequired,
        showAddToCart: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isWithAttributes: false,
        isWithProductTitleBlock: true,
        isWithShortDescription: false
    };

    /**
     * New quantity input
     */
    renderQuantityInput() {
        const {
            quantity,
            setQuantity,
            showQtyInputField,
            isSelect,
            quantitySet,
            isShowQuantityProductCard
        } = this.props;

        if (!showQtyInputField && !isSelect) {
            return null;
        }

        return (
            <Field
              id="item_qty"
              name="item_qty"
              type={ isShowQuantityProductCard ? 'saas_input_select' : 'hidden' }
              value={ quantity }
              isSelect={ isSelect }
              mix={ { block: 'ProductCard', elem: 'Qty' } }
              onChange={ setQuantity }
              selectOptions={ quantitySet }
            />
        );
    }

    /**
     * Add quantity from props
     */
    renderAddToCart() {
        const {
            product,
            product: {
                type_id,
                options = [],
                configurable_options: confOptions = {}
            },
            configurableVariantIndex,
            layout,
            quantity,
            showSelectOptionsNotification,
            inStock,
            showAddToCart
        } = this.props;

        const groupedProductQuantity = {};

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions
        };

        const redirectOnConfig = type_id === CONFIGURABLE
            && Object.keys(confOptions).length !== Object.keys(this.getAttributesToShow()).length;

        // Hides the "Add to Cart" button
        if (!showAddToCart) {
            return null;
        }

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button
                  block="Button AddToCart"
                  mods={ { layout } }
                  onClick={ showSelectOptionsNotification }
                >
                    { __('Add To Cart') }
                </button>
            );
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
              disabled={ !inStock }
              layout={ layout }
            />
        );
    }

    /**
     * New click handler
     */
    renderClickHandler(e) {
        e.preventDefault();
    }

    /**
     * New key handler
     */
    renderKeyHandler() {
    }

    /**
     * New add to wrapper
     */
    renderAddToWrapper() {
        const { product: { type_id } } = this.props;

        if (type_id !== SIMPLE) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="AddToWrapper"
              onClick={ this.renderClickHandler }
              onKeyDown={ this.renderKeyHandler }
              role="button"
              tabIndex="-1"
            >
                { this.renderQuantityInput() }
                { this.renderAddToCart() }
            </div>
        );
    }

    /**
     * New additional content
     */
    renderAdditionalContent() {
        const { children } = this.props;

        if (!children) {
            return null;
        }

        return (
            <div block="ProductCard" elem="AdditionalContent">
                { children }
            </div>
        );
    }

    /**
     * Replace product card name with saas product title block
     * TODO: Toggle to define if title must be SaasProductTitle or default

     */
    renderMainDetails() {
        const {
            isWithProductTitleBlock,
            product: {
                attributes = {},
                name
            }
        } = this.props;

        if (isWithProductTitleBlock) {
            return (
                <SaasProductTitleBlock
                  name={ name }
                  attributes={ attributes }
                  mix={ {
                      block: 'ProductCard',
                      elem: 'Name',
                      mods: { isLoaded: !!name }
                  } }
                />
            );
        }

        return super.renderMainDetails();
    }

    /**
     * Replaced renderAddToCart with renderAddToWrapper
     */
    renderVisibleOnHover() {
        return (
            <>
                { this.renderConfigurableOptions() }
                <div block="ProductCard" elem="Footer">
                    { this.renderProductPrice() }
                    { this.renderAddToWrapper() }
                    { /* { this.renderProductActions() } */ }
                </div>
            </>
        );
    }

    renderProductCardFigureReview() {
        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="FigureReview">
                <figure block="ProductCard" elem="Figure">
                    { this.renderPicture() }
                </figure>
                { /** this.renderReviews() */ }
            </div>
        ));
    }

    renderAttribute = (attributeCode) => {
        const {
            product: {
                attributes: {
                    [attributeCode]: attribute
                } = {}
            }
        } = this.props;

        if (!attribute || attributeCode === 'short_description') {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="Attribute"
              key={ attribute.attribute_label }
            >
                <dt block="ProductCard" elem="AttributeLabel">
                    { `${attribute.attribute_label}:` }
                </dt>
                <dd block="ProductCard" elem="ValueLabel">
                    <ProductAttributeValue
                      key={ attribute.attribute_label }
                      attribute={ attribute }
                      isFormattedAsText
                    />
                </dd>
            </div>
        );
    };

    renderAttributes() {
        const {
            device,
            isWithAttributes,
            listAttributes
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        if (!isWithAttributes || listAttributes.length === 0) {
            return null;
        }

        return (
            <ul
              block="ProductCard"
              elem="Attributes"
            >
                { listAttributes.map(this.renderAttribute) }
            </ul>
        );
    }

    /**
     * Render a short description (2 lines) of the product description
     */
    renderShortDescription() {
        const { isWithShortDescription } = this.props;

        if (!isWithShortDescription) {
            return null;
        }

        const {
            product: {
                short_description: {
                    html: short_description
                }
            }
        } = this.props;

        if (!short_description) {
            return null;
        }

        const isReadMore = !!short_description;

        return (
            <div block="ProductCard" elem="ShortDescription">
                <p>{ short_description }</p>
                { this.renderShortDescriptionReadMore(isReadMore, short_description.length) }
            </div>
        );
    }

    /**
     * Show "Read more" text by short description
     */
    renderShortDescriptionReadMore(isReadMore, textLength) {
        const MAX_CHARACTERS = 50;

        if (!isReadMore || (isReadMore && textLength < MAX_CHARACTERS)) {
            return null;
        }

        return (
            <span block="ProductCard" elem="ShortDescription" mods={ { ReadMore: isReadMore } }>
                { __('Read more') }
            </span>
        );
    }

    /**
     * Removed elem VisibleOnHover
     * Added custom renderAttributes method
     */
    renderCardContent() {
        const {
            renderContent
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                { this.renderProductCardFigureReview() }
                <div block="ProductCard" elem="Content">
                    { this.renderCardLinkWrapper((
                        <>
                            { this.renderMainDetails() }
                            { this.renderShortDescription() }
                            { this.renderAttributes() }
                        </>
                    )) }
                    { this.renderVisibleOnHover() }
                </div>
            </>
        );
    }

    /**
     * Add children
     */
    render() {
        const {
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        return (
            <li
              block="ProductCard"
              mods={ mods }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { this.renderAdditionalContent() }
            </li>
        );
    }
}

export default ProductCardComponent;

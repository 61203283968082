import { getPriceLabel } from 'Util/Price';
import { MIN_MAX } from 'Util/Price/Price.config';

export class ProductCardComponent {
    renderProductTypePriceBadge(args, callback, instance) {
        const {
            product: {
                type_id,
                price_tiers = [],
                price_range: {
                    maximum_price: {
                        final_price: {
                            value: maxPrice
                        }
                    },
                    minimum_price: {
                        final_price: {
                            value: minPrice
                        }
                    }
                }
            },
            siblingsHavePriceBadge,
            setSiblingsHavePriceBadge
        } = instance.props;

        const typeId = (type_id === 'simple' && maxPrice !== minPrice) ? MIN_MAX : type_id;
        const label = getPriceLabel(typeId, price_tiers);

        if (label && !siblingsHavePriceBadge) {
            setSiblingsHavePriceBadge();
        }

        return label;
    }
}

const {
    renderProductTypePriceBadge
} = new ProductCardComponent();

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderProductTypePriceBadge
        }
    }
};

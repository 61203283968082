/**
 * Added isNotLayoutGroup property to Html component, so it doenst render as a layout group
 */

import { cloneElement } from 'react';

import LayoutGroupPropType from '../util/LayoutGroupPropType';

export class CmsBlockComponent extends LayoutGroupPropType {
    render = (args, callback, instance) => {
        const result = callback(...args);

        if (!result) {
            return null;
        }

        const { isNotLayoutGroup } = instance.props;
        const { props, props: { children: Html } } = result;

        return cloneElement(result, {
            ...props,
            children: cloneElement(Html, {
                ...Html.props,
                isNotLayoutGroup
            })
        });
    };
}

const {
    defaultProps,
    render,
    staticPropTypes
} = new CmsBlockComponent();

export default {
    'Component/CmsBlock/Component': {
        'member-function': {
            render
        },
        'static-member': {
            defaultProps,
            propTypes: staticPropTypes
        }
    }
};

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ESCAPE_KEY } from 'Component/MenuRegular/MenuRegular.config';
import { updateIsToggled } from 'Store/SaasMenu/SaasMenu.action';
import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/SaasMenu';

import MenuButton from './MenuButton.component';

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

/** @namespace Pwasaas/Component/MenuButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeMenuItem: state.SaasMenuReducer.activeMenuItem,
    device: state.ConfigReducer.device
});

/** @namespace Pwasaas/Component/MenuButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeMenu(dispatch)
    ),
    openMenu: (item) => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.openMenu(dispatch, item)
    ),
    setIsToggled: () => dispatch(updateIsToggled(true))
});

/** @namespace Pwasaas/Component/MenuButton/Container/MenuButtonContainer */
export class MenuButtonContainer extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired,
        closeMenu: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        item: MenuItemType.isRequired,
        openMenu: PropTypes.func.isRequired,
        setIsToggled: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleKeyDown: this.handleKeyDown.bind(this),
        handleToggle: this.handleToggle.bind(this)
    };

    containerProps = () => {
        const {
            device,
            item
        } = this.props;

        return {
            device,
            isActive: this._getIsActive(),
            item
        };
    };

    _getIsActive() {
        const {
            activeMenuItem,
            item
        } = this.props;

        return item === activeMenuItem;
    }

    handleKeyDown(e) {
        const { closeMenu } = this.props;

        if (!this._getIsActive()) {
            return;
        }

        if (e.keyCode === ESCAPE_KEY) {
            closeMenu();
        }
    }

    handleToggle() {
        const {
            closeMenu,
            item,
            openMenu,
            setIsToggled
        } = this.props;

        if (this._getIsActive()) {
            closeMenu();

            return;
        }

        setIsToggled();
        openMenu(item);
    }

    render() {
        return (
            <MenuButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuButtonContainer);

import Html from 'Component/Html';

import { SHOW_ALL_PRODUCTS_BY_ATTRIBUTE } from '../ShowAllProductsByAttribute.config';

export class ProductActionsComponent {
    actionsRenderMap = (originalMember, instance) => {
        const { isShowAllEnabled } = instance.props;

        if (!isShowAllEnabled) {
            return originalMember;
        }

        return {
            ...originalMember,
            [SHOW_ALL_PRODUCTS_BY_ATTRIBUTE]: this.renderShowAllProductsByAttribute.bind(instance)
        };
    };

    renderShowAllProductsByAttribute() {
        const { showAllProductsWidget } = this.props;

        return (
            <div
              block="ProductActions"
              elem="ShowAllProductsByAttribute"
            >
                <Html content={ showAllProductsWidget } />
            </div>
        );
    }
}

const {
    actionsRenderMap
} = new ProductActionsComponent();

export default {
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-property': {
            actionsRenderMap
        }
    }
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import { QtySelectOptionsType } from 'Type/ProductQtyConfig';

import './SaasInputSelectField.manual.style';

/** @namespace Pwasaas/Component/SaasInputSelectField/Component/SaasInputSelectFieldComponent */
export class SaasInputSelectFieldComponent extends PureComponent {
    static propTypes = {
        selectOptionValue: PropTypes.func.isRequired,
        isSelect: PropTypes.bool.isRequired,
        isSelectExpanded: PropTypes.bool.isRequired,
        inputUpdateQty: PropTypes.func.isRequired,
        handleCollapSelect: PropTypes.func.isRequired,
        handleInputStateChange: PropTypes.func.isRequired,
        handleToggleSelect: PropTypes.func.isRequired,
        currentValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        max: PropTypes.number,
        selectOptions: PropTypes.arrayOf(QtySelectOptionsType)
    };

    static defaultProps = {
        currentValue: null,
        max: null,
        selectOptions: []
    };

    renderInputSelectOption = (option) => {
        const { value, label, id } = option;
        const {
            selectOptionValue,
            isSelectExpanded: isExpanded
        } = this.props;

        return (
            <div
              block="SaasInputSelectField"
              elem="Option"
              id={ id }
              key={ id }
              role="button"
              data-value={ value }
              onClick={ selectOptionValue }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
            </div>
        );
    };

    renderInputSelect = () => {
        const {
            handleCollapSelect,
            isSelectExpanded: isExpanded,
            selectOptions
        } = this.props;

        if (!isExpanded) {
            return null;
        }

        return (
            <ClickOutside onClick={ handleCollapSelect }>
                <div
                  block="SaasInputSelectField"
                  elem="Options"
                  mods={ { isExpanded } }
                  aria-label="Select drop-down"
                  aria-expanded={ isExpanded }
                >
                    { selectOptions.map(this.renderInputSelectOption) }
                </div>
            </ClickOutside>
        );
    };

    render() {
        const {
            max,
            currentValue,
            inputUpdateQty,
            isSelect,
            handleInputStateChange,
            handleToggleSelect
        } = this.props;

        const dimmedZeroValue = currentValue === 0 ? '' : currentValue;

        return (
            <div
              block="SaasInputSelectField"
              mods={ { isSelect } }
              onClick={ handleToggleSelect }
              role="presentation"
            >
                <input
                  type="number"
                  value={ dimmedZeroValue }
                  onBlur={ inputUpdateQty }
                  onChange={ handleInputStateChange }
                  block="SaasInputSelectField"
                  elem="Input"
                  max={ max }
                />
                { this.renderInputSelect() }
            </div>
        );
    }
}

export default SaasInputSelectFieldComponent;

import PropTypes from 'prop-types';

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductInformation as SourceProductInformation
} from 'SourceComponent/ProductInformation/ProductInformation.component';
import { ProductType } from 'Type/ProductList';

import './ProductInformation.override.manual.style';

/** @namespace Pwasaas/Component/ProductInformation/Component/ProductInformationComponent */
export class ProductInformationComponent extends SourceProductInformation {
    static propTypes = {
        product: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired
    };

    /**
     * New render attribute
     */
    renderAttribute(attributeItem, i) {
        const { attribute_label, attribute_value } = attributeItem;

        // Prevent rendering of empty values
        if (!attribute_value) {
            return null;
        }

        return (
            <div
              block="ProductInformation"
              elem="AttributeItem"
              key={ `${attribute_value}_${i}` }
            >
                <h3
                  block="ProductInformation"
                  elem="AttributeItemLabel"
                >
                    { attribute_label }
                </h3>
                <p
                  block="ProductInformation"
                  elem="AttributeItemValue"
                >
                    { attribute_value }
                </p>
            </div>
        );
    }

    /**
     * New render heading
     */
    renderHeading() {
        const {
            areDetailsLoaded
        } = this.props;
        const heading = areDetailsLoaded ? __('Product information') : '';

        if (!heading) {
            return false;
        }

        if (typeof heading === 'string') {
            return (
                <span
                  block="ProductInformation"
                  elem="Heading"
                >
                    <TextPlaceholder content={ heading } length="medium" />
                </span>
            );
        }

        return (
            <span
              block="ProductInformation"
              elem="Heading"
            >
                { heading }
            </span>
        );
    }

    /**
     * New render subheading
     */
    renderSubHeading() {
        const {
            subHeading
        } = this.props;

        if (!subHeading) {
            return false;
        }

        return (
            <span
              block="ProductInformation"
              elem="SubHeading"
            >
                { subHeading }
            </span>
        );
    }

    /**
     * Return null instead of text placeholder
     */
    renderDescription() {
        const { product: { description: { html } = {} } } = this.props;

        if (!html) {
            return null;
        }

        const cleanDescription = html.replace(/<\/?[^>]+(>|$)/g, '');

        return (
            <div block="ProductInformation" elem="Description">
                <meta itemProp="description" content={ cleanDescription } />
                <Html content={ html } />
            </div>
        );
    }

    /**
     * Replace original content with new renders
     */
    renderContent() {
        const { selectedAttributes } = this.props;

        if (!selectedAttributes) {
            return null;
        }

        return (
            <div block="ProductInformation" elem="Content">
                { this.renderHeading() }
                { this.renderSubHeading() }
                { this.renderDescription() }
                { selectedAttributes.map(this.renderAttribute) }
            </div>
        );
    }
}

export default ProductInformationComponent;

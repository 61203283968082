import MostOrderedReducer from 'Store/MostOrdered/MostOrdered.reducer';

export const getStaticReducers = (args, callback) => ({
    ...callback(args),
    MostOrderedReducer
});

export const config = {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

export default config;

/**
 * Force update of product list data on location key change
 */

import { PRODUCT_DATA_FIX_ENABLED } from './ProductData.config';

export class ProductListContainer {
    componentDidUpdate = (args, callback, instance) => {
        if (!PRODUCT_DATA_FIX_ENABLED) {
            return callback(...args);
        }

        const { location: { key } } = instance.props;
        const [{ location: { key: prevKey } }] = args;

        if (key !== prevKey) {
            instance.requestPage(instance._getPageFromUrl());
        }

        return callback(...args);
    };
}

const {
    componentDidUpdate
} = new ProductListContainer();

export default {
    'Component/ProductList/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * Add isNotLayoutGroup prop to all current widgets
 */
import { cloneElement } from 'react';

import LayoutGroupPropType from '../util/LayoutGroupPropType';

export class HtmlComponent extends LayoutGroupPropType {
    replaceWidget = (args, callback, instance) => {
        const { isNotLayoutGroup } = instance.props;
        const result = callback(...args);
        const { props, props: { children: WidgetFactory } } = result;

        return cloneElement(result, {
            ...props,
            children: cloneElement(WidgetFactory, {
                ...WidgetFactory.props,
                isNotLayoutGroup
            })
        });
    };
}

const {
    defaultProps,
    replaceWidget,
    staticPropTypes
} = new HtmlComponent();

export default {
    'Component/Html/Component': {
        'member-function': {
            replaceWidget
        },
        'static-member': {
            defaultProps,
            staticPropTypes
        }
    }
};

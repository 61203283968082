import { ADD_ADDRESS } from 'Component/MyAccountAddressPopup/MyAccountAddressPopup.config';

export class MyAccountAddressBookContainer {
    showCreateNewPopup = (args, callback, instance) => {
        const { customer, showPopup } = instance.props;

        if (customer.default_billing && customer.default_shipping) {
            callback(...args);

            return;
        }

        showPopup({
            action: ADD_ADDRESS,
            title: __('Add new address'),
            address: {
                default_billing: customer.default_billing === null,
                default_shipping: customer.default_shipping === null
            }
        });
    };
}

const {
    showCreateNewPopup
} = new MyAccountAddressBookContainer();

export default {
    'Component/MyAccountAddressBook/Container': {
        'member-function': {
            showCreateNewPopup
        }
    }
};

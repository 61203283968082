import SaasDate from 'Util/Saas';
import { getStore } from 'Util/Store';

export const MIN_PASSWORD_LENGTH = 8;
export const POSTCODE_LENGTH_NL = 6;

/** @namespace Pwasaas/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace Pwasaas/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace Pwasaas/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace Pwasaas/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^\+?(?:[0-9-] ?){6,14}[0-9]$/);

/** @namespace Pwasaas/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace Pwasaas/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    const { current: { value: passwordValue } } = password || { current: {} };

    return value === passwordValue;
};

/** @namespace Pwasaas/Component/Form/Config/validatePostcode */
export const validatePostcode = ({ value }, { country_id = {} }) => {
    const countryCode = country_id.current?.value || getStore().getState().ConfigReducer.default_country;

    if (countryCode === 'NL') {
        const newValue = value.replace(new RegExp(' ', 'g'), '');
        return newValue.length === POSTCODE_LENGTH_NL && newValue.match(/[0-9][0-9][0-9][0-9][A-z][A-z]/);
    }

    return true;
};

/** @namespace Pwasaas/Component/Form/Config/validateDate */
export const validateDate = ({ value }) => value.trim().length > 0
    && value.match(/(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/);

/** @namespace Pwasaas/Component/Form/Config/validateAge */
export const validateAge = ({ value }) => {
    const MIN_AGE = 18;

    return new SaasDate().getDifference(
        value,
        'dd-MM-yyyy',
        'years'
    ) >= MIN_AGE;
};

/** @namespace Pwasaas/Component/Form/Config/validateHouseNumber */
export const validateHouseNumber = ({ value }) => value.length > 0 && value.match(/^\d+$/);

/** @namespace Pwasaas/Component/Form/Config/validateCity */
export const validateCity = ({ value }) => value.length > 0 && value.match(/^[a-zA-Z-]+$/);

/** @namespace Pwasaas/Component/Form/Config/formConfig */
export const formConfig = () => ({
    city: {
        validate: validateCity,
        message: __('City may only contain letters and dashes')
    },
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least 8 characters long')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    cityPostcode: {
        validate: ({ value }) => value.length && validatePostcode({ value }, {}) && validateCity({ value }),
        message: __('Postcode or city is invalid')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    postcode: {
        validate: validatePostcode,
        message: __('Postcode is invalid!')
    },
    /**
     * Todo, move to plugin
     */
    date: {
        validate: validateDate,
        message: __('The date you entered is not valid')
    },
    /**
     * Todo, move to plugin
     */
    age: {
        validate: validateAge,
        message: __('The age you entered is not valid')
    },
    /**
     * Todo, move to plugin
     */
    houseNumber: {
        validate: validateHouseNumber,
        message: __('House number is not valid')
    }
});

export default formConfig();

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/AddProductsToCartQuery/Query/AddProductsToCartQueryQuery */
export class AddProductsToCartQueryQuery {
    getAddProductsToCartMutation(cartId, cartItems) {
        return new Field('addProductsToCart')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('cartItems', '[CartItemInput!]!', cartItems)
            .addFieldList(
                [
                    new Field('cart').addFieldList(
                        [
                            'email'
                        ]
                    )
                ]
            );
    }
}

export default new AddProductsToCartQueryQuery();

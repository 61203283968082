import MyAccountAdditionalEmails from '../component/MyAccountAdditionalEmails';
import { MY_ADDITIONAL_EMAILS } from '../component/MyAccountAdditionalEmails/MyAccountAdditionalEmails.config';

export class MyAccountComponent {
    renderMap = (originalMember, instance) => {
        const { isAdditionalEmailsEnabled } = instance.props;

        if (!isAdditionalEmailsEnabled) {
            return originalMember;
        }

        return {
            ...originalMember,
            [MY_ADDITIONAL_EMAILS]: MyAccountAdditionalEmails
        };
    };
}

const {
    renderMap
} = new MyAccountComponent();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap
        }
    }
};

/**
 * Intercept fetch call and see if backend and frontend version differ
 *
 * @todo:
 * - do not refresh on every call, eg search or checkout no refresh?
 * - do not delete every service worker graphql cache
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { VERSION_FIX_ENABLED } from './Version.config';

export const VERSION_IDENTIFIER = 'x-saas-version';

export class RequestPlugin {
    getFetchThen = (args, callback) => {
        if (!VERSION_FIX_ENABLED) {
            return callback(...args);
        }

        const result = callback(...args);

        // get backend version
        const versionBackend = args[0] ? args[0].headers.get(VERSION_IDENTIFIER) : null;

        // if no backend version, resume as normal
        if (!versionBackend) {
            return result;
        }

        // get frontend version
        const versionFrontend = BrowserDatabase.getItem(VERSION_IDENTIFIER);

        // if no frontend version, set it, but do not reload
        // this could be either
        // - initial load
        // - recurring visitor with very old local version
        if (!versionFrontend) {
            BrowserDatabase.setItem(versionBackend, VERSION_IDENTIFIER);

            return result;
        }

        // if version differ, update it and reload
        if (versionFrontend !== versionBackend) {
            BrowserDatabase.setItem(versionBackend, VERSION_IDENTIFIER);

            // delete graphql cache from service worker
            caches.delete('graphql');

            window.location.reload();
        }

        // resume as normal
        return result;
    };
}

const {
    getFetchThen
} = new RequestPlugin();

export default {
    'Util/Request/getFetchThen': {
        function: getFetchThen
    }
};

import parser from 'html-react-parser';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MenuItemType } from 'Type/SaasMenu';

import MenuAside from './MenuAside.component';

/** @namespace Pwasaas/Component/MenuAside/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeMenuItem: state.SaasMenuReducer.activeMenuItem
});

/** @namespace Pwasaas/Component/MenuAside/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwasaas/Component/MenuAside/Container/MenuAsideContainer */
export class MenuAsideContainer extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired
    };

    containerProps = () => ({
        content: this._getContent(),
        widgetType: this._getWidgetType()
    });

    _getContent() {
        const {
            activeMenuItem: {
                mega_setting: {
                    rich_content
                } = {}
            }
        } = this.props;

        return rich_content || '';
    }

    _getWidgetType() {
        const {
            activeMenuItem: {
                mega_settings: {
                    rich_content
                } = {}
            }
        } = this.props;

        const {
            props: {
                type = ''
            } = {}
        } = parser(rich_content || '');

        return type;
    }

    render() {
        return (
            <MenuAside
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuAsideContainer);

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    brandLogoAttribute: state.SaasConfigReducer.productViewAttributes?.brand_logo_attribute || ''
});

export class ProductActionsContainer {
    containerProps = (args, callback, instance) => {
        const { brandLogoAttribute } = instance.props;

        return {
            ...callback(...args),
            brandLogoAttribute,
            hasBrandLogo: !!brandLogoAttribute
        };
    };
}

const {
    containerProps
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    }
};

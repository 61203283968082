/**
 * Format price as currency wihout symbol
 */

import { getStore } from 'Util/Store';

class PriceUtil {
    formatPrice = (args, callback) => {
        const { show_price_currency_symbol = true } = getStore().getState().SaasConfigReducer?.config || {};

        if (!show_price_currency_symbol) {
            const [price, currency = 'USD'] = args;

            const language = navigator.languages ? navigator.languages[0] : navigator.language;

            // get result as code to replace letter symbols
            return new Intl.NumberFormat(language, {
                style: 'currency',
                currency,
                currencyDisplay: 'code'
            }).format(price).replace(/[a-z]{3}/i, '').trim();
        }

        return callback(...args);
    };
}

const {
    formatPrice
} = new PriceUtil();

export default {
    'Util/Price/formatPrice': {
        function: formatPrice
    }
};

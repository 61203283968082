import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProducts } from 'Util/Product';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { GET_MOST_ORDERED } from './MostOrdered.action';

export const MOST_ORDERED = 'most_ordered';

export const mostOrderedList = BrowserDatabase.getItem(MOST_ORDERED) || [];

/** @namespace Pwasaas/Store/MostOrdered/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: !mostOrderedList.length,
    mostOrderedList
});

/** @namespace Pwasaas/Store/MostOrdered/Reducer/MostOrderedReducer */
export const MostOrderedReducer = (
    state = getInitialState(),
    action
) => {
    const {
        items = [],
        status,
        type
    } = action;

    switch (type) {
    case GET_MOST_ORDERED:
        const formattedProducts = getIndexedProducts(items);

        BrowserDatabase.setItem(formattedProducts, MOST_ORDERED, ONE_MONTH_IN_SECONDS);

        return {
            ...state,
            isLoading: status,
            mostOrderedList: formattedProducts
        };

    default:
        return state;
    }
};

export default MostOrderedReducer;

import {
    configureStore,
    getStore,
    noopReducer
} from 'SourceUtil/Store';

/** @namespace Pwasaas/Util/Store/Index/getFilteredStoreData */
export const getFilteredStoreData = (data, forceKeyDelete = '') => {
    const toCamelCase = (string) => string.replace(/_[a-z]/g, (match) => match.substr(1).toUpperCase());

    return Object.entries(data).reduce(
        (acc, [key, value]) => (
            value === null || key === forceKeyDelete
                ? acc
                : { ...acc, [toCamelCase(key)]: value }
        ), {}
    );
};

export {
    configureStore,
    getStore,
    noopReducer
};

export default getStore;

import Loader from 'Component/Loader';
import { updateTotals } from 'Store/Cart/Cart.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        productConfig: state.SaasConfigReducer.product
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        clearCrossSellProducts: () => LinkedProductsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.clearCrossSellProducts(dispatch)
        ),
        removeProduct: (itemId, item) => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.removeProductFromCart(dispatch, itemId, item)
        ),
        updateCartTotals: (cartData) => dispatch(updateTotals(cartData))
    };
};

export const addLoaderComponentTo = (args, callback, instance) => {
    const { isLoading } = instance.props;

    return (
        <>
            <Loader
              isFixed
              isLoading={ isLoading }
            />
            { callback(instance, args) }
        </>
    );
};

export class AddRemoveAllFromCartToCartPagePlugin {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        removeAllFromCart: this.removeAllFromCart.bind(instance)
    });

    state = (originalState) => ({
        ...originalState,
        isLoading: false
    });

    removeAllFromCart() {
        const {
            clearCrossSellProducts,
            removeProduct,
            showNotification,
            totals: {
                items = []
            } = {},
            updateCartTotals
        } = this.props;

        this.setState({
            isLoading: true
        });

        Promise.all(items.map(async (item) => {
            const { item_id } = item;
            const cartData = await removeProduct(item_id, item);

            if (cartData) {
                updateCartTotals(cartData);
            }

            return cartData;
        })).then(
            () => {
                clearCrossSellProducts();
                updateCartTotals({});

                showNotification('success', __('Products removed from cart!'));
            },
            (errors) => showNotification('error', `__('Something went wrong:') ${errors}`)
        ).finally(
            () => this.setState({
                isLoading: false
            })
        );
    }
}

const {
    containerFunctions,
    state
} = new AddRemoveAllFromCartToCartPagePlugin();

export default {
    'Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CartPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/CartPage/Container': {
        'member-property': {
            containerFunctions,
            state
        }
    },
    'Route/CartPage/Component': {
        'member-function': {
            render: addLoaderComponentTo
        }
    }
};

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    showProductStockStatus: state.SaasConfigReducer.config?.show_product_stock_status || false,
    showProductStockIcon: state.SaasConfigReducer.config?.show_product_stock_icon || false,
    showProductStockLabel: state.SaasConfigReducer.config?.show_product_stock_label || true,
    showProductStockWhenInStock: state.SaasConfigReducer.config?.show_product_stock_status_only_in_stock || false,
    backorders: state.SaasConfigReducer.config?.backorders || '0'
});

export class GroupedProductsItemContainer {
    containerProps = (args, callback, {
        props: {
            showProductStockStatus,
            showProductStockIcon,
            showProductStockLabel,
            showProductStockWhenInStock,
            backorders
        }
    }) => ({
        ...callback(...args),
        showProductStockStatus,
        showProductStockIcon,
        showProductStockLabel,
        showProductStockWhenInStock,
        backorders
    });
}

const {
    containerProps
} = new GroupedProductsItemContainer();

export default {
    'Pwasaas/Component/GroupedProductsItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/GroupedProductsItem/Container': {
        'member-function': {
            containerProps
        }
    }
};

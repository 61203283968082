import { updateDeliveryDate } from 'Store/OrderDate/OrderDate.action';

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        updateDeliveryDate: (value) => dispatch(updateDeliveryDate(value))
    };
};

const onOptionClick = (args, callback, instance) => {
    const { updateDeliveryDate } = instance.props;

    updateDeliveryDate({
        selectedDay: '',
        selectedTime: ''
    });

    return callback(...args);
};

export default {
    'Component/CheckoutDeliveryOption/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutDeliveryOption/Container': {
        'member-function': {
            onOptionClick
        }
    }
};

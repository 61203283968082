import { Fragment } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import { ProductAttributes } from 'SourceComponent/ProductAttributes/ProductAttributes.component';
import ProductAttribute, { getVisibleProductAttributes } from 'Util/Attribute';
/** @namespace Pwasaas/Component/ProductAttributes/Component/ProductAttributesComponent */
export class ProductAttributesComponent extends ProductAttributes {
    /**
     * Render attributes instead of group
     */
    renderContent() {
        const {
            areDetailsLoaded,
            product: {
                attributes
            }
        } = this.props;
        const heading = areDetailsLoaded ? __('Product Specifications') : '';

        if (!Object.keys(attributes).length) {
            return null;
        }

        return (
            <ExpandableContent
              // TODO: show placeholder if the details are not loaded
              heading={ heading }
              mix={ { block: 'ProductAttributes', elem: 'Content' } }
            >
                { this.renderAttributes() }
            </ExpandableContent>
        );
    }

    /**
     * Render only when attribute value is available
     */
    renderAttribute = (attribute) => {
        if (ProductAttribute.getValue(attribute)?.value === 'N/A') {
            return null;
        }

        return (
            <Fragment key={ attribute.attribute_label }>
                <dt block="ProductAttributes" elem="AttributeLabel">
                    { attribute.attribute_label }
                </dt>
                <dd block="ProductAttributes" elem="ValueLabel">
                    <ProductAttributeValue
                      key={ attribute.attribute_label }
                      attribute={ attribute }
                      isFormattedAsText
                    />
                </dd>
            </Fragment>
        );
    };

    /**
     * Render only one list
     */
    renderAttributes() {
        const { attributesWithValues } = this.props;

        if (!Object.keys(attributesWithValues).length) {
            return null;
        }

        return (
            <dl block="ProductAttributes" elem="Attributes">
                { getVisibleProductAttributes(attributesWithValues).map(this.renderAttribute) }
            </dl>
        );
    }

    /**
     * Add content wrapper
     */
    render() {
        const { areDetailsLoaded } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <ContentWrapper
              label={ __('Product Specifications') }
              mix={ { block: 'ProductAttributes' } }
              wrapperMix={ {
                  block: 'ProductAttributes',
                  elem: 'Wrapper'
              } }
            >
                { this.renderContent() }
            </ContentWrapper>
        );
    }
}

export default ProductAttributesComponent;

import {
    ON_ADD,
    ON_EDIT
} from '../component/CompleteOrderProductCard/CompleteOrderProductCard.config';
import {
    addItem,
    editItem,
    removeItem
} from '../store/CompleteOrder/CompleteOrder.action';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isCompleteOrderEnabled: state.SaasConfigReducer.config?.complete_order_enabled || false
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        addCompleteOrderItem: (item) => dispatch(addItem(item)),
        editCompleteOrderItem: (item) => dispatch(editItem(item)),
        removeCompleteOrderItem: (item) => dispatch(removeItem(item))
    };
};

export class ProductSectionNavigationContainer {
    containerFunctions = (originalMember, instance) => {
        const { isCompleteOrderEnabled } = instance.props;

        if (!isCompleteOrderEnabled) {
            return originalMember;
        }

        return {
            ...originalMember,
            handleCompleteOrderItemChange: this.onCompleteOrderItemChange.bind(instance)
        };
    };

    containerProps = (args, callback, instance) => {
        const { isCompleteOrderEnabled } = instance.props;

        return {
            ...callback(...args),
            isCompleteOrderEnabled
        };
    };

    onCompleteOrderItemChange(item, type) {
        const {
            addCompleteOrderItem,
            editCompleteOrderItem,
            removeCompleteOrderItem
        } = this.props;

        switch (type) {
        case ON_ADD:
            addCompleteOrderItem(item);
            break;

        case ON_EDIT:
            editCompleteOrderItem(item);
            break;

        default:
            removeCompleteOrderItem(item);
        }
    }
}

const {
    containerFunctions,
    containerProps
} = new ProductSectionNavigationContainer();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/ProductSectionNavigation/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Pwasaas/Component/ProductSectionNavigation/Container/ProductSectionNavigationContainer': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions
        }
    }
};

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Plugin/MageMacleanAdditionalEmails/Query/MyAdditionalEmails/Query/MyAdditionalEmailsQuery */
export class MyAdditionalEmailsQuery {
    getDeleteEmailMutation(id) {
        return new Field('deleteAdditionEmail')
            .addArgument('id', 'Int!', id)
            .addFieldList(this.getAdditionalEmailFieldList())
            .setAlias('additionalEmails');
    }

    getSaveEmailMutation(data) {
        return new Field('saveAdditionEmail')
            .addArgument('input', 'AdditionalEmailInput!', data)
            .addFieldList(this.getAdditionalEmailFieldList())
            .setAlias('additionalEmails');
    }

    getAdditionalEmailFieldList() {
        return [
            'id',
            'email',
            'name',
            'position'
        ];
    }
}

export default new MyAdditionalEmailsQuery();

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ChildrenType } from 'Type/Common';
import { SiteUspItemsType } from 'Type/Store';

import SiteUsp from './SiteUsp.component';

/** @namespace Pwasaas/Component/SiteUsp/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    items: state.SaasConfigReducer.uspItems
});

/** @namespace Pwasaas/Component/SiteUsp/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwasaas/Component/SiteUsp/Container/SiteUspContainer */
export class SiteUspContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType,
        fallbackIcon: PropTypes.string,
        isHeader: PropTypes.bool,
        isSlider: PropTypes.bool,
        isSticky: PropTypes.bool,
        items: SiteUspItemsType.isRequired
    };

    static defaultProps = {
        children: [],
        fallbackIcon: '',
        isHeader: false,
        isSlider: false,
        isSticky: false
    };

    containerProps = () => {
        const {
            children,
            fallbackIcon,
            isHeader,
            isSlider,
            isSticky,
            items = []
        } = this.props;

        return {
            children,
            fallbackIcon,
            isHeader,
            isSlider,
            isSticky,
            items
        };
    };

    render() {
        return (
            <SiteUsp
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteUspContainer);

import PropTypes from 'prop-types';

import { StoreItems } from 'SourceComponent/StoreItems/StoreItems.component';

import './StoreItems.override.manual.style';

/** @namespace Pwasaas/Component/StoreItems/Component/StoreItemsComponent */
export class StoreItemsComponent extends StoreItems {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        item: PropTypes.object.isRequired,
        getStoreCode: PropTypes.func.isRequired
    };

    /**
     * Add flag images
     */
    render() {
        const {
            item: { flag, label },
            getStoreCode
        } = this.props;

        return (
            <button
              block="StoreItems"
              elem="Item"
              onClick={ getStoreCode }
            >
                <img src={ flag } alt={ label } />
            </button>
        );
    }
}

export default StoreItemsComponent;

import { ProductBundleItems } from 'Component/ProductBundleItems/ProductBundleItems.component';

import ProductVisualBundleItem from '../ProductVisualBundleItem';

/** @namespace Pwasaas/Plugin/VisualBundleSelector/Component/ProductVisualBundleItems/Component/ProductVisualBundleItemsComponent */
export class ProductVisualBundleItemsComponent extends ProductBundleItems {
    renderContent() {
        const {
            items,
            setSelectedCheckboxValues,
            setSelectedDropdownValue,
            maxQuantity,
            updateQuantity,
            productOptionsData,
            price_range,
            type_id,
            price_range: { minimum_price: { regular_price: { currency } } },
            selectedCheckboxValues
        } = this.props;

        return items.map((item, key) => (
            <ProductVisualBundleItem
              option={ item }
              price_range={ price_range }
              key={ item.id || key }
              setSelectedCheckboxValues={ setSelectedCheckboxValues }
              setSelectedDropdownValue={ setSelectedDropdownValue }
              selectedCheckboxValues={ selectedCheckboxValues }
              maxQuantity={ maxQuantity }
              updateQuantity={ updateQuantity }
              productOptionsData={ productOptionsData }
              currencyCode={ currency }
              type_id={ type_id }
            />
        ));
    }
}

export default ProductVisualBundleItemsComponent;

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/CustomerCart/Query/CustomerCartQuery */
export class CustomerCartQuery {
    getCustomerCart() {
        return new Field('customerCart')
            .addFieldList(this._getCustomerCartQuery())
            .setAlias('customerCart');
    }

    _getCustomerCartQuery() {
        return [
            'id',
            'email'
        ];
    }
}

export default new CustomerCartQuery();

/** TODO: Merge Video.js and CmsPage.js as one transformer util */

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';
export const VIDEO = 'video';
export const YOUTUBE_IMG_URL = 'https://img.youtube.com/vi/';

/** @namespace Pwasaas/Util/Saas/Video/getYoutubeImageSizeMap */
export const getYoutubeImageSizeMap = () => ({
    [SIZE_SMALL]: {
        file: '/mqdefault.jpg'
    },
    [SIZE_LARGE]: {
        file: '/sddefault.jpg'
    }
});

/** @namespace Pwasaas/Util/Saas/Video/getWidgetKeyData */
export const getWidgetKeyData = (key) => {
    const stringKey = key.split('_').pop();

    return {
        currentIndex: stringKey.charAt(0),
        key: stringKey.slice(1).toLowerCase()
    };
};

/** @namespace Pwasaas/Util/Saas/Video/getTransformedVideoData */
export const getTransformedVideoData = (props) => Object.entries(props).reduce(
    (videos = [], [currentKey, value]) => {
        if (currentKey.includes(VIDEO)) {
            const { currentIndex, key } = getWidgetKeyData(currentKey);
            const videoItem = videos.find(({ index }) => index === currentIndex);

            if (!videoItem) {
                return [
                    ...videos,
                    {
                        active: currentIndex === '1',
                        index: currentIndex,
                        [key]: value
                    }
                ];
            }

            videoItem[key] = value;
        }

        return videos;
    }, []
);

/** @namespace Pwasaas/Util/Saas/Video/getYoutubeVideoImage */
export const getYoutubeVideoImage = (
    code,
    size = SIZE_MEDIUM
) => {
    const { [size]: { file } = {} } = getYoutubeImageSizeMap();

    return file ? `${YOUTUBE_IMG_URL}${code}${file}` : '';
};

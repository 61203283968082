import SaasDate from './Date';

/** @namespace Pwasaas/Util/Saas/Delivery/getFirstDeliveryDayOption */
export const getFirstDeliveryDayOption = (
    days,
    thisDay,
    orderTime,
    daysToDeliver
) => {
    const currentDate = new SaasDate().setLocale('en_GB').setLabelFormat('EEEE');

    const date = {
        index: days.indexOf(currentDate.setDate(thisDay).getDateLabel().toLowerCase()),
        count: currentDate.getTime() >= orderTime
            ? daysToDeliver + 1
            : daysToDeliver,
        today: currentDate.getDateLabel().toLowerCase(),
        tomorrow: currentDate.addDays(1).getDateLabel().toLowerCase()
    };

    if (date.index < 0) {
        return '';
    }

    const deliverDay = days[date.index + date.count];

    switch (true) {
    case deliverDay === date.today:
        return __('Today');
    case deliverDay === date.tomorrow:
        return __('Tomorrow');
    default:
        return deliverDay || days[date.count] || '';
    }
};

/** @namespace Pwasaas/Util/Saas/Delivery/getAvailableDays */
export const getAvailableDays = (deliveryData) => Object.entries(deliveryData).reduce(
    (days, [day, active]) => (
        active && day !== 'time'
            ? [...days, day]
            : days
    ), []
);

export class ConfigQueryPlugin {
    _getStoreConfigFields = (args, callback) => ([
        ...callback(args),
        'cart_cms',
        'checkout_billing_cms',
        'checkout_shipping_cms',
        'contact_us_cms_block',
        'footer_cms',
        'contacts_cms',
        'header_cms',
        'header_menu',
        'head_includes',
        'minicart_cms',
        'attribute_to_display',
        'head_shortcut_icon'
    ]);

    _getStoreListFields = (args, callback) => ([
        ...callback(args),
        'locale'
    ]);
}

const {
    _getStoreConfigFields,
    _getStoreListFields
} = new ConfigQueryPlugin();

export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields,
            _getStoreListFields
        }
    }
};

export default config;

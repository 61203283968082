import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';
import Form from 'Component/Form';
import Image from 'Component/Image';
import { MixType } from 'Type/Common';

import {
    GIFT_CARD,
    STATIC_IMG_URL
} from './GiftCard.config';

import './GiftCard.style';

/** @namespace Pwasaas/Component/GiftCard/Component/GiftCardComponent */
export class GiftCardComponent extends FieldForm {
    static propTypes = {
        ...this.propTypes,
        mediaBaseUrl: PropTypes.string.isRequired,
        mix: MixType.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleToggleImage: PropTypes.func.isRequired,
        isImageVisible: PropTypes.bool.isRequired
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        return {
            from: {
                placeholder: __('From'),
                validation: ['notEmpty']
            },
            to: {
                placeholder: __('To'),
                validation: ['notEmpty']
            },
            message: {
                placeholder: __('Message'),
                type: 'textarea',
                validation: ['notEmpty']
            }
        };
    }

    renderGiftCardImage() {
        const {
            mediaBaseUrl,
            isImageVisible
        } = this.props;

        if (!isImageVisible) {
            return null;
        }

        return (
            <div
              block="GiftCard"
              elem="ImageWrapper"
            >
                <Image
                  src={ `${ mediaBaseUrl }${ STATIC_IMG_URL }` }
                  mix={ {
                      block: 'GiftCard',
                      elem: 'Image'
                  } }
                  ratio="custom"
                  alt="Gift card thumbnail"
                />
                <img
                  style={ { display: 'none' } }
                  alt="Gift card thumbnail"
                  src={ `${ mediaBaseUrl }${ STATIC_IMG_URL }` }
                />
            </div>
        );
    }

    renderActions() {
        const {
            handleToggleImage,
            isImageVisible
        } = this.props;

        const buttonValue = !isImageVisible
            ? __('View card')
            : __('Enter message');

        return (
            <div
              block="GiftCard"
              elem="FormActions"
            >
                <button
                  block="Button"
                  mix={ {
                      block: 'GiftCard',
                      elem: 'ToggleButton'
                  } }
                  mods={ {
                      isHollow: true
                  } }
                  onClick={ handleToggleImage }
                >
                    { buttonValue }
                </button>
                <button
                  block="Button"
                  mix={ {
                      block: 'GiftCard',
                      elem: 'FormSubmitButton'
                  } }
                  mods={ {
                      isAdd: true
                  } }
                  type="submit"
                >
                    { __('Add') }
                </button>
            </div>
        );
    }

    renderGiftCardFields() {
        const { isFormVisible } = this.props;

        if (!isFormVisible) {
            return null;
        }

        return this.renderFields();
    }

    renderHeading() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <div
              block="GiftCard"
              elem="Heading"
            >
                <strong>{ title }</strong>
            </div>
        );
    }

    render() {
        const {
            mix,
            handleSubmit
        } = this.props;

        return (
            <div
              block="GiftCard"
              elem="ContentBlock"
              mix={ mix }
            >
                { this.renderHeading() }
                { this.renderGiftCardImage() }
                <Form
                  name={ GIFT_CARD }
                  onSubmitSuccess={ handleSubmit }
                >
                    { this.renderGiftCardFields() }
                    { this.renderActions() }
                </Form>
            </div>
        );
    }
}

export default GiftCardComponent;

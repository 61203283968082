import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { getFilteredStoreData } from 'Util/Store';

import {
    SET_ACTIVE_INFO_WINDOW,
    SET_ACTIVE_STORE_PAGE,
    SET_FILTER_IS_LOADING,
    SET_IS_LOADING,
    UPDATE_CURRENT_LOCATION,
    UPDATE_FILTER_LIST,
    UPDATE_MAIN_PAGE,
    UPDATE_PICKUP_STORE,
    UPDATE_STORE_LOCATORS,
    UPDATE_STORE_SELECT_LIST
} from './StoreLocator.action';

export const SAAS_STORES = 'saas_stores';

/** @namespace Pwasaas/Store/StoreLocator/Reducer/filterData */
export const filterData = (stores) => stores.reduce(
    (acc, store) => {
        const data = {};
        data.filtered = getFilteredStoreData(store);

        if (data.filtered.cmsPage) {
            data.filtered = {
                ...data.filtered,
                cmsPage: getFilteredStoreData(data.filtered.cmsPage)
            };
        }

        return [
            ...acc,
            data.filtered
        ];
    }, []
);

export const {
    activePage = {},
    stores = []
} = BrowserDatabase.getItem(SAAS_STORES) || {
    activePage: {},
    stores: []
};

/** @namespace Pwasaas/Store/StoreLocator/Reducer/getInitialState */
export const getInitialState = () => ({
    activeInfoWindow: {},
    activeStorePage: activePage,
    currentLocation: {},
    filterIsLoading: false,
    isLoading: false,
    mainPage: {},
    pickupStore: null,
    stores,
    storeFilterList: stores,
    storeSelectList: []
});

/** @namespace Pwasaas/Store/StoreLocator/Reducer/StoreLocatorReducer */
export const StoreLocatorReducer = (state = getInitialState(), action) => {
    const {
        activeInfoWindow,
        storePage,
        currentLocation,
        filterIsLoading,
        isLoading,
        mainPage,
        pickupStore,
        stores,
        storeFilterList,
        storeSelectList,
        type
    } = action;

    switch (type) {
    case SET_ACTIVE_INFO_WINDOW:
        return {
            ...state,
            activeInfoWindow
        };

    case SET_ACTIVE_STORE_PAGE:
        const { cmsPage } = storePage;
        const omitSingle = (key, { [key]: _, ...obj }) => obj;

        const activeStorePage = !cmsPage
            ? storePage
            : {
                storeInfo: {
                    ...omitSingle('cmsPage', storePage)
                },
                ...cmsPage
            };

        BrowserDatabase.setItem(
            {
                ...BrowserDatabase.getItem(SAAS_STORES) || {},
                activePage: activeStorePage
            },
            SAAS_STORES,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            activeStorePage
        };

    case SET_FILTER_IS_LOADING:
        return {
            ...state,
            filterIsLoading
        };

    case SET_IS_LOADING:
        return {
            ...state,
            isLoading
        };

    case UPDATE_MAIN_PAGE:
        return {
            ...state,
            mainPage: getFilteredStoreData(mainPage)
        };

    case UPDATE_STORE_LOCATORS:
        const storeData = filterData(stores);

        BrowserDatabase.setItem(
            {
                ...BrowserDatabase.getItem(SAAS_STORES) || {},
                stores: storeData
            },
            SAAS_STORES,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            storeFilterList: storeData,
            stores: storeData
        };

    case UPDATE_FILTER_LIST:
        return {
            ...state,
            storeFilterList
        };

    case UPDATE_STORE_SELECT_LIST:
        if (!storeSelectList.length) {
            return {
                ...state
            };
        }
        const [{ id = null }] = storeSelectList;

        return {
            ...state,
            storeSelectList,
            pickupStore: id
        };

    case UPDATE_CURRENT_LOCATION:
        return {
            ...state,
            currentLocation
        };

    case UPDATE_PICKUP_STORE:
        return {
            ...state,
            pickupStore
        };

    default:
        return state;
    }
};

export default StoreLocatorReducer;

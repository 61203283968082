import { Field } from 'Util/Query';

/** @namespace Pwasaas/Plugin/MageWorxCustomProductOptions/Query/DependencyState/Query/DependencyStateQuery */
export class DependencyStateQuery {
    getQuery(productSku, selectedValues) {
        return new Field('dependencyState')
            .addArgument('productSku', 'String', productSku)
            .addArgument('selectedValues', 'String', selectedValues)
            .addFieldList([
                'hidden_options',
                'hidden_values',
                'preselected_values'
            ]);
    }
}

export default new DependencyStateQuery();

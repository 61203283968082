import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { SaasConfigType } from 'Type/Store';
import SessionDatabase from 'Util/SessionDatabase/SessionDatabase';

import AgeCheckPopup from './AgeCheckPopup.component';
import { AGE_CHECK_POPUP_ID } from './AgeCheckPopup.config';

/** @namespace Pwasaas/Component/AgeCheckPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    code: state.ConfigReducer.code,
    config: state.SaasConfigReducer.config
});

/** @namespace Pwasaas/Component/AgeCheckPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(AGE_CHECK_POPUP_ID, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Pwasaas/Component/AgeCheckPopup/Container/AgeCheckPopupContainer */
export class AgeCheckPopupContainer extends PureComponent {
    static propTypes = {
        activeOverlay: PropTypes.string.isRequired,
        code: PropTypes.string,
        config: SaasConfigType.isRequired,
        showPopup: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        code: ''
    };

    state = {
        isDeclined: false
    };

    containerFunctions = {
        handleDecline: this.handleDecline.bind(this),
        handleSubmit: this.handleSubmit.bind(this)
    };

    componentDidUpdate() {
        const {
            activeOverlay,
            config: {
                show_age_check: showAgeCheck
            } = {},
            showPopup
        } = this.props;

        const isVisible = showAgeCheck === '1'
            && !this._getIsAccepted();

        if (isVisible && activeOverlay !== AGE_CHECK_POPUP_ID) {
            showPopup({
                title: __('Age validation!')
            });
        }
    }

    containerProps = () => {
        const {
            config: {
                text_age_check: content = ''
            } = {}
        } = this.props;
        const { isDeclined } = this.state;

        return {
            content,
            isDeclined
        };
    };

    _getIsAccepted() {
        const { code } = this.props;
        const param = `${ AGE_CHECK_POPUP_ID }_${ code }`;

        return !!SessionDatabase.getItem(param);
    }

    handleDecline() {
        this.setState({ isDeclined: true });
    }

    handleSubmit() {
        const {
            code,
            hideActiveOverlay
        } = this.props;
        const param = `${ AGE_CHECK_POPUP_ID }_${ code }`;

        SessionDatabase.setItem(param, true);

        hideActiveOverlay();
    }

    render() {
        return (
            <AgeCheckPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgeCheckPopupContainer);

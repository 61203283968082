import { connect } from 'react-redux';

import LoginAsCustomerQuery from 'Query/LoginAsCustomer.query';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateMasterAccount } from 'Store/SaasConfig/SaasConfig.action';
import { SaasConfigType } from 'Type/Store';
import { setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { sortBySortOrder } from 'Util/Product';
import { fetchQuery } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountLoginAsCustomer from './MyAccountLoginAsCustomer.component';
import { AUTH_TOKEN, CUSTOMER } from './MyAccountLoginAsCustomer.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace Pwasaas/Component/MyAccountLoginAsCustomer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    config: state.SaasConfigReducer.config
});

/** @namespace Pwasaas/Component/MyAccountLoginAsCustomer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateCustomerSignInStatus: (status) => dispatch(updateCustomerSignInStatus(status)),
    updateInitialProductCompareData: () => ProductCompareDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
    ),
    updateInitialWishlistData: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
    )
});

/** @namespace Pwasaas/Component/MyAccountLoginAsCustomer/Container/MyAccountLoginAsCustomerContainer */
export class MyAccountLoginAsCustomerContainer extends DataContainer {
    static propTypes = {
        config: SaasConfigType.isRequired
    };

    state = {
        customerList: [],
        customerSearchResults: [],
        searchCriteria: ''
    };

    containerFunctions = {
        handleChange: this.handleChange.bind(this),
        handleLoginAs: this.handleLoginAs.bind(this),
        onSearchEnterPress: this.onSearchEnterPress.bind(this)
    };

    componentDidMount() {
        this._initializeCustomerList();
    }

    containerProps = () => {
        const {
            customerSearchResults,
            searchCriteria
        } = this.state;

        return {
            customerSearchResults,
            searchCriteria
        };
    };

    _initializeCustomerList() {
        this.fetchData(
            [LoginAsCustomerQuery.getCustomerListQuery()],
            ({ customerList: { customer: customerList = [] } }) => {
                this.setState({ customerList: sortBySortOrder(customerList, 'company') });
            }
        );
    }

    _getCustomerSearchResultsBy(criteria) {
        const { customerList } = this.state;

        return customerList.filter((customer) => Object.values(customer).some(
            (val) => String(val).toLowerCase().includes(criteria.toLowerCase())
        ));
    }

    async _setCustomerDataAndRedirect(
        email,
        token
    ) {
        const {
            customer: {
                firstname,
                lastname
            },
            requestCustomerData,
            showNotification,
            updateCustomerSignInStatus,
            updateInitialProductCompareData,
            updateInitialWishlistData
        } = this.props;

        if (token.includes('nope')) {
            showNotification('error', __('Did not received a valid token.'));
            return;
        }

        setAuthorizationToken(token);
        updateCustomerSignInStatus(true);
        await requestCustomerData();

        updateMasterAccount(`${firstname } ${ lastname}`);
        updateInitialProductCompareData();
        updateInitialWishlistData();

        const { store_base_url: redirectUrl = '' } = BrowserDatabase.getItem(CUSTOMER);

        if (window.location.origin !== redirectUrl) {
            BrowserDatabase.deleteItem(AUTH_TOKEN);
            BrowserDatabase.deleteItem(CUSTOMER);

            window.location = `${redirectUrl}my-account?masteraccount=${firstname } ${ lastname}&token=${token}`;

            return;
        }

        showNotification('success', __('You are now logged in with %s as email', email));

        location.href = window.location.origin + appendWithStoreCode(
            `/my-account?masteraccount=${firstname } ${ lastname}`
        );
    }

    handleChange(e) {
        const { target: { value } } = e;

        this.setState({
            customerSearchResults: this._getCustomerSearchResultsBy(value),
            searchCriteria: value
        });
    }

    async handleLoginAs(email) {
        const {
            config: { secret = '' },
            showNotification
        } = this.props;

        await fetchQuery(
            LoginAsCustomerQuery.getCustomerTokenByEmailQuery(email, secret)
        ).then(
            /** @namespace Pwasaas/Component/MyAccountLoginAsCustomer/Container/fetchQuery/then */
            ({ getCustomerTokenByEmail: token }) => {
                this._setCustomerDataAndRedirect(email, token);
            },
            /** @namespace Pwasaas/Component/MyAccountLoginAsCustomer/Container/fetchQuery/then */
            (error) => showNotification('error', JSON.stringify(error))
        );
    }

    onSearchEnterPress(e) {
        const { key, target: { value } } = e;
        const { searchCriteria } = this.state;
        const trimmedSearch = searchCriteria.trim();

        if (key === 'Enter' && trimmedSearch !== '') {
            if (searchCriteria === value) {
                return;
            }

            this.setState({
                customerSearchResults: this._getCustomerSearchResultsBy(value),
                searchCriteria: value
            });
        }
    }

    render() {
        return (
            <MyAccountLoginAsCustomer
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountLoginAsCustomerContainer);

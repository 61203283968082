import {
    mapStateToProps,
    ProductConfigSetQuantity
} from '../util/ProductConfig';

export class OrderListItemContainer extends ProductConfigSetQuantity {}

const {
    containerProps,
    state
} = new OrderListItemContainer();

export default {
    'Pwasaas/Component/OrderListItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Pwasaas/Component/OrderListItem/Container/OrderListItemContainer': {
        'member-function': {
            containerProps,
            state
        }
    }
};

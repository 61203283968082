export class AddResetFunctionToCategoryFilterOverlayPlugin {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onResetButtonClick: this.onResetButtonClick.bind(instance)
    });

    onResetButtonClick() {
        const {
            goToPreviousHeaderState,
            goToPreviousNavigationState
        } = this.props;

        goToPreviousHeaderState();
        goToPreviousNavigationState();
    }
}

const {
    containerFunctions
} = new AddResetFunctionToCategoryFilterOverlayPlugin();

export default {
    'Component/CategoryFilterOverlay/Container': {
        'member-property': {
            containerFunctions
        }
    }
};

import {
    ROUTE_BLOG,
    ROUTE_RECIPES,
    ROUTE_STORES
} from 'Component/Router/Router.custom.config';

export const customUrlMapping = {
    [ROUTE_BLOG]: __('blog'),
    [ROUTE_RECIPES]: __('recipes'),
    [ROUTE_STORES]: __('stores')
};

/** @namespace Pwasaas/Util/Saas/CustomUrl/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Pwasaas/Util/Saas/CustomUrl/getCustomRoute */
export const getCustomRoute = (route) => customUrlMapping[route].toLowerCase();

/** @namespace Pwasaas/Util/Saas/CustomUrl/getCustomRouteCollection */
export const getCustomRouteCollection = (
    routes,
    subRoutes = []
) => routes.reduce(
    (urls, route) => {
        const currentRoute = customUrlMapping[route].toLowerCase();

        if (currentRoute) {
            const customUrl = [currentRoute];

            subRoutes.forEach((routeParam) => {
                customUrl.push(routeParam);
            });

            urls.push(withStoreRegex(`/${customUrl.join('/')}`));
        }

        return urls;
    }, []
);

/**
 * Force update of product data on component did mount
 */

import { IN_STOCK, OUT_OF_STOCK } from 'Component/ProductCard/ProductCard.config';

import { PRODUCT_DATA_FIX_ENABLED } from './ProductData.config';

export class ProductPageContainer {
    componentDidMount = (args, callback, instance) => {
        if (!PRODUCT_DATA_FIX_ENABLED) {
            callback(...args);
        }

        instance.requestProduct();

        callback(...args);
    };

    getProductOrVariant = (args, callback, instance) => {
        const dataSource = instance.getDataSource();
        const { variants } = dataSource;
        const currentVariantIndex = instance.getConfigurableVariantIndex(variants);
        const variant = variants && variants[currentVariantIndex];

        if (variants?.length > 0) {
            dataSource.stock_status = variants.some((v) => v.stock_status === IN_STOCK) ? IN_STOCK : OUT_OF_STOCK;
        }

        /** Addition to 'getProductinoOrVariant' function: use configurable media gallery when there are no simple product images */
        if (variant) {
            const { media_gallery_entries = [] } = variant;

            if (media_gallery_entries.length === 0) {
                variant.media_gallery_entries = dataSource.media_gallery_entries;
            }
        }

        return variant || dataSource;
    };
}

const {
    componentDidMount,
    getProductOrVariant
} = new ProductPageContainer();

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidMount,
            getProductOrVariant
        }
    }
};

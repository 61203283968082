import { showPopup } from 'Store/Popup/Popup.action';

import { IN_BACKORDER_LABEL } from '../../ShowProductStock/component/ProductStock/ProductStock.config';
import ExceedStockPopup from '../component/ExceedStockPopup';
import { EXCEED_STOCK_POPUP_ID } from '../component/ExceedStockPopup/ExceedStockPopup.config';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    const {
        show_exceed_qty_popup,
        exceed_qty_content
    } = state.SaasConfigReducer?.product || {};

    const {
        cartTotals: {
            items: cartItems
        }
    } = state.CartReducer;

    return {
        ...callback(state),
        cartItems,
        show_exceed_qty_popup,
        exceed_qty_content
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        showPopup: (payload) => dispatch(showPopup(EXCEED_STOCK_POPUP_ID, payload))
    };
};

export class AddToCartContainer {
    containerProps = (args, callback, instance) => {
        const {
            show_exceed_qty_popup = false,
            exceed_qty_content
        } = instance.props;

        return {
            ...callback(...args),
            show_exceed_qty_popup,
            exceed_qty_content
        };
    };

    openPopup = (instance) => {
        const {
            showPopup,
            product: {
                name,
                stock_item: {
                    qty
                }
            }
        } = instance.props;

        showPopup({ qty, name });
    };

    addProductToCart = (args, callback, instance) => {
        const {
            show_exceed_qty_popup,
            product: {
                sku,
                type_id,
                stock_status,
                stock_item: {
                    qty: availableQty // product available
                }
            },
            quantity: inputQty, // input
            cartItems
        } = instance.props;

        // check if the item already has qty in the cart
        // and if the sum of the new required qty and the one already in the cart exceed the stock qty
        const doesExceedWithCartQty = cartItems.length === 0 ? inputQty > availableQty
            : cartItems.some((item) => {
                if (item.sku === sku) {
                    return (item.qty + inputQty) > availableQty;
                }

                return inputQty > availableQty;
            });

        // if all conditions apply -> show exceedStockPopup
        if (show_exceed_qty_popup // if feature is enabled
            && doesExceedWithCartQty // if the reqQty and cartQty exceed availableQty
            && stock_status !== IN_BACKORDER_LABEL // if item doesn't have backorder enabled
            && availableQty > 0) { // if the available qty is indeed higher than 0
            instance.setState({ isLoading: false });
            this.openPopup(instance);
            return;
        }

        const addToCartHandler = instance.addToCartHandlerMap[type_id];

        if (addToCartHandler) {
            addToCartHandler();

            return;
        }

        instance.addSimpleProductToCart();
    };

    renderPopup() {
        return (
            <ExceedStockPopup />
        );
    }

    render = (args, callback, instance) => {
        const {
            show_exceed_qty_popup
        } = instance.props;

        if (!show_exceed_qty_popup) {
            return (
                { ...callback(...args) }
            );
        }

        return (
            <>
                { callback(args) }
                { this.renderPopup() }
            </>
        );
    };
}

const {
    render,
    containerProps,
    addProductToCart
} = new AddToCartContainer();

export default {
    'Component/AddToCart/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/AddToCart/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/AddToCart/Container': {
        'member-function': {
            containerProps,
            addProductToCart
        }
    },
    'Component/AddToCart/Component': {
        'member-function': {
            render
        }
    }
};

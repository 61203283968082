import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import {
    formatPrice,
    roundPrice
} from 'Util/Price';

import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
} from './ProductPrice.config';

/**
 * Product price
 * @class ProductPrice
 * @namespace Pwasaas/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    /**
     * For some reason on certain products the currency is not available on the regular_price object
     * Added the currency from final_price to fix US Dollars on regular price (because its undefined)
     * Price normally is collected in ProductActions.container.js with getProductPrice() class method;
     */
    getRoundedRegularPrice() {
        const {
            price: {
                minimum_price: {
                    final_price: {
                        currency: priceCurrency
                    },
                    regular_price: {
                        value: regularPriceValue = 0
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue = 0
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice
        } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return formatPrice(roundPrice(regularPriceExclTaxValue), priceCurrency);
        }

        return formatPrice(roundPrice(regularPriceValue), priceCurrency);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { AGE_CHECK_POPUP_ID } from './AgeCheckPopup.config';

import './AgeCheckPopup.style';

/** @namespace Pwasaas/Component/AgeCheckPopup/Component/AgeCheckPopupComponent */
export class AgeCheckPopupComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired,
        handleDecline: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isDeclined: PropTypes.bool.isRequired
    };

    renderNotice() {
        const { content } = this.props;

        if (!content) {
            return null;
        }

        return (
            <p>
                { content }
            </p>
        );
    }

    renderAcceptButton() {
        const { handleSubmit } = this.props;

        return (
            <button
              type="submit"
              block="AgeCheckPopup"
              elem="SubmitButton"
              mix={ { block: 'Button' } }
              onClick={ handleSubmit }
            >
                { __('I am 18 years or older') }
            </button>
        );
    }

    renderDeclineButton() {
        const { handleDecline } = this.props;

        return (
            <button
              type="submit"
              block="AgeCheckPopup"
              elem="DeclineButton"
              mix={ {
                  block: 'Button',
                  mods: { isHollow: true }
              } }
              onClick={ handleDecline }
            >
                { __('I am under 18 years old') }
            </button>
        );
    }

    renderButtons() {
        return (
            <div block="AgeCheckPopup" elem="Buttons">
                { this.renderAcceptButton() }
                { this.renderDeclineButton() }
            </div>
        );
    }

    renderContent() {
        return (
            <div block="AgeCheckPopup" elem="Content">
                { this.renderNotice() }
                { this.renderButtons() }
            </div>
        );
    }

    render() {
        const { isDeclined } = this.props;

        return (
            <Popup
              id={ AGE_CHECK_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'AgeCheckPopup' } }
              hideContent={ isDeclined }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default AgeCheckPopupComponent;

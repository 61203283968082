export class AddStoreBaseUrlToCustomerQueryPlugin {
    _getCustomerFields = (args, callback) => ([
        ...callback(args),
        'store_base_url'
    ]);
}

const {
    _getCustomerFields
} = new AddStoreBaseUrlToCustomerQueryPlugin();

export const config = {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields
        }
    }
};

export default config;

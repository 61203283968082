import PropTypes from 'prop-types';

import {
    CHECKBOX_TYPE,
    DATE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SAAS_INPUT_SELECT_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE
} from 'Component/Field/Field.config';

const propTypes = (originalMember) => ({
    ...originalMember,
    type: PropTypes.oneOf([
        TEXT_TYPE,
        CHECKBOX_TYPE,
        DATE_TYPE,
        NUMBER_TYPE,
        PASSWORD_TYPE,
        RADIO_TYPE,
        SAAS_INPUT_SELECT_TYPE,
        SELECT_TYPE,
        TEXTAREA_TYPE
    ]).isRequired
});

export default {
    'Component/Field/Container': {
        'static-member': {
            propTypes
        }
    }
};

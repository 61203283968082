import getStore from 'Util/Store';

export class RemoveActionButtonsIfNotEditablePlugin {
    removeActionButtonsFrom = (args, callback, instance) => {
        const { config: { address_is_editable = false } } = instance.props;

        if (!address_is_editable) {
            return null;
        }

        return callback(instance, args);
    };

    containerProps = (args, callback, instance) => {
        const { config } = instance.props;

        if (!config) {
            const { getState } = getStore();
            const { SaasConfigReducer: { config } } = getState();

            return {
                ...callback(...args),
                config
            };
        }

        return {
            ...callback(...args),
            config
        };
    };
}

const {
    removeActionButtonsFrom,
    containerProps
} = new RemoveActionButtonsIfNotEditablePlugin();

export default {
    'Component/MyAccountAddressBook/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/MyAccountAddressBook/Component': {
        'member-function': {
            renderActions: removeActionButtonsFrom
        }
    },
    'Component/MyAccountCustomerTable/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/MyAccountCustomerTable/Component': {
        'member-function': {
            renderActions: removeActionButtonsFrom
        }
    },
    'Component/MyAccountAddressTable/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/MyAccountAddressTable/Component': {
        'member-function': {
            renderActions: removeActionButtonsFrom
        }
    },
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/CheckoutAddressBook/Component': {
        'member-function': {
            renderOptionalCustomAddress: removeActionButtonsFrom
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderSameAsShippingCheckbox: removeActionButtonsFrom,
            renderHeading: removeActionButtonsFrom
        }
    }
};

import { getInitialState } from 'Store/Config/Config.reducer';

export const UPDATE_HEADER_LOGO = 'UPDATE_HEADER_LOGO';

export const updateHeaderLogo = (logoConfig) => ({
    type: UPDATE_HEADER_LOGO,
    logoConfig
});

export const SaasConfigReducer = (args, callback) => {
    const [state = getInitialState(), action] = args;

    const {
        type,
        logoConfig
    } = action;

    switch (type) {
    case UPDATE_HEADER_LOGO:
        return {
            ...state,
            ...logoConfig
        };

    default:
        return callback(...args);
    }
};

export default {
    'Store/Config/Reducer': {
        function: SaasConfigReducer
    }
};

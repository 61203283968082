import { UPDATE_CHECKOUT_CUSTOM_FIELDS } from './CheckoutCustomFields.action';

/** @namespace Pwasaas/Store/CheckoutCustomFields/Reducer/getInitialState */
export const getInitialState = () => ({
    field: '',
    value: ''
});

/** @namespace Pwasaas/Store/CheckoutCustomFields/Reducer/CheckoutCustomFieldsReducer */
export const CheckoutCustomFieldsReducer = (state = getInitialState(), action) => {
    const {
        field,
        type,
        value
    } = action;

    if (type === UPDATE_CHECKOUT_CUSTOM_FIELDS) {
        return {
            ...state,
            field,
            value
        };
    }

    return state;
};

export default CheckoutCustomFieldsReducer;

export class FieldContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onDateChange: this.onDateChange.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const { availableDateRange } = instance.props;

        return {
            ...callback(...args),
            availableDateRange
        };
    };

    onDateChange(value) {
        const { onChange } = this.props;

        onChange(value);
    }
}

const {
    containerFunctions,
    containerProps
} = new FieldContainer();

export default {
    'Component/Field/Container': {
        'member-function': {
            containerProps
        },
        'member-property': {
            containerFunctions
        }
    }
};

import { cloneElement } from 'react';

export class ProductCardComponent {
    render = (args, callback, instance) => {
        const { listItemView } = instance.props;
        const renderResult = callback(...args);

        if (!listItemView) {
            return renderResult;
        }

        const { props, props: { className: currentClass } } = renderResult;
        const bemClass = `${currentClass.split(' ')[0] || ''}_listItemView_${listItemView}`;

        return cloneElement(renderResult, {
            ...props,
            className: `${currentClass} ${bemClass}`
        });
    };
}

const {
    render
} = new ProductCardComponent();

export default {
    'Pwasaas/Component/ProductCard/Component/ProductCardComponent': {
        'member-function': {
            render
        }
    }
};

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX } from 'Component/ProductPrice/ProductPrice.config';
import ProductListQuery from 'Query/ProductList.query';
import { ProductLinksType, ProductType } from 'Type/ProductList';
import DataContainer from 'Util/Request/DataContainer';

import {
    clearItems,
    updateCurrentQty
} from '../../store/CompleteOrder/CompleteOrder.action';
import CompleteOrder from './CompleteOrder.component';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currencyCode: state.ConfigReducer.currencyData.current_currency_code,
    currentQuantity: state.CompleteOrderReducer.quantity,
    displayTaxInPrice: state.ConfigReducer.priceTaxDisplay?.product_price_display_type,
    product: state.ProductReducer?.product || {},
    selected: state.CompleteOrderReducer.selected
});

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrder/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    clearItems: () => dispatch(clearItems()),
    clearCurrentQty: () => dispatch(updateCurrentQty(0))
});

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrder/Container/CompleteOrderContainer */
export class CompleteOrderContainer extends DataContainer {
    static propTypes = {
        currencyCode: PropTypes.string.isRequired,
        currentQuantity: PropTypes.number.isRequired,
        displayTaxInPrice: PropTypes.string,
        isProductLinksType: PropTypes.bool,
        items: PropTypes.oneOfType([
            ProductLinksType,
            PropTypes.arrayOf(ProductType)
        ]).isRequired,
        onItemChange: PropTypes.func.isRequired,
        product: ProductType.isRequired,
        selected: PropTypes.arrayOf(ProductType).isRequired
    };

    static defaultProps = {
        displayTaxInPrice: DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX,
        isProductLinksType: false
    };

    containerFunctions = {
        handleItemChange: this.onItemChange.bind(this)
    };

    state = {
        availableItems: []
    };

    componentDidMount() {
        const {
            items,
            isProductLinksType
        } = this.props;

        if (!items.length) {
            return;
        }

        if (!isProductLinksType) {
            this.setState({ availableItems: items });

            return;
        }

        this.fetchData(
            [ProductListQuery.getQuery(this.getProductQueryOptions())],
            ({ products: { items = [] } }) => {
                this.setState({ availableItems: items });
            }
        );
    }

    componentWillUnmount() {
        const {
            clearItems,
            clearCurrentQty
        } = this.props;

        clearItems();
        clearCurrentQty();
    }

    containerProps = () => {
        const {
            props: {
                currencyCode,
                currentQuantity,
                displayTaxInPrice,
                product,
                selected
            },
            state: {
                availableItems
            }
        } = this;

        return {
            availableItems,
            currencyCode,
            currentQuantity,
            displayTaxInPrice,
            product,
            selected
        };
    };

    getProductQueryOptions() {
        const { items } = this.props;
        const skus = items.map((item) => item.linked_product_sku.replace(/ /g, '%20'));

        return {
            args: {
                filter: {
                    productsSkuArray: skus
                }
            },
            notRequireInfo: true
        };
    }

    onItemChange(item, type) {
        const { onItemChange } = this.props;

        onItemChange(item, type);
    }

    render() {
        return (
            <CompleteOrder
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteOrderContainer);

import {
    cloneElement
} from 'react';

export const addNoImageClassTo = (args, callback, instance) => {
    const RenderedComponent = callback(instance, args);
    const { props, props: { children = [], className: currentClass = '' } } = RenderedComponent;

    return cloneElement(RenderedComponent, {
        ...props,
        children,
        className: `${currentClass} ${currentClass}_noImage`
    });
};

export default {
    'Route/SearchPage/Component': {
        'member-function': {
            renderCategoryDetails: addNoImageClassTo
        }
    }
};

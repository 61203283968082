import {
    Breadcrumbs as SourceBreadcrumbs
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

import './Breadcrumbs.override.manual.style';

/** @namespace Pwasaas/Component/Breadcrumbs/Component/BreadcrumbsComponent */
export class BreadcrumbsComponent extends SourceBreadcrumbs {}

export default BreadcrumbsComponent;

/**
 * Add stock status to grouped product items after product sku
 */
import { cloneElement } from 'react';

import ProductStock from '../component/ProductStock';
import { getStockStatus } from '../util/ProductStock';

export class GroupedProductsItemComponent {
    renderTitle = (args, callback, instance) => {
        const {
            backorders,
            product: {
                stock_item,
                stock_item: {
                    in_stock
                } = {},
                stock_status
            } = {},
            showProductStockIcon,
            showProductStockLabel,
            showProductStockStatus,
            showProductStockWhenInStock
        } = instance.props;

        if (!showProductStockStatus || (!in_stock && showProductStockWhenInStock)) {
            return callback(instance, args);
        }

        const RenderedComponent = callback(instance, args);
        const { props, props: { children: baseChildren } } = RenderedComponent;

        const index = baseChildren.findIndex((item) => {
            const { className = '' } = item.props;

            return new RegExp('ProductSku', 'i').test(className);
        }) + 1;

        const children = [
            ...baseChildren.slice(0, index),
            <ProductStock
              isWithIcon={ showProductStockIcon }
              isWithLabel={ showProductStockLabel }
              mix={ {
                  block: 'GroupedProductsItem',
                  elem: 'ProductStock'
              } }
              status={ getStockStatus(stock_item, backorders, stock_status) }
            />,
            ...baseChildren.slice(index)
        ];

        return cloneElement(RenderedComponent, {
            ...props,
            children
        });
    };
}

const {
    renderTitle
} = new GroupedProductsItemComponent();

export default {
    'Component/GroupedProductsItem/Component': {
        'member-function': {
            renderTitle
        }
    }
};

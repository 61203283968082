import {
    COMPLETEORDER_ADD_ITEM,
    COMPLETEORDER_CLEAR_ITEMS,
    COMPLETEORDER_EDIT_ITEM,
    COMPLETEORDER_REMOVE_ITEM,
    COMPLETEORDER_UPDATE_CURRENT_QTY
} from './CompleteOrder.action';

/** @namespace Pwasaas/Plugin/CompleteOrder/Store/CompleteOrder/Reducer/getInitialState */
export const getInitialState = () => ({
    selected: [],
    quantity: 0
});

/** @namespace Pwasaas/Plugin/CompleteOrder/Store/CompleteOrder/Reducer/CompleteOrderReducer */
export const CompleteOrderReducer = (state = getInitialState(), action) => {
    const {
        item,
        quantity,
        type
    } = action;

    switch (type) {
    case COMPLETEORDER_ADD_ITEM:
        return {
            ...state,
            selected: [
                ...state.selected,
                item
            ]
        };

    case COMPLETEORDER_CLEAR_ITEMS:
        return {
            ...state,
            selected: []
        };

    case COMPLETEORDER_EDIT_ITEM:
        const productIndex = state.selected.findIndex(({ id }) => id === item.id);

        return {
            ...state,
            selected: state.selected.reduce(
                (acc, current, index) => [
                    ...acc,
                    productIndex === index
                        ? item
                        : current
                ], []
            )
        };

    case COMPLETEORDER_REMOVE_ITEM:
        return {
            selected: state.selected.filter(({ id }) => id !== item.id)
        };

    case COMPLETEORDER_UPDATE_CURRENT_QTY:
        return {
            ...state,
            quantity
        };

    default:
        return state;
    }
};

export default CompleteOrderReducer;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import MenuLink from 'Component/MenuLink';
import { DeviceType } from 'Type/Device';
import { MenuItemModsType, MenuItemType } from 'Type/SaasMenu';

import { LINK_TYPE_DISABLE_LINK } from './MenuColumn.config';

import './MenuColumn.style';

/** @namespace Pwasaas/Component/MenuColumn/Component/MenuColumnComponent */
export class MenuColumnComponent extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
        handleItemToggle: PropTypes.func.isRequired,
        handleLinkClick: PropTypes.func.isRequired,
        isWithButton: PropTypes.bool.isRequired,
        item: MenuItemType.isRequired,
        itemMods: MenuItemModsType.isRequired
    };

    renderMenuColumnListItem = (item) => {
        const {
            handleLinkClick,
            itemMods
        } = this.props;
        const { id } = item;

        return (
            <li
              block="MenuColumn"
              elem="ListItem"
              key={ id }
            >
                <MenuLink
                  item={ item }
                  itemMods={ {
                      ...itemMods,
                      isLink: true
                  } }
                  onItemClick={ handleLinkClick }
                />
            </li>
        );
    };

    renderMenuColumnHeading() {
        const {
            device,
            handleItemToggle,
            isActive,
            item,
            item: { link_type = '' },
            itemMods
        } = this.props;

        return (
            <MenuLink
              item={ item }
              itemMods={ {
                  ...itemMods,
                  isActive: device.isMobile
                      ? isActive
                      : false,
                  isLink: (!device.isMobile && link_type !== LINK_TYPE_DISABLE_LINK)
              } }
              mix={ {
                  block: 'MenuLink',
                  elem: !device.isMobile
                      ? 'Title'
                      : 'Item'
              } }
              onItemClick={ handleItemToggle }
            />
        );
    }

    renderMenuColumnButton() {
        const {
            handleLinkClick,
            isWithButton,
            item: {
                link
            }
        } = this.props;

        if (!isWithButton) {
            return null;
        }

        return (
            <Link
              block="MenuColumn"
              elem="Button"
              mix={ {
                  block: 'Button',
                  mods: {
                      isGrey: true,
                      isSmall: true
                  }
              } }
              to={ link }
              tabIndex="0"
              onClick={ handleLinkClick }
            >
                { __('Show all') }
            </Link>
        );
    }

    renderMenuColumnList() {
        const {
            isActive,
            isWithButton,
            item: {
                childs = []
            }
        } = this.props;

        if (!childs || !childs.length) {
            return null;
        }

        return (
            <ul
              block="MenuColumn"
              elem="List"
              mods={ {
                  isActive,
                  type: isWithButton ? 'button' : ''
              } }
            >
                { childs.map(this.renderMenuColumnListItem) }
                { this.renderMenuColumnButton() }
            </ul>
        );
    }

    render() {
        return (
            <div
              block="MenuColumn"
              elem="Container"
            >
                { this.renderMenuColumnHeading() }
                { this.renderMenuColumnList() }
            </div>
        );
    }
}

export default MenuColumnComponent;

/**
 * Show images from first child in slider on grouped pdp
 */

export class ProductGalleryContainer {
    getGalleryPictures = (args, callback, instance) => {
        const {
            product: {
                media_gallery_entries: mediaGallery = [],
                type_id
            }
        } = instance.props;

        if (!mediaGallery.length && type_id === 'grouped') {
            const {
                product: {
                    items = []
                }
            } = instance.props;

            if (items.length) {
                const {
                    product: {
                        media_gallery_entries: mediaGallery = []
                    }
                } = items[0];

                if (mediaGallery.length) {
                    return mediaGallery
                        .filter(({ disabled }) => !disabled)
                        .sort((a, b) => a.position - b.position);
                }
            }
        }

        return callback(...args);
    };
}

const {
    getGalleryPictures
} = new ProductGalleryContainer();

export default {
    'Component/ProductGallery/Container': {
        'member-function': {
            getGalleryPictures
        }
    }
};

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    updateActiveMenuItem,
    updateActiveNavigationItem
} from 'Store/SaasMenu/SaasMenu.action';
import { DeviceType } from 'Type/Device';
import { MenuItemType } from 'Type/SaasMenu';
import { getBannerItem } from 'Util/Saas';

import MenuNavigation from './MenuNavigation.component';
import { NAVIGATION_MAIN_ITEM_LEVEL } from './MenuNavigation.config';

export const SaasMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SaasMenu/SaasMenu.dispatcher'
);

/** @namespace Pwasaas/Component/MenuNavigation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeMenuItem: state.SaasMenuReducer.activeMenuItem,
    activeNavigationItem: state.SaasMenuReducer.activeNavigationItem,
    device: state.ConfigReducer.device,
    history: state.SaasMenuReducer.history,
    items: state.SaasMenuReducer.header.items || [],
    toggleItems: state.SaasMenuReducer.header.toggleItems || []
});

/** @namespace Pwasaas/Component/MenuNavigation/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => SaasMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeMenu(dispatch)
    ),
    setActiveMenuItem: (item) => dispatch(updateActiveMenuItem(item)),
    setActiveNavigationItem: (item) => dispatch(updateActiveNavigationItem(item))
});

/** @namespace Pwasaas/Component/MenuNavigation/Container/MenuNavigationContainer */
export class MenuNavigationContainer extends PureComponent {
    static propTypes = {
        activeMenuItem: MenuItemType.isRequired,
        activeNavigationItem: MenuItemType.isRequired,
        closeMenu: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        history: PropTypes.arrayOf(MenuItemType).isRequired,
        items: PropTypes.arrayOf(MenuItemType).isRequired,
        setActiveMenuItem: PropTypes.func.isRequired,
        setActiveNavigationItem: PropTypes.func.isRequired,
        toggleItems: PropTypes.arrayOf(MenuItemType).isRequired
    };

    containerFunctions = {
        handleOnClose: this.handleOnClose.bind(this),
        handlePrevious: this.handlePrevious.bind(this)
    };

    componentDidMount() {
        const {
            activeMenuItem: {
                childs = []
            },
            device,
            setActiveNavigationItem
        } = this.props;

        if (device.isMobile) {
            return;
        }

        if (!childs || !childs.length) {
            return;
        }

        setActiveNavigationItem(childs[0]);
    }

    containerProps = () => {
        const { device } = this.props;

        return {
            activeNavigationItem: this._getActiveItem(),
            bannerItem: this._getBannerItem(),
            device,
            items: this._getItems(),
            isWithHistory: this._getIsWithHistory(),
            isWithIcons: this._getIsWithIcons()
        };
    };

    _getActiveItem() {
        const {
            activeMenuItem,
            activeNavigationItem,
            device
        } = this.props;

        return Object.keys(activeNavigationItem).length && device.isMobile
            ? activeNavigationItem
            : activeMenuItem;
    }

    /**
     * TODO: Add new attribute in backend for image url & visibility on mobile
    */
    _getBannerItem() {
        const {
            activeMenuItem: {
                mega_setting: {
                    description = ''
                },
                title = ''
            },
            activeNavigationItem: {
                title: navTitle = ''
            }
        } = this.props;

        const activeItem = {
            ...this._getActiveItem(),
            title: navTitle || title
        };

        return getBannerItem(activeItem, description);
    }

    /**
     * TODO: Add new attribute in backend for icons visibility on mobile
    */
    _getIsWithIcons() {
        const { device } = this.props;
        const { childs, level } = this._getActiveItem();

        const hasIcons = childs.some(({ icon_image }) => icon_image !== '');

        return device.isMobile
            && level === NAVIGATION_MAIN_ITEM_LEVEL
                && hasIcons;
    }

    _getItems() {
        const { childs = [] } = this._getActiveItem();

        if (!childs) {
            return [];
        }

        return childs;
    }

    _getIsWithHistory() {
        const {
            device,
            history
        } = this.props;

        return device.isMobile && history.length > 1;
    }

    handleOnClose() {
        const { closeMenu } = this.props;

        closeMenu();
    }

    handlePrevious() {
        const {
            history,
            setActiveNavigationItem
        } = this.props;

        const activeItem = this._getActiveItem();
        const previousItem = history[history.length - 2] || {};

        if (activeItem.id === previousItem.id) {
            setActiveNavigationItem({});

            return;
        }

        setActiveNavigationItem(previousItem);
    }

    render() {
        return (
            <MenuNavigation
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuNavigationContainer);

import PropTypes from 'prop-types';

export const staticFieldContainer = (FieldContainer) => {
    Object.assign(FieldContainer, {
        propTypes: {
            // eslint-disable-next-line react/forbid-foreign-prop-types
            ...FieldContainer.propTypes,
            info: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({})
            ])
        }
    });

    return FieldContainer;
};

export class FieldContainer {
    containerProps = (args, callback, instance) => {
        const { info } = instance.props;

        return {
            ...callback(...args),
            info
        };
    };
}

const {
    containerProps
} = new FieldContainer();

export default {
    'Component/Field/Container': {
        class: staticFieldContainer,
        'member-function': {
            containerProps
        }
    }
};

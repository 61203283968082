import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.manual.style';

/** @namespace Pwasaas/Component/ProductConfigurableAttributes/Component/ProductConfigurableAttributesComponent */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
    render() {
        const { configurable_options } = this.props;

        if (Object.keys(configurable_options).length === 0) {
            return null;
        }

        return super.render();
    }
}

export default ProductConfigurableAttributesComponent;

export class ProductActionsComponent {
    renderReviews = (args, callback, instance) => {
        const { isReviewEnabled } = instance.props;

        if (!isReviewEnabled) {
            return null;
        }

        return callback.apply(instance, args);
    };
}

const {
    renderReviews
} = new ProductActionsComponent();

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderReviews
        }
    },
    'Pwasaas/Component/ProductActions/Component/ProductActionsComponent': {
        'member-function': {
            renderReviews
        }
    }
};

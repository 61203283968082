import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/Banner/Query/BannerQuery */
export class BannerQuery {
    __construct() {
        super.__construct();
    }

    getQuery({ groupId, storeCode }) {
        return new Field('banners')
            .addArgument('identifier', 'Int!', groupId)
            .addArgument('store_code', 'String!', storeCode)
            .addFieldList(this._getBannerFields())
            .setAlias('bannerGroup');
    }

    _getBannerFields() {
        return [
            'group_id',
            'identifier',
            'name',
            'type',
            'is_slider',
            'qty_per_row',
            'has_arrows',
            'has_pagination',
            'autoplay_duration',
            'css_class',
            'slides_amount_on_mobile',
            this._getBannerItemsField()
        ];
    }

    _getBannerItemsField() {
        return new Field('items')
            .addFieldList(this._getBannerItemFields());
    }

    _getBannerItemFields() {
        return [
            'item_id',
            'name',
            'position',
            'image',
            'image_clickable_link',
            'image_gradient',
            'detail_image',
            'hover_image',
            'content_title',
            'content_subtitle',
            'content',
            'product_sku',
            'button_title',
            'button_url',
            'active_from',
            'css_class'
        ];
    }
}

export default new BannerQuery();

import { cloneElement } from 'react';

export class BaseComponent {
    render = (args, callback, instance) => {
        const { children: issuers } = instance.props;
        const result = callback(...args);

        return cloneElement(result, {
            ...result.props,
            children: [result.props.children, issuers]
        });
    };
}

const {
    render
} = new BaseComponent();

export default {
    'ScandipwaMultisafepayPaymentIntegration/Component/BaseComponent/Component/BaseComponentComponent': {
        'member-function': {
            render
        }
    }
};

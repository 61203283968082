import { Field } from 'Util/Query';

/** @namespace Pwasaas/Plugin/SalesPromotion/Query/SalesPromotion/Query/SalesPromotionQuery */
export class SalesPromotionQuery {
    getMutation(fields, validateCoupons) {
        return new Field('addSalesPromotionCandidate')
            .addArgument('input', 'SalesPromotionCandidateInput!', fields)
            .addArgument('validateCoupons', 'Boolean!', validateCoupons)
            .addFieldList(this._getPromotionFieldList());
    }

    getDataByIdQuery(id) {
        return new Field('salesPromotionData')
            .addArgument('id', 'Int!', id)
            .addFieldList(this._getPromotionFieldList());
    }

    _getPromotionFieldList() {
        return [
            'cms_identifier_success',
            'date_from',
            'date_to'
        ];
    }
}

export default new SalesPromotionQuery();

import {
    AVAILABLE_DAYS,
    ORDER_DATE_FIELDS
} from 'Util/OrderDate';

export class MyAccountQueryPlugin {
    _getCustomerFields = (args, callback) => ([
        ...callback(args),
        ...this._getOrderDatesFields()
    ]);

    _getOrderDatesFields() {
        const orderDateFields = [...ORDER_DATE_FIELDS, 'allowed'];

        const fields = Object.keys(AVAILABLE_DAYS).map(
            (day) => orderDateFields.map((suffix) => `${day}_${suffix}`)
        );

        return [].concat([], ...fields);
    }
}

const {
    _getCustomerFields
} = new MyAccountQueryPlugin();

export const config = {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields
        }
    }
};

export default config;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { MixType } from 'Type/Common';

import './CustomerNotesTextArea.style';

/** @namespace Pwasaas/Component/CustomerNotesTextArea/Component/CustomerNotesTextAreaComponent */
export class CustomerNotesTextAreaComponent extends PureComponent {
    static propTypes = {
        customerNote: PropTypes.string.isRequired,
        handleSetCustomerNote: PropTypes.func.isRequired,
        mix: MixType.isRequired
    };

    renderCustomerNotesTextArea() {
        const {
            customerNote,
            handleSetCustomerNote
        } = this.props;

        return (
            <Field
              label={ __('Notes') }
              id="customer_notes"
              mix={ { block: 'CustomerNotesTextArea', elem: 'Field' } }
              name="customer_notes"
              type="textarea"
              placeholder={ __('Note...') }
              value={ customerNote }
              onChange={ handleSetCustomerNote }
            />
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <div
              block="CustomerNotesTextArea"
              mix={ mix }
            >
                { this.renderCustomerNotesTextArea() }
            </div>
        );
    }
}

export default CustomerNotesTextAreaComponent;

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import Field from 'Component/Field';
import Loader from 'Component/Loader';
import { ProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { filterConfigurableOptions } from 'Util/Product';

import './CompleteOrderProductCard.style';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrderProductCard/Component/CompleteOrderProductCardComponent */
export class CompleteOrderProductCardComponent extends ProductCard {
    renderReviews() {
        const {
            product: {
                review_summary: {
                    rating_summary
                } = {}
            }
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return super.renderReviews();
    }

    renderAdditionalProductDetails() {
        const { getAttribute } = this.props;
        const {
            product_list_content: {
                attribute_to_display
            } = {}
        } = window.contentConfiguration;
        const brand = getAttribute(attribute_to_display || 'brand');

        if (!brand) {
            return null;
        }

        return (
            <div block="ProductCard" elem="Brand">
                { this.renderBrandValue() }
            </div>
        );
    }

    getAttributesToShow() {
        const {
            product: {
                configurable_options = {},
                variants
            }
        } = this.props;

        return filterConfigurableOptions(configurable_options, variants);
    }

    renderSku() {
        const {
            product: {
                sku
            }
        } = this.props;

        return (
            <span
              block="CompleteOrderProductCard"
              elem="Sku"
            >
                { __('SKU: %s', sku) }
            </span>
        );
    }

    renderQuantityInput() {
        const {
            quantity,
            setQuantity,
            showQtyInputField,
            isSelect,
            quantitySet
        } = this.props;

        if (!showQtyInputField && !isSelect) {
            return null;
        }

        return (
            <Field
              id="item_qty"
              name="item_qty"
              type="saas_input_select"
              value={ quantity }
              isSelect={ isSelect }
              mix={ { block: 'ProductCard', elem: 'Qty' } }
              onChange={ setQuantity }
              selectOptions={ quantitySet }
            />
        );
    }

    renderCardListContent() {
        const {
            children,
            configurableOptionsRef,
            handleAddToTotals,
            handleRemoveFromTotals,
            isSelected,
            layout
        } = this.props;

        return (
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    { this.renderCardLinkWrapper((
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    )) }
                </div>
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    { this.renderCardLinkWrapper((
                        <div block="ProductCard" elem="MainInfo">
                            { this.renderReviews() }
                            { this.renderAdditionalProductDetails() }
                            { this.renderMainDetails() }
                            { this.renderSku() }
                        </div>
                    )) }
                    <div
                      ref={ configurableOptionsRef }
                      block="ProductCard"
                      elem="AttributeWrapper"
                    >
                        { this.renderConfigurableOptions() }
                    </div>
                    <div
                      block="CompleteOrderProductCard"
                      elem="QtyPriceWrapper"
                    >
                        { this.renderQuantityInput() }
                        { this.renderProductPrice() }
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                    <div block="ProductCard" elem="CompleteOrderActions">
                        <button
                          block="CompleteOrderProductCard"
                          elem="Button"
                          mods={ { isSelected } }
                          onClick={ isSelected ? handleRemoveFromTotals : handleAddToTotals }
                        >
                            <i className={ `fa ${isSelected ? 'fa-check' : 'fa-plus'}` } />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            mix,
            isLoading,
            layout,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions
        } = this.props;

        const mods = {
            layout,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions
        };

        return (
            <li
              block="ProductCard"
              mods={ mods }
              mix={ {
                  ...mix,
                  block: 'CompleteOrderProductCard'
              } }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardListContent() }
            </li>
        );
    }
}

export default CompleteOrderProductCardComponent;

import './Field.manual.style';

export class FieldComponent {
    renderMessage = (args, callback, instance) => {
        const {
            info,
            message,
            validationStatus
        } = instance.props;

        if (!message && !info) {
            return null;
        }

        return (
            <p
              block="Field"
              elem="Message"
              mods={ { isInfo: !!(validationStatus !== false && info) } }
            >
                { message || info }
            </p>
        );
    };
}

const {
    renderMessage
} = new FieldComponent();

export default {
    'Component/Field/Component': {
        'member-function': {
            renderMessage
        }
    }
};

import BrowserDatabase from 'Util/BrowserDatabase';

import { LOCAL_STORE_SAAS_THEME } from '../../SaasTheme/store/SaasTheme/SaasTheme.reducer';
import { updateHeaderLogo } from './SaasConfig.reducer.plugin';
import { updateButtons, updateColors } from './SaasTheme.reducer.plugin';

export const BASE_CONFIG = 'config';
export const INTERVAL_IN_MS = 50;

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        updateHeaderLogo: (logoConfig) => dispatch(updateHeaderLogo(logoConfig)),
        updateColors: (colors) => dispatch(updateColors(colors)),
        updateButtons: (buttons) => dispatch(updateButtons(buttons))
    };
};

export class CategoryPageContainer {
    initialHeaderLogo = {};

    initialColors = {};

    initialButtons = {};

    _getInitialData(
        configItem,
        configKey
    ) {
        return new Promise((resolve, reject) => {
            const _this = setInterval(() => {
                const result = BrowserDatabase.getItem(configItem) || {};

                if (Object.keys(result).length) {
                    clearInterval(_this);

                    if (!result[configKey]) {
                        reject();
                    }

                    resolve(result[configKey]);
                }
            }, INTERVAL_IN_MS);
        });
    }

    _setConfigData(
        instance,
        logoConfig = this.initialHeaderLogo,
        colors = this.initialColors,
        buttons = this.initialButtons
    ) {
        const {
            updateButtons,
            updateColors,
            updateHeaderLogo
        } = instance.props;

        updateButtons(buttons);
        updateColors(colors);
        updateHeaderLogo(logoConfig);
    }

    _getLogoConfig(config) {
        return {
            header_logo_src: config.header_logo_src,
            logo_width: config.logo_width,
            logo_height: config.logo_height
        };
    }

    _getColors(config) {
        return Object.keys(config).reduce(
            (colors, key) => {
                if (!key.includes('_color') || key.includes('buttons_')) {
                    return colors;
                }

                return {
                    ...colors,
                    [key]: config[key].replace(/^[0-9a-z]{0,6}$/i, '#$&')
                };
            }, {}
        );
    }

    _getButtons(config) {
        return Object.keys(config).reduce(
            (buttons, key) => {
                if (!key.includes('buttons_')) {
                    return buttons;
                }

                return {
                    ...buttons,
                    [key]: config[key].replace(/^[0-9a-z]{0,6}$/i, '#$&')
                };
            }, {}
        );
    }

    componentDidMount = async (args, callback, instance) => {
        const logoConfig = await this._getInitialData(BASE_CONFIG, 'storeConfig');
        this.initialHeaderLogo = this._getLogoConfig(logoConfig);

        this.initialColors = await this._getInitialData(LOCAL_STORE_SAAS_THEME, 'colors');
        this.initialButtons = await this._getInitialData(LOCAL_STORE_SAAS_THEME, 'buttons');

        const {
            category: {
                shop_in_shop = {}
            }
        } = instance.props;

        if (shop_in_shop.is_shop) {
            this._setConfigData(
                instance,
                this._getLogoConfig(shop_in_shop),
                this._getColors(shop_in_shop),
                this._getButtons(shop_in_shop)
            );
        }

        return callback.apply(instance, args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [{
            category: {
                id: prevId,
                shop_in_shop: {
                    is_shop: prevIsShop
                } = {}
            }
        }] = args;

        const {
            category: {
                id,
                shop_in_shop = {}
            },
            isSearchPage
        } = instance.props;

        if (this.initialHeaderLogo && this.initialColors) {
            if (shop_in_shop.is_shop && prevId !== id) {
                this._setConfigData(
                    instance,
                    this._getLogoConfig(shop_in_shop),
                    this._getColors(shop_in_shop),
                    this._getButtons(shop_in_shop)
                );

                return callback.apply(instance, args);
            }

            if (isSearchPage || prevIsShop !== shop_in_shop.is_shop) {
                this._setConfigData(instance);
            }
        }

        return callback.apply(instance, args);
    };

    componentWillUnmount = (args, callback, instance) => {
        const {
            category: {
                shop_in_shop: {
                    is_shop
                } = {}
            }
        } = instance.props;

        if (is_shop) {
            this._setConfigData(instance);
        }

        callback.apply(instance, args);
    };
}

const {
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount
} = new CategoryPageContainer();

export default {
    'Route/CategoryPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/SearchPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            componentWillUnmount
        }
    }
};

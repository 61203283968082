import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// possible sample rates
export const TRACE_SAMPLE_RATE_ALL = 1.0;
export const TRACE_SAMPLE_RATE_PRODUCTION = 0.2;

// set sample rate
export const TRACE_SAMPLE_RATE = TRACE_SAMPLE_RATE_PRODUCTION;

export class AddSentryToAppPlugin {
    __sentryConstruct = (args, callback, instance) => {
        // ignore development or production
        if (process.env.NODE_ENV !== 'production') {
            return callback.apply(instance, args);
        }

        Sentry.init({
            dsn: 'https://a26f20ad620747dfa6f7766e0118e4cf@o316326.ingest.sentry.io/6708353',
            integrations: [new BrowserTracing()],
            tracesSampleRate: TRACE_SAMPLE_RATE
        });

        return callback.apply(instance, args);
    };
}

const {
    __sentryConstruct
} = new AddSentryToAppPlugin();

export const config = {
    'Component/App/Component': {
        'member-function': {
            __construct: __sentryConstruct
        }
    }
};

export default config;

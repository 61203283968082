export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        device: state.ConfigReducer.device
    };
};

export class AddDevicePropToCategoryFilterOverlayPlugin {
    containerProps = (args, callback, instance) => {
        const { device } = instance.props;

        return {
            ...callback(args),
            device
        };
    };
}

const {
    containerProps
} = new AddDevicePropToCategoryFilterOverlayPlugin();

export default {
    'Component/CategoryFilterOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            containerProps
        }
    }
};

import { withRouter } from 'react-router-dom';

import ProductListPage from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.override.manual.style';

/** @namespace Pwasaas/Component/ProductListPage/Component/ProductListPageComponent */
export class ProductListPageComponent extends ProductListPage {}

export default withRouter(ProductListPage);

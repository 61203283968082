import { cloneElement } from 'react';

import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import ProductAttribute from 'Util/Attribute';

import CompleteOrder from '../component/CompleteOrder';
import { COMPLETE_ORDER_LINK_TYPE } from '../component/CompleteOrder/CompleteOrder.config';

export class ProductSectionNavigationComponent {
    linkTypes = [RELATED, UPSELL];

    renderFilteredSections = (args, callback, instance) => {
        const type = this.getShouldNotRenderLinkType(instance.props);
        const sectionMap = type ? this.getFilteredSectionMap(args[0], type) : args[0];

        return callback(sectionMap);
    };

    renderInformationSection = (args, callback, instance) => {
        const { isCompleteOrderEnabled } = instance.props;
        const result = callback(...args);

        if (!isCompleteOrderEnabled) {
            return result;
        }

        const { props, props: { children } } = result;
        const productInformation = children[0] || null;
        const productAttributes = children[1] || null;
        const productCompleteOrder = this.renderCompleteOrder(instance);

        return cloneElement(result, {
            ...props,
            children: [
                productInformation,
                productCompleteOrder,
                productAttributes
            ]
        });
    };

    renderCompleteOrder(instance) {
        const { handleCompleteOrderItemChange } = instance.props;

        const {
            dataSource: {
                attributes: {
                    [COMPLETE_ORDER_LINK_TYPE]: completeOrderLinkType = {}
                } = {},
                product_links = []
            } = {}
        } = instance.props;

        const linkType = ProductAttribute.getValue(completeOrderLinkType);

        if (!linkType) {
            return null;
        }

        const productLinks = product_links.filter(({ link_type }) => link_type === linkType);

        if (!productLinks.length) {
            return null;
        }

        return (
            <CompleteOrder
              isProductLinksType
              items={ productLinks }
              onItemChange={ handleCompleteOrderItemChange }
            />
        );
    }

    getShouldNotRenderLinkType = (props) => {
        const {
            isCompleteOrderEnabled,
            dataSource: {
                attributes: {
                    [COMPLETE_ORDER_LINK_TYPE]: sourceCompleteOrderLinkType
                } = {}
            } = {},
            product: {
                attributes: {
                    [COMPLETE_ORDER_LINK_TYPE]: completeOrderLinkType = {}
                } = {}
            } = {}
        } = props;

        const type = ProductAttribute.getValue(sourceCompleteOrderLinkType || completeOrderLinkType);

        return isCompleteOrderEnabled && this.linkTypes.includes(type) ? type : '';
    };

    getFilteredSectionMap = (sectionMap, type) => Object.entries(sectionMap).reduce(
        (newMember, [key, value]) => (type === key
            ? newMember
            : {
                ...newMember,
                [key]: value
            }), {}
    );
}

const {
    renderInformationSection,
    renderFilteredSections
} = new ProductSectionNavigationComponent();

export default {
    'Pwasaas/Component/ProductSectionNavigation/Component/ProductSectionNavigationComponent': {
        'member-function': {
            renderInformationSection,
            renderSectionContentItems: renderFilteredSections,
            renderSectionNavigationItems: renderFilteredSections
        }
    }
};

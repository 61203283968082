export const UPDATE_MASTER_ACCOUNT = 'UPDATE_MASTER_ACCOUNT';
export const UPDATE_SAASCONFIG = 'UPDATE_SAASCONFIG';

/** @namespace Pwasaas/Store/SaasConfig/Action/updateConfig */
export const updateConfig = (saasConfig) => ({
    type: UPDATE_SAASCONFIG,
    saasConfig
});

/** @namespace Pwasaas/Store/SaasConfig/Action/updateMasterAccount */
export const updateMasterAccount = (masterAccount) => ({
    type: UPDATE_MASTER_ACCOUNT,
    masterAccount
});

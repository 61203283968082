import { cloneElement } from 'react';

import SaasProductLabels from '../component/SaasProductLabels';
import { addChildComponentByIndex } from '../util/ProductLabels';

export class ProductGalleryComponent {
    childComponentIndex = 1;

    renderProductLabels(instance) {
        const { productLabels = [] } = instance.props;

        if (!productLabels.length) {
            return null;
        }

        return <SaasProductLabels items={ productLabels } />;
    }

    renderSlider = (args, callback, instance) => {
        const result = callback(...args);

        const { props: { children } } = result;

        return cloneElement(result, {
            ...result.props,
            children: addChildComponentByIndex(
                this.renderProductLabels(instance),
                children,
                this.childComponentIndex
            )
        });
    };
}

const {
    renderSlider
} = new ProductGalleryComponent();

export default {
    'Component/ProductGallery/Component': {
        'member-function': {
            renderSlider
        }
    }
};

import { cloneElement } from 'react';

import MyAccountReorderButton from 'Component/MyAccountReorderButton';

export class MyAccountOrderPopupComponent {
    renderContent = (args, callback, instance) => {
        const RenderedComponent = callback(instance, args);
        const { isReorderEnabled } = instance.props;

        if (!isReorderEnabled || !RenderedComponent) {
            return RenderedComponent;
        }

        const { props, props: { children: baseChildren = [] } } = RenderedComponent;
        const { order } = instance.props;
        const children = [...baseChildren,
        <MyAccountReorderButton
          title={ __('Place order again') }
          order={ order }
        />
        ];

        return cloneElement(RenderedComponent, {
            ...props,
            children
        });
    };
}

const {
    renderContent
} = new MyAccountOrderPopupComponent();

export default {
    'Component/MyAccountOrderPopup/Component': {
        'member-function': {
            renderContent
        }
    }
};

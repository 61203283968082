import Html from 'Component/Html';
import { DISPLAY_MODE_CMS_BLOCK } from 'Route/CategoryPage/CategoryPage.config';

export class CategoryPageComponent {
    render = (args, callback, instance) => {
        const RenderComponents = callback(instance, args);
        const {
            category: {
                cms_block,
                display_mode,
                shop_in_shop = {}
            } = {},
            search
        } = instance.props;

        if (
            shop_in_shop.is_shop
                && search === ''
                && display_mode === DISPLAY_MODE_CMS_BLOCK
                && cms_block
        ) {
            const { content, identifier } = cms_block;
            return (
                <main block="CategoryPage" mods={ { isShop: true } }>
                    <section
                      block="CategoryPage"
                      elem="Wrapper"
                      mods={ { isFullPage: true } }
                    >
                        <div
                          block="CategoryPage"
                          elem="CMS"
                        >
                            <Html
                              key={ identifier }
                              content={ content }
                            />
                        </div>
                    </section>
                </main>
            );
        }

        return RenderComponents;
    };
}

const {
    render
} = new CategoryPageComponent();

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            render
        }
    }
};

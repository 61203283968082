import { Field } from 'Util/Query';

/** @namespace Pwasaas/Plugin/PostcodeCheck/Query/PostcodeCheck/Query/PostcodeCheckQuery */
export class PostcodeCheckQuery {
    getQuery(options) {
        const {
            postcode,
            houseNumber,
            houseNumberAddition
        } = options;

        return new Field('postcode')
            .addArgument('postcode', 'String!', postcode)
            .addArgument('houseNumber', 'String!', houseNumber)
            .addArgument('houseNumberAddition', 'String', houseNumberAddition)
            .addFieldList(this._getPostcodeFields());
    }

    _getPostcodeFields() {
        return [
            'street',
            'houseNumber',
            'houseNumberAddition',
            'postcode',
            'city',
            'municipality',
            'province',
            'rdX',
            'rdY',
            'latitude',
            'longitude',
            'bagNumberDesignationId',
            'bagAddressableObjectId',
            'addressType',
            'purposes',
            'surfaceArea',
            'houseNumberAdditions'
        ];
    }
}

export default new PostcodeCheckQuery();

import { cloneElement } from 'react';

import SaasProductLabels from '../component/SaasProductLabels';
import { addChildComponentByIndex } from '../util/ProductLabels';

export class ProductCardComponent {
    childComponentIndex = 1;

    renderProductLabels(instance) {
        const {
            product: {
                productLabels = []
            } = {}
        } = instance.props;

        if (!productLabels.length) {
            return null;
        }

        return <SaasProductLabels items={ productLabels } />;
    }

    render = (args, callback, instance) => {
        const result = callback(...args);
        const { props: { children, className } } = result;

        return cloneElement(result, {
            ...result.props,
            className: `${className} ${className.split(' ')[0]}_hasProductLabels`,
            children: addChildComponentByIndex(
                this.renderProductLabels(instance),
                children,
                this.childComponentIndex
            )
        });
    };
}

const {
    render
} = new ProductCardComponent();

export default {
    'Pwasaas/Component/ProductCard/Component/ProductCardComponent': {
        'member-function': {
            render
        }
    }
};

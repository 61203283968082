import { connect } from 'react-redux';

import {
    RADIO,
    SELECT
} from 'Component/ProductBundleItem/ProductBundleItem.config';
import {
    mapDispatchToProps,
    mapStateToProps
} from 'Component/ProductCustomizableOption/ProductCustomizableOption.container';
import {
    ProductBundleItemContainer as SourceProductBundleItemContainer
} from 'SourceComponent/ProductBundleItem/ProductBundleItem.container';

import ProductVisualBundleItem from './ProductVisualBundleItem.component';
import { SLIDES_PER_VIEW } from './ProductVisualBundleItem.config';

/** @namespace Pwasaas/Plugin/VisualBundleSelector/Component/ProductVisualBundleItem/Container/ProductVisualBundleItemContainer */
export class ProductVisualBundleItemContainer extends SourceProductBundleItemContainer {
    containerFunctions = {
        ...this.containerFunctions,
        handleSwatchSelect: this.onSwatchSelect.bind(this)
    };

    state = {
        ...this.state,
        isSlider: false
    };

    containerProps() {
        const { currencyCode } = this.props;

        return {
            ...super.containerProps(),
            currencyCode,
            isSlider: this._getIsSlider()
        };
    }

    _getIsSlider() {
        const { option: { options } } = super.containerProps();

        return options.length > SLIDES_PER_VIEW;
    }

    onSwatchSelect(item) {
        const {
            option: { option_id },
            setSelectedDropdownValue,
            setSelectedCheckboxValues
        } = this.props;

        const { id, quantity } = item;
        const value = id.toString();

        switch (this.getOptionType()) {
        case RADIO:
        case SELECT:
            setSelectedDropdownValue(option_id, { value, quantity });
            this.setState({ selectedDropdownValue: id });
            break;
        default:
            setSelectedCheckboxValues(option_id, { value, quantity });
        }
    }

    render() {
        return (
            <ProductVisualBundleItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVisualBundleItemContainer);

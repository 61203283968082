export const COMPLETEORDER_ADD_ITEM = 'COMPLETEORDER_ADD_ITEM';
export const COMPLETEORDER_CLEAR_ITEMS = 'COMPLETEORDER_CLEAR_ITEMS';
export const COMPLETEORDER_EDIT_ITEM = 'COMPLETEORDER_EDIT_ITEM';
export const COMPLETEORDER_REMOVE_ITEM = 'COMPLETEORDER_REMOVE_ITEM';
export const COMPLETEORDER_UPDATE_CURRENT_QTY = 'COMPLETEORDER_UPDATE_CURRENT_QTY';

/** @namespace Pwasaas/Plugin/CompleteOrder/Store/CompleteOrder/Action/addItem */
export const addItem = (item) => ({
    type: COMPLETEORDER_ADD_ITEM,
    item
});

/** @namespace Pwasaas/Plugin/CompleteOrder/Store/CompleteOrder/Action/clearItems */
export const clearItems = () => ({
    type: COMPLETEORDER_CLEAR_ITEMS
});

/** @namespace Pwasaas/Plugin/CompleteOrder/Store/CompleteOrder/Action/editItem */
export const editItem = (item) => ({
    type: COMPLETEORDER_EDIT_ITEM,
    item
});

/** @namespace Pwasaas/Plugin/CompleteOrder/Store/CompleteOrder/Action/removeItem */
export const removeItem = (item) => ({
    type: COMPLETEORDER_REMOVE_ITEM,
    item
});

/** @namespace Pwasaas/Plugin/CompleteOrder/Store/CompleteOrder/Action/updateCurrentQty */
export const updateCurrentQty = (quantity) => ({
    type: COMPLETEORDER_UPDATE_CURRENT_QTY,
    quantity
});

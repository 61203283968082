export const HIDDEN_PRODUCT_PAGE_ATTRIBUTE_CODES = [
    'complete_order_link_type'
];

export const getHiddenAttributeCodes = (args, callback) => [
    ...callback(...args),
    ...HIDDEN_PRODUCT_PAGE_ATTRIBUTE_CODES
];

export default {
    'Pwasaas/Util/Attribute/Index/getHiddenAttributeCodes': {
        function: getHiddenAttributeCodes
    }
};

/**
 * Add show product stock status to render types
 */

import { SHOW_PRODUCT_STOCK_STATUS } from 'Component/ProductActions/ProductActions.config';

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    showProductStockStatus: state.SaasConfigReducer.config?.show_product_stock_status || false,
    showProductStockIcon: state.SaasConfigReducer.config?.show_product_stock_icon || false,
    showProductStockLabel: state.SaasConfigReducer.config?.show_product_stock_label || false,
    showProductStockWhenInStock: state.SaasConfigReducer.config?.show_product_stock_status_only_in_stock || false,
    backorders: state.SaasConfigReducer.config?.backorders || '0'
});

export class ProductActionsContainer {
    containerProps = (args, callback, {
        props: {
            showProductStockStatus,
            showProductStockIcon,
            showProductStockLabel,
            showProductStockWhenInStock,
            backorders
        }
    }) => ({
        ...callback(...args),
        showProductStockStatus,
        showProductStockIcon,
        showProductStockLabel,
        showProductStockWhenInStock,
        backorders
    });

    getRenderTypes = (args, callback) => ([
        ...callback(...args),
        SHOW_PRODUCT_STOCK_STATUS
    ]);
}

const {
    containerProps,
    getRenderTypes
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    },
    'Pwasaas/Component/ProductActions/Container/ProductActionsContainer': {
        'member-function': {
            getRenderTypes
        }
    }
};

import { cloneElement } from 'react';

import { isSignedIn } from 'Util/Auth';

export class GroupedProductsItemComponent {
    renderTitle = (args, callback, instance) => {
        const { isShowPricesOnlyWhenSignedIn } = instance.props;
        const result = callback(...args);

        if (isShowPricesOnlyWhenSignedIn && !isSignedIn()) {
            const { props } = result;

            return cloneElement(result, {
                ...props,
                children: props.children.filter(({ props }) => !Object.prototype.hasOwnProperty.call(props, 'price'))
            });
        }

        return result;
    };

    renderQuantity = (args, callback, instance) => {
        const { isShowPricesOnlyWhenSignedIn } = instance.props;

        if (isShowPricesOnlyWhenSignedIn && !isSignedIn()) {
            return null;
        }

        return callback(...args);
    };
}

const {
    renderTitle,
    renderQuantity
} = new GroupedProductsItemComponent();

export default {
    'Component/GroupedProductsItem/Component': {
        'member-function': {
            renderTitle,
            renderQuantity
        }
    }
};

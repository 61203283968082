import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { MenuItemModsType, MenuItemType } from 'Type/SaasMenu';

import { LINK_TYPE_DISABLE_LINK, MEDIA_FOLDER } from './MenuLink.config';

import './MenuLink.style';

/** @namespace Pwasaas/Component/MenuLink/Component/MenuLinkComponent */
export class MenuLinkComponent extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        handleItemClick: PropTypes.func,
        handleItemEnter: PropTypes.func,
        item: MenuItemType.isRequired,
        itemMods: MenuItemModsType.isRequired,
        mix: MixType.isRequired
    };

    static defaultProps = {
        handleItemClick: () => {},
        handleItemEnter: () => {}
    };

    renderLink() {
        const {
            handleItemClick,
            handleItemEnter,
            item: {
                id,
                link,
                link_target: target,
                link_type,
                title
            },
            itemMods,
            mix
        } = this.props;

        if (link_type === LINK_TYPE_DISABLE_LINK) {
            return (
                <div
                  block="MenuLink"
                  elem="Link"
                  mix={ mix }
                  id={ id }
                  mods={ itemMods }
                >
                    { title }
                </div>
            );
        }

        return (
            <Link
              target={ target }
              to={ link }
              block="MenuLink"
              elem="Link"
              mix={ mix }
              id={ id }
              mods={ itemMods }
              onMouseEnter={ handleItemEnter }
              onClick={ handleItemClick }
            >
                { title }
            </Link>
        );
    }

    renderIconItem() {
        const {
            handleItemClick,
            item: {
                icon_image,
                title
            },
            mix
        } = this.props;

        return (
            <button
              block="MenuLink"
              elem="IconButton"
              mix={ mix }
              onClick={ handleItemClick }
            >
                <div
                  block="MenuLink"
                  elem="Icon"
                  style={ { backgroundImage: `url(${icon_image.replace(MEDIA_FOLDER, `.thumbs${MEDIA_FOLDER}`)})` } }
                />
                <figcaption
                  block="MenuLink"
                  elem="IconTitle"
                >
                    { title }
                </figcaption>
            </button>
        );
    }

    renderMobileColumnItem() {
        const {
            handleItemClick,
            item: {
                title
            },
            itemMods,
            mix: {
                block,
                elem
            }
        } = this.props;

        return (
            <button
              block={ block }
              elem={ elem }
              mods={ itemMods }
              onClick={ handleItemClick }
            >
                <figcaption
                  block="MenuLink"
                  elem="Caption"
                >
                    { title }
                </figcaption>
            </button>
        );
    }

    render() {
        const {
            device,
            handleItemEnter,
            item: {
                title
            },
            itemMods,
            mix
        } = this.props;

        const {
            isIcon,
            isLink
        } = itemMods;

        if (isLink) {
            return this.renderLink();
        }

        if (isIcon) {
            return this.renderIconItem();
        }

        if (device.isMobile) {
            return this.renderMobileColumnItem();
        }

        return (
            <figure
              block="MenuLink"
              elem="Figure"
              mix={ mix }
              mods={ itemMods }
              onMouseEnter={ handleItemEnter }
            >
                <figcaption
                  block="MenuLink"
                  elem="Caption"
                >
                    { title }
                </figcaption>
            </figure>
        );
    }
}

export default MenuLinkComponent;

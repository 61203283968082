import { getStore } from 'Util/Store';

export const ProductLabelDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/ProductLabel/ProductLabel.dispatcher'
);

/** @namespace Pwasaas/Plugin/ProductLabel/Util/ProductLabels/addChildComponentByIndex */
export const addChildComponentByIndex = (
    component,
    children,
    index
) => [
    ...children.slice(0, index),
    component,
    ...children.slice(index)
];

/** @namespace Pwasaas/Plugin/ProductLabel/Util/ProductLabels/AddProductLabelsToProductsStore */
export class AddProductLabelsToProductsStore {
    dispatcher = null;

    idsNotAvailable = [];

    storedProductLabels = {};

    _initializeStore() {
        if (this.dispatcher) {
            return null;
        }

        return ProductLabelDispatcher.then(
            ({ default: dispatcher }) => {
                this.dispatcher = dispatcher;
            }
        );
    }

    _storeProductLabelData = (
        notAvailable,
        productLabels
    ) => {
        this.idsNotAvailable = notAvailable;
        this.storedProductLabels = productLabels;
    };

    _syncProductLabelsByIds(productIds, mode) {
        if (!productIds.length) {
            return null;
        }

        const { dispatch, getState } = getStore();
        const {
            ProductLabelReducer: {
                productLabels = {}
            } = {}
        } = getState();

        if (Object.keys(productLabels).length && this.storedProductLabels === productLabels) {
            return null;
        }

        const options = {
            productIds,
            mode
        };

        return this.dispatcher.fetchData(
            options,
            dispatch
        ).then(
            /** @namespace Pwasaas/Plugin/ProductLabel/Util/ProductLabels/fetchData/then */
            ({ productLabels, notAvailable }) => {
                this._storeProductLabelData(notAvailable, productLabels);
            }
        );
    }

    _addLabelsToProduct = (product) => {
        const {
            id: productId,
            price_range: {
                minimum_price: {
                    default_final_price: {
                        value: default_final_price_value
                    },
                    discount: {
                        percent_off
                    } = {}
                } = {}
            } = {}
        } = product;

        return {
            ...product,
            productLabels: (this.storedProductLabels[productId] || []).reduce(
                (acc, label) => {
                    if (Math.round(percent_off) === 0 || default_final_price_value === 0) {
                        return [
                            ...acc,
                            {
                                ...label
                            }
                        ];
                    }

                    return [
                        ...acc,
                        {
                            ...label,
                            discountPercent: Math.round(percent_off)
                        }
                    ];
                },
                []
            )
        };
    };

    _getProductsWithLabels = async (products, mode) => {
        await this._initializeStore();

        const productIds = products.reduce(
            (acc, { id }) => {
                if (this.idsNotAvailable.includes(id) || this.storedProductLabels[id] !== undefined) {
                    return acc;
                }

                return [
                    ...acc,
                    id
                ];
            }, []
        );

        await this._syncProductLabelsByIds(productIds, mode);

        return products.map(this._addLabelsToProduct);
    };

    addProductLabelsToProducts = async (args, callback, instance) => {
        const [{ products, products: { items: oldItems } }, ...otherArgs] = args;
        const items = await this._getProductsWithLabels(oldItems, 'CATEGORY');

        const data = {
            products: {
                ...products,
                items
            }
        };

        return callback.apply(instance, [data, ...otherArgs]);
    };
}

const {
    addProductLabelsToProducts
} = new AddProductLabelsToProductsStore();

export default addProductLabelsToProducts;

export const mapStateToProps = ([state], callback) => ({
    ...callback(state),
    isVisualBundleSelector: state.SaasConfigReducer.config?.visual_bundle_selector_enabled || false
});

export class ProductActionsContainer {
    containerProps = (args, callback, { props: { isVisualBundleSelector } }) => ({
        ...callback(...args),
        isVisualBundleSelector
    });
}

const {
    containerProps
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    }
};

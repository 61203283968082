import PropTypes from 'prop-types';

import { ORDER_LIST_SEARCH } from 'Component/MyAccountOrderList/MyAccountOrderList.config';

export class SearchItemComponent {
    propExtension = (originalStaticMember) => ({
        ...originalStaticMember,
        handleAddToOrderList: PropTypes.func.isRequired,
        isAlreadyAddedInOrderList: PropTypes.bool,
        type: PropTypes.string.isRequired
    });

    defaultProps = (originalStaticMember) => ({
        ...originalStaticMember,
        isAlreadyAddedInOrderList: false
    });

    renderAddToOrderListButton(instance) {
        const {
            handleAddToOrderList,
            isAlreadyAddedInOrderList,
            product: {
                type_id
            }
        } = instance.props;

        return (
            <button
              block="Button"
              mix={ { block: 'MyAccountOrderList', elem: 'Button' } }
              onClick={ handleAddToOrderList }
              disabled={ isAlreadyAddedInOrderList || type_id !== 'simple' }
            >
                { __('Add to list') }
            </button>
        );
    }

    renderOrderListSearchItem(instance) {
        return (
            <div
              block="SearchItem"
              elem="OrderListItem"
            >
                <figure
                  block="SearchItem"
                  elem="Wrapper"
                  mods={ { isOrderList: true } }
                >
                    { this.renderAddToOrderListButton(instance) }
                    { instance.renderImage() }
                    { instance.renderContent() }
                </figure>
            </div>
        );
    }

    render = (args, callback, instance) => {
        const { type } = instance.props;

        if (type === ORDER_LIST_SEARCH) {
            return (
                <li block="SearchItem">
                    { this.renderOrderListSearchItem(instance) }
                </li>
            );
        }

        return callback(...args);
    };
}

const {
    defaultProps,
    propExtension,
    render
} = new SearchItemComponent();

export default {
    'Component/SearchItem/Component': {
        'member-function': {
            render
        },
        'static-member': {
            defaultProps,
            propTypes: propExtension
        }
    }
};

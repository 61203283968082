import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductType } from 'Type/ProductList';
import { getIndexedProduct } from 'Util/Product';

import CompleteOrderProductCard from '../CompleteOrderProductCard';

import './CompleteOrderProductList.style';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrderProductList/Component/CompleteOrderProductListComponent */
export class CompleteOrderProductListComponent extends PureComponent {
    static propTypes = {
        handleSelectChange: PropTypes.func.isRequired,
        products: PropTypes.arrayOf(ProductType).isRequired,
        selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    renderItem = (item, index) => {
        const { handleSelectChange, selectedIds } = this.props;
        const { id } = item;

        return (
            <CompleteOrderProductCard
              key={ id || index }
              product={ getIndexedProduct(item) }
              layout={ LIST_LAYOUT }
              isSelected={ selectedIds.includes(id) }
              onSelectChange={ handleSelectChange }
            />
        );
    };

    render() {
        const { products } = this.props;

        if (!products.length) {
            return null;
        }

        return (
            <ul block="CompleteOrderProductList">
                { products.map(this.renderItem) }
            </ul>
        );
    }
}

export default CompleteOrderProductListComponent;

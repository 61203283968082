import { Field } from 'Util/Query';

/** @namespace Pwasaas/Plugin/ProductAttributeIcons/Query/ProductIcon/Query/ProductIconQuery */
export class ProductIconQuery {
    getQuery(sku) {
        if (!sku) {
            throw new Error('Missing argument `productSKU`');
        }

        return new Field('getProductIcons')
            .addFieldList([
                'show_product_usp_attributes',
                this._getProductUspAttributesField()
            ])
            .addArgument('sku', 'String!', sku)
            .setAlias('productIcons');
    }

    _getProductUspAttributesField() {
        return new Field('product_usp_attributes')
            .addFieldList([
                'label',
                'icon_url'
            ]);
    }
}

export default new ProductIconQuery();

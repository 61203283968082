import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';

import './ProductBrandLogo.style';

/** @namespace Pwasaas/Plugin/ProductBrandLogo/Component/ProductBrandLogo/Component/ProductBrandLogoComponent */
export class ProductBrandLogoComponent extends PureComponent {
    static propTypes = {
        brandLogo: PropTypes.shape({
            label: PropTypes.string,
            icon_url: PropTypes.string
        }).isRequired
    };

    renderBrandImage(label, url) {
        return (
            <>
                <Image
                  src={ url }
                  mix={ {
                      block: 'ProductBrandLogo',
                      elem: 'Image'
                  } }
                  ratio="custom"
                  alt={ label }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ label }
                  src={ url }
                />
            </>
        );
    }

    renderContent(label, url) {
        if (url) {
            return this.renderBrandImage(label, url);
        }

        return (
            <div
              block="ProductBrandLogo"
              elem="Label"
            >
                { label }
            </div>
        );
    }

    render() {
        const {
            brandLogo: {
                label,
                icon_url
            }
        } = this.props;

        if (!label && !icon_url) {
            return null;
        }

        return (
            <div block="ProductBrandLogo">
                { this.renderContent(label, icon_url) }
            </div>
        );
    }
}

export default ProductBrandLogoComponent;

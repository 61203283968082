import { getStore } from 'Util/Store';

import { updateCurrentQty } from '../store/CompleteOrder/CompleteOrder.action';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isCompleteOrderEnabled: state.SaasConfigReducer.config?.complete_order_enabled || false,
        currentQuantity: state.CompleteOrderReducer.quantity
    };
};

/**
 * Add default dispatcher to fix empty params in scandi product actions mapDispatchToProps
 */
export const mapDispatchToProps = (args, callback) => {
    const [dispatch = getStore().dispatch] = args;

    return {
        ...callback(dispatch),
        updateCurrentQty: (value) => dispatch(updateCurrentQty(value))
    };
};

export class ProductActionsContainer {
    componentDidMount = (args, callback, instance) => {
        const { isCompleteOrderEnabled, updateCurrentQty } = instance.props;

        if (isCompleteOrderEnabled) {
            const { quantity } = instance.state;

            updateCurrentQty(quantity);
        }

        return callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const { isCompleteOrderEnabled } = instance.props;

        if (isCompleteOrderEnabled) {
            const [, { quantity: prevQty }] = args;
            const { quantity } = instance.state;
            const { currentQuantity, updateCurrentQty } = instance.props;

            if (prevQty !== quantity && currentQuantity !== quantity) {
                updateCurrentQty(quantity);
            }
        }

        return callback(...args);
    };
}

const {
    componentDidMount,
    componentDidUpdate
} = new ProductActionsContainer();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductActions/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductActions/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    }
};

import { cloneElement } from 'react';

export const renderDropdownOrSwatch = (args, callback, instance) => {
    const RenderedComponent = callback.apply(instance, args);
    const { props } = RenderedComponent;
    const [{ attribute_code }] = args;

    return cloneElement(RenderedComponent, {
        ...props,
        mix: {
            ...props.mix,
            mods: { attribute_code }
        }
    });
};

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderDropdownOrSwatch
        }
    }
};

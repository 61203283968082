/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import FormPortal from 'Component/FormPortal';
import { FieldForm } from 'SourceComponent/FieldForm/FieldForm.component';

import { DATE_OF_BIRTH_FIELD } from './CheckoutDateOfBirth.config';

/** @namespace Pwasaas/Component/CheckoutDateOfBirth/Component/CheckoutDateOfBirthComponent */
export class CheckoutDateOfBirthComponent extends FieldForm {
    get fieldMap() {
        return { ...DATE_OF_BIRTH_FIELD };
    }

    render() {
        const { id } = this.props;

        return (
            <FormPortal
              id={ id }
              name="CheckoutAddressForm"
            >
                <div
                  block="FieldForm"
                  mix={ { block: 'CheckoutAddressForm' } }
                >
                    { this.renderFields() }
                </div>
            </FormPortal>
        );
    }
}

export default CheckoutDateOfBirthComponent;

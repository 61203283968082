import CustomerNotesQuery from 'Query/CustomerNotes.query';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    getAuthorizationToken,
    isSignedIn
} from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        isCustomerNoteEnabled: state.SaasConfigReducer.config?.customer_notes_enabled_in_checkout
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(dispatch),
        showNotification: (type, message) => dispatch(showNotification(type, message))
    };
};

export class CheckoutShippingContainer {
    state = (originalMember) => ({
        ...originalMember,
        customerNote: ''
    });

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        handleCustomerNote: this.handleCustomerNote.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const { isCustomerNoteEnabled } = instance.props;
        const { customerNote } = instance.state;

        return {
            ...callback(...args),
            customerNote,
            isCustomerNoteEnabled
        };
    };

    handleCustomerNote(value) {
        this.setState({
            customerNote: value
        });
    }

    onShippingSuccess = (args, callback, instance) => {
        const cartId = isSignedIn() ? getAuthorizationToken() : getGuestQuoteId();
        const { customerNote } = instance.state;

        const {
            isCustomerNoteEnabled,
            showNotification
        } = instance.props;

        if (!isCustomerNoteEnabled || !customerNote) {
            return callback(...args);
        }

        return fetchMutation(
            CustomerNotesQuery.getSaveCustomerNotesMutation(customerNote, cartId)
        ).then(
            () => instance.setState({
                customerNote: ''
            }),
            (error) => showNotification('error', 'Error saving customer notes!', error)
        ).finally(
            () => callback(...args)
        );
    };
}

const {
    containerFunctions,
    containerProps,
    onShippingSuccess,
    state
} = new CheckoutShippingContainer();

export default {
    'Component/CheckoutShipping/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            containerProps,
            onShippingSuccess
        },
        'member-property': {
            containerFunctions,
            state
        }
    }
};

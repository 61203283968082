import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common';

import './AddAllToCart.style';

/** @namespace Pwasaas/Component/AddAllToCart/Component/AddAllToCartComponent */
export class AddAllToCartComponent extends PureComponent {
    static propTypes = {
        addAllToCart: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        mix: MixType.isRequired
    };

    render() {
        const {
            addAllToCart,
            isActive,
            isVisible,
            mix
        } = this.props;

        if (!isVisible) {
            return null;
        }

        mix.elem = 'AddAllToCart';

        return (
            <div
              block="AddAllToCart"
              mix={ mix }
            >
                <button
                  block="Button"
                  onClick={ addAllToCart }
                  disabled={ !isActive }
                >
                    { __('Add all to Cart') }
                </button>
            </div>
        );
    }
}

export default AddAllToCartComponent;

export class DisplayShippingAddressOnlyPlugin {
    mapStateToProps = (args, callback) => {
        const [state] = args;
        const {
            SaasConfigReducer: {
                config: {
                    display_both_addresses_in_checkout: displayBothAddressesInCheckout
                }
            }
        } = state;

        return {
            ...callback(state),
            displayBothAddressesInCheckout
        };
    };

    isSameShippingAddress = (args, callback, instance) => {
        const { displayBothAddressesInCheckout } = instance.props;

        if (!displayBothAddressesInCheckout) {
            return true;
        }

        return callback(args);
    };

    renderAddressList = (args, callback, instance) => {
        const {
            isBilling,
            selectedAddressId,
            displayBothAddressesInCheckout,
            customer: { addresses }
        } = instance.props;

        if (!addresses || !addresses.length || displayBothAddressesInCheckout) {
            return callback(args);
        }

        if (!selectedAddressId) {
            return addresses.slice(0, 1).map(instance.renderAddress);
        }

        const address = addresses.find(({ id }) => id === selectedAddressId && isBilling === false);

        if (!address) {
            return addresses.slice(0, 1).map(instance.renderAddress);
        }

        return [address].map(instance.renderAddress);
    };
}

const { mapStateToProps, isSameShippingAddress, renderAddressList } = new DisplayShippingAddressOnlyPlugin();

export default {
    'Component/CheckoutAddressBook/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutAddressBook/Component': {
        'member-function': {
            renderAddressList
        }
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            isSameShippingAddress
        }
    },
    'Component/CheckoutShipping/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common';

import './UnitPrice.style';

/** @namespace Pwasaas/Plugin/ShowUnitPrice/Component/UnitPrice/Component/UnitPriceComponent */
export class UnitPriceComponent extends PureComponent {
    static propTypes = {
        price: PropTypes.string.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    render() {
        const {
            price,
            mix
        } = this.props;

        return (
            <p block="UnitPrice" mix={ mix }>
                { `(${__('Price: %s / Piece', price)})` }
            </p>
        );
    }
}

export default UnitPriceComponent;

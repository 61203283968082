export class StoreQuery {
    _getStoreProductViewAttributeFields = (args, callback) => ([
        ...callback(args),
        'brand_logo_attribute'
    ]);
}

const {
    _getStoreProductViewAttributeFields
} = new StoreQuery();

export default {
    'Pwasaas/Query/Store/Query/StoreQuery': {
        'member-function': {
            _getStoreProductViewAttributeFields
        }
    }
};

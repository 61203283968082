import getStore from 'Util/Store';

import { updateActivePromotions } from './Checkout.reducer.plugin';

export class CheckoutContainer {
    saveAddressInformationFetchMutationThen = (args, callback) => {
        const [{
            saveAddressInformation: {
                active_sale_promotions: activePromotions
            }
        }] = args;

        if (activePromotions && activePromotions.length) {
            const { dispatch } = getStore();

            dispatch(updateActivePromotions(activePromotions));
        }

        callback(...args);
    };
}

const {
    saveAddressInformationFetchMutationThen
} = new CheckoutContainer();

export default {
    'Route/Checkout/Container/saveAddressInformationFetchMutationThen': {
        function: saveAddressInformationFetchMutationThen
    }
};

import { getInitialState } from 'Store/Product/Product.reducer';

export const UPDATE_PRODUCT_PRICE = 'UPDATE_PRODUCT_PRICE';

export const updateProductPrice = (productPrice) => ({
    type: UPDATE_PRODUCT_PRICE,
    productPrice
});

export const _getInitialState = (args, callback, context) => ({
    // eslint-disable-next-line react/destructuring-assignment
    ...callback.apply(context, args),
    surfacePriceCalculation: null
});

export const ProductReducer = (args, callback) => {
    const [state = getInitialState(), action] = args;

    const {
        productPrice,
        type
    } = action;

    switch (type) {
    case UPDATE_PRODUCT_PRICE:
        return {
            ...state,
            surfacePriceCalculation: productPrice
        };

    default:
        return callback(...args);
    }
};

export default {
    'Store/Product/Reducer/getInitialState': {
        function: _getInitialState
    },
    'Store/Product/Reducer': {
        function: ProductReducer
    }
};

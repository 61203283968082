import BrowserDatabase from 'Util/BrowserDatabase';

import {
    UPDATE_LOADING_STATUS,
    UPDATE_ORDERLIST,
    UPDATE_STATED_ORDER_LIST,
    UPDATE_USER_CAN_EDIT_ORDERLIST
} from './OrderList.action';

export const ORDER_LIST_TOTALS = 'order_list_totals';

/** @namespace Pwasaas/Store/OrderList/Reducer/updateOrderListData */
export const updateOrderListData = (action, state) => {
    const { items } = action;

    BrowserDatabase.setItem(
        { items },
        ORDER_LIST_TOTALS
    );

    return {
        ...state,
        items
    };
};

export const {
    items
} = BrowserDatabase.getItem(ORDER_LIST_TOTALS) || { items: [] };

/** @namespace Pwasaas/Store/OrderList/Reducer/getInitialState */
export const getInitialState = () => ({
    items,
    isEditable: false,
    isLoading: false
});

/** @namespace Pwasaas/Store/OrderList/Reducer/OrderListReducer */
export const OrderListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        isEditable,
        isLoading,
        items,
        type
    } = action;

    switch (type) {
    case UPDATE_ORDERLIST:
        return updateOrderListData(action, state);

    case UPDATE_STATED_ORDER_LIST:
        BrowserDatabase.setItem(
            { items },
            ORDER_LIST_TOTALS
        );

        return {
            ...state,
            items
        };

    case UPDATE_USER_CAN_EDIT_ORDERLIST:
        return {
            ...state,
            isEditable
        };

    case UPDATE_LOADING_STATUS:
        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default OrderListReducer;

/* eslint-disable max-len */
import { lazy } from 'react';

import {
    BANNER_GROUP,
    CATALOG_PRODUCT_LIST,
    CATEGORY_FILTER,
    EVENT_CALENDAR,
    LAYOUT_GROUP,
    MOST_ORDERED,
    NEW_PRODUCTS,
    RECENTLY_VIEWED,
    REORDER,
    SAAS_BUTTON_WIDGET,
    SAAS_CMS_PAGE_LIST,
    SAAS_SLIDER,
    STORE_HOURS,
    STORE_LOCATOR,
    VIDEO_WIDGET
} from 'Component/WidgetFactory/WidgetFactory.config.custom';

export const BannerGroupWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/BannerGroupWidget'));
export const CategoryFilterWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/CategoryFilterWidget'));
export const EventCalendarWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/EventCalendarWidget'));
export const LayoutGroupWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/LayoutGroupWidget'));
export const MostOrderedWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/MostOrderedWidget'));
export const NewProducts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/NewProducts'));
export const ProductListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/ProductListWidget'));
export const RecentlyViewedWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/RecentlyViewedWidget'));
export const ReorderWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/ReorderWidget'));
export const SaasButtonWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/SaasButtonWidget'));
export const SaasCmsPageListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/SaasCmsPageListWidget'));
export const SaasSliderWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/SaasSliderWidget'));
export const StoreHoursWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/StoreHoursWidget'));
export const StoreLocatorWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/StoreLocatorWidget'));
export const VideoWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "widget" */ 'Component/VideoWidget'));

/** @namespace Pwasaas/Util/Widget/getWidgetRenderMap */
export const getWidgetRenderMap = () => ({
    [BANNER_GROUP]: {
        component: BannerGroupWidget
    },
    [CATALOG_PRODUCT_LIST]: {
        component: ProductListWidget
    },
    [CATEGORY_FILTER]: {
        component: CategoryFilterWidget
    },
    [EVENT_CALENDAR]: {
        component: EventCalendarWidget
    },
    [LAYOUT_GROUP]: {
        component: LayoutGroupWidget
    },
    [MOST_ORDERED]: {
        component: MostOrderedWidget
    },
    [NEW_PRODUCTS]: {
        component: NewProducts
    },
    [RECENTLY_VIEWED]: {
        component: RecentlyViewedWidget
    },
    [REORDER]: {
        component: ReorderWidget
    },
    [SAAS_BUTTON_WIDGET]: {
        component: SaasButtonWidget
    },
    [SAAS_CMS_PAGE_LIST]: {
        component: SaasCmsPageListWidget
    },
    [SAAS_SLIDER]: {
        component: SaasSliderWidget
    },
    [STORE_HOURS]: {
        component: StoreHoursWidget
    },
    [STORE_LOCATOR]: {
        component: StoreLocatorWidget
    },
    [VIDEO_WIDGET]: {
        component: VideoWidget
    }
});

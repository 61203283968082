export class AddIsOrderListEnabledToConfigPlugin {
    _getSaasConfigFields = (args, callback) => ([
        ...callback(args),
        'order_list_enabled'
    ]);
}

const {
    _getSaasConfigFields
} = new AddIsOrderListEnabledToConfigPlugin();

export const config = {
    'Pwasaas/Query/Store/Query/StoreQuery': {
        'member-function': {
            _getSaasConfigFields
        }
    }
};

export default config;

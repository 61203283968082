import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';
import { getFilteredSelectedAttributes } from 'Util/Product';

import ProductInformation from './ProductInformation.component';
import { TEXTAREA } from './ProductInformation.config';

/** @namespace Pwasaas/Component/ProductInformation/Container/ProductInformationContainer */
export class ProductInformationContainer extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/boolean-prop-naming
        areDetailsLoaded: PropTypes.bool.isRequired,
        product: ProductType.isRequired
    };

    containerProps = () => {
        const {
            areDetailsLoaded,
            product
        } = this.props;

        const selectedAttributes = this._getSelectedAttributeCollection();

        return {
            areDetailsLoaded,
            product,
            selectedAttributes
        };
    };

    _getSelectedAttributeCollection() {
        const { product: { attributes = {} } } = this.props;

        return Object.values(getFilteredSelectedAttributes(
            attributes,
            TEXTAREA,
            true
        )).map((values) => values);
    }

    render() {
        return (
            <ProductInformation
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductInformationContainer;

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';

import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';
import { getStoreDetailWidgetRenderMap } from 'Util/Widget';

import './StoreDetailWidgetFactory.style';

/** @namespace Pwasaas/Component/StoreDetailWidgetFactory/Component/StoreDetailWidgetFactoryComponent */
export class StoreDetailWidgetFactoryComponent extends SourceWidgetFactory {
    static propTypes = {
        ...this.propTypes,
        widgetTitle: PropTypes.string
    };

    static defaultProps = {
        widgetTitle: ''
    };

    renderMap = getStoreDetailWidgetRenderMap();

    renderContent() {
        const { type, widgetTitle } = this.props;
        const {
            component: StoreDetailWidget,
            props
        } = this.renderMap[type] || {};

        const widgetProps = {
            ...props.reduce(
                (acc, prop) => ({
                    ...acc,
                    [prop]: this.props[prop]
                }), {}
            ),
            title: widgetTitle
        };

        if (StoreDetailWidget !== undefined) {
            return <StoreDetailWidget { ...widgetProps } />;
        }

        return null;
    }
}

export default StoreDetailWidgetFactoryComponent;

export const TYPE_TEXT = 'text';
export const TYPE_MULTISELECT = 'multiselect';
export const TYPE_SELECT = 'select';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        headerLogo: state.ConfigReducer.header_logo_src,
        name: state.MetaReducer.default_title || '',
        url: state.MetaReducer.canonical_url || '',
        richSnippets: state.SaasConfigReducer.richSnippets?.product || {}
    };
};

export class AddOrganizationAsSellerToProductPagePlugin {
    containerProps = (args, callback, instance) => {
        const {
            headerLogo,
            name,
            url,
            richSnippets
        } = instance.props;

        return {
            ...callback(...args),
            headerLogo,
            name,
            url,
            richSnippets
        };
    };

    render = (args, callback, instance) => {
        const RenderedComponent = callback.apply(instance, args);

        return (
            <>
                { RenderedComponent }
                { this.renderJsonLdSchemaScope(instance) }
            </>
        );
    };

    renderJsonLdSchemaScope(instance) {
        const {
            dataSource: {
                attributes
            } = {},
            productOrVariant: {
                name,
                price_range: {
                    minimum_price: {
                        final_price: {
                            value: minFinalPrice = 0
                        } = {}
                    } = {},
                    maximum_price: {
                        final_price: {
                            currency,
                            value: maxFinalPrice = 0
                        } = {},
                        regular_price: {
                            value: maxRegularPrice = 0
                        } = {}
                    } = {}
                } = {},
                image: {
                    url: imageUrl = ''
                } = {},
                description = '',
                special_to_date,
                stock_status = ''
            } = {},
            url = '',
            richSnippets
        } = instance.props;

        if (!attributes) {
            return null;
        }

        const offersSchemaData = {
            '@context': 'https://schema.org',
            '@type': 'Offer',
            availability: stock_status === 'IN_STOCK' ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock',
            url,
            highPrice: maxRegularPrice.toString(),
            price: (minFinalPrice < maxFinalPrice) ? minFinalPrice.toString() : maxFinalPrice.toString(),
            priceCurrency: currency,
            priceValidUntil: special_to_date ? special_to_date.split(' ')[0] : ''
        };

        const schemaData = {
            '@context': 'https://schema.org',
            '@type': 'Product',
            image: imageUrl,
            description: description.html,
            name,
            offers: offersSchemaData,
            ...this.getSnippets(richSnippets, attributes)
        };

        return (
            <script type="application/ld+json">
                { JSON.stringify(schemaData) }
            </script>
        );
    }

    getSnippets(fields, attributes) {
        return Object.entries(fields).reduce(
            (snippets, [key, attributeCode]) => {
                if (!attributes[attributeCode]) {
                    return snippets;
                }

                return {
                    ...snippets,
                    [key]: this.getSnippetContent(key, attributes[attributeCode])
                };
            }, {}
        );
    }

    getSnippetContent(snippetKey, attribute) {
        if (snippetKey === 'brand') {
            return {
                '@type': 'Brand',
                name: this.getAttributeValue(attribute)
            };
        }

        return this.getAttributeValue(attribute);
    }

    getAttributeValue({
        attribute_options: options,
        attribute_type: type,
        attribute_value: value
    }) {
        if (!value) {
            return '';
        }

        switch (type) {
        case TYPE_TEXT:
            return value.replace(/<\/?[^>]+(>|$)/g, '');
        case TYPE_SELECT:
            const { label } = this.getOptionLabel(options, value);

            return label;

        case TYPE_MULTISELECT:
            const labelsArray = value.split(',').reduce((labels, value) => {
                const { label } = this.getOptionLabel(options, value);
                if (label) {
                    labels.push(label);
                }

                return labels;
            }, []);

            return labelsArray.join(', ');

        default:
            return '';
        }
    }

    getOptionLabel(options, value) {
        if (options) {
            const optionValues = options[value];
            if (optionValues) {
                const { label } = optionValues;

                return { ...optionValues, labelText: label };
            }
        }

        return {};
    }
}

const {
    containerProps,
    render
} = new AddOrganizationAsSellerToProductPagePlugin();

export default {
    'Route/ProductPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/ProductPage/Container': {
        'member-function': {
            containerProps
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            render
        }
    }
};

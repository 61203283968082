export class StoreQuery {
    _getSaasConfigFields = (args, callback) => ([
        ...callback(args),
        'user_can_create_account',
        'register_form_exclude_coc_field'
    ]);
}

const {
    _getSaasConfigFields
} = new StoreQuery();

export default {
    'Pwasaas/Query/Store/Query/StoreQuery': {
        'member-function': {
            _getSaasConfigFields
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import MyAdditionalEmailsQuery from '../../query/MyAdditionalEmails.query';
import MyAccountAdditionalEmails from './MyAccountAdditionalEmails.component';
import { ADDITIONAL_EMAILS_POPUP_ID } from './MyAccountAdditionalEmails.config';

/** @namespace Pwasaas/Plugin/MageMacleanAdditionalEmails/Component/MyAccountAdditionalEmails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Pwasaas/Plugin/MageMacleanAdditionalEmails/Component/MyAccountAdditionalEmails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error))),
    showPopup: (payload) => dispatch(showPopup(ADDITIONAL_EMAILS_POPUP_ID, payload)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    updateCustomerDetails: (customer) => dispatch(updateCustomerDetails(customer))
});

/** @namespace Pwasaas/Plugin/MageMacleanAdditionalEmails/Component/MyAccountAdditionalEmails/Container/MyAccountAdditionalEmailsContainer */
export class MyAccountAdditionalEmailsContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        updateCustomerDetails: PropTypes.func.isRequired
    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        onDeleteEmail: this.handleDeleteEmail.bind(this),
        onSaveEmail: this.handleSaveEmail.bind(this),
        showCreateNewPopup: this.showCreateNewPopup.bind(this)
    };

    containerProps = () => {
        const {
            props: {
                customer: {
                    additional_emails
                }
            },
            state: {
                isLoading
            }
        } = this;

        return {
            additionalEmails: this._sortByPosition(additional_emails || []),
            isLoading
        };
    };

    showCreateNewPopup() {
        const { showPopup } = this.props;

        showPopup({
            title: __('Add new email')
        });
    }

    _onSuccess = ({ additionalEmails }) => {
        const {
            customer,
            hideActiveOverlay,
            showSuccessNotification,
            updateCustomerDetails
        } = this.props;

        updateCustomerDetails({
            ...customer,
            additional_emails: additionalEmails
        });

        showSuccessNotification(__('Your additional emails has been updated!'));

        this.setState({ isLoading: false }, () => {
            hideActiveOverlay();
        });
    };

    _onError = (error) => {
        const { showErrorNotification } = this.props;
        showErrorNotification(error);

        this.setState({ isLoading: false });
    };

    _sortByPosition = (options) => options.sort(
        (a, b) => {
            if (a.position < b.position) {
                return -1;
            }

            if (a.position > b.position) {
                return 1;
            }

            return 0;
        }
    );

    _getInputData(data) {
        const {
            customer: {
                additional_emails,
                id: customer_id
            } = {}
        } = this.props;

        return {
            ...data,
            customer_id,
            position: (additional_emails || []).length
                ? Math.max(...additional_emails.map(({ position }) => position)) + 1
                : 1
        };
    }

    handleDeleteEmail(id) {
        this.setState({ isLoading: true }, () => {
            fetchMutation(MyAdditionalEmailsQuery.getDeleteEmailMutation(id)).then(
                this._onSuccess,
                this._onError
            );
        });
    }

    handleSaveEmail(data) {
        this.setState({ isLoading: true }, () => {
            fetchMutation(MyAdditionalEmailsQuery.getSaveEmailMutation(this._getInputData(data))).then(
                this._onSuccess,
                this._onError
            );
        });
    }

    render() {
        return (
            <MyAccountAdditionalEmails
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAdditionalEmailsContainer);

import toStyle from 'css-to-style';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';

import { ProductLabelType } from '../../type/ProductLabel';
import { DEFAULT_POSITION, IMAGE_SHAPE, RIGHT_POSITION } from './SaasProductLabels.config';

import './SaasProductLabels.manual.style';

/** @namespace Pwasaas/Plugin/ProductLabel/Component/SaasProductLabels/Component/SaasProductLabelsComponent */
export class SaasProductLabelsComponent extends PureComponent {
    static propTypes = {
        baseUrl: PropTypes.string.isRequired,
        defaultItems: PropTypes.arrayOf(ProductLabelType).isRequired,
        positionRightItem: ProductLabelType
    };

    static defaultProps = {
        positionRightItem: null
    };

    renderProductLabelImage() {
        const {
            baseUrl,
            positionRightItem: {
                image
            }
        } = this.props;

        if (!image || image.includes(IMAGE_SHAPE)) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'SaasProductLabels', elem: 'ProductLabelImage' } }
              src={ baseUrl + image }
              ratio="custom"
            />
        );
    }

    renderDiscountLabel() {
        const {
            positionRightItem: {
                discountPercent,
                txt: discountLabel
            }
        } = this.props;

        if (!discountPercent) {
            return null;
        }

        return (
            <span
              block="SaasProductLabels"
              elem="ProductDiscountLabel"
            >
                <span
                  block="SaasProductLabels"
                  elem="ProductDiscountLabelPercentage"
                >
                    { discountPercent }
                    %
                </span>
                { discountLabel }
            </span>
        );
    }

    renderPositionRightLabel() {
        const { positionRightItem } = this.props;

        if (!positionRightItem) {
            return null;
        }

        const { image, isVisible } = positionRightItem;

        if (!isVisible) {
            return null;
        }

        return (
            <div
              block="SaasProductLabels"
              elem="ProductLabelGroup"
              mods={ { position: RIGHT_POSITION, image: !!image } }
            >
                { this.renderDiscountLabel() }
                { this.renderProductLabelImage() }
            </div>
        );
    }

    renderLabel = (label) => {
        const {
            labelId,
            txt = '',
            image = '',
            isVisible = true,
            style = ''
        } = label;

        if (!isVisible) {
            return null;
        }

        return (
            <div
              key={ labelId }
              block="SaasProductLabels"
              elem="ProductLabel"
              mods={ { hasShape: image.includes(IMAGE_SHAPE) } }
              style={ toStyle(style) }
            >
                <span
                  block="SaasProductLabels"
                  elem="ProductLabelText"
                >
                    { txt }
                </span>
            </div>
        );
    };

    renderDefaultLabels() {
        const { defaultItems } = this.props;

        if (!defaultItems.length) {
            return null;
        }

        return (
            <div
              block="SaasProductLabels"
              elem="ProductLabelGroup"
              mods={ { position: DEFAULT_POSITION } }
              key={ DEFAULT_POSITION }
            >
                { defaultItems.map(this.renderLabel) }
            </div>
        );
    }

    render() {
        const { defaultItems = [], positionRightItem } = this.props;

        if (!defaultItems.length && !positionRightItem) {
            return null;
        }

        return (
            <>
                { this.renderDefaultLabels() }
                { this.renderPositionRightLabel() }
            </>
        );
    }
}

export default SaasProductLabelsComponent;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common';
import { ProductAttributeIconsType } from 'Type/ProductAttributeIcons';

import { PLACEHOLDER_URL } from './ProductAttributeIcons.config';

import './ProductAttributeIcons.manual.style';

/** @namespace Pwasaas/Plugin/ProductAttributeIcons/Component/ProductAttributeIcons/Component/ProductAttributeIconsComponent */
export class ProductAttributeIconsComponent extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        isEnabled: PropTypes.bool.isRequired,
        mediaUrl: PropTypes.string.isRequired,
        productAttributeIcons: PropTypes.arrayOf(
            ProductAttributeIconsType
        ).isRequired
    };

    renderProductAttributeIconItem = ({ label, icon_url }, index) => {
        const { mediaUrl } = this.props;
        const iconUrl = /^https?:\/\//.test(icon_url)
            ? icon_url
            : `${mediaUrl }${ icon_url || PLACEHOLDER_URL}`;

        return (
          <div
            key={ index }
            block="ProductAttributeIcons"
            elem="Item"
          >
            <img
              block="ProductAttributeIcons"
              elem="ItemImage"
              src={ iconUrl }
              alt=""
            />
            <p
              block="ProductAttributeIcons"
              elem="ItemLabel"
            >
                { label }
            </p>
          </div>
        );
    };

    render() {
        const {
            children,
            isEnabled,
            productAttributeIcons
        } = this.props;

        if (!isEnabled) {
            return null;
        }

        return (
            <div block="ProductAttributeIcons">
                { productAttributeIcons.map(this.renderProductAttributeIconItem) }
                { children }
            </div>
        );
    }
}

export default ProductAttributeIconsComponent;

export const HIDDEN_PRODUCT_PAGE_ATTRIBUTE_CODES = [
    'enable_surface_price_calculation',
    'sales_order_contents',
    'sales_order_unit'
];

export const getHiddenAttributeCodes = (args, callback) => [
    ...callback(...args),
    ...HIDDEN_PRODUCT_PAGE_ATTRIBUTE_CODES
];

export default {
    'Pwasaas/Util/Attribute/Index/getHiddenAttributeCodes': {
        function: getHiddenAttributeCodes
    }
};

import PropTypes from 'prop-types';

import { CHECKOUT } from 'Component/Header/Header.config';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        page_title: state.MetaReducer.title,
        email: state.ConfigReducer.email || '',
        isUspActive: !!(state.SaasConfigReducer.uspItems || []).length,
        phone: state.ConfigReducer.phone || '',
        requestedUrl: state.UrlRewritesReducer.requestedUrl
    };
};

export class HeaderContainer {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        email: PropTypes.string.isRequired,
        isUspActive: PropTypes.bool.isRequired,
        page_title: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        requestedUrl: PropTypes.string
    });

    defaultProps = (originalProps) => ({
        ...originalProps,
        requestedUrl: ''
    });

    containerProps = (args, callback, instance) => {
        const {
            page_title,
            email,
            isUspActive,
            phone,
            requestedUrl
        } = instance.props;

        // force normal header on checkout
        const result = callback(...args);
        result.isCheckout = false;

        return {
            ...result,
            page_title,
            email,
            phone,
            requestedUrl,
            isUspActive
        };
    };

    componentDidMountOrUpdate = (args, callback, instance) => {
        this.handleIsCheckout(instance);

        callback(...args);
    };

    handleIsCheckout(instance) {
        const { navigationState: { name = '' } } = instance.props;

        if (name === CHECKOUT) {
            document.documentElement.classList.add('isCheckout');

            return;
        }

        document.documentElement.classList.remove('isCheckout');
    }
}

const {
    staticPropTypes,
    defaultProps,
    componentDidMountOrUpdate,
    containerProps
} = new HeaderContainer();

export default {
    'Component/Header/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Header/Container': {
        'static-member': {
            defaultProps,
            propTypes: staticPropTypes
        },
        'member-function': {
            containerProps,
            componentDidMount: componentDidMountOrUpdate,
            componentDidUpdate: componentDidMountOrUpdate
        }
    }
};

export class ProductGalleryContainer {
  containerProps = (args, callback, instance) => {
      const {
          product: {
              productLabels
          }
      } = instance.props;

      return {
          ...callback(args),
          productLabels
      };
  };
}

const {
    containerProps
} = new ProductGalleryContainer();

export default {
    'Component/ProductGallery/Container': {
        'member-function': {
            containerProps
        }
    }
};

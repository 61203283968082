import { cloneElement } from 'react';

import { SAAS_MENU_ID } from 'Component/SaasHeader/SaasHeader.config';

import '../style/SaasHeader.override.manual.style';

export class SaasHeaderComponent {
    renderSaasHeaderNavList = (args, callback, instance) => {
        const { isShowStoreSwitcher } = instance.props;
        const result = callback(...args);

        if (!isShowStoreSwitcher) {
            return result;
        }

        const { className = '' } = result.props;

        return cloneElement(result, {
            ...result.props,
            className: `${className} ${className}_isShowStoreSwitcher`
        });
    };

    renderSaasHeaderNav = (args, callback, instance) => {
        const result = callback(...args);
        const {
            device,
            isMenuPositionBottom
        } = instance.props;

        if (!isMenuPositionBottom || device.isMobile) {
            return result;
        }

        const { props, props: { children: childResult } } = result;
        const children = childResult.filter(
            (item) => {
                if (!item) {
                    return false;
                }

                const { props: { id } = {} } = item;

                return id !== SAAS_MENU_ID;
            }
        );

        return cloneElement(result, {
            ...props,
            children
        });
    };

    renderSaasHeader = (args, callback, instance) => {
        const result = callback(...args);
        const {
            device,
            isMenuPositionBottom
        } = instance.props;

        if (!isMenuPositionBottom || device.isMobile) {
            return result;
        }

        const { props, props: { children } } = result;

        return cloneElement(result, {
            ...props,
            children: this.getChildrenWithMenuBottom(children, instance)
        });
    };

    renderCurrencySwitcher = (args, callback, instance) => {
        const { isShowCurrencySwitcher } = instance.props;

        if (!isShowCurrencySwitcher) {
            return null;
        }

        return callback(...args);
    };

    renderStoreSwitcher = (args, callback, instance) => {
        const { isShowStoreSwitcher } = instance.props;

        if (!isShowStoreSwitcher) {
            return null;
        }

        return callback(...args);
    };

    renderBottomMenu(instance) {
        return (
            <div
              block="SaasHeader"
              elem="MenuBottom"
            >
                { instance.renderMenu() }
            </div>
        );
    }

    getChildrenWithMenuBottom = (children, instance) => {
        if (!Array.isArray(children)) {
            return [
                children,
                this.renderBottomMenu(instance)
            ];
        }

        return children.reduce(
            (acc, child) => {
                const result = [
                    ...acc,
                    child
                ];

                if (child?.type === 'nav') {
                    result.push(this.renderBottomMenu(instance));
                }

                return result;
            }, []
        );
    };
}

const {
    renderSaasHeader,
    renderSaasHeaderNav,
    renderSaasHeaderNavList,
    renderCurrencySwitcher,
    renderStoreSwitcher
} = new SaasHeaderComponent();

export default {
    'Pwasaas/Component/SaasHeader/Component/SaasHeaderComponent': {
        'member-function': {
            renderSaasHeader,
            renderSaasHeaderNav,
            renderSaasHeaderNavList,
            renderCurrencySwitcher,
            renderStoreSwitcher
        }
    }
};

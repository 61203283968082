/**
 * Add possibility to use mailto inside a link href
 */

export class LinkContainer {
    getTo = (args, callback, instance) => {
        const { to } = instance.props;

        if (typeof to === 'string' && /mailto|tel/.test(to)) {
            return to.trim();
        }

        return callback(...args);
    };
}

const {
    getTo
} = new LinkContainer();

export default {
    'Component/Link/Container': {
        'member-function': {
            getTo
        }
    }
};

export const EMAIL_TYPE = 'email';
export const TEXT_TYPE = 'text';
export const NUMBER_TYPE = 'number';
export const RADIO_TYPE = 'radio';
export const CHECKBOX_TYPE = 'checkbox';
export const TEXTAREA_TYPE = 'textarea';
export const PASSWORD_TYPE = 'password';
export const SELECT_TYPE = 'select';
export const FILE_TYPE = 'file';
export const DATE_TYPE = 'date';
export const SAAS_INPUT_SELECT_TYPE = 'saas_input_select';
export const HIDDEN_TYPE = 'hidden';

export const ENTER_KEY_CODE = 13;

export const VALIDATION_STATUS = {
    success: true,
    error: false,
    skip: 'skip'
};

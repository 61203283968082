/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import SaasCheckoutForm from 'Component/SaasCheckoutForm';

import { ENABLE_SAAS_CHECKOUT_FORM } from '../SaasCheckoutForm.config';

export class CheckoutAddressBookComponent {
    renderCustomAddress = (args, callback) => {
        const result = callback(...args);

        if (!ENABLE_SAAS_CHECKOUT_FORM) {
            return result;
        }

        return (
            <SaasCheckoutForm
              { ...result.props }
            />
        );
    };
}

const {
    renderCustomAddress
} = new CheckoutAddressBookComponent();

export default {
    'Component/CheckoutAddressBook/Component': {
        'member-function': {
            renderCustomAddress
        }
    }
};

import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import CmsBlockQuery from 'Query/CmsBlock.query';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { updatePageHeaderContent } from 'Store/PageHeader/PageHeader.action';
import { BlockListType } from 'Type/CMS';
import DataContainer from 'Util/Request/DataContainer';

import { setDocumentStyling } from '../../util/SalesPromotion';
import SalesPromotionPage from './SalesPromotionPage.component';
import {
    SALES_PROMOTION_BLOCK_TYPE,
    SALES_PROMOTION_PAGE
} from './SalesPromotionPage.config';

/** @namespace Pwasaas/Plugin/SalesPromotion/Route/SalesPromotionPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isPageHeaderVisible: state.PageHeaderReducer.isVisible,
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState
});

/** @namespace Pwasaas/Plugin/SalesPromotion/Route/SalesPromotionPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    clearPageHeader: () => dispatch(updatePageHeaderContent()),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    updatePageHeader: (data) => dispatch(updatePageHeaderContent(data))
});

/** @namespace Pwasaas/Plugin/SalesPromotion/Route/SalesPromotionPage/Container/SalesPromotionPageContainer */
export class SalesPromotionPageContainer extends DataContainer {
    static propTypes = {
        clearPageHeader: PropTypes.func.isRequired,
        isBreadcrumbsActive: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isPageHeaderVisible: PropTypes.bool.isRequired,
        navigationState: PropTypes.shape({
            name: PropTypes.string
        }).isRequired,
        page: BlockListType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        updatePageHeader: PropTypes.func.isRequired
    };

    state = {
        sidebarContent: ''
    };

    parseRules = [
        {
            name: 'widget',
            attr: 'block_id',
            type: SALES_PROMOTION_BLOCK_TYPE,
            handler: this._setSidebarContent
        }
    ];

    parserOptions = {
        replace: (domNode) => {
            const {
                name: domName,
                attribs = {}
            } = domNode;

            const rule = this.parseRules.find((rule) => {
                const { name, type } = rule;
                const { type: domType } = attribs;

                return domName === name && domType === type;
            });

            if (!rule) {
                return;
            }

            const { attr, handler } = rule;

            if (!attribs[attr]) {
                return;
            }

            handler.call(this, attribs[attr]);
        }
    };

    sidebarHeading = createRef();

    componentDidMount() {
        this._setHeaderData();
        this._parsePageContent();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isPageHeaderVisible, navigationState: { name } } = this.props;
        const { navigationState: { name: prevName } } = prevProps;

        if (
            name !== prevName
                && name === SALES_PROMOTION_PAGE
        ) {
            this._setPageHeader();
        }

        const { sidebarContent } = this.state;
        const { sidebarContent: prevSidebarContent } = prevState;

        if (sidebarContent !== prevSidebarContent) {
            setDocumentStyling(
                isPageHeaderVisible,
                sidebarContent,
                this.sidebarHeading
            );
        }
    }

    componentWillUnmount() {
        const { clearPageHeader } = this.props;

        clearPageHeader();
    }

    containerProps = () => {
        const {
            props: {
                isBreadcrumbsActive,
                isLoading,
                page
            },
            sidebarHeading,
            state: {
                sidebarContent
            }
        } = this;

        return {
            isBreadcrumbsActive,
            isLoading,
            page,
            sidebarContent,
            sidebarHeading
        };
    };

    _setHeaderData() {
        const {
            page: {
                title: name
            },
            setHeaderState
        } = this.props;

        setHeaderState({
            name: SALES_PROMOTION_PAGE,
            title: name,
            onBackClick: () => history.goBack()
        });
    }

    _setPageHeader() {
        const {
            navigationState,
            page: {
                page_image,
                short_description: description
            },
            updatePageHeader
        } = this.props;

        if (!page_image) {
            return;
        }

        const image = page_image;

        updatePageHeader({
            currentState: navigationState,
            description,
            image,
            isVisible: true
        });
    }

    _parsePageContent() {
        const { page: { content } } = this.props;

        if (!content) {
            return;
        }

        parser(content, this.parserOptions);
    }

    _setSidebarContent(blockId) {
        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [blockId] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                const [{ content }] = items;

                this.setState({
                    sidebarContent: content
                });
            }
        );
    }

    render() {
        return (
            <SalesPromotionPage
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPromotionPageContainer);

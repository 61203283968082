import history from 'Util/History';

export class CartDispatcher {
    addProductToCart = (args, callback) => callback(...args).catch(
        () => {
            const [, options] = args;
            const { product: { url } } = options;
            history.push(url);

            return Promise.reject();
        }
    );
}

const {
    addProductToCart
} = new CartDispatcher();

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart
        }
    }
};

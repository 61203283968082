import getStore from 'Util/Store';

export const WYSIWYG_MEDIA = 'wysiwyg/';
export const CATEGORY_MEDIA = 'catalog/category/';
export const PRODUCT_MEDIA = 'catalog/product';
export const LOGO_MEDIA = 'logo/';

/** @namespace Pwasaas/Util/Media/getIsCategoryLogo */
export const getIsCategoryLogo = (
    src,
    subPath,
    isMediaPath
) => src.includes(CATEGORY_MEDIA) && subPath === LOGO_MEDIA && isMediaPath;

export default (src, subPath = '', isMediaPath = true) => {
    const { ConfigReducer: { secure_base_media_url, base_url } } = getStore().getState();
    const isCategoryLogo = getIsCategoryLogo(src, subPath, isMediaPath);

    const baseUrl = isMediaPath
        ? secure_base_media_url || '/media/'
        : base_url;

    return isCategoryLogo
        ? `${ base_url }${ src }`
        : `${ baseUrl }${ subPath }${ src }`;
};

import PropTypes from 'prop-types';

/** @namespace Pwasaas/Plugin/LayoutGroupWidget/Util/LayoutGroupPropType/LayoutGroupPropType */
export class LayoutGroupPropType {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        isNotLayoutGroup: PropTypes.bool
    });

    defaultProps = (originalProps) => ({
        ...originalProps,
        isNotLayoutGroup: false
    });
}

export default LayoutGroupPropType;

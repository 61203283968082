export class ProductActionsComponent {
    componentDidUpdate = (args, callback, instance) => {
        const [prevProps] = args;
        const { product: { id: prevId } } = prevProps;
        const {
            product: { id },
            productConfig: {
                defaultQty: {
                    value = 1
                } = {}
            } = {},
            setQuantity
        } = instance.props;

        if (id !== prevId) {
            setQuantity(value);

            return;
        }

        callback.apply(instance, args);
    };
}

const {
    componentDidUpdate
} = new ProductActionsComponent();

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            componentDidUpdate
        }
    }
};

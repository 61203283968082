import {
    TOGGLE_PAGE_HEADER,
    UPDATE_PAGE_HEADER_CONTENT,
    UPDATE_PAGE_HEADER_WIDGET
} from './PageHeader.action';

/** @namespace Pwasaas/Store/PageHeader/Reducer/getInitialState */
export const getInitialState = () => ({
    buttonTitle: '',
    buttonUrl: '',
    currentState: {},
    description: '',
    detailImage: '',
    image: '',
    isVisible: false,
    name: '',
    widget: {}
});

/** @namespace Pwasaas/Store/PageHeader/Reducer/PageHeaderReducer */
export const PageHeaderReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_PAGE_HEADER_CONTENT:
        const { content = getInitialState() } = action;

        return {
            ...state,
            ...content
        };

    case UPDATE_PAGE_HEADER_WIDGET:
        const { widget } = action;

        return {
            ...state,
            widget
        };

    case TOGGLE_PAGE_HEADER:
        const { isVisible } = action;

        return {
            ...state,
            isVisible
        };

    default:
        return state;
    }
};

export default PageHeaderReducer;

export class CmsPageQuery {
    _getPageFields = (args, callback) => ([
        ...callback(...args),
        'page_layout'
    ]);
}

const {
    _getPageFields
} = new CmsPageQuery();

export default {
    'Query/CmsPage': {
        'member-function': {
            _getPageFields
        }
    }
};

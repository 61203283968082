import LayoutGroupPropType from '../util/LayoutGroupPropType';

export class CmsBlockContainer extends LayoutGroupPropType {
    containerProps = (args, callback, { props: { isNotLayoutGroup } }) => ({
        ...callback(...args),
        isNotLayoutGroup
    });
}

const {
    containerProps,
    defaultProps,
    staticPropTypes
} = new CmsBlockContainer();

export default {
    'Component/CmsBlock/Container': {
        'member-function': {
            containerProps
        },
        'static-member': {
            defaultProps,
            propTypes: staticPropTypes
        }
    }
};

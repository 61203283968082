import {
    mapStateToProps,
    ProductConfigSetQuantity
} from '../util/ProductConfig';

export class CartItemContainer extends ProductConfigSetQuantity {}

const {
    containerProps
} = new CartItemContainer();

export default {
    'Component/CartItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartItem/Container': {
        'member-function': {
            containerProps
        }
    }
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';

import CompleteOrderProductList from './CompleteOrderProductList.component';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrderProductList/Container/CompleteOrderProductListContainer */
export class CompleteOrderProductListContainer extends PureComponent {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        products: PropTypes.arrayOf(ProductType).isRequired,
        selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    containerFunctions = {
        handleSelectChange: this.onSelectChange.bind(this)
    };

    containerProps = () => {
        const { products, selectedIds } = this.props;

        return {
            products,
            selectedIds
        };
    };

    onSelectChange(item, type) {
        const { onSelect } = this.props;

        onSelect(item, type);
    }

    render() {
        return (
            <CompleteOrderProductList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default CompleteOrderProductListContainer;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SurfacePriceCalculation from './SurfacePriceCalculation.component';
import { ONE_HUNDRED_PERCENT } from './SurfacePriceCalculation.config';

/** @namespace Pwasaas/Component/SurfacePriceCalculation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSurfacePriceCalculationEnabled: state.SaasConfigReducer.config?.surface_price_calculation_enabled || false,
    salesOrderUnitLoss: state.SaasConfigReducer.config?.surface_price_calculation_sales_order_unit_loss || [],
    productInformation: state.ProductReducer.product || {}
});

/** @namespace Pwasaas/Component/SurfacePriceCalculation/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/SurfacePriceCalculation/Container/SurfacePriceCalculationContainer */
export class SurfacePriceCalculationContainer extends PureComponent {
    static propTypes = {
        isSurfacePriceCalculationEnabled: PropTypes.bool.isRequired,
        salesOrderUnitLoss: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ).isRequired,
        productInformation: PropTypes.shape().isRequired,
        setQuantityOnCalculate: PropTypes.func.isRequired
    };

    state = {
        numberValue: '',
        selectedValue: '',
        calculatedBoxes: '0',
        calculatedPrice: '0.00',
        calculatedSurface: '0.00',
        productPricePerUnit: null
    };

    containerFunctions = {
        getSelectOptions: this.getSelectOptions.bind(this),
        handleOnlyNumbersOnChange: this.handleOnlyNumbersOnChange.bind(this),
        handleSelectedValue: this.handleSelectedValue.bind(this)
    };

    componentDidMount() {
        const {
            productInformation: {
                attributes: {
                    sales_order_contents: {
                        attribute_value: squareMeterPerBox = ''
                    } = {}
                } = {},
                price_range: {
                    minimum_price: {
                        final_price: productPrice
                    } = {}
                } = {}
            }
        } = this.props;

        this.setState({ productPricePerUnit: (productPrice.value / Number(squareMeterPerBox)).toFixed(2) });
    }

    containerProps = () => {
        const {
            salesOrderUnitLoss,
            productInformation: {
                price_range: {
                    minimum_price: {
                        final_price: {
                            currency: productCurrency = 'EUR',
                            value: productPrice = ''
                        } = {}
                    } = {}
                } = {},
                attributes: {
                    sales_order_unit: {
                        attribute_value: attrValue = '',
                        attribute_options: salesOrderUnitOptions = {}
                    } = {},
                    sales_order_contents: {
                        attribute_value: squareMeterPerBox = ''
                    } = {}
                } = {}
            }
        } = this.props;

        const { label: orderUnit = '' } = salesOrderUnitOptions[attrValue] || {};

        const {
            numberValue,
            selectedValue,
            calculatedBoxes,
            calculatedPrice,
            calculatedSurface,
            productPricePerUnit
        } = this.state;

        return {
            salesOrderUnitLoss,
            productPrice,
            productPricePerUnit,
            productCurrency,
            orderUnit,
            squareMeterPerBox,
            numberValue,
            selectedValue,
            calculatedBoxes,
            calculatedPrice,
            calculatedSurface
        };
    };

    getSelectOptions() {
        const { salesOrderUnitLoss } = this.props;
        const { selectedValue } = this.state;

        if (!salesOrderUnitLoss.length) {
            return [];
        }

        const unitLoss = salesOrderUnitLoss.map((el, i) => {
            if (i === 0 && selectedValue === '') {
                this.setState({ selectedValue: el.value });
            }

            return { ...el, id: i };
        });

        return unitLoss;
    }

    handleOnlyNumbersOnChange(val) {
        const numberValue = val.replace(/\D/g, '');

        this.setState(
            { numberValue },
            () => this.setCalculated()
        );
    }

    handleSelectedValue(selectedValue) {
        this.setState(
            { selectedValue },
            () => this.setCalculated()
        );
    }

    setCalculated() {
        const {
            numberValue: squareMeter,
            selectedValue
        } = this.state;

        const {
            setQuantityOnCalculate,
            productInformation: {
                price_range: {
                    minimum_price: {
                        final_price: productPrice
                    } = {}
                } = {},
                attributes: {
                    sales_order_contents: {
                        attribute_value: squareMeterPerBox = ''
                    } = {}
                } = {}
            }
        } = this.props;

        const {
            calculatedSurface,
            calculatedBoxes
        } = this.getCalculatedBoxes(
            squareMeter,
            selectedValue,
            Number(squareMeterPerBox)
        );

        const calculatedPrice = `${ (productPrice.value * calculatedBoxes).toFixed(2) }`;

        setQuantityOnCalculate(calculatedBoxes);

        this.setState({
            calculatedSurface,
            calculatedBoxes,
            calculatedPrice
        });
    }

    getCalculatedBoxes(
        squareMeter,
        selectedValue,
        squareMeterPerBox
    ) {
        const percent = selectedValue[selectedValue.length - 1] === '%';

        const unitLoss = percent
            ? parseFloat(selectedValue) / ONE_HUNDRED_PERCENT + 1
            : parseFloat(selectedValue);

        const calculatedSurface = percent
            ? (Number(squareMeter) * unitLoss)
            : (Number(squareMeter) + unitLoss);

        const calculatedBoxes = Math.ceil(calculatedSurface / squareMeterPerBox);

        return {
            calculatedSurface: calculatedSurface.toFixed(2),
            calculatedBoxes
        };
    }

    render() {
        return (
            <SurfacePriceCalculation
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurfacePriceCalculationContainer);

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MyAccountReorderButton.style';

/** @namespace Pwasaas/Component/MyAccountReorderButton/Component/MyAccountReorderButtonComponent */
export class MyAccountReorderButtonComponent extends PureComponent {
    static propTypes = {
        handleClick: PropTypes.func.isRequired,
        isLoading: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired
    };

    render() {
        const {
            handleClick,
            isLoading,
            title
        } = this.props;

        return (
            <button
              block="MyAccountReorderButton"
              elem="ReorderButton"
              mix={ { block: 'Button' } }
              onClick={ handleClick }
              disabled={ isLoading }
            >
                { title }
            </button>
        );
    }
}

export default MyAccountReorderButtonComponent;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CompleteOrderTotals.style';

/** @namespace Pwasaas/Plugin/CompleteOrder/Component/CompleteOrderTotals/Component/CompleteOrderTotalsComponent */
export class CompleteOrderTotalsComponent extends PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf({
            options: PropTypes.arrayOf(PropTypes.string),
            price: PropTypes.number,
            title: PropTypes.string
        }).isRequired,
        priceTaxLabel: PropTypes.string.isRequired,
        totalPrice: PropTypes.number.isRequired
    };

    renderOption(option) {
        return (
            <li
              block="CompleteOrderTotals"
              elem="Option"
            >
                { option }
            </li>
        );
    }

    renderOptionList(quantity, options) {
        return (
            <ul
              block="CompleteOrderTotals"
              elem="OptionList"
            >
                { options.map(this.renderOption) }
                <li
                  block="CompleteOrderTotals"
                  elem="Option"
                  mods={ { isQuantity: true } }
                >
                    { __('Quantity: %s', quantity) }
                </li>
            </ul>
        );
    }

    renderItem = ({
        options,
        price,
        quantity,
        title
    }) => (
        <li
          block="CompleteOrderTotals"
          elem="Item"
        >
            <div
              block="CompleteOrderTotals"
              elem="ItemTitle"
            >
                <span>{ title }</span>
                { this.renderOptionList(quantity, options) }
            </div>
            <div
              block="CompleteOrderTotals"
              elem="ItemPrice"
            >
                { price }
            </div>
        </li>
    );

    renderTotal() {
        const { priceTaxLabel, totalPrice } = this.props;

        return (
            <li
              block="CompleteOrderTotals"
              elem="Item"
            >
                <div
                  block="CompleteOrderTotals"
                  elem="ItemTitle"
                >
                    <span>{ __('Your total price (%s)', priceTaxLabel) }</span>
                </div>
                <div
                  block="CompleteOrderTotals"
                  elem="ItemPrice"
                  mods={ { isTotal: true } }
                >
                    { totalPrice }
                </div>
            </li>
        );
    }

    render() {
        const { items } = this.props;

        if (!items.length) {
            return null;
        }

        return (
            <div block="CompleteOrderTotals">
                <ul
                  block="CompleteOrderTotals"
                  elem="ItemList"
                >
                    { items.map(this.renderItem) }
                    { this.renderTotal() }
                </ul>
            </div>
        );
    }
}

export default CompleteOrderTotalsComponent;

export const THEME_KEYS_VARIABLE_IMPORT = [
    'colors',
    'buttons',
    'header',
    'footer',
    'usps',
    'storeNotice',
    'subcategory',
    'dropdownMenu',
    'menu'
];

import PropTypes from 'prop-types';
import { CheckoutFormType } from 'src/plugin/SaasLayout/type/SaasLayout';

import CheckoutAddressForm from 'Component/CheckoutAddressForm/CheckoutAddressForm.component';
import { DATE_OF_BIRTH } from 'Component/CheckoutDateOfBirth/CheckoutDateOfBirth.config';
import Field from 'Component/Field';
import FormPortal from 'Component/FormPortal';
import { getSelectedFormProps } from 'Util/Saas';

import {
    CITY, COMPANY, HOUSENUMBER, REGION, STREET, TELEPHONE, VAT
} from './SaasCheckoutForm.config';

import './SaasCheckoutForm.manual.style';

/** @namespace Pwasaas/Component/SaasCheckoutForm/Component/SaasCheckoutFormComponent */
export class SaasCheckoutFormComponent extends CheckoutAddressForm {
    static propTypes = {
        ...CheckoutAddressForm.propTypes,
        checkoutFormFields: CheckoutFormType.isRequired,
        companyShow: PropTypes.string.isRequired,
        customFieldsOptionMap: PropTypes.shape({}).isRequired,
        editFields: PropTypes.shape({}).isRequired,
        isDateOfBirthEnabled: PropTypes.bool.isRequired,
        isShowVatNumber: PropTypes.bool.isRequired,
        vatNumberShow: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...CheckoutAddressForm.defaultProps
    };

    placeholdersToOverride = {
        [COMPANY]: __('Company'),
        [CITY]: __('City'),
        [DATE_OF_BIRTH]: __('DD-MM-YYYY'),
        [HOUSENUMBER]: __('House number'),
        [TELEPHONE]: __('Telephone'),
        [REGION]: __('Region'),
        [STREET]: __('Street'),
        [VAT]: __('VAT')
    };

    getSortedCustomFieldMap = () => {
        const {
            customFieldsOptionMap,
            checkoutFormFields,
            editFields
        } = this.props;

        if (!checkoutFormFields) {
            return {};
        }

        const fieldMap = Object.entries(checkoutFormFields).reduce(
            (fieldMap, [name, fieldOptions]) => {
                const newField = {
                    ...fieldMap[name] || {},
                    enabled: fieldOptions.enabled,
                    mix: {
                        block: 'SaasCheckoutForm',
                        elem: 'Field',
                        mods: {
                            name,
                            width: fieldOptions.width || '100'
                        }
                    }
                };

                const {
                    conditions,
                    onChangeHandler
                } = customFieldsOptionMap[name] || {};

                const {
                    enabled,
                    ...selectedFieldProps
                } = getSelectedFormProps(
                    conditions,
                    newField,
                    editFields[name] || {},
                    this
                );

                if (enabled === false) {
                    return !fieldMap[name] ? fieldMap : {
                        ...fieldMap,
                        [name]: {
                            ...fieldMap[name],
                            position: fieldOptions.position,
                            type: 'hidden'
                        }
                    };
                }

                if (typeof this.props[onChangeHandler] === 'function') {
                    selectedFieldProps.onChange = (
                        inputValue
                    ) => this.props[onChangeHandler].apply(this, [name, inputValue]);
                }

                return {
                    ...fieldMap,
                    [name]: {
                        ...selectedFieldProps,
                        position: fieldOptions.position,
                        placeholder: this.placeholdersToOverride[name]
                            || fieldMap[name]?.placeholder
                            || ''
                    }
                };
            }, this.fieldMap
        );

        return Object.fromEntries(
            Object.entries(fieldMap).sort(([, a], [, b]) => a.position - b.position)
        );
    };

    renderField = (fieldEntry) => {
        const defaultValues = this.getDefaultValues(fieldEntry);

        return (
            <Field
              key={ defaultValues.key }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...defaultValues }
            />
        );
    };

    renderFields() {
        const sortedfieldMap = this.getSortedCustomFieldMap();

        return (
            <div
              block="FieldForm"
              elem="Fields"
            >
                { Object.entries(sortedfieldMap).map(this.renderField) }
            </div>
        );
    }

    render() {
        const { id } = this.props;

        return (
            <FormPortal
              id={ id }
              name="CheckoutAddressForm"
            >
                <div
                  block="FieldForm"
                  mix={ { block: 'SaasCheckoutForm' } }
                >
                    { this.renderFields() }
                </div>
            </FormPortal>
        );
    }
}

export default SaasCheckoutFormComponent;

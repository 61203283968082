import PropTypes from 'prop-types';

import '../style/Field.manual.style';

export class FieldComponent {
    staticPropTypes = (originalProps) => ({
        ...originalProps,
        handleMultiplyField: PropTypes.func.isRequired,
        isMultipliable: PropTypes.bool.isRequired,
        isWithInputWrapper: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired
    });

    renderInputWithWrapper = (args, callback, instance) => {
        const result = callback(...args);
        const { isWithInputWrapper } = instance.props;

        if (!isWithInputWrapper) {
            return result;
        }

        return (
            <>
                <div
                  block="Field"
                  elem="InputWrapper"
                >
                    { instance.renderMessage(true) }
                    { result }
                </div>
                { this.renderInputMultiplyButton(instance) }
            </>
        );
    };

    renderMessage = (args, callback, instance) => {
        const [forceRender] = args;
        const { isWithInputWrapper } = instance.props;

        if (isWithInputWrapper && !forceRender) {
            return null;
        }

        return callback(...args);
    };

    renderInputMultiplyButton(instance) {
        const {
            handleMultiplyField,
            isMultipliable,
            name,
            label,
            position,
            type
        } = instance.props;

        if (!isMultipliable) {
            return null;
        }

        return (
            <button
              block="Field"
              elem="MultiplyButton"
              mix={ { block: 'Button' } }
              type="button"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleMultiplyField({
                  isMultipliable,
                  label,
                  name,
                  position,
                  type,
                  value: ''
              }) }
            >
                { `${__('Another %s', label)} +` }
            </button>
        );
    }
}

const {
    renderInputWithWrapper,
    renderMessage,
    staticPropTypes
} = new FieldComponent();

export default {
    'Component/Field/Component': {
        'member-function': {
            renderTextarea: renderInputWithWrapper,
            renderTypeText: renderInputWithWrapper,
            renderTypeEmail: renderInputWithWrapper,
            renderTypePassword: renderInputWithWrapper,
            renderTypeNumber: renderInputWithWrapper,
            renderFile: renderInputWithWrapper,
            renderSelectWithOptions: renderInputWithWrapper,
            renderMessage
        },
        'static-member': {
            propTypes: staticPropTypes
        }
    }
};

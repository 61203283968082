/**
 * Reset display mode to DISPLAY_MODE_PRODUCTS when coming from a category without having a visible product list
 */

import { DISPLAY_MODE_PRODUCTS } from 'Route/CategoryPage/CategoryPage.config';
import { updateCurrentCategory } from 'Store/Category/Category.action';

export const mapDispatchToProps = ([dispatch], callback) => ({
    ...callback(dispatch),
    updateCurrentCategory: (category) => dispatch(updateCurrentCategory(category))
});

export class SearchPageContainer {
    initialDisplayMode;

    componentDidUpdate = (args, callback, instance) => {
        const {
            category,
            category: {
                display_mode
            },
            updateCurrentCategory
        } = instance.props;

        if (!this.initialDisplayMode) {
            this.initialDisplayMode = display_mode;
        }

        // Prevent a display update loop. SearchPage display mode should always be PRODUCTS
        if (display_mode !== DISPLAY_MODE_PRODUCTS) {
            updateCurrentCategory({
                ...category,
                display_mode: DISPLAY_MODE_PRODUCTS
            });
        }

        return callback(...args);
    };

    componentWillUnmount = (args, callback, instance) => {
        const {
            category,
            category: {
                display_mode
            },
            updateCurrentCategory
        } = instance.props;

        console.log(this.initialDisplayMode);

        // Reset display mode when leaving search page
        if (this.initialDisplayMode && this.initialDisplayMode !== display_mode) {
            updateCurrentCategory({
                ...category,
                display_mode: this.initialDisplayMode
            });
        }

        return callback(...args);
    };
}

const {
    componentDidUpdate,
    componentWillUnmount
} = new SearchPageContainer();

export default {
    'Route/SearchPage/Container': {
        'member-function': {
            componentDidUpdate,
            componentWillUnmount
        }
    }
};

import { Field } from 'Util/Query';

/** @namespace Pwasaas/Query/LoginAsCustomer/Query/LoginAsCustomerQuery */
export class LoginAsCustomerQuery {
    getCustomerListQuery() {
        return new Field('customerList')
            .addField(this._getCustomerField());
    }

    getCustomerTokenByEmailQuery(email, secret) {
        return new Field('getCustomerTokenByEmail')
            .addArgument('email', 'String!', email)
            .addArgument('secret', 'String!', secret);
    }

    getSaveOrderAccountManagerMutation(cartId, masterAccount) {
        return new Field('setOrderAccountManager')
            .addArgument('cart_id', 'String', cartId)
            .addArgument('account_manager', 'String', masterAccount);
    }

    _getCustomerField() {
        return new Field('customer')
            .addFieldList([
                'company',
                'firstname',
                'middlename',
                'lastname',
                'email'
            ]);
    }
}

export default new LoginAsCustomerQuery();

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CustomTextPlaceholder from 'Component/CustomTextPlaceholder';
import { MixType } from 'Type/Common';
import { ProductAttributeHighlightType } from 'Type/ProductAttributeHighlight';

import './SaasProductTitleBlock.style';

/** @namespace Pwasaas/Component/SaasProductTitleBlock/Component/SaasProductTitleBlockComponent */
export class SaasProductTitleBlockComponent extends PureComponent {
    static propTypes = {
        isHeading: PropTypes.bool.isRequired,
        mix: MixType.isRequired,
        name: PropTypes.string.isRequired,
        subAttributeLabel: ProductAttributeHighlightType.isRequired,
        superAttributeLabel: ProductAttributeHighlightType.isRequired
    };

    renderAttributeSuperLabel() {
        const {
            superAttributeLabel: {
                showLabel,
                label
            } = {}
        } = this.props;

        if (showLabel !== '1' || !label) {
            return null;
        }

        return (
            <span
              block="SaasProductTitleBlock"
              elem="AttributeSuperLabel"
            >
                { `${label}: ` }
            </span>
        );
    }

    renderAttributeSuper() {
        const {
            superAttributeLabel: {
                value,
                label
            } = {}
        } = this.props;

        if (!value && !label) {
            return null;
        }

        if (value === '-1') {
            return null;
        }

        return (
            <div
              block="SaasProductTitleBlock"
              elem="AttributeSuper"
            >
                { this.renderAttributeSuperLabel() }
                <span
                  block="SaasProductTitleBlock"
                  elem="AttributeSuperValue"
                >
                    { value }
                </span>
            </div>
        );
    }

    renderAttributeSubLabel() {
        const {
            subAttributeLabel: {
                showLabel,
                label
            } = {}
        } = this.props;

        if (showLabel !== '1' || !label) {
            return null;
        }

        return (
            <span
              block="SaasProductTitleBlock"
              elem="AttributeSubLabel"
            >
                { label }
                :
            </span>
        );
    }

    renderAttributeSub() {
        const {
            subAttributeLabel: {
                value = '',
                label = ''
            } = {}
        } = this.props;

        if (!value && !label) {
            return null;
        }

        if (value === '-1') {
            return null;
        }

        return (
            <div
              block="SaasProductTitleBlock"
              elem="AttributeSub"
            >
                { this.renderAttributeSubLabel() }
                <span
                  block="SaasProductTitleBlock"
                  elem="AttributeSubValue"
                >
                    { value }
                </span>
            </div>
        );
    }

    renderTitle() {
        const { isHeading, name } = this.props;

        if (isHeading) {
            return (
                <h1
                  block="ProductActions"
                  elem="Title"
                  itemProp="name"
                >
                    <CustomTextPlaceholder content={ name } length="medium" />
                </h1>
            );
        }

        return (
            <h2
              block="ProductActions"
              elem="Title"
              itemProp="name"
            >
                <CustomTextPlaceholder content={ name } length="medium" />
            </h2>
        );
    }

    render() {
        const {
            mix,
            name
        } = this.props;

        if (!name) {
            return null;
        }

        return (
            <div
              block="SaasProductTitleBlock"
              mix={ mix }
            >
                { this.renderAttributeSuper() }
                { this.renderTitle() }
                { this.renderAttributeSub() }
            </div>
        );
    }
}

export default SaasProductTitleBlockComponent;

import SearchItem from 'Component/SearchItem';

export class SearchOverlayComponent {
    renderSearchItem = (args, callback, instance) => {
        const { type } = instance.props;
        const result = callback(...args);

        if (!type) {
            return result;
        }

        return (
            <SearchItem
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...result.props }
              type={ type }
            />
        );
    };
}

const {
    renderSearchItem
} = new SearchOverlayComponent();

export default {
    'Component/SearchOverlay/Component': {
        'member-function': {
            renderSearchItem
        }
    }
};

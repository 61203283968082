/**
 * Add missing onSignIn prop
 */
export class CheckoutGuestFormContainer {
    containerProps = (args, callback, instance) => {
        const { onSignIn } = instance.props;

        return {
            ...callback(args),
            onSignIn
        };
    };
}

const {
    containerProps
} = new CheckoutGuestFormContainer();

export default {
    'Component/CheckoutGuestForm/Container': {
        'member-function': {
            containerProps
        }
    }
};

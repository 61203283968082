import { formatPrice } from 'Util/Price';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(state),
        calculatedPrice: state.ProductReducer?.surfacePriceCalculation || null
    };
};

export class ProductPriceContainer {
    containerProps = (args, callback, instance) => {
        const { calculatedPrice } = instance.props;
        const { priceCurrency, formattedFinalPrice, ...otherProps } = callback(...args);

        return {
            ...otherProps,
            priceCurrency,
            formattedFinalPrice: calculatedPrice !== null
                ? formatPrice(calculatedPrice, priceCurrency)
                : formattedFinalPrice
        };
    };
}

const {
    containerProps
} = new ProductPriceContainer();

export default {
    'Component/ProductPrice/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/ProductPrice/Container': {
        'member-function': {
            containerProps
        }
    }
};
